import React from 'react';
import {Switch, Redirect, useHistory, useLocation} from 'react-router-dom';
import {LOCAL_STORAGE_KEYS, LOCALE_ROUTE_PARAMS, LOCALES_ENABLED, ORG_TYPE} from "./utils/constants";

import CommonRoutes from "./routes/common.routes";
import PartnerRoutes from "./routes/partner.routes";
import SchoolRoutes from "./routes/school.routes";
import CompanyRoutes from "./routes/company.routes";
import {NotFound as NotFoundPage} from "./pages";
import {Minimal as MinimalLayout} from "./layouts";
import {RouteWithLayout} from "./components";
import {urlRewriteUtil} from "./components/Wrapper/common.wrapper";

const getOrgTypeRoutes = (org_type) => {
    switch(org_type) {
        case ORG_TYPE.DEFAULT+'':
            return PartnerRoutes;
        case ORG_TYPE.SCHOOL+'':
            return SchoolRoutes;
        case ORG_TYPE.COMPANY+'':
            return CompanyRoutes;
        default:
            return []
    }
}

const Routes = () => {

    const org_type = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type);

    const history = useHistory();
    const location = useLocation();
    urlRewriteUtil(location, history);

    return (
        <Switch>

            <Redirect
                exact
                from={`/:lc${LOCALE_ROUTE_PARAMS}`}
                to={`/:lc/login`}
            />

            {getOrgTypeRoutes(org_type)}
            {CommonRoutes}

            <RouteWithLayout
                component={NotFoundPage}
                exact={false}
                layout={MinimalLayout}
                path={`/:lc${LOCALE_ROUTE_PARAMS}/:path`}
            />

        </Switch>
    );
};

export default Routes;
