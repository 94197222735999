import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { useAuth } from '../../../context/auth';
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import SearchBar from "./Search.comp";

import {makeStyles, withStyles} from "@material-ui/core/styles";

import {
    Avatar as MuiAvatar,
    Box, Button, Card,
    Chip as MuiChip,
    Divider as MuiDivider,
    IconButton,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel, Tooltip,
    Typography,
} from "@material-ui/core";

import { green, orange } from "@material-ui/core/colors";

import {
    Archive as ArchiveIcon,
    RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {toastWarning} from "../../../utils/utils";
import {getEmployeesList} from "../../../services/employees.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GRADE_LABELS} from "../../../utils/constants";
import MouseOverPopoverComp from "./MouseOverPopover.comp";
import {Search as SearchIcon} from "react-feather";
import EnhancedTableCell from "./EnhancedTableCell.comp";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 150px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const useStyles = makeStyles((theme) => ({
    paper_style_for_table: {
        display: "block",
        overflowX: "auto",
        whiteSpace: "nowrap"
    },

    d_act_btn: {
        marginTop: '0px',
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
}));

const headCells = [
    { id: "username", alignment: "left", label: "Username", sort: true },
    { id: "email", alignment: "left", label: "Email", sort: true },
    { id: "last_name", alignment: "left", label: "Last Name", sort: true },
    { id: "first_name", alignment: "left", label: "First Name", sort: true },
    { id: "gender", alignment: "left", label: "Gender", sort: true },
    { id: "category_1", alignment: "left",  label:"CATEGORY_2_LABEL", sort: true },
    { id: "category_2", alignment: "left", label: "CATEGORY_1_LABEL", sort: true },
    { id: "completion_rate", alignment: "left", label: "Completion Rate", sort: true },
    { id: "dq_avg_score", alignment: "left", label: "DQ Score", sort: true },
    { id: "grade", alignment: "left", label: "Grade", sort: false },
];

const companyLabels = {
    category_1: "Department",
    category_2: "Job Title",
};

const schoolLabels = {
    category_1: "Class Name",
    category_2: "Grade Level",
};
function ListEmployees({ listOfType }) {

    const classes = useStyles();

    const labels = listOfType === 'Employee' ? companyLabels : schoolLabels;
    const { authUser } = useAuth();
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState(headCells[0].id);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [searchString, setSearchString] = useState('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dataList, setDataList] = React.useState([]);
    const [recordCount, setRecordCount] = React.useState(0);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect( () => {
        let refresh = false;
        if(firstLoad) {
            refresh = true;
            setFirstLoad(false);
        }
        loadEmployeesList(refresh).then(d => console.log('employees list data loaded!', d));
    }, [page, rowsPerPage, order, orderBy, searchString]);

    const loadEmployeesList = async (refresh) => {
        if(refresh) {
            setLoading(true);
        }
        try {
            const result = await getEmployeesList(authUser.org_code, page, rowsPerPage, order, orderBy, searchString);
            if (result.data && result.data.all_employees) {
                console.log(result.data.all_employees)
                let profiles = [];
                for (let item of result.data.all_employees) {
                    item.identifier = '';
                    for (let i=1; i<item._id.length; i+=2) {
                        item.identifier += item._id[i];
                    }
                    item.name = `${item.first_name} ${item.last_name}`
                    profiles.push(item);
                }
                setDataList(profiles);
                setRecordCount(result.data.total_count);
            }
        } catch (e) {
            console.log('api::getEmployeesList -> Error ==> ', e);
            // error already toasted
        }

        setLoading(false);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    function capitalizeFirstLetter(string) {
        if(typeof string === "string")
        {
            return string.charAt(0).toUpperCase() + string.slice(1)
        }
        return string;
    }

    return (
        <div>
            <SearchBar placeholder={'Search by email'} callback={setSearchString} />
            <Card mb={6}>
                <Paper>
                    <TableContainer>
                        <Table size={"medium"}>
                            <TableHead>
                                <TableRow>
                                    {
                                        headCells.map((item) => (
                                            <EnhancedTableCell
                                                id={item.id}
                                                alignment={item.alignment}
                                                disablePadding={item.disablePadding}
                                                sort={item.sort}
                                                orderBy={orderBy}
                                                order={order}
                                                onRequestSort={handleRequestSort}
                                            >
                                                {/*{item.label}*/}
                                                {item.id in labels ? labels[item.id] : item.label}
                                            </EnhancedTableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    dataList.map((row, index) => {
                                        let grade;
                                        const counts = Object.keys(GRADE_LABELS).map((item) => Number(item));
                                        const goal = row.dq_avg_score === null ? 0 : row.dq_avg_score;
                                        const output = counts.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
                                        grade = GRADE_LABELS[output.toString()]
                                        return (
                                            <CustomTableRow
                                                hover
                                                key={`${row._id}-${index}`}
                                            >
                                                <TableCell align="left">{row.username}</TableCell>
                                                <TableCell align="left">{row.email}</TableCell>
                                                <TableCell align="left">{row.last_name}</TableCell>
                                                <TableCell align="left">{row.first_name}</TableCell>
                                                <TableCell align="left">{row.gender}</TableCell>
                                                <TableCell align="left">{row.category_1}</TableCell>
                                                <TableCell align="left">{row.category_2}</TableCell>
                                                <TableCell align="left">{row.completion_rate} %</TableCell>
                                                <TableCell align="left">{goal}</TableCell>
                                                <TableCell align="left">{grade}</TableCell>
                                            </CustomTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={recordCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={`${GET_APP_LOCALIZE_TEXT(pageLc, 'employees__rows_per_page')}`}
                    />
                </Paper>
            </Card>
            <LoaderWithBackDrop loading={loading} />
        </div>
    );
}

export default ListEmployees;