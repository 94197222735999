import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography, CardContent, Box,
} from "@material-ui/core";

import { Tab } from "../../../../../components"


import {useAuth} from "../../../../../context/auth";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";


import CredibilityTab from "../../tabs/Credibility.tab";
import ComprehensiveTab from "../../tabs/Comprehensive.tab";
import AgeTab from "../../tabs/Age.tab";
import {spacing} from "@material-ui/system";

const Typography = styled(MuiTypography)(spacing);

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

function Assessment() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);
    const [currentTab, setCurrentTab] = useState(0);

    const handleChange = (value) => {
        setCurrentTab(value)
    };

    const TabsComponents = [
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__comprehensive"),
            tag: <ComprehensiveTab key={0}/>,
            footer: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__align_analysis"),
            footer_link: "/partner/enhancement/content"
        },
        // {
        //     title: "Credibility",
        //     tag: <CredibilityTab/>,
        // },
        // {
        //     title: "Appropriateness",
        //     tag: <AgeTab/>,
        //     footer: "Section 3.1. Appropriateness",
        //     footer_link: "/partner/enhancement/content",
        // },
    ]

    return (
        <React.Fragment>
            <Helmet title={`${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__content")}`}/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__section_2")}: {GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")}
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__assessment_desc")}
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" custom_color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`2.1 ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__content")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")}: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__content_desc")}
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
                <Tab
                    titles={TabsComponents.map((component) => component.title)}
                    onChange={handleChange}
                >
                {TabsComponents.map((component) => component.tag)}
                </Tab>
            </Box>
            <Box mx={"3vw"} my={"2vw"}>
                <ColouredTypography variant={"body1"} size={"16px"} align={"center"}>
                    {`${GET_APP_LOCALIZE_TEXT(pageLc, "assessment__footer")} `}
                    <a href={TabsComponents[currentTab].footer_link} style={{color: "#25b7db"}}>
                        {TabsComponents[currentTab].footer}
                    </a>.
                </ColouredTypography>
            </Box>
            <LoaderWithBackDrop loading={loading} />
        </React.Fragment>
    );
}

export default Assessment;
