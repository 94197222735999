import React from "react";
import {useLocation} from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";

import orange from "@material-ui/core/colors/orange";
import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Bar } from "react-chartjs-2";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  padding: 20px;
  height: 300px;
  width: 100%;
`;

function BarChart({theme, labels, values, color= false, threshold= undefined, header = "", titleX = undefined, titleY = undefined}) {

  const location = useLocation();
  const pageLc = location.pathname.split('/')[1];

  const colors = []

  values.forEach((value) => {
    if(color && value < ( threshold ? threshold : (Math.max(...values)/2) ))
      colors.push("#ff69b4")
    else
      colors.push("#25b7db")
  })

  const data = {
    labels: labels.map((label) => label.split(" ")),
  // #FF1B0A
    datasets: [
      {
        label: '',
        backgroundColor: colors,
        borderColor: colors,
        hoverBackgroundColor: colors,
        hoverBorderColor: colors,
        data: values,
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: !!titleY,
            labelString: titleY,
          },
          gridLines: {
            display: true,
          },
          stacked: false,
          ticks: {
            beginAtZero: true
          },
        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: !!titleX,
            labelString: titleX,
          },
          stacked: false,
          gridLines: {
            color: "transparent",
          },
          ticks: {
            maxRotation: 0,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: function(tooltipItem) {
          return tooltipItem[0].label.split(",").join(" ");
        },
      }
    },
  };

  return (
      <>
        <ChartWrapper>
          <Typography variant="h4" gutterBottom align={'center'}>
            {header}
          </Typography>
          <Bar data={data} options={options}/>
        </ChartWrapper>
      </>

  );
}

export default withTheme(BarChart);
