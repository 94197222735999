import styled from "styled-components/macro";
import {InputBase} from "@material-ui/core";
import {Search as SearchIcon} from "react-feather";
import React from "react";

const SearchComp = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const Search = styled.div`
  border-style: solid;
  border-radius: 25px;
  border-width: medium;
  border-color: ${(props) => props.theme.sidebar.header.background};
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  margin-left: auto;
  margin-bottom: 10px;
  width: 20%;
  &:hover {
    background-color: ${(props) => props.theme.header.background};
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchBar = (props) => {
  const {placeholder = '', callback = () => {}} = props;
  return <Search>
    <SearchComp>
      <SearchIcon />
    </SearchComp>
    <Input placeholder={placeholder} onChange={(event) => {callback(event.target.value)}}/>
  </Search>
}

export default SearchBar;

