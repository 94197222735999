import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet";

import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography, CardContent, Box
} from "@material-ui/core";

import { Tab } from "../../components"

import { spacing } from "@material-ui/system";

import {useAuth} from "../../context/auth";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

import Dq24Tab from "./components/Dq24.tab";
import ProgramOverviewDefaultTab from "./components/ProgramOverview.default.tab";
import ProgramOverviewSchoolTab from "./components/ProgramOverview.school.tab";
import AboutReportTab from "./components/AboutReport.tab";
import DqImpactRatingTab from "./components/DqImpactRating.tab";
import {Home} from "../index";
import createTheme from "../../theme";

import {
    StylesProvider,
    ThemeProvider as MuiThemeProvider,
    jssPreset,
} from "@material-ui/core/styles";

import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../../utils/constants";
import {showDQImpactRating} from "../../utils/utils";
import DqSealModulesTab from "./components/DqSealModules.tab";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

//if a tab does not contain for_org_types field, it is included in all org_types
const tabs = [
    {
        title: "overview__dq_impact_rating",
        tag: <DqImpactRatingTab/>,
        for_org_types: [ORG_TYPE.DEFAULT],
    },
    {
        title: "navigation__program_overview",
        tag: <ProgramOverviewDefaultTab/>,
        for_org_types: [ORG_TYPE.DEFAULT],
    },
    {
        title: "navigation__program_overview",
        tag: <ProgramOverviewSchoolTab/>,
        for_org_types: [ORG_TYPE.SCHOOL, ORG_TYPE.COMPANY],
    },
    {
        title: "overview__mod_dq24",
        tag: <Dq24Tab/>,
        for_org_types: [ORG_TYPE.DEFAULT]
    },
    {
        title: "overview__mod_dq_seal",
        tag: <DqSealModulesTab/>,
        for_org_types: [ORG_TYPE.DEFAULT]
    },
    {
        title: "overview__about_report",
        tag: <AboutReportTab/>,
        for_org_types: [ORG_TYPE.DEFAULT]
    },
]

function Overview() {

    const { authUser } = useAuth();
    const isSealModule = authUser.org_code.slice(-5) === "_seal";

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);

    const org_type = parseInt(localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type));

    const filteredTabs = tabs.filter((tab) => {
        if (tab.title === "overview__dq_impact_rating" && !showDQImpactRating()) {
            return false;
        }
        if (tab.title === "overview__mod_dq_seal") {
            return isSealModule;
        }
        if (tab.title === "overview__mod_dq24" && isSealModule) {
            return false;
        }
        return !tab.for_org_types || tab.for_org_types.includes(org_type)
    })

    useEffect( () => {

    }, []);

    return (
        <React.Fragment>
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__program_overview")}`}/>
            <Box mx={"3vw"}>
                <Tab
                    titles = {filteredTabs.map((tab) => GET_APP_LOCALIZE_TEXT(pageLc, tab.title))}>
                    {filteredTabs.map((tab) => tab.tag)}
                </Tab>
            </Box>
            <LoaderWithBackDrop loading={loading} />

        </React.Fragment>
    );
}

export default Overview;
