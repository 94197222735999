import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const getAssessmentAdoptionv2 = async (org_code, country, states, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/assessment/adoption',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country,
        states,
        date_range
    };
    return invokeApi(requestObj);
}

export const getAssessmentEfficacyv2 = async (org_code, skill, country, states, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/assessment/efficacy',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        skill,
        country,
        states,
        date_range
    };
    return invokeApi(requestObj);
}