import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import styled from "styled-components/macro";

import {
    Card as MuiCard,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { withStyles } from "@material-ui/core/styles";
import {useAuth} from "../../../context/auth";
import {AreaUIConfServices} from "../../../services/organization.service";

import { SketchPicker } from 'react-color'
import UploadImageDialogue from "../components/uploadImageDialogue.comp";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  {
    background-color: rgba(35, 48, 68, 0.10);
  }
`;


const useStyles = makeStyles((theme)=>({
    colorCube: {
        height: 40,
        width: 60,
        border: '1px solid #000',
        cursor: "pointer"
    },
    imageDiv: {
        height: 60,
        marginTop: -10,
        cursor: 'pointer'
    },
    tr_act: {
        // background: '#eaeaea',
    },
    tr_d_act: {
        background: '#f8dede!important'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));



function AreasListView() {

    const classes = useStyles();

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);

    const [pageData, setPageData] = useState([]);
    const [updateIconOfItem, setUpdateIconOfItem] = useState(-1);
    const [itemSelected, setItemSelected] = useState(-1);
    const [columnSelected, selectColumnSelected] = useState('');

    useEffect( () => {
        loadPageData().then(d => console.log('table data loaded!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);

        try {
            const result = await AreaUIConfServices.list(authUser.org_code);
            if (result.data && result.data.areas) {
                setPageData(result.data.areas);
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const clickedColorDiv = (index, colorColumn) => {
        if (index === itemSelected) {
            if (colorColumn === columnSelected) {
                setItemSelected(-1);
            } else {
                selectColumnSelected(colorColumn);
            }
        } else {
            setItemSelected(index);
            selectColumnSelected(colorColumn);
        }
    }

    const finishColorChange = (color, index, column) => {
        pageData[index][column] = color.hex;
        setPageData([...pageData]);
    }

    const saveColor = async (index) => {
        setLoading(true);
        setItemSelected(-1);
        try {
            await AreaUIConfServices.update(pageData[index]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const saveUpdateIcon = async (iconURL) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[updateIconOfItem].icon_url = iconURL;
        try {
            await AreaUIConfServices.update(data_copy[updateIconOfItem]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setUpdateIconOfItem(-1);
        setLoading(false);
    }

    const activateItem = async (index) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[index].is_active = true;
        try {
            await AreaUIConfServices.update(data_copy[index]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const disableItem = async (index) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[index].is_active = false;
        try {
            await AreaUIConfServices.update(data_copy[index]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const renderTD = (item, index, column) => {
        return <CustomTableCell align="left">
            <div className={classes.colorCube}
                 style={{background: item[column]}}
                 onClick={() => clickedColorDiv(index, column)}
            />
            {itemSelected === index && columnSelected === column && <div><SketchPicker
                color={item[column]}
                onChangeComplete={ color => finishColorChange(color, index, column) }
            />
                <button className={'level3-uic-color-picker-btn'}
                        onClick={()=>{saveColor(index)}}>Save</button>
            </div>}
        </CustomTableCell>
    }

    const renderURL = (url) => {
        if (url.substring(0,8) === 'https://') {
            return url
        }
        if (url.substring(0,7) === 'http://') {
            return url
        }
        return `${process.env.REACT_APP_MYDQ_API}${url}`
    }

    return (
        <Card mb={6}>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>Sr.#</CustomTableCell>
                            <CustomTableCell align="center">code</CustomTableCell>
                            <CustomTableCell align="center">Icon</CustomTableCell>
                            <CustomTableCell align="left">Theme Color</CustomTableCell>
                            <CustomTableCell align="left">Badge Background</CustomTableCell>
                            <CustomTableCell align="left">Lock Background</CustomTableCell>
                            <CustomTableCell align="left">Lock Text Color</CustomTableCell>
                            <CustomTableCell align="left">Primary Color</CustomTableCell>
                            <CustomTableCell align="left">Primary Color Locked</CustomTableCell>
                            <CustomTableCell align="left">Secondary Color</CustomTableCell>
                            <CustomTableCell align="left">Secondary Color Locked</CustomTableCell>
                            <CustomTableCell align="center">Actions</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pageData.map( (item, index) => (
                                <CustomTableRow key={index} className={item.is_active ? classes.tr_act : classes.tr_d_act}>
                                    <CustomTableCell align="center">{index+1}</CustomTableCell>
                                    <CustomTableCell align="center">{item.area_code}</CustomTableCell>
                                    <CustomTableCell align="center">
                                        <img className={classes.imageDiv} src={renderURL(item.icon_url)}
                                             onClick={()=>{setUpdateIconOfItem(index)}}
                                        />
                                    </CustomTableCell>
                                    {
                                        [
                                            renderTD(item, index, 'theme_color'),
                                            renderTD(item, index, 'badge_background'),
                                            renderTD(item, index, 'lock_background'),
                                            renderTD(item, index, 'lock_text_color'),
                                            renderTD(item, index, 'primary_color'),
                                            renderTD(item, index, 'primary_color_locked'),
                                            renderTD(item, index, 'secondary_color'),
                                            renderTD(item, index, 'secondary_color_locked'),
                                        ]
                                    }
                                    {
                                        item.is_active ?
                                            <CustomTableCell align="center"><Button onClick={()=>disableItem(index)}
                                                        className={classes.d_act_btn}>Disable</Button></CustomTableCell> :
                                            <CustomTableCell align="center"><Button onClick={()=>activateItem(index)}
                                                        className={classes.act_btn}>Activate</Button></CustomTableCell>
                                    }
                                </CustomTableRow>
                            ))
                        }
                        {/*{rows.map((row) => (*/}
                        {/*    <CustomTableRow key={row.id}>*/}
                        {/*        <CustomTableCell component="th" scope="row">*/}
                        {/*            {row.name}*/}
                        {/*        </CustomTableCell>*/}
                        {/*        <CustomTableCell align="right">{row.calories}</CustomTableCell>*/}
                        {/*        <CustomTableCell align="right">{row.fat}</CustomTableCell>*/}
                        {/*        <CustomTableCell align="right">{row.carbs}</CustomTableCell>*/}
                        {/*        <CustomTableCell align="right">{row.protein}</CustomTableCell>*/}
                        {/*    </CustomTableRow>*/}
                        {/*))}*/}
                    </TableBody>
                </Table>

                <LoaderWithBackDrop loading={loading} />

                <UploadImageDialogue
                    openDialogue={updateIconOfItem !== -1}
                    dialogueCloseHandler={()=>{setUpdateIconOfItem(-1)}}
                    successCB={saveUpdateIcon}
                />

            </Paper>
        </Card>
    );
}

export default AreasListView;