import React, {useEffect, useState} from "react";

import {Paper, Box, Grid, Card, CardContent, Typography, Spacer,} from "../../../../components/StyledComponents/styledComponents"
import {useAuth} from "../../../../context/auth";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import ProgressTable from "../components/ProgressTable";
import {Helmet} from "react-helmet";
import {getSchools} from "../../../../services/school.service";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";

function Progress() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const {org_code} = authUser;

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [graphData, setGraphData] = useState(undefined);

    useEffect( () => {
        loadDataList().then(d => console.log('', d));
    }, []);

    const loadDataList = async (page = 0, rowsPerPage = 10, order = "asc", orderBy = undefined) => {
        setLoading(true);
        try {
            const result = await getSchools(org_code, page, rowsPerPage, order, orderBy)
            if (result.data) {
                console.log('Result Data of Progress Page --> ', result.data);
                setGraphData(result.data);
            }
            else {
                setGraphData(undefined);
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching progress data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    return (
        <Paper container>
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__progress")} | ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__schools")}`}/>
            <Grid container alignItems={'stretch'} spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    {
                        graphData ?
                            <Paper container >
                                <ProgressTable
                                    org_code={org_code}
                                    data={graphData}
                                    fetchSortedData={loadDataList}
                                />
                            </Paper>
                            :
                            <Typography primary variant={"h3"} align={'center'} py={5}>{errorMessage}</Typography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default Progress;