import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import Helmet from "react-helmet";

import {
    Grid,
    Divider as MuiDivider,
    Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../../utils/constants";
import ListSchools from "./components/ListSchools";


const Divider = styled(MuiDivider)(spacing);


function Classes() {
    const [listOfType, setListOfType] = useState('Employee');

    useEffect( ()=> {
        const org_type = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type);
        if (org_type+'' === ORG_TYPE.SCHOOL+'') {
            setListOfType('Student');
        }
    }, [] )

    return (
        <React.Fragment>
            <Helmet title={`DQ-PRO | ${listOfType}s`} />
            <Typography variant="h3" gutterBottom display="inline">
                Schools
            </Typography>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <ListSchools />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Classes;
