import React, {useEffect, useMemo, useState} from "react";
import {Paper, Spacer, Typography, Grid, Button} from "../../../components/StyledComponents/styledComponents";
import styled, {css} from "styled-components";
import {Helmet} from "react-helmet";
import {darken} from "polished";
import Modal from "./components/Modal";

import IntroToDQWorld from "./components/IntroToDQWorld";
import Stepper from "./components/Stepper";
import ParentalConsent from "./components/ParentalConsent";
import TeachersMaterials from "./components/TeachersMaterials";
import SkillPacks from "./components/SkillPacks";
import Skill from "./components/Skill";
import {pages} from "./data/skills";
import DQWorldCurriculum from "./components/DQWorldCurriculum";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";

const ResourceButton = styled(Button)`
  font-size: 16px;
  width: 150px;
  height: 150px;
  border-radius: 20px;
  ${
      props => props.background_color && css`background-color: ${props.background_color};`
  };
  ${
      props => props.background_color && css`&:hover {background-color: ${darken(0.1, props.background_color)}};`
  };
`

const redirectToLink = (link) => {
    window.open(link, "_blank")
}

const downloadDocument = (link) => {
    //TODO implementation
}

const colors = [
    "#95b5fb",
    "#bcd4a4",
    "#f9e1aa",
    "#93a4b0",
    "#908c87",
]

const ACTION_TYPES = {
    OPEN_MODAL: 0,
    REDIRECT: 1,
    DOWNLOAD: 2
}

const resourcesLC = (locale) => [
    {
        list_name: GET_APP_LOCALIZE_TEXT(locale, "resources__dq_world"),
        items: [
            {
                title: GET_APP_LOCALIZE_TEXT(locale, "resources__intro_to_dqw"),
                action: ACTION_TYPES.OPEN_MODAL,
                component: <Stepper>
                    <IntroToDQWorld/>
                    <DQWorldCurriculum/>
                </Stepper>
            },
            {
                title: GET_APP_LOCALIZE_TEXT(locale, "resources__parent_consent_letter"),
                action: ACTION_TYPES.OPEN_MODAL,
                component: <ParentalConsent/>
            },
            {
                title: GET_APP_LOCALIZE_TEXT(locale, "resources__skill_packs"),
                action: ACTION_TYPES.OPEN_MODAL,
                component: <Stepper hideStepsIndicator={true}>
                    <SkillPacks/>
                    {pages.map((skill) => <Skill {...skill}/>)}
                </Stepper>
            },
            {
                title: GET_APP_LOCALIZE_TEXT(locale, "resources__teacher_mats"),
                action: ACTION_TYPES.OPEN_MODAL,
                component: <TeachersMaterials/>
            },
            {
                title: GET_APP_LOCALIZE_TEXT(locale, "resources__goto_dqw_student"),
                action: ACTION_TYPES.REDIRECT,
                link: `https://dqassessment.com/${locale}/login`,
            },
        ],
    },
]

const Resources = () => {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [openModal, setOpenModal] = useState(false);
    const [activeComponent, setActiveComponent] = useState(null);

    const RESOURCES = useMemo(() => resourcesLC(pageLc), [])

    return <Paper p={20}>
            <Modal open={openModal} handleClose={() => setOpenModal(false)}>
                {activeComponent}
            </Modal>
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "resources__title")}`}/>
            {
                RESOURCES.map((resource, index) =>
                    <Spacer key={`resource_grid_${index}`} my={10}>
                        <Typography font_weight={"bold"} font_color={"black"} font_size={'16px'}>
                            {resource.list_name}
                        </Typography>
                        <Spacer my={5}/>
                        <Grid container spacing={2} justify='space-around'>
                            {
                                resource.items.map((item, sub_index) =>
                                    <Grid key={`resource_button_${index}_${sub_index}`} item xs={'auto'} >
                                        <ResourceButton variant={"contained"} background_color={colors[index]}
                                            onClick={() => {
                                                switch(item.action) {
                                                    case ACTION_TYPES.OPEN_MODAL:
                                                        setActiveComponent(item.component);
                                                        setOpenModal(true);
                                                        break
                                                    case ACTION_TYPES.REDIRECT:
                                                        redirectToLink(item.link);
                                                        break;
                                                    case ACTION_TYPES.DOWNLOAD:
                                                        downloadDocument(item.link);
                                                        break;
                                                }
                                            }}
                                        >
                                            {
                                                item.title
                                            }
                                        </ResourceButton>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Spacer>
                )
            }
    </Paper>
}

export default Resources;