import React from 'react';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {TableCell, TableSortLabel, Tooltip, Typography} from "@material-ui/core";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableSortLabel = withStyles({
    root: {
        color: 'white',
        '&:hover': {
            color: 'lightgrey',
            // '&& $icon': {
            //     opacity: 0.75,
            //     color: 'lightgrey',
            // },
        },
        '&& $icon': {
            opacity: 0.25,
            color: 'white',
        },
        '&.MuiTableSortLabel-active': {
            opacity: 1,
            color: 'white',
            '&:hover': {
                color: 'lightgrey',
            },
            '&& $icon': {
                color: 'white',
            },
        },
    },
    icon: {}

})(TableSortLabel);

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableCell = (props) => {

    const {id, alignment, disablePadding, sort, orderBy, order, onRequestSort, children} = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <CustomTableCell
            key={id}
            align={alignment}
            padding={disablePadding ? "none" : "default"}
            sortDirection={orderBy === id ? order : false}
        >
            {
                sort ?
                    <LightTooltip title={`Click to sort`} placement="top-start">
                        <CustomTableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : "asc"}
                            onClick={createSortHandler(id)}
                        >
                            {children}
                        </CustomTableSortLabel>
                    </LightTooltip>
                    : <Typography>{children}</Typography>
            }
        </CustomTableCell>
    );
}


export default EnhancedTableCell;