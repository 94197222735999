import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../context/auth"
import {getProgramOverviewData} from "../../../services/partner.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const GridContainer = styled(Grid)`
    padding: 30px 20px 10px 20px;
`

const HeaderTypography = styled(Typography)`
    color: ${(props) => props.theme.sidebar.background};
`

const BodyTypography = styled(Typography)`
    color: grey;
    font-size: 14px;
`
const Greyul = styled.ul`
    color: grey;
`
const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>

function ProgramOverviewDefaultTab() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect( () => {
        loadPageData().then(d => console.log('Program Overview Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        //setLoading(true);
        try {
            const result = await getProgramOverviewData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Program Overview --> ', result.data);
                setPageData(result.data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching program overview data")
        }
        //setTimeout(() => {setLoading(false)}, 200);
    }


    return (
        pageData ?
        <Card p={10}>
            <CardContent>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={5}>
                        <GridContainer item xs={12} sm={6} md={6}>
                            { (pageData.org_name || pageData.org) && (
                                <>
                                <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__organisation")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                {
                                    [].concat(pageData.org_name !== "" ? pageData.org_name : pageData.org).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )
                                }
                            </Greyul>
                                </>
                            )}
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__program_name")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                { pageData.program_name ?
                                    [].concat(pageData.program_name).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    ) :
                                    [].concat(pageData.license).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )
                                }
                            </Greyul>
                            { pageData.program_description ? <>
                                <HeaderTypography variant="h6">
                                    <Bold>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__program_description")}:
                                    </Bold>
                                </HeaderTypography>
                                <Greyul>
                                    {[].concat(pageData.program_description).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )}
                                </Greyul>
                            </> : null }
                            { pageData.program_type ? <>
                                <HeaderTypography variant="h6">
                                    <Bold>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__program_type")}:
                                    </Bold>
                                </HeaderTypography>
                                <Greyul>
                                    {[].concat(pageData.program_type).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )}
                                </Greyul>
                            </> : null }
                            { pageData.contact_persons ? <>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__contact_persons")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                {[].concat(pageData.contact_persons).map((person) =>
                                    <li>
                                        <BodyTypography variant="h6">
                                            {`${person.name}, ${person.desig}`}
                                        </BodyTypography>
                                    </li>
                                )}
                            </Greyul>
                            </> : null }
                            { pageData.kpi_track ? <>
                                <HeaderTypography variant="h6">
                                    <Bold>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__kpi_track")}:
                                    </Bold>
                                </HeaderTypography>
                                <Greyul>
                                    {[].concat(pageData.kpi_track).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )}
                                </Greyul>
                            </> : null }
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__objectives")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                { pageData.program_aims ?
                                    [].concat(pageData.program_aims).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    ) :
                                    [].concat(pageData.objectives).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )
                                }
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__program_delivery")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                {[].concat(pageData.program_delivery).map((text) =>
                                    <li>
                                        <BodyTypography variant="h6">
                                            {text}
                                        </BodyTypography>
                                    </li>
                                )}
                            </Greyul>
                            { pageData.success ? <>
                                <HeaderTypography variant="h6">
                                    <Bold>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__success_definition")}:
                                    </Bold>
                                </HeaderTypography>
                                <Greyul>
                                    {[].concat(pageData.success).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )}
                                </Greyul>
                            </> : null }
                            { pageData.success_measure ? <>
                                <HeaderTypography variant="h6">
                                    <Bold>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__success_measure")}:
                                    </Bold>
                                </HeaderTypography>
                                <Greyul>
                                    {[].concat(pageData.success_measure).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )}
                                </Greyul>
                            </> : null }
                        </GridContainer>
                        {/*Second Column*/}
                        <GridContainer item xs={12} sm={6} md={6}>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__venue")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                { pageData.program_venue ?
                                    [].concat(pageData.program_venue).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    ) :
                                    [].concat(pageData.venues).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )
                                }
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__target_audiences")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                { pageData.program_target ?
                                    [].concat(pageData.program_target).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    ) :
                                    [].concat(pageData.target_audiences).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )
                                }
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__time_taken")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                { pageData.program_duration ?
                                    [].concat(pageData.program_duration).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    ) :
                                    [].concat(pageData.time_taken).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )
                                }
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__cost_of_use")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                { pageData.program_price ?
                                    [].concat(pageData.program_price).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    ) :
                                    [].concat(pageData.cost_of_use).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )
                                }
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__year_of_implement")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                { pageData.start_year ?
                                    [].concat(pageData.start_year).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    ) :
                                    [].concat(pageData.year_of_implement_and_status).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )
                                }
                            </Greyul>
                            <HeaderTypography variant="h6">
                                <Bold>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__ref_frameworks")}:
                                </Bold>
                            </HeaderTypography>
                            <Greyul>
                                { pageData.ref_framework ?
                                    [].concat(pageData.ref_framework).map((text) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {text}
                                            </BodyTypography>
                                        </li>
                                    ) :
                                    [].concat(pageData.frameworks).length === 0 ?
                                        <li>
                                            <BodyTypography variant="h6">
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__ref_frameworks_none")}
                                            </BodyTypography>
                                        </li> :
                                        [].concat(pageData.frameworks).map((text) =>
                                            <li>
                                                <BodyTypography variant="h6">
                                                    {text}
                                                </BodyTypography>
                                            </li>
                                        )
                                }
                            </Greyul>
                            { pageData.links ? <>
                                <HeaderTypography variant="h6">
                                    <Bold>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__reference_links")}:
                                    </Bold>
                                </HeaderTypography>
                                <Greyul>
                                    {[].concat(pageData.links).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )}
                                </Greyul>
                            </> : null }
                            { pageData.ref_resource ? <>
                                <HeaderTypography variant="h6">
                                    <Bold>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__ref_resource")}:
                                    </Bold>
                                </HeaderTypography>
                                <Greyul>
                                    {[].concat(pageData.ref_resource).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )}
                                </Greyul>
                            </> : null }
                            { pageData.target_country ? <>
                                <HeaderTypography variant="h6">
                                    <Bold>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__countries")}:
                                    </Bold>
                                </HeaderTypography>
                                <Greyul>
                                    {[].concat(pageData.target_country).map((item) =>
                                        <li>
                                            <BodyTypography variant="h6">
                                                {item}
                                            </BodyTypography>
                                        </li>
                                    )}
                                </Greyul>
                            </> : null }
                        </GridContainer>
                    </Grid>
                    <LoaderWithBackDrop loading={loading} />
                </Box>
            </CardContent>
        </Card>
        :
        <Paper container>
            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default ProgramOverviewDefaultTab;