import React from "react";
import {Redirect} from "react-router-dom";
import {PrivateRouteWithLayout} from "../components";
import {Main as MainLayout} from "../layouts";

import {
    BaseliningSkills as Company_Baselining_Skills,
    BaseliningCyber as Company_Baselining_Cyber,
} from "../pages/CompanyDashboard";
import {Employees as EmployeesPage} from "../pages";

export default [
        <Redirect
            exact
            from={`/:lc/home`}
            to={`/:lc/company/home`}
        />,

        <Redirect
            exact
            from={`/:lc/company`}
            to={`/:lc/company/home`}
        />,

        <Redirect
            exact
            from={`/:lc/company/home`}
            to={`/:lc/company/home/competencies`}
        />,

        <PrivateRouteWithLayout
            component={Company_Baselining_Skills}
            exact
            layout={MainLayout}
            path={`/:lc/company/home/competencies`}
        />,

        <PrivateRouteWithLayout
            component={Company_Baselining_Cyber}
            exact
            layout={MainLayout}
            path={`/:lc/company/home/cyber`}
        />,

        <PrivateRouteWithLayout
            component={EmployeesPage}
            exact
            layout={MainLayout}
            path={`/:lc/employees/progress`}
        />,

        <PrivateRouteWithLayout
            component={EmployeesPage}
            exact
            layout={MainLayout}
            path={`/:lc/employees/scores`}
        />,
]