import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components/macro";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
} from "@material-ui/core";

import TabChild from './header';
import {NavLink, Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root:{
        backgroundColor: "grey",
        color: theme.palette.secondary.contrastText,
        margin: "0px 1px 0px 1px",
        padding: "0px 0px 0px 0px",
        minWidth: "auto",
        minInlineSize: "auto",

        "&.Mui-selected": {
            backgroundColor: theme.sidebar.background,
            color: theme.palette.secondary.contrastText,

        }
    },

    indicator: {
        backgroundColor: "primary",
        height: "100%",
        opacity: "0%",
        width: "100%",
    },

    link: {

    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
    };
}


export default function CenteredTabs(props) {

    const {titles: titles, children: comps, value: selected_tab, links: links, onChange: parentHandleChange = undefined} = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(selected_tab ? selected_tab : 0);

    console.log("Links: ", links);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(parentHandleChange)
            parentHandleChange(newValue);
    };

    return <Box>
        <Tabs centered variant="fullWidth" scrollButtons="auto" value={value} onChange={handleChange} aria-label="styled tabs example" TabIndicatorProps={{ className: classes.indicator }} style={{paddingLeft: "30px", paddingRight: "30px"}}>
            {
                titles.map((title, index) =>
                    <Tab
                        key={index}
                        classes={{root: classes.root}}
                        label={
                            links ?
                                <Link style={{ textDecoration: 'none', color: "white", width: "100%", padding: "10px"}} to={links[index]}>
                                    <TabChild title={title}/>
                                </Link>
                            : <TabChild title={title}/>
                        }
                        {...a11yProps(index)}
                    />
                )
            }
        </Tabs>
        {comps.map((component, index) =>
            <TabPanel key={index} value={value} index={index}>
                {component}
            </TabPanel>
        )}
    </Box>;
}