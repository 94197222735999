import React from "react";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    container: {
      margin: '15px'
    },
    badge_image: {
        height: '90px'
    },
    short_name: {
        textAlign: 'left',
        marginTop: '-6px',
        marginLeft: '6px'
    },
    full_name: {
        textAlign: 'left',
        marginTop: '-12px',
        marginLeft: '6px'
    },
}));

const DqModuleBadge = ({short_name, full_name, logo}) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <img
                style={{
                    padding: '10px 4px',
                    background: '#d2e3f8'
                }}
                src={logo} className={classes.badge_image}></img>
            <h2 className={classes.short_name}>{short_name}</h2>
            <h4 className={classes.full_name}>{full_name}</h4>
        </div>
    )
}

export default DqModuleBadge;