import React from "react";
import {useLocation} from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";

import orange from "@material-ui/core/colors/orange";
import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Bar } from "react-chartjs-2";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  padding: 0px;
  height: 300px;
  width: 100%;
`;

const colors = [
    "#78a8dd",
    "#b9d7a7",
    "#5b5c5c",
]

function BarChart({theme, labels, datasets, header = "", legend = "true", line_values= []}) {

  const location = useLocation();
  const pageLc = location.pathname.split('/')[1];

  const data = {
    labels: labels,
    datasets: datasets.map((dataset, index) =>
      ({
        label: dataset.label,
        backgroundColor: colors[index],
        borderColor: colors[index],
        hoverBackgroundColor: colors[index],
        hoverBorderColor: colors[index],
        data: dataset.data,
        barPercentage: 1,
        categoryPercentage: 0.5,
        order: 1,
      })
    )
  };


  // const line_dataset = {
  //       type: "line",
  //       label: "Exposure",
  //       data: line_values,
  //       fill: false,
  //       borderColor: "#233044",
  //       lineTension: 0.1,
  //       borderWidth: 4,
  //       order: 0,
  //   };
  //
  // if(line_values.length > 0)
  //   data.datasets.push(line_dataset)

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: legend,
      position: 'bottom',
      labels: {
        fontColor: '#666',
        fontStyle: 'bold',
        boxWidth: 40,
        usePointStyle: false,
      }
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
          },
          // display: false,
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
          ticks: {
            maxRotation: 0,
          }
        },
      ],
    },
  };

  return (
      <>
        <ChartWrapper>
          <Typography variant="h4" gutterBottom align={'center'}>
            {header}
          </Typography>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </>


  );
}

export default withTheme(BarChart);
