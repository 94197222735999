import React from "react";
import {Redirect} from "react-router-dom";
import {PrivateRouteWithLayout} from "../components";
import {Main as MainLayout} from "../layouts";

import {
    BaseliningSkills as Partner_Baselining_Skills,
    BaseliningCyber as Partner_Baselining_Cyber,
    AssessmentContent as Partner_Assessment_Content,
    AssessmentAdoption as Partner_Assessment_Adoption,
    AssessmentEfficacy as Partner_Assessment_Efficacy,
    EnhancementContent as Partner_Enhancement_Content,
    EnhancementAdoption as Partner_Enhancement_Adoption,
    EnhancementEfficacy as Partner_Enhancement_Efficacy,
} from "../pages/PartnerDashboard";

export default [
    <Redirect
        exact
        from={`/:lc/home`}
        to={`/:lc/partner/home`}
    />,
    <Redirect
        exact
        from={`/:lc/partner/home`}
        to={`/:lc/partner/home/competencies`}
    />,
    <PrivateRouteWithLayout
        component={Partner_Baselining_Skills}
        exact
        layout={MainLayout}
        path={`/:lc/partner/home/competencies`}
    />,

    <PrivateRouteWithLayout
        component={Partner_Baselining_Cyber}
        exact
        layout={MainLayout}
        path={`/:lc/partner/home/cyber`}
    />,

    <Redirect
        exact
        from={`/:lc/partner/assessment`}
        to={`/:lc/partner/assessment/content`}
    />,

    <PrivateRouteWithLayout
        component={Partner_Assessment_Content}
        layout={MainLayout}
        exact
        path={`/:lc/partner/assessment/content`}
    />,

    <PrivateRouteWithLayout
        component={Partner_Assessment_Adoption}
        layout={MainLayout}
        exact
        path={`/:lc/partner/assessment/reach`}
    />,

    <PrivateRouteWithLayout
        component={Partner_Assessment_Efficacy}
        layout={MainLayout}
        exact
        path={`/:lc/partner/assessment/efficacy`}
    />,

    <Redirect
        exact
        from={`/:lc/partner/enhancement`}
        to={`/:lc/partner/enhancement/content`}
    />,

    <PrivateRouteWithLayout
        component={Partner_Enhancement_Content}
        layout={MainLayout}
        exact
        path={`/:lc/partner/enhancement/content`}
    />,

    <PrivateRouteWithLayout
        component={Partner_Enhancement_Adoption}
        layout={MainLayout}
        exact
        path={`/:lc/partner/enhancement/reach`}
    />,

    <PrivateRouteWithLayout
        component={Partner_Enhancement_Efficacy}
        layout={MainLayout}
        exact
        path={`/:lc/partner/enhancement/efficacy`}
    />,
]