import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid, Typography} from "../../../../components/StyledComponents/styledComponents";
import {GET_APP_LOCALIZE_LINK, GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme)=>({
    primary: {
        color: theme.palette.secondary.main,
        fontFamily: "sans-serif",
    },
    secondary: {
        color: "#004765",
        fontFamily: "sans-serif",
    },
    title: {
        fontWeight: "bold",
        fontSize: "32px"
    },
    description: {
        fontSize: "18px",
    },
    logo: {
        textAlign: "center",
        width: "256px",
    },
    video_container: {
        position: "relative",
        width: "100%",
        // overflow: "hidden",
        paddingTop: "56.25%" /* 16:9 Aspect Ratio */
    },
    video: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
        border: "none",
    },
    video_description: {
        fontSize: "18px",
        fontWeight: "bold"
    }
}));

function IntroToDQWorld(props) {

    const classes = useStyles();
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    return <Grid container alignItems={'center'} justify={'space-evenly'} direction={'column'} spacing={5}>
        <Grid item xs>
            <img className={classes.logo} src={'https://www.dqworld.net/static/307239050/img/zones/landing/dqworld.png'} alt={'DQ World Logo'}/>
        </Grid>
        <Grid item xs>
            <Typography className={`${classes.primary} ${classes.title}`} align={'center'}>
                {GET_APP_LOCALIZE_TEXT(pageLc, "resources__intro_to_dqw_full")}
            </Typography>
        </Grid>
        <Grid item xs>
            <Grid container justify={'space-evenly'} spacing={5}>
                <Grid item xs={12} md={6}>
                    <Typography className={`${classes.secondary} ${classes.description}`} variant={'body2'} paragraph={true} align={'justify'}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, "resources__watch_short_vid")}
                    </Typography>
                    <Typography className={`${classes.primary} ${classes.video_description}`}>
                        ({GET_APP_LOCALIZE_TEXT(pageLc, "resources__vid_time")} - 3:35)
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className={classes.video_container}>
                        <iframe
                            className={classes.video}
                            src={GET_APP_LOCALIZE_LINK(pageLc, "links__resource_dqw_intro_vid")}
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen="" title="DQ World Introduction Video" data-ready="true">
                        </iframe>
                    </div>
                </Grid>
            </Grid>
        </Grid>

    </Grid>
}

export default IntroToDQWorld;