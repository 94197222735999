import React from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider, Grid, LinearProgress as MuiLinearProgress,
    Typography as MuiTypography,
} from "@material-ui/core";

import DqModuleBadge from './DqModuleBadge.comp';

import { spacing } from "@material-ui/system";
import {toastWarning} from "../../../utils/utils";
import Button from "@material-ui/core/Button";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const LinearProgress = styled(MuiLinearProgress)`
  height: 14px;
  width: 180px;
  border-radius: 3px;
  background: ${(props) => props.theme.palette.grey[200]};
`;


const MicroBadgeInfoTable = ({ modulesData }) => {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    return (
        <React.Fragment>
            <Typography variant="h3" gutterBottom align={'center'}>
                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__dq_assessments')}`}
            </Typography>

            <Card mb={3}>
                <CardContent>
                    <Grid container spacing={6}>
                        <Grid item sm={2}>
                            <Typography variant="h4" mb={8} ml={6} align={'left'} fontWeight="fontWeightBold">
                                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__dq_modules')}`}
                            </Typography>
                        </Grid>
                        <Grid item sm={5}>
                            <Typography variant="h4" mb={8} ml={6} align={'left'} fontWeight="fontWeightBold">
                                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__dq_microbadges')}`}
                            </Typography>
                        </Grid>
                        <Grid item sm={2}>
                            <Typography variant="h4" mb={1} ml={4} align={'left'} fontWeight="fontWeightBold">
                                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__average_score')}`}
                            </Typography>
                            <Typography variant="h5" mb={4} ml={1} align={'left'} fontWeight="fontWeightBold">
                                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__company_national')}`}
                            </Typography>
                        </Grid>
                        <Grid item sm={2}>
                            <Typography variant="h4" mb={8} align={'left'} fontWeight="fontWeightBold">
                                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__completion_rate')}`}
                            </Typography>
                        </Grid>
                        <Grid item sm={1}>
                        </Grid>
                    </Grid>

                    <Divider style={{
                        height: '4px',
                        marginTop: '-25px',
                        marginBottom: '20px'
                    }} />

                    {
                        modulesData && modulesData.map( module => (
                            <Grid
                                style={{
                                    padding: '16px 0px'
                                }}
                                container spacing={6}>
                                <Grid item sm={2}>
                                    <DqModuleBadge
                                        short_name={module.area_code}
                                        full_name={module.full_name}
                                        logo={module.icon_url}
                                    />
                                </Grid>
                                <Grid item sm={5}>
                                    {
                                        module.badges.map( badge => (
                                            <Typography variant="h5" mb={5} align={'left'} >
                                                {badge.app_badge_name}
                                            </Typography>
                                        ))
                                    }
                                </Grid>
                                <Grid item sm={2}>
                                    {
                                        module.badges.map( badge => (
                                            <Typography variant="h5" ml={6} mb={5} align={'left'}>
                                                {badge.avg_score}
                                            </Typography>
                                        ))
                                    }

                                </Grid>
                                <Grid item sm={2}>
                                    {
                                        module.badges.map( badge => (
                                            <div style={{
                                                height: '30px',
                                                marginTop: '9px'
                                            }}>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={badge.certified}
                                                    color={'Secondary'}
                                                />
                                            </div>
                                        ))
                                    }
                                </Grid>
                                <Grid item sm={1}>
                                    {
                                        module.badges.map( badge => (
                                            <Typography variant="h5" mb={1} align={'center'}>
                                                <Button
                                                    variant="outlined" color="primary"
                                                    onClick={()=>{toastWarning('Not Implemented !!');}}
                                                >{`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__details')}`}</Button>
                                            </Typography>
                                        ))
                                    }
                                </Grid>


                            </Grid>
                        ))
                    }

                </CardContent>
            </Card>

        </React.Fragment>

    );
};

export default MicroBadgeInfoTable;
