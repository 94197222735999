import React, {useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import LensIcon from '@material-ui/icons/Lens';
import {darken} from "polished";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme)=>({
    navigator: {
        clipPath: "polygon(0 50%, 100% 100%, 100% 0)",
        backgroundColor: theme.palette.secondary.main,
        maxWidth: "50px",
        maxHeight: "100px",
        minWidth: "50px",
        minHeight: "100px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: darken("0.02", theme.palette.secondary.main)
        }
    },
    left_navigator: {
        textAlign: "left",
    },
    right_navigator: {
        textAlign: "right",
        rotate: "180deg",
    },
    indicator: {
        color: "lightgray",
    },
    active_indicator: {
        color: theme.palette.secondary.main,
    }
}));

function flatten(a) {
    return Array.isArray(a) ? [].concat(...a.map(flatten)) : a;
}

function Stepper(props) {

    const classes = useStyles();

    const {hideStepsIndicator = false, children} = props;
    const flatted_children = flatten(children);
    const totalSteps = Array.isArray(children) ? flatted_children.length : 1;

    const [currentStep, setCurrentStep] = useState(0);

    function onChangeStep(stepCount) {
        const newStep = currentStep + stepCount;
        if(newStep < 0) {
            setCurrentStep(totalSteps - 1)
        } else if(newStep > (totalSteps - 1)) {
            setCurrentStep(0);
        } else {
            setCurrentStep(newStep);
        }
    }

    const CurrentStepComponent = flatted_children[currentStep];

    return totalSteps === 1 ?
        children :
        <>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-around'} alignItems={'center'}>
                <Box className={`${classes.navigator} ${classes.left_navigator}`} justifySelf={'flex-start'} textAlign={'left'} onClick={() => onChangeStep(-1)}/>
                <Box mx={5}>
                    {React.cloneElement(CurrentStepComponent, {setStep: setCurrentStep})}
                </Box>
                <Box className={`${classes.navigator} ${classes.right_navigator}`} justifySelf={'flex-end'} textAlign={'right'} onClick={() => onChangeStep(1)}/>
            </Box>
            {
                !hideStepsIndicator &&
                <Box my={5} align={"center"}>
                    {
                        children.map((_, i) => i === currentStep ?
                            <LensIcon className={classes.active_indicator} fontSize={"large"} onClick={() => setCurrentStep(i)}/>
                            :
                            <LensIcon className={classes.indicator} fontSize={"large"} onClick={() => setCurrentStep(i)}/>
                        )
                    }
                </Box>
            }
        </>
}

export default Stepper;