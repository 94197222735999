import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box, Button, Grid, Typography} from "../../../../components/StyledComponents/styledComponents";
import {Link} from "@material-ui/core";
import {darken} from "polished";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme)=>({
    logo: {
        textAlign: "center",
        width: "256px",
    },
    title: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        fontFamily: "sans-serif",
        fontSize: "32px"
    },
    description: {
        color: "#004765",
        fontFamily: "sans-serif",
        fontSize: "24px",
    },
    link: {
        display: "block",
        textDecoration: "none",
        padding: "20px",
        margin: "10px",
        borderRadius: "5px",
        backgroundColor: theme.palette.secondary.main,
        fontSize: "24px",
        fontWeight: "bold",
        color: "#ffffff",
        '&:hover': {
            backgroundColor: darken(0.1, theme.palette.secondary.main),
        }
    }
}));

function TeachersMaterials(props) {

    const classes = useStyles();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    function LinkButton(props) {
        const {href, target, children, ...rest} = props;
        return <a
            className={classes.link}
            href={href}
            target={target}
            {...rest}
        >
            {children}
        </a>
    }

    return <Box align={'center'} my={5} mx={5}>
        <img className={classes.logo} src={'https://www.dqworld.net/static/307239050/img/zones/landing/dqworld.png'} alt={'DQ World Logo'}/>
        <Typography className={classes.title} align={'center'} my={5}>
            {GET_APP_LOCALIZE_TEXT(pageLc, "resources__teacher_mats")}
        </Typography>
        <Box display={"flex"} flexDirection={"row"}>
            <LinkButton href={`https://storage.googleapis.com/izhero-public/resources/${pageLc}/DQWorldIntro720.mkv`} target={"_blank"}>
                {GET_APP_LOCALIZE_TEXT(pageLc, "resources__dqw_intro_vid")}
            </LinkButton>
            <LinkButton href={`https://storage.googleapis.com/izhero-public/resources/${pageLc}/DQWORLDWorkbook.pdf`} target={"_blank"}>
                {GET_APP_LOCALIZE_TEXT(pageLc, "resources__student_workbook")}
            </LinkButton>
            <LinkButton href={`https://storage.googleapis.com/izhero-public/resources/${pageLc}/Final%20Quiz%20Questions%20and%20Answers.pdf`} target={"_blank"}>
                {GET_APP_LOCALIZE_TEXT(pageLc, "resources__quiz_ans_key")}
            </LinkButton>
            <LinkButton href={`https://storage.googleapis.com/izhero-public/resources/${pageLc}/DQWORLDWorkbook_Answers.pdf`} target={"_blank"}>
                {GET_APP_LOCALIZE_TEXT(pageLc, "resources__workbook_ans_key")}
            </LinkButton>

        </Box>
    </Box>
}

export default TeachersMaterials;