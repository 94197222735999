import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import { Helmet } from "react-helmet";

import { Tab } from "../../../../../components"

import {useAuth} from "../../../../../context/auth";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";


import EfficacyTab from "../../tabs/v3/GenericEfficacy.tab"
import styled from "styled-components/macro";
import {Box, Typography as MuiTypography} from "@material-ui/core";
import {spacing} from "@material-ui/system";
import {showImprovement} from "../../../../../services/school.service";

const Typography = styled(MuiTypography)(spacing);

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

function Efficacy() {


    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const TabsDetails = [
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__mod_score"),
            props: {
                skill: "Score",
                description: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__mod_score_desc"),
                header2: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__mod_score_complete")
            }
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__imp"),
            props: {
                skill: "Improvement",
                description: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__imp_desc"),
                // header1: "Competencies Improved",
                header2: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__imp_rate")
            }
        }
    ]

    function convertTabToComponent (tab) {
        return {
            title: tab.title,
            tag: <EfficacyTab title={tab.title} {...tab.props}/>
        }
    }

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);
    const [tabComponents, setTabComponents] = useState([convertTabToComponent(TabsDetails[0])]);

    async function loadAdditionalTabs() {
        setLoading(true);
        try {
            const result = await showImprovement(authUser.org_code);
            if(result.data && result.data.exists) {
                setTabComponents([...tabComponents, convertTabToComponent(TabsDetails[1])])
            }
        }
        catch (e) {
            console.warn(`Some error occurred while fetching show improvement => ${e}`)
        }
        setLoading(false);
    }

    useEffect( () => {
        //loadAdditionalTabs().then();
    }, []);

    return (
        <React.Fragment>
            <Helmet title={`${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__efficacy")}`}/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__section_2")}: {GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")}
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__assessment_desc")}
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`2.2 ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__efficacy")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__assessment")}: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__efficacy_desc")}
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
                <Tab
                    titles={tabComponents.map((component) => component.title)}
                >
                {tabComponents.map((component) => component.tag)}
                </Tab>
            </Box>
            <LoaderWithBackDrop loading={loading} />
        </React.Fragment>
    );
}

export default Efficacy;
