import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../context/auth"
import {getDqSealModules} from "../../../services/partner.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Table,
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {alphaNumericSort} from "../../../utils/utils";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;


const useStyles = makeStyles((theme)=>({
    tr_act: {

    },
    tr_d_act: {
        background: '#f8dede!important'
    },
    act_btn: {
        marginTop: '0px',
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        marginTop: '0px',
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '10px 0px'
    },
    deleteBtn: {
        marginLeft: '6px',
        marginTop: '6px!important',
        color: '#ee3434',
        cursor: 'pointer',
        '&:hover': {
            color: '#f55353',
        }
    }
}));


const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-right: ${(props) => props.theme.spacing(12)}px;
  
  &:first-child {
    padding-top: ${(props) => props.theme.spacing(12)}px;
  }
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(12)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>


function DqSealModulesTab(props) {

    const classes = useStyles();
    const { authUser } = useAuth();
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);

    useEffect( () => {
        loadPageData().then(d => console.log('DQ Modules Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getDqSealModules(authUser.org_code);
            if (result.data) {
                console.log('Result Data of DQ Modules --> ', result.data);
                setPageData(result.data.sort(alphaNumericSort("module_id", true)));
            }
        } catch (e) {
            console.log("Some error occurred while fetching dq modules")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    return (
        pageData ?
        <Card mb={3}>
            <CardContent>
                <ColouredTypography mb={10} variant="h6" color="grey" wrapped="true" font="14px">
                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__mod_dqseal_desc")}
                </ColouredTypography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell align="left">{GET_APP_LOCALIZE_TEXT(pageLc, "overview__mod_id")}</CustomTableCell>
                            <CustomTableCell align="left">{GET_APP_LOCALIZE_TEXT(pageLc, "overview__mod_name")}</CustomTableCell>
                            <CustomTableCell align="left">{GET_APP_LOCALIZE_TEXT(pageLc, "overview__mod_learn_objectives")}</CustomTableCell>
                            <CustomTableCell align="left">{GET_APP_LOCALIZE_TEXT(pageLc, "overview__mod_target_audience")}</CustomTableCell>
                            <CustomTableCell align="left">{GET_APP_LOCALIZE_TEXT(pageLc, "overview__mod_difficulty")}</CustomTableCell>
                            <CustomTableCell align="left">{GET_APP_LOCALIZE_TEXT(pageLc, "overview__mod_time_minutes")}</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pageData.map((item, index) => (
                                <CustomTableRow key={index}>
                                    <CustomTableCell component="th" scope="row">
                                        <Bold>{item.module_id}</Bold>
                                    </CustomTableCell>
                                    <CustomTableCell >
                                        <Bold>{item.module_name}</Bold>
                                    </CustomTableCell>
                                    <CustomTableCell>{item.learning_objective}</CustomTableCell>
                                    <CustomTableCell>{item.target_audience}</CustomTableCell>
                                    <CustomTableCell>{item.difficulty}</CustomTableCell>
                                    <CustomTableCell>{item.time_minutes}</CustomTableCell>
                                </CustomTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
                <LoaderWithBackDrop loading={loading} />
            </CardContent>
        </Card>
        :  <LoaderWithBackDrop loading={loading} />
    );
}



export default DqSealModulesTab;