import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {Copyright} from '../../../components';

import { store } from 'react-notifications-component';
import { withRouter } from "react-router";

import { useFormik } from 'formik';

import {registerSchool, registerUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import Helmet from "react-helmet";
import {FormControl, InputLabel, MenuItem} from "@material-ui/core";
import {toastWarning} from "../../../utils/utils";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const school_types = (locale) => [
    GET_APP_LOCALIZE_TEXT(locale, "auth__school_primary"),
    GET_APP_LOCALIZE_TEXT(locale, "auth__school_secondary"),
    GET_APP_LOCALIZE_TEXT(locale, "auth__school_high"),
    GET_APP_LOCALIZE_TEXT(locale, "auth__school_other")
];
const school_sizes = (locale) => [
    `<10 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`,
    `10-100 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`,
    `101-500 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`,
    `501-1000 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`,
    `>1000 ${GET_APP_LOCALIZE_TEXT(locale, "navigation__students")}`
];

const RegisterSchool = (props) => {
    const classes = useStyles();

    const { setAuthToken, setAuthUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const school_types_en = school_types("en");
    const school_types_locale = school_types(pageLc);

    const school_sizes_en = school_sizes("en");
    const school_sizes_locale = school_sizes(pageLc);

    const formik = useFormik({
        initialValues: {
            school_name: '',
            school_type: '',
            school_size: '',
            country: '',
            city: '',
            postal_code: '',
            owner_first_name: '',
            owner_last_name: '',
            owner_position: '',
            owner_email: '',
            password: '',
            confirm_password: ''
        },
        onSubmit: async (values) => {
            console.log('Form data => ', values);
            if (values.password !== values.confirm_password) {
                return store.addNotification({
                    title: GET_APP_LOCALIZE_TEXT(pageLc, "auth__err_pass_mismatch"),
                    message: GET_APP_LOCALIZE_TEXT(pageLc, "auth__provide_same_pass"),
                    type: "warning",
                    insert: "bottom",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 2000,
                        showIcon: true
                    }
                });
            }

            try {
                const result = await registerSchool(values);
                if (result.data && result.data.user) {
                    const c_user = result.data.user;
                    c_user.org_obj = result.data.org_object;
                    localStorage.setItem(LOCAL_STORAGE_KEYS.active_org_type, c_user.org_obj.type);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.active_org_code, c_user.org_obj.org_code);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.refresh_token, JSON.stringify(result.data.refresh_token))
                    await setAuthToken(result.data.auth_token);
                    await setAuthUser(c_user);
                    props.history.push('/');
                }
            } catch (e) {
                // already toasted the error
            }
        }
    });
    const Countries=[
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__afghanistan'), "value": "AF"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__aland_islands'), "value": "AX"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__albania'), "value": "AL"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__algeria'), "value": "DZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__american_samoa'), "value": "AS"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__andorra'), "value": "AD"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__angola'), "value": "AO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__anguilla'), "value": "AI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__antarctica'), "value": "AQ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__antigua_and_barbuda'), "value": "AG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__argentina'), "value": "AR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__armenia'), "value": "AM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__aruba'), "value": "AW"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__australia'), "value": "AU"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__austria'), "value": "AT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__azerbaijan'), "value": "AZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bahamas'), "value": "BS"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bahrain'), "value": "BH"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bangladesh'), "value": "BD"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__barbados'), "value": "BB"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__belarus'), "value": "BY"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__belgium'), "value": "BE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__belize'), "value": "BZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__benin'), "value": "BJ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bermuda'), "value": "BM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bhutan'), "value": "BT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bolivia'), "value": "BO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bosnia_and_herzegovina'), "value": "BA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__botswana'), "value": "BW"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bouvet_island'), "value": "BV"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__brazil'), "value": "BR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__british_indian_ocean_territory'), "value": "IO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__brunei_darussalam'), "value": "BN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__bulgaria'), "value": "BG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__burkina_faso'), "value": "BF"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__burundi'), "value": "BI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cambodia'), "value": "KH"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cameroon'), "value": "CM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__canada'), "value": "CA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cape_verde'), "value": "CV"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cayman_islands'), "value": "KY"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__central_african_republic'), "value": "CF"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__chad'), "value": "TD"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__chile'), "value": "CL"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__china'), "value": "CN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__christmas_island'), "value": "CX"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cocos_keeling_islands'), "value": "CC"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__colombia'), "value": "CO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__comoros'), "value": "KM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__congo'), "value": "CG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__congo_the_democratic_republic_of_the'), "value": "CD"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cook_islands'), "value": "CK"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__costa_rica'), "value": "CR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cote_divoire'), "value": "CI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__croatia'), "value": "HR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cuba'), "value": "CU"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__cyprus'), "value": "CY"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__czech_republic'), "value": "CZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__denmark'), "value": "DK"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__djibouti'), "value": "DJ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__dominica'), "value": "DM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__dominican_republic'), "value": "DO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ecuador'), "value": "EC"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__egypt'), "value": "EG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__el_salvador'), "value": "SV"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__equatorial_guinea'), "value": "GQ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__eritrea'), "value": "ER"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__estonia'), "value": "EE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ethiopia'), "value": "ET"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__falkland_islands_malvinas'), "value": "FK"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__faroe_islands'), "value": "FO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__fiji'), "value": "FJ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__finland'), "value": "FI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__france'), "value": "FR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__french_guiana'), "value": "GF"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__french_polynesia'), "value": "PF"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__french_southern_territories'), "value": "TF"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__gabon'), "value": "GA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__gambia'), "value": "GM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__georgia'), "value": "GE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__germany'), "value": "DE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ghana'), "value": "GH"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__gibraltar'), "value": "GI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__greece'), "value": "GR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__greenland'), "value": "GL"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__grenada'), "value": "GD"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guadeloupe'), "value": "GP"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guam'), "value": "GU"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guatemala'), "value": "GT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guernsey'), "value": "GG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guinea'), "value": "GN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guinea_bissau'), "value": "GW"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__guyana'), "value": "GY"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__haiti'), "value": "HT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__heard_island_and_mcdonald_islands'), "value": "HM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__holy_see_vatican_city_state'), "value": "VA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__honduras'), "value": "HN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__hong_kong'), "value": "HK"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__hungary'), "value": "HU"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__iceland'), "value": "IS"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__india'), "value": "IN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__indonesia'), "value": "ID"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__iran_islamic_republic_of'), "value": "IR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__iraq'), "value": "IQ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ireland'), "value": "IE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__isle_of_man'), "value": "IM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__israel'), "value": "IL"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__italy'), "value": "IT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__jamaica'), "value": "JM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__japan'), "value": "JP"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__jersey'), "value": "JE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__jordan'), "value": "JO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kazakhstan'), "value": "KZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kenya'), "value": "KE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kiribati'), "value": "KI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__korea_democratic_peoples_republic_of'), "value": "KP"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__korea_republic_of'), "value": "KR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kuwait'), "value": "KW"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__kyrgyzstan'), "value": "KG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__lao_peoples_democratic_republic'), "value": "LA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__latvia'), "value": "LV"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__lebanon'), "value": "LB"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__lesotho'), "value": "LS"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__liberia'), "value": "LR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__libyan_arab_jamahiriya'), "value": "LY"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__liechtenstein'), "value": "LI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__lithuania'), "value": "LT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__luxembourg'), "value": "LU"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__macao'), "value": "MO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__macedonia_the_former_yugoslav_republic_of'), "value": "MK"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__madagascar'), "value": "MG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__malawi'), "value": "MW"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__malaysia'), "value": "MY"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__maldives'), "value": "MV"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mali'), "value": "ML"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__malta'), "value": "MT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__marshall_islands'), "value": "MH"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__martinique'), "value": "MQ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mauritania'), "value": "MR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mauritius'), "value": "MU"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mayotte'), "value": "YT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mexico'), "value": "MX"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__micronesia_federated_states_of'), "value": "FM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__moldova_republic_of'), "value": "MD"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__monaco'), "value": "MC"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mongolia'), "value": "MN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__montserrat'), "value": "MS"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__morocco'), "value": "MA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__mozambique'), "value": "MZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__myanmar'), "value": "MM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__namibia'), "value": "NA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__nauru'), "value": "NR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__nepal'), "value": "NP"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__netherlands'), "value": "NL"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__netherlands_antilles'), "value": "AN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__new_caledonia'), "value": "NC"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__new_zealand'), "value": "NZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__nicaragua'), "value": "NI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__niger'), "value": "NE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__nigeria'), "value": "NG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__niue'), "value": "NU"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__norfolk_island'), "value": "NF"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__northern_mariana_islands'), "value": "MP"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__norway'), "value": "NO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__oman'), "value": "OM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__pakistan'), "value": "PK"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__palau'), "value": "PW"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__palestinian_territory_occupied'), "value": "PS"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__panama'), "value": "PA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__papua_new_guinea'), "value": "PG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__paraguay'), "value": "PY"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__peru'), "value": "PE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__philippines'), "value": "PH"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__pitcairn'), "value": "PN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__poland'), "value": "PL"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__portugal'), "value": "PT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__puerto_rico'), "value": "PR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__qatar'), "value": "QA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__reunion'), "value": "RE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__romania'), "value": "RO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__russian_federation'), "value": "RU"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__rwanda'), "value": "RW"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_helena'), "value": "SH"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_kitts_and_nevis'), "value": "KN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_lucia'), "value": "LC"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_pierre_and_miquelon'), "value": "PM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saint_vincent_and_the_grenadines'), "value": "VC"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__samoa'), "value": "WS"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__san_marino'), "value": "SM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sao_tome_and_principe'), "value": "ST"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__saudi_arabia'), "value": "SA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__senegal'), "value": "SN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__serbia_and_montenegro'), "value": "CS"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__seychelles'), "value": "SC"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sierra_leone'), "value": "SL"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__singapore'), "value": "SG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__slovakia'), "value": "SK"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__slovenia'), "value": "SI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__solomon_islands'), "value": "SB"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__somalia'), "value": "SO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__south_africa'), "value": "ZA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__south_georgia_and_the_south_sandwich_islands'), "value": "GS"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__spain'), "value": "ES"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sri_lanka'), "value": "LK"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sudan'), "value": "SD"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__suriname'), "value": "SR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__svalbard_and_jan_mayen'), "value": "SJ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__swaziland'), "value": "SZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__sweden'), "value": "SE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__switzerland'), "value": "CH"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__syrian_arab_republic'), "value": "SY"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__taiwan_province_of_china'), "value": "TW"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tajikistan'), "value": "TJ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tanzania_united_republic_of'), "value": "TZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__thailand'), "value": "TH"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__timor_leste'), "value": "TL"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__togo'), "value": "TG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tokelau'), "value": "TK"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tonga'), "value": "TO"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__trinidad_and_tobago'), "value": "TT"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tunisia'), "value": "TN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__turkey'), "value": "TR"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__turkmenistan'), "value": "TM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__turks_and_caicos_islands'), "value": "TC"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__tuvalu'), "value": "TV"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__uganda'), "value": "UG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__ukraine'), "value": "UA"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__united_arab_emirates'), "value": "AE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__united_kingdom'), "value": "GB"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__united_states'), "value": "US"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__united_states_minor_outlying_islands'), "value": "UM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__uruguay'), "value": "UY"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__uzbekistan'), "value": "UZ"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__vanuatu'), "value": "VU"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__venezuela'), "value": "VE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__viet_nam'), "value": "VN"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__virgin_islands_british'), "value": "VG"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__virgin_islands_us'), "value": "VI"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__wallis_and_futuna'), "value": "WF"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__western_sahara'), "value": "EH"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__yemen'), "value": "YE"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__zambia'), "value": "ZM"},
        {"label": GET_APP_LOCALIZE_TEXT(pageLc, 'dq_country__zimbabwe'), "value": "ZW"},
    ];
    const countryOptions = Object.values(Countries);
    return (
        <Container component="main" maxWidth="xs">
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "auth__reg_school")}`} />
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {GET_APP_LOCALIZE_TEXT(pageLc, "auth__reg_school")}
                </Typography>


                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="school_name"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "progress__school_name")}
                                name="school_name"
                                autoComplete="school_name"
                                onChange={formik.handleChange}
                                value={formik.values.school_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" m={2}>
                                <InputLabel

                                    id="demo-simple-select-outlined-label"
                                >{GET_APP_LOCALIZE_TEXT(pageLc, "auth__school_type")}</InputLabel>
                                <Select
                                    required
                                    fullWidth
                                    labelId="demo-simple-select-outlined-label"
                                    id="school_type"
                                    name="school_type"
                                    onChange={formik.handleChange}
                                    value={formik.values.school_type}
                                    label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__school_type")}
                                >
                                    { school_types_locale.map((item_type, index) => <MenuItem value={school_types_en[index]}>{item_type}</MenuItem>) }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" m={2}>
                                <InputLabel

                                    id="demo-simple-select-outlined-label"
                                >{GET_APP_LOCALIZE_TEXT(pageLc, "auth__school_size")}</InputLabel>
                                <Select
                                    required
                                    fullWidth
                                    labelId="demo-simple-select-outlined-label"
                                    id="school_size"
                                    name="school_size"
                                    onChange={formik.handleChange}
                                    value={formik.values.school_size}
                                    label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__school_size")}
                                >
                                    { school_sizes_locale.map((item_type, index) => <MenuItem value={school_sizes_en[index]}>{item_type}</MenuItem>) }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Autocomplete
                                fullWidth={true}
                                name={'country'}
                                id={'country'}
                                options={countryOptions}
                                getOptionLabel={(option) => option.label}
                                getOptionSelected={(option, value) => option.value === value.value}
                                onChange={(e, option) => formik.setFieldValue("country", option.value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth={true}
                                        variant="outlined"
                                        label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__country")}
                                        InputLabelProps={ { required: true }}
                                    />
                                )}
                            />
                            {/*<TextField*/}
                            {/*    variant="outlined"*/}
                            {/*    required*/}
                            {/*    fullWidth*/}
                            {/*    id="country"*/}
                            {/*    label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__country")}*/}
                            {/*    name="country"*/}
                            {/*    autoComplete="country"*/}
                            {/*    onChange={formik.handleChange}*/}
                            {/*    value={formik.values.country}*/}
                            {/*/>*/}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="city"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__city")}
                                name="city"
                                autoComplete="city"
                                onChange={formik.handleChange}
                                value={formik.values.city}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="postal_code"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__postal")}
                                name="postal_code"
                                autoComplete="postal_code"
                                onChange={formik.handleChange}
                                value={formik.values.postal_code}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="owner_first_name"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__your_first_name")}
                                name="owner_first_name"
                                autoComplete="owner_first_name"
                                onChange={formik.handleChange}
                                value={formik.values.owner_first_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="owner_last_name"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__your_last_name")}
                                name="owner_last_name"
                                autoComplete="owner_last_name"
                                onChange={formik.handleChange}
                                value={formik.values.owner_last_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="owner_position"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__your_position")}
                                name="owner_position"
                                autoComplete="owner_position"
                                onChange={formik.handleChange}
                                value={formik.values.owner_position}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="owner_email"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__your_email")}
                                name="owner_email"
                                autoComplete="owner_email"
                                onChange={formik.handleChange}
                                value={formik.values.owner_email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__create_pass")}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="confirm_password"
                                label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__confirm_pass")}
                                type="password"
                                id="confirm_password"
                                autoComplete="current-password"
                                onChange={formik.handleChange}
                                value={formik.values.confirm_password}
                            />
                        </Grid>
                        <FormControlLabel
                            control={<Checkbox
                                name="remember_me"
                                id="remember_me"
                                value={formik.values.remember_me}
                                onChange={formik.handleChange}
                                color="primary"
                            />}
                            label={GET_APP_LOCALIZE_TEXT(pageLc, "auth__keep_me_login")}
                        />
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {GET_APP_LOCALIZE_TEXT(pageLc, "auth__register")}
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                {GET_APP_LOCALIZE_TEXT(pageLc, "auth__already_have_acc")}
                            </Link>
                        </Grid>
                    </Grid>
                </form>


            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withRouter(RegisterSchool);