import React, {useEffect, useState} from 'react';
import {Box, Grid, Spacer, Typography} from "../../../../components/StyledComponents/styledComponents";
import {fetchInsightHTML} from "./insight_text";
import './print.css'
import RadarChart from "./RadarChart";

const ExecutiveSummary = ({rows, chart_values}) => {

    let weakest = {skill: 'Nil', avg_score: 0};
    let strongest = {skill: 'Nil', avg_score: 0};
    let best_class = {class: 'Nil', avg_score: 0};
    let total_avg_score = 0;

    //Calculation
    const skills_sum = {}
    let max_avg_score_class_index = 0;
    rows.forEach((row, index) => {
        const { dq_avg_score, dq_skill_scores } = row;
        //Max Avg Score Calc
        if(dq_avg_score > rows[max_avg_score_class_index]["dq_avg_score"])
        {
            max_avg_score_class_index = index;
        }
        //Total DQ Score
        total_avg_score += dq_avg_score
        //Individual Skills
        for(const skill_obj of dq_skill_scores)
        {
            const {skill, dq_score} = skill_obj;
            if(skills_sum[skill])
                skills_sum[skill] += dq_score
            else
                skills_sum[skill] = dq_score
        }
    })
    //Best Class
    best_class.class = rows[max_avg_score_class_index]["name"];
    best_class.avg_score = rows[max_avg_score_class_index]["dq_avg_score"];
    //Total Average DQ Score
    total_avg_score = Math.round(total_avg_score/rows.length)
    //Calculating Weakest and Strongest Skill
    const skills_sum_array = Object.keys(skills_sum).map((skill) => [skill, skills_sum[skill]])
    skills_sum_array.sort((a, b) => a[1] - b[1]); //where '1' index is value
    const start_index = 0;
    const end_index = skills_sum_array.length-1;
    if(skills_sum_array[start_index])
    {
        weakest.skill = skills_sum_array[start_index][0];
        weakest.avg_score = Math.round(skills_sum_array[start_index][1]/rows.length);
    }
    if(skills_sum_array[end_index])
    {
        strongest.skill = skills_sum_array[end_index][0];
        strongest.avg_score = Math.round(skills_sum_array[end_index][1]/rows.length);
    }

    for(const chart_row_index in chart_values) {
        const skill = chart_values[chart_row_index].skill;
        chart_values[chart_row_index]["School"] = Math.round(skills_sum[skill]/rows.length);
    }

    useEffect(() => {
        console.log("use Effect Happened")
    }, [rows])

    return <>
        <Typography align={'center'} font_color={'darkorange'} font_weight={'bold'} font_size={'24px'} py={10}>
            Executive Summary
        </Typography>

        <Grid container>
            <Grid item xs={6}>
                <RadarChart
                    className={'chartPrint'}
                    data={{
                        labels: chart_values.map((row) => row.skill),
                        selected: chart_values.map(() => false),
                        graphData: ["School", "Nation", "Global"].map((category, index) => {
                            return {
                                index: index,
                                country: category,
                                data: chart_values.map((object) => object[category]),
                                border_opacity: 1.0 ,
                                background_opacity: 0.2,
                                show: true,
                            }
                        })
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={5}>
                    <Grid item xs={4} >
                        <Typography font_weight={'bold'} align={'center'}>Score</Typography>
                        <Spacer py={2}/>
                        <Box align={'center'} bgcolor={"#44b4f3"} color={"white"} border={1} borderRadius={"borderRadius"} font_weight={'bold'} p={3}>
                            Excellent<br/>(Above 115)
                        </Box>
                        <Box align={'center'}>│</Box>
                        <Box align={'center'} bgcolor={"#fbc208"} color={"white"} border={1} borderRadius={"borderRadius"} font_weight={'bold'} p={3}>
                            Satisfactory<br/>(Above 100-115)
                        </Box>
                        <Box align={'center'}>│</Box>
                        <Box align={'center'} bgcolor={"#ec6420"} color={"white"} border={1} borderRadius={"borderRadius"} font_weight={'bold'} p={3}>
                            Less than Satisfactory<br/>(85-99)
                        </Box>
                        <Box align={'center'}>│</Box>
                        <Box align={'center'} bgcolor={"#f41708"} color={"white"} border={1} borderRadius={"borderRadius"} font_weight={'bold'} p={3}>
                            At Risk<br/>(Below 85)
                        </Box>
                    </Grid>
                    <Grid container item xs={8} alignItems={'center'} mt={5}>
                        <Typography>
                            Your school's average overall DQ Citizenship Score is <Typography display={'inline'} font_weight={'bold'}>{total_avg_score}</Typography>.
                        </Typography>
                        <Typography>
                            Your school's strongest performing DQ Skill is <Typography display={'inline'} font_weight={'bold'}>{strongest.skill}</Typography>.
                            (average DQ Score: <Typography display={'inline'} font_weight={'bold'}>{strongest.avg_score}</Typography>).
                        </Typography>
                        <Typography>
                            Your school's weakest performing DQ Skill is <Typography display={'inline'} font_weight={'bold'}>{weakest.skill}</Typography>.
                            (average DQ Score: <Typography display={'inline'} font_weight={'bold'}>{weakest.avg_score}</Typography>).
                        </Typography>
                        <Typography>
                            Your school's best performing class is <Typography display={'inline'} font_weight={'bold'}>{best_class.class} </Typography>
                            (Average DQ Citizenship Score: <Typography display={'inline'} font_weight={'bold'}>{best_class.avg_score}</Typography>)
                        </Typography>
                        <Typography>
                            Congratulations to your student's for their great efforts!
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Spacer my={5}/>
        <Grid container spacing={5} justify={'center'} alignItems={'stretch'}>
            {
                rows[0].insights.map((insight) =>
                    <Grid item xs={4}>
                        <Box border={1} borderRadius={"15px"} borderColor={"lightGrey"} p={5}>
                            <Typography align={'center'} font_weight={'bold'}>
                                {insight.skill}
                            </Typography>
                            <Spacer p={2}/>
                            {
                                fetchInsightHTML(insight.skill, insight.params)
                            }
                        </Box>
                    </Grid>
                )
            }
        </Grid>
    </>
}


export default ExecutiveSummary;