import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {CustomSelect} from "../../components/Select.comp";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import BarChart from "../../components/ExtendedBarChart";
import {
    addItemToState,
    alphaNumericSort, isDQWEnabled,
    predefinedSort,
    removeItemFromState,
    setSingleKeyTrueInState, splitList,
} from "../../../../../utils/utils";
import {X} from "react-feather";
import {useAuth} from "../../../../../context/auth";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import MonthRangePicker from "../../../../../components/DatePickers/MonthRangePicker.comp";
import {getEnhancementAdoptionSchool} from "../../../../../services/school.service";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color :props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function BySchoolTab() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const {org_code, institute_code, org_obj} = authUser;
    const dqwEnabled = isDQWEnabled(org_obj);

    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    const subListDivision = 3;
    const [schools, setSchools] = useState([]);
    const [classes, setClasses] = useState(undefined);

    const schoolHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_school");
    const [defaultSchools, setDefaultSchools] = useState([]);
    const [additionalSchools, setAdditionalSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(undefined);

    const classHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_class");
    const [defaultClasses, setDefaultClasses] = useState([]);
    const [additionalClasses, setAdditionalClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState(undefined);

    const timeHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time");
    const [timePeriods, setTimePeriods] = useState([]);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState([undefined, undefined]);
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);

    const [participantTypes, setParticipantTypes] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [header, setHeader] = useState("");

    const [schoolState, setSchoolState] = useState(undefined);
    const [classState, setClassState] = useState(undefined);
    const [timeState, setTimeState] = useState(undefined);

    const selectAllSchoolsOption = "All"
    const selectAllSchoolsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_school")

    const selectAllClassesOption = "All"
    const selectAllClassesAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_class")

    const selectAllYears = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_year");

    const getDateRangeOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length - 1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        return [startDate, endDate]
    }

    const getRegionAlias = (code) => {
        return code === selectAllSchoolsOption ? selectAllSchoolsAliasedOption : code;
    }

    const getRegionReverseAlias = (alias) => {
        return alias === selectAllSchoolsAliasedOption ? selectAllSchoolsOption : alias;
    }

    const getClassAlias = (code) => {
        return code === selectAllClassesOption ? selectAllClassesAliasedOption : code;
    }

    const getClassReverseAlias = (alias) => {
        return alias === selectAllClassesAliasedOption ? selectAllClassesOption : alias;
    }

    useEffect(() => {
        if(!selectorsData && !firstLoad)
        {
            setGraphData([])
            setSelectedTimePeriod([undefined, undefined]);
            setSelectedClass(undefined);
            setSelectedSchool(undefined);

            setClasses(undefined);
            setSchools([]);
            setTimePeriods([]);
            setMinDate(undefined);
            setMaxDate(undefined);
        }
    }, [selectorsData])

    useEffect(() => {
        if(firstLoad) {
            // setTimeout(() => {loadSelectors().then(d => console.log('Assessment Adoption Selectors Fetched', d))}, 200)
            loadSelectors().then(d => console.log('Assessment Adoption Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const loadSelectors = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementAdoptionSchool(org_code, institute_code);
            if(result.data)
            {
                const {selectors, data} = result.data;
                const {schools: _schools, classes: _classes, participant_types} = selectors;
                //Initialize Schools
                const _school = initializeSchools(_schools);
                //Initialize Classes
                setClasses(_classes);
                initializeClasses(_school, selectors);
                //Initialize Time Periods
                initializeTimePeriods(_school, selectors);
                //Initialize Participants Types
                setParticipantTypes(participant_types.filter((type) => dqwEnabled || !type.toLowerCase().includes("dqw")));
                //Initialize Graph Data
                loadGraphData(data.data);
                //Set Selectors Data and Page Data
                setSelectorsData(selectors);
                setPageData(data.data);
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching enhancement adoption selectors", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadPageData = async (_school = selectedSchool, _class = selectedClass, _time = selectedTimePeriod) => {
        setLoading(true);
        try {
            setPageData(undefined);
            // const result = {data: getDummyBySchoolData(org_code, _school, _class, _time)};
            const result = await getEnhancementAdoptionSchool(org_code, _school, _class, _time);
            if (result.data) {
                const {data} = result.data;
                setPageData(data.data);
                loadGraphData(data.data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption data")
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function loadGraphData(data)
    {
        const sliced = data;
        setGraphData(sliced.sort(alphaNumericSort("title", true)))
    }

    function initializeSchools(_schools) {
        _schools.sort().sort(predefinedSort([selectAllSchoolsOption]))
        const subLists = splitList(_schools, subListDivision)
        setDefaultSchools(subLists[0]);
        setAdditionalSchools(subLists[1])
        setSchools(_schools);
        selectSchool(subLists[0][0], false);
        return subLists[0][0];
    }

    function initializeClasses(_school, selectors = selectorsData) {
        let _class = undefined;
        if(_school && selectors)
        {
            const {classes: _all_classes} = selectors;
            const _classes = _all_classes[_school];
            _classes.sort((a, b) => a - b).sort(predefinedSort([selectAllClassesOption]));
            const subLists = splitList(_classes, subListDivision)
            setDefaultClasses(subLists[0]);
            setAdditionalClasses(subLists[1]);
            _class = null;
            setSelectedClass(_class);
            setSingleKeyTrueInState(subLists[0].map((cl) => getClassAlias(cl)), getClassAlias(_class), setClassState) //ensuring checkbox consistency
        }
        return _class;
    }

    function initializeTimePeriods(_school, selectors = selectorsData) {
        let date_range = [undefined, undefined];
        if(_school && selectors)
        {
            const {years: _years} = selectors;
            const _timePeriods = _years[_school].map((year) => year.toString());
            setTimePeriods(_timePeriods);
            setSingleKeyTrueInState([selectAllYears, ..._timePeriods], selectAllYears, setTimeState);
            if(_timePeriods.length > 0) {
                date_range = getDateRangeOfYears(_timePeriods);
                const [start_date_iso, end_date_iso] = date_range;
                setMinDate(new Date(start_date_iso));
                setMaxDate(new Date(end_date_iso));
                selectTimePeriod(date_range, false);
            }
        }
        return date_range;
    }

    function selectSchool(_school, fetch = false, _class = selectedClass, _time = selectedTimePeriod)
    {
        setSelectedSchool(_school);
        if(_school)
        {
            if(fetch)
            {
                loadPageData(_school, null, _time).then(d => console.log('Enhancement Adoption By School Data Fetched - By Selection of School', d));
            }
            setHeader(_school);
        }
        else
        {
            if(!pageData)
            {
                setDefaultSchools([]);
                setAdditionalSchools([]);
            }
            setDefaultClasses([])
            setAdditionalClasses([])
            setGraphData([])
            setHeader("");
        }
    }

    function selectClass(_class = null, fetch = false)
    {
        setSelectedClass(_class);
        if(_class)
        {
            if(fetch)
            {
                loadPageData(selectedSchool, _class).then(d => console.log('Enhancement Adoption By School Data Fetched - By Selection of Class', d));
            }
            setHeader(_class);
        }
        else
        {
            selectSchool(selectedSchool, fetch);
        }
    }

    function selectTimePeriod(_timePeriods, fetch = false)
    {
        setSelectedTimePeriod(_timePeriods);
        if(fetch)
        {
            loadPageData(selectedSchool, selectedClass, _timePeriods).then(d => console.log('Enhancement Adoption By School Data Fetched - By Selection of Time Period', d));
        }
    }

    function onAddSchool(event, state, setState) {
        const aliasedItem = event.target.value
        const item = getRegionReverseAlias(aliasedItem);
        removeItemFromState(item, additionalSchools, setAdditionalSchools);
        addItemToState(item, defaultSchools, setDefaultSchools);
        //TODO: Replace with setSingleKeyTrueInState
        setState({
            ...Object.fromEntries(defaultSchools.map((r) => getRegionAlias(r)).map((school) => [school, false])),
            [aliasedItem]: true,
        });
        const _class = initializeClasses(item);
        const _time = initializeTimePeriods(item);
        selectSchool(item, true, _class, _time);
    }

    function onRemoveSchool(aliasedItem, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        const newDefaultSchools = removeItemFromState(item, defaultSchools, setDefaultSchools)
        addItemToState(item, additionalSchools, setAdditionalSchools, true)
        //TODO: Replace with setSingleKeyTrueInState
        setState({
            ...Object.fromEntries(newDefaultSchools.map((r) => getRegionAlias(r)).map((school, index) => index === 0 ? [school, true] : [school, false])),
        });
        if(selectedSchool !== newDefaultSchools[0])
        {
            const _class = initializeClasses(newDefaultSchools[0]);
            const _time = initializeTimePeriods(newDefaultSchools[0]);
            selectSchool(newDefaultSchools[0], true, _class, _time);
        }
    }

    function onSelectSchool(aliasedItem, isChecked, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        if(isChecked) {
            const _class = initializeClasses(item);
            const _time = initializeTimePeriods(item);
            selectSchool(item, true, _class, _time);
            //TODO: Replace with setSingleKeyTrueInState
            setState(Object.fromEntries(defaultSchools.map((r) => getRegionAlias(r)).map(
                (school) => school === aliasedItem ? [school, true] : [school, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [aliasedItem]: true
            });
        }
    }

    function onAddClass(event, state, setState) {
        const aliasedItem = event.target.value;
        const item = getClassReverseAlias(aliasedItem);
        removeItemFromState(item, additionalClasses, setAdditionalClasses);
        addItemToState(item, defaultClasses, setDefaultClasses);
        //TODO: Replace with setSingleKeyTrueInState
        setState({
            ...Object.fromEntries(defaultClasses.map((cl) => getClassAlias(cl)).map((cl) => [cl, false])),
            [aliasedItem]: true,
        });
        selectClass(item, true);
    }

    function onRemoveClass(aliasedItem, state, setState) {
        const item = getClassReverseAlias(aliasedItem);
        const newDefaultClasses = removeItemFromState(item, defaultClasses, setDefaultClasses)
        addItemToState(item, additionalClasses, setAdditionalClasses, true)
        //TODO: Replace with setSingleKeyTrueInState
        setState({
            ...Object.fromEntries(newDefaultClasses.map((cl) => getClassAlias(cl)).map((cl, index) => index === 0 ? [cl, true] : [cl, false])),
        });
        selectClass(newDefaultClasses[0], true);
    }

    function onSelectClass(aliasedItem, isChecked, state, setState) {
        const item = getClassReverseAlias(aliasedItem);
        if(isChecked) {
            selectClass(item, true);
            //TODO: Replace with setSingleKeyTrueInState
            setState(Object.fromEntries(defaultClasses.map((cl) => getClassAlias(cl)).map(
                (cl) => cl === aliasedItem ? [cl, true] : [cl, false]
            )));
        }
        else
        {
            selectClass(undefined, true);
        }
    }

    function onSelectYear (item, isChecked, state, setState) {
        if(isChecked)
        {
            if(item === selectAllYears)
            {
                const date_range = getDateRangeOfYears(timePeriods);
                selectTimePeriod(date_range, true)
            }
            else
            {
                const date_range = getDateRangeOfYears([item]);
                selectTimePeriod(date_range, true)
            }
            //TODO: Replace with setSingleKeyTrueInState
            setState(Object.fromEntries([selectAllYears, ...timePeriods].map(
                (time) => time === item ? [time, true] : [time, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onClickDatePicker(event, state, setState) {
        //TODO: Replace with setSingleKeyTrueInState
        setState(Object.fromEntries([selectAllYears, ...timePeriods].map(
            (time) => [time, false]
        )));
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();
        selectTimePeriod([start_date, end_date], true)
    }

    let sums = {
        "_total": 0,
    }
    if(graphData)
    {
        graphData.forEach((item, index) => {
            participantTypes.forEach(type => {
                const value = item[type] ? item[type] : 0;
                sums[type] = sums[type] ? sums[type] + value : value;
                sums["_total"] = sums["_total"] + value;
            })
        })
    }

    const title = graphData.length > 0 && selectedSchool ? schools.includes(graphData[0].title) ? GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__school") :
        GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__class") : GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__title")

    return (
        <Paper container>
            <Grid container alignItems={'stretch'}>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[defaultSchools.map((r) => getRegionAlias(r)), defaultClasses.map((cl) => getClassAlias(cl)), [selectAllYears, ...timePeriods]]}
                        onCheckCallbacks = {[onSelectSchool, onSelectClass, onSelectYear]}
                        headers = {[schoolHeader, classHeader, timeHeader]}
                        colourize = {[false, false, false]}
                        actions = {[X, X, undefined]}
                        actionsData = {[{style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, undefined]}
                        actionsCallbacks = {[onRemoveSchool, onRemoveClass, undefined]}
                        footers = {[CustomSelect, CustomSelect, MonthRangePicker]}
                        footersData = {[
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_school"),
                                data: additionalSchools.map((r) => getRegionAlias(r)),
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_class"),
                                data: additionalClasses.map((cl) => getClassAlias(cl)),
                            },
                            {onChangeDate: onSelectDateRange, minDate, maxDate},
                        ]}
                        footersCallbacks = {[{onChange: onAddSchool}, {onChange: onAddClass}, {onOpen: onClickDatePicker}]}
                        defaultCheckIndices = {[0, undefined, 0]}
                        stateCallbacks = {[
                            {
                                state: schoolState,
                                setState: setSchoolState
                            },
                            {
                                state: classState,
                                setState: setClassState
                            },
                            {
                                state: timeState,
                                setState: setTimeState
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                    {
                        pageData ?
                            <Paper container mt={10} mb={5}>
                                <Typography mb={5} variant="h4" gutterBottom align={'center'}>
                                    {
                                        selectedSchool ?
                                            selectedClass ? `${getRegionAlias(selectedSchool)} - ${getClassAlias(selectedClass)}`
                                            : `${getRegionAlias(selectedSchool)}`
                                        : GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__school_need")
                                    }
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Card>
                                            <CardContent>
                                                <BarChart
                                                    labels={[...graphData.map((item) => item.title)]}
                                                    datasets={
                                                        [...participantTypes.map((type) => ({
                                                            label: type,
                                                            data: graphData.map((item) => item[type])
                                                        }))]
                                                    }
                                                    titleX={title}
                                                    titleY={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_count")}
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Card>
                                            <CardContent>
                                                {
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <CustomTableCell align="center"/>
                                                                <CustomTableCell align="center" colSpan={participantTypes.length}>
                                                                    <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                        {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_count")}
                                                                    </DefaultColouredTypography>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="center"/>
                                                            </TableRow>
                                                            <TableRow>
                                                                <CustomTableCell align="center">
                                                                    <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                        {title}
                                                                    </DefaultColouredTypography>
                                                                </CustomTableCell>
                                                                {
                                                                    participantTypes.map(type =>
                                                                        <CustomTableCell align="center">
                                                                            <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                                {type}
                                                                            </DefaultColouredTypography>
                                                                        </CustomTableCell>
                                                                    )
                                                                }
                                                                <CustomTableCell align="center">
                                                                    <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                        {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_percent")}
                                                                    </DefaultColouredTypography>
                                                                </CustomTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                graphData.map((item, index) => (
                                                                    <CustomTableRow key={index}>
                                                                        <CustomTableCell align="center">{item.title}</CustomTableCell>
                                                                        {
                                                                            participantTypes.map(type =>
                                                                                <CustomTableCell align="center">{item[type]}</CustomTableCell>
                                                                            )
                                                                        }
                                                                        <CustomTableCell align="center">
                                                                            {
                                                                                Math.round(
                                                                                    (participantTypes
                                                                                    .map((type) => item[type] ? item[type] : 0)
                                                                                    .reduce((a, b) => a+b ,0))
                                                                                    /(sums["_total"] !== 0 ? sums["_total"] : 1)*100
                                                                                )
                                                                            }%
                                                                        </CustomTableCell>
                                                                    </CustomTableRow>
                                                                ))
                                                            }
                                                            <CustomTableRow key={"sum"}>
                                                                <CustomTableCell align="center">
                                                                    <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "general__total")}</Bold>
                                                                </CustomTableCell>
                                                                {
                                                                    participantTypes.map(type =>
                                                                        <CustomTableCell align="center">
                                                                            <Bold>{sums[type]}</Bold>
                                                                        </CustomTableCell>
                                                                    )
                                                                }
                                                                <CustomTableCell align="center">
                                                                    <Bold>{Math.round(sums["_total"]/(sums["_total"] !== 0 ? sums["_total"] : 1)*100)}%</Bold>
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                        </TableBody>
                                                    </Table>
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Paper>
                        :
                            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default BySchoolTab;