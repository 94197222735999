import React from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import {
  Grid,
  Hidden,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
} from "@material-ui/core";

import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function FooterComp() {

  const location = useLocation();
  const pageLc = location.pathname.split('/')[1];

  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem button={true} component="a" href="#">
                <ListItemText primary={`${GET_APP_LOCALIZE_TEXT(pageLc, 'footer__support')}`} />
              </ListItem>
              <ListItem button={true} component="a" href="#">
                <ListItemText primary={`${GET_APP_LOCALIZE_TEXT(pageLc, 'footer__help_center')}`} />
              </ListItem>
              <ListItem button={true} component="a" href="#">
                <ListItemText primary={`${GET_APP_LOCALIZE_TEXT(pageLc, 'footer__privacy')}`} />
              </ListItem>
              <ListItem button={true} component="a" href="#">
                <ListItemText primary={`${GET_APP_LOCALIZE_TEXT(pageLc, 'footer__terms_of_service')}`} />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem button={true}>
              <ListItemText
                primary={`© ${new Date().getFullYear()} ${GET_APP_LOCALIZE_TEXT(pageLc, 'footer__dq_lab_pte_ltd_all_rights_reserved')}`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default FooterComp;
