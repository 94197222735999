import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import styled from "styled-components/macro";
import { useAuth } from '../../../context/auth'

import {
    Card as MuiCard,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { withStyles } from "@material-ui/core/styles";
import {BadgeUIConfServices} from "../../../services/organization.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import UploadImageDialogue from "../components/uploadImageDialogue.comp";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(35, 48, 68, 0.020);
  }
`;

const useStyles = makeStyles((theme)=>({
    imageDiv: {
        height: 60,
        marginTop: -10,
        cursor: "pointer"
    },
    tr_act: {

    },
    tr_d_act: {
        background: '#f8dede!important'
    },
    act_btn: {
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    }
}));


function BadgesListView() {

    const classes = useStyles();

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);
    const [updateIconOfItem, setUpdateIconOfItem] = useState(-1);

    useEffect( () => {
        loadPageData().then(d => console.log('table data loaded!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);

        try {
            const result = await BadgeUIConfServices.list(authUser.org_code)
            if (result.data && result.data.badges) {
                setPageData(result.data.badges);
            }
        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const saveUpdateIcon = async (iconURL) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[updateIconOfItem].icon_url = iconURL;
        try {
            await BadgeUIConfServices.update(data_copy[updateIconOfItem]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setUpdateIconOfItem(-1);
        setLoading(false);
    }

    const activateItem = async (index) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[index].is_active = true;
        try {
            await BadgeUIConfServices.update(data_copy[index]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const disableItem = async (index) => {
        setLoading(true);
        let data_copy = pageData;
        data_copy[index].is_active = false;
        try {
            await BadgeUIConfServices.update(data_copy[index]);
            setPageData([...data_copy]);
        } catch (e) {
            // already toasted error
        }
        setLoading(false);
    }

    const renderURL = (url) => {
        if (url.substring(0,8) === 'https://') {
            return url
        }
        if (url.substring(0,7) === 'http://') {
            return url
        }
        return `${process.env.REACT_APP_MYDQ_API}${url}`
    }

    return (
        <Card mb={6}>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CustomTableCell>Sr.#</CustomTableCell>
                            <CustomTableCell align="center">Code</CustomTableCell>
                            <CustomTableCell align="center">Area</CustomTableCell>
                            <CustomTableCell align="center">Duration</CustomTableCell>
                            <CustomTableCell align="center">Icon</CustomTableCell>
                            <CustomTableCell align="center">Actions</CustomTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pageData.map( (item, index) => (
                                <CustomTableRow key={index} className={item.is_active ? 'classes.tr_act' : classes.tr_d_act}>
                                    <CustomTableCell component="th" scope="row">{index+1}</CustomTableCell>
                                    <CustomTableCell align="center">{item.badge_code}</CustomTableCell>
                                    <CustomTableCell align="center">{item.area_code}</CustomTableCell>
                                    <CustomTableCell align="center">{item.duration}</CustomTableCell>
                                    <CustomTableCell align="center">
                                        <img className={classes.imageDiv} src={renderURL(item.icon_url)}
                                             onClick={()=>{setUpdateIconOfItem(index)}}
                                        />
                                    </CustomTableCell>
                                    {
                                        item.is_active ?
                                            <CustomTableCell align="center"><Button onClick={()=>disableItem(index)}
                                                        className={classes.d_act_btn}>Disable</Button></CustomTableCell> :
                                            <CustomTableCell align="center"><Button onClick={()=>activateItem(index)}
                                                        className={classes.act_btn}>Activate</Button></CustomTableCell>
                                    }
                                </CustomTableRow>
                            ))
                        }
                    </TableBody>
                </Table>

                <LoaderWithBackDrop loading={loading} />

                <UploadImageDialogue
                    openDialogue={updateIconOfItem !== -1}
                    dialogueCloseHandler={()=>{setUpdateIconOfItem(-1)}}
                    successCB={saveUpdateIcon}
                />

            </Paper>
        </Card>
    );
}

export default BadgesListView;