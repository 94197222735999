import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import Helmet from "react-helmet";

import {
    Grid,
    Divider as MuiDivider,
    Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../../utils/constants";
import ListProfilesApprovals from "./components/ListProfilesApprovals";
import UserListView from "../PortalUsers/List/UserList.view";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";


const Divider = styled(MuiDivider)(spacing);


function PendingApprovals() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [listOfType, setListOfType] = useState('Employee');

    useEffect( ()=> {
        const org_type = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type);
        if (org_type+'' === ORG_TYPE.SCHOOL+'') {
            setListOfType('Student');
        }
    }, [] )

    return (
        <React.Fragment>
            <Helmet title={`DQ-PRO | ${listOfType}s`} />
            <Typography variant="h3" gutterBottom display="inline">
                {`${GET_APP_LOCALIZE_TEXT(pageLc, `employees__${listOfType.toLowerCase()}`)} ${GET_APP_LOCALIZE_TEXT(pageLc, 'main__pending_approvals')}`}
            </Typography>

            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <ListProfilesApprovals />
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

export default PendingApprovals;
