
import React from "react";

import styled from "styled-components/macro";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

class TabChild extends React.Component {

    render() {
        return <Typography variant="h4">
                <Box fontWeight="fontWeightBold">{this.props.title}</Box>
            </Typography>
    }
}

export default TabChild;
