import React from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Helmet from "react-helmet";

import {
    CardContent,
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import OrdersListView from "./components/OrderListView.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);


function Orders() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    return (
        <React.Fragment>
            <Helmet title="DQ-PRO | Orders" />
            <Typography variant="h3" gutterBottom display="inline">
                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'orders__orders')}`}
            </Typography>


            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>

                    <OrdersListView />

                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Orders;
