import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import Helmet from "react-helmet";

import {
    Divider as MuiDivider,
    Grid,
    Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {makeStyles} from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import TopSectionBadgeDashboard from "../Home/components/topSectionBadgeDashboard.comp";
import StatsSimpleTable from "./components/StatsSimpleTable";
import {nonOrgDqStatsService} from "../../services/stats.service";
import {useAuth} from "../../context/auth";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme)=>({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10
    },
}));

const temp_data = [
    {name: 'US', users: 124, percentage: 9.11},
    {name: 'UK', users: 60, percentage: 3.4},
    {name: 'UAE', users: 114, percentage: 8.9},
    {name: 'SG', users: 234, percentage: 18.2},
    {name: 'PK', users: 2, percentage: 0.2},
]

function StatsPage() {

    const classes = useStyles();

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [statsData,setStatsData] = useState({});

    useEffect( () => {
        loadStatsData().then(d => console.log('licenses packs data loaded!', d));
    }, []);

    const loadStatsData = async () => {
        setLoading(true);

        try {

            const result = await nonOrgDqStatsService(authUser.org_code);
            console.log('api::d23__resp -> ', result.data)
            if (result.data) {
                setStatsData(result.data);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }


    return (
        <React.Fragment>
            <Helmet title="DQ-PRO | Stats" />
            <Typography variant="h3" gutterBottom display="inline">
                {GET_APP_LOCALIZE_TEXT(pageLc, 'stats_dq_stats')}
            </Typography>


            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <TopSectionBadgeDashboard
                        title={GET_APP_LOCALIZE_TEXT(pageLc, 'stats_total_users')}
                        value={statsData.total_users ? statsData.total_users : '0'}
                        title_color={'red'}
                        value_color={'red'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <TopSectionBadgeDashboard
                        title={GET_APP_LOCALIZE_TEXT(pageLc, 'stats_users_without_demographics')}
                        value={statsData.users_no_demographic ? statsData.users_no_demographic : '0'}
                        title_color={'red'}
                        value_color={'red'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <TopSectionBadgeDashboard
                        title={GET_APP_LOCALIZE_TEXT(pageLc, 'stats_male_users')}
                        value={statsData.male_users ? statsData.male_users : '0'}
                        title_color={'red'}
                        value_color={'red'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <TopSectionBadgeDashboard
                        title={GET_APP_LOCALIZE_TEXT(pageLc, 'stats_female_users')}
                        value={statsData.female_users ? statsData.female_users : '0'}
                        title_color={'red'}
                        value_color={'red'}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} lg={6}>
                    <StatsSimpleTable
                        title={GET_APP_LOCALIZE_TEXT(pageLc, 'stats_users_by_country')}
                        aggregate_col={GET_APP_LOCALIZE_TEXT(pageLc, 'stats_country_name')}
                        data={statsData.count_by_country}
                        total_users={statsData.total_users ? statsData.total_users : 0}
                    />
                </Grid>
            </Grid>


            <LoaderWithBackDrop loading={loading} />

        </React.Fragment>
    );
}

export default StatsPage;
