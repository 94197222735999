import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../context/auth"
import {
    getComprehensivenessData,
} from "../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {
    Paper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    makeStyles, Button as MuiButton, Divider,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {Badge} from "../components/Badge.comp";
import LineChart from "../components/LineChart";
import HorizontalBarChart from "../components/HorizontalBarChart";
import XLSX from "xlsx";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";
import {getLevel3Service} from "../../../../services/analysis.service";

const useStyles = makeStyles(theme => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" } // KEY CHANGES
}));

const Grid = styled(MuiGrid)(spacing);

const Button = styled(MuiButton)(spacing);

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textalign ? props.textalign : "left"};
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.custom_color ? props.custom_color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function ComprehensiveTab() {

    const {authUser} = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');
    const [LEVEL3, setLEVEL3] = useState({});

    useEffect( () => {
        loadPageData().then(d => console.log('Program Overview Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getComprehensivenessData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Comprehensiveness --> ', result.data);
                setPageData(result.data);
                await loadLevel3();
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching comprehensiveness data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const transformMetaData = (objects) => {
        return objects.reduce((acc, obj) => {
            if (obj.dqc_no.startsWith("DQC") && obj.dqc_no.length === 5) {
                const modifiedDqcNo = obj.dqc_no.replace('C0', '').replace('C', '');
                acc[modifiedDqcNo] = {
                    title: obj.full_name,
                    definition: obj.definition,
                    color: obj.color_code
                };
            }
            return acc;
        }, {});
    };

    const loadLevel3 = async () => {
        try {
            const result = await getLevel3Service();
            if(!result.error) {
                const transformedMetaData = transformMetaData(result.data.metaData);
                setLEVEL3(transformedMetaData);
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
    }

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        const final_array = []
        let max_column_width = 10;
        const columns = ["DQ Competency", "Coverage"]
        final_array.push(columns)
        const items = pageData.digital.concat(pageData.beyond)
            .filter((item) => item.Value !== 0)
            .map((item) => {
                const dqTitle = `${item["DQ#"]}: ${LEVEL3[item["DQ#"]].title}`;
                max_column_width = Math.max(max_column_width, dqTitle.length, item["Value"].toString().length);
                return [dqTitle, item["Value"]];
            });
        items.sort((a, b) => b[1] - a[1]);
        final_array.push(...items);
        const worksheet = XLSX.utils.aoa_to_sheet(final_array);
        worksheet["!cols"] = columns.map((column, index) => {
            return { wch: index == 0 ? max_column_width : column.length }
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, "Comprehensiveness");
        XLSX.writeFile(workbook, "Assessment Content.xlsx");
    }

    const badge_data = [
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__comprehensive_score"),
            value: pageData ? pageData.score : "0",
        },
    ]

    const combinedData = pageData ?
        pageData.digital.filter((item) => item.Value !== 0)
            .concat(pageData.beyond.filter((item) => item.Value !== 0))
        : [];

    const mainChart = {
        header: "",
        labels: combinedData ? combinedData.filter((item) => item.Value !== 0).map((item) => item["DQ#"]) : ["DQ 1", "DQ 2", "DQ 3", "DQ 4", "DQ 5", "DQ 6", "DQ 7", "DQ 8", "DQ 9", "DQ 10", "DQ11", "DQ 12", "DQ 13", "DQ 14", "DQ 15", "DQ 16"],
        data: combinedData ? combinedData.filter((item) => item.Value !== 0).map((item) => (item.Value * 100).toFixed(2)) : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    }
    const mainChartData = mainChart.labels.map((label, index) => ({
        label,
        value: mainChart.data[index]
    }));
    mainChartData.sort((a, b) => b.value - a.value);
    const mainChartLabels = mainChartData.map(item => item.label);
    const mainChartValues = mainChartData.map(item => item.value);
    const mainChartColors = LEVEL3 ? mainChartData.reduce((acc, item) => {
        if (LEVEL3[item.label]) {
            acc[item.label] = LEVEL3[item.label].color;
        }
        return acc;
    }, {}) : {};


    // const secondChart = {
    //     header: "",
    //     labels: pageData ? pageData.beyond.filter((item) => item.Value !== 0).map((item) => item["DQ#"]) : ["DQ 9", "DQ 10", "DQ11", "DQ 12", "DQ 13", "DQ 14", "DQ 15", "DQ 16"],
    //     data: pageData ? pageData.beyond.filter((item) => item.Value !== 0).map((item) => item.Value*100) : [0, 0, 0, 0, 0, 0, 0],
    // }
    // const secondChartData = secondChart.labels.map((label, index) => ({
    //     label,
    //     value: secondChart.data[index]
    // }));
    // secondChartData.sort((a, b) => b.value - a.value);
    // const secondChartLabels = secondChartData.map(item => item.label);
    // const secondChartValues = secondChartData.map(item => item.value);

    function headers_callback(value, index) {
        if(index === 0)
            return 0;
        else if(index === 10)
            return 100;
        else
            return "";
    }

    function headers_callback2(value, index) {
        return value;
    }


    return (
        pageData ?
        <Paper>
            <Card>
                <CardContent>
                    <Box sx={{ flexGrow: 1 }} align={"right"}>
                        <Grid container align={"center"} alignItems={"center"}>
                            {/*{badge_data.map((badge, index) => (*/}
                            {/*        <Grid key={index} item xs={12} sm={5} md={4} lg={3}>*/}
                            {/*            <Badge*/}
                            {/*                title={badge.title}*/}
                            {/*                value={badge.value.toFixed(2)}*/}
                            {/*            />*/}
                            {/*            <Typography>*/}
                            {/*                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__out_of")} 5.00*/}
                            {/*            </Typography>*/}
                            {/*        </Grid>*/}
                            {/*    )*/}
                            {/*)}*/}
                            <Grid item xs sm md lg>
                                <ColouredTypography my={5} mr={10} variant={"body1"} custom_color={"dimgrey"} size={"16px"} textalign={"justify"}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__comprehensive_desc")}
                                </ColouredTypography>
                            </Grid>
                        </Grid>
                        <Spacer mb={5}/>

                        {/*MainChart*/}
                        <Grid container alignItems={"center"}>
                            <Grid item xs={2} sm={3} md={4} lg={3}>
                                <ColouredTypography variant={"body1"} textalign={"right"} fontWeight={"bold"} mr={5}>
                                    DQ Competency
                                </ColouredTypography>
                            </Grid>
                            <Grid item xs={10} sm={9} md={8} lg={9}>
                                <ColouredTypography variant={"h4"} textalign={"center"}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__curriculum_cov")}
                                </ColouredTypography>
                                <HorizontalBarChart
                                    labels={mainChartLabels}
                                    values={mainChartValues}
                                    color={true}
                                    // threshold={50}
                                    datalabels={true}
                                    percentage={true}
                                    xtickslabels={true}
                                    suggestedMax={100}
                                    // barsGap={65}
                                    endGap={40}
                                    headers_callback={headers_callback}
                                    DQColors={mainChartColors}
                                />
                                {/*<Spacer px={11.5}>*/}
                                {/*    <hr/>*/}
                                {/*</Spacer>*/}
                            </Grid>
                        </Grid>
                        {/*<Grid container alignItems={"center"}>*/}
                        {/*    <Grid item xs={2} sm={3} md={4} lg={3}>*/}
                        {/*        <ColouredTypography variant={"body1"} textalign={"right"} fontWeight={"bold"} mr={5}>*/}
                        {/*            {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__dig_creative")}*/}
                        {/*        </ColouredTypography>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={10} sm={9} md={8} lg={9} mt={-2}>*/}
                        {/*        <HorizontalBarChart*/}
                        {/*            labels={secondChartLabels}*/}
                        {/*            values={secondChartValues}*/}
                        {/*            // color={true}*/}
                        {/*            // threshold={50}*/}
                        {/*            datalabels={true}*/}
                        {/*            percentage={true}*/}
                        {/*            xtickslabels={false}*/}
                        {/*            suggestedMax={100}*/}
                        {/*            // barsGap={65}*/}
                        {/*            endGap={40}*/}
                        {/*            headers_callback={headers_callback2}*/}
                        {/*            titleX={GET_APP_LOCALIZE_TEXT(pageLc, "assessment__cov_percent")}*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        <Button mt={5} variant="contained" onClick={onClickDownload}>
                            {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <LoaderWithBackDrop loading={loading} />
        </Paper>
        :
        <Paper>
            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default ComprehensiveTab;