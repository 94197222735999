import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton, Tooltip,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import {X} from "react-feather";
import {CustomSelect} from "../../components/Select.comp";
import XLSX from "xlsx";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {useAuth} from "../../../../../context/auth";
import {
    alphaNumericSort,
    createLcKey,
    getUniqueValues,
    isNUSOrg,
    predefinedSort, splitCountries,
    toastDanger
} from "../../../../../utils/utils";
import {
    COUNTRY_CONFIGS,
} from "../../../../../utils/constants";
import MonthRangePicker from "../../../../../components/DatePickers/MonthRangePicker.comp";
import {LinearProgressWithLabel} from "../../components/LinearProgressWithLabel.comp";
import {postProcessSelectors} from "../../utils/post_process_selectors";
import {getEnhancementEfficacyv2} from "../../../../../services/enhancement.service";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";
import {getDigitalSkillsDownloadData} from "../../../../../services/partner.service";
import {createExcelOutputFormat} from "../../utils/excel_output_format";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
        padding: 10,
        minWidth: 10,
        maxWidth: 50,
    },
    body: {
        padding: 10,
        minWidth: 10,
        maxWidth: 50,
        fontSize: 12,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.customcolor ? props.customcolor : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.customcolor ? props.customcolor : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function splitList(list, min_count)
{
    const sublist = []
    const length = list.length;

    sublist.push(list.slice(0, Math.min(min_count, list.length)));
    sublist.push(list.slice(Math.min(min_count, list.length), length));
    return sublist;
}

const cat_reverse_translations = {}
function GenericEfficacyTab({skill, title, description}) {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const { org_country } = authUser.org_obj;
    const countryConfigKey = COUNTRY_CONFIGS[org_country] ? org_country : "default";
    const countryConfigKeyForIncludedCountries = COUNTRY_CONFIGS[org_country] && COUNTRY_CONFIGS[org_country].included_countries ? org_country : "default";
    const countriesFromConfig = [org_country, ...COUNTRY_CONFIGS[countryConfigKeyForIncludedCountries].included_countries]
    const {administrative_sector, socio_economic_class} = COUNTRY_CONFIGS[countryConfigKey];

    const programMods = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__prog_mods")

    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [postalCodes, setPostalCodes] = useState(undefined);
    const [instituteCodes, setInstituteCodes] = useState(undefined);
    const [modules, setModules] = useState([programMods]);
    const [additionalCountries, setAdditionalCountries] = useState([]);

    const countryHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const [selectedCountry, setSelectedCountry] = useState({country: undefined});

    const regionHeader = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__select_${administrative_sector.toLowerCase()}`);
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({region: undefined});

    const postalHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_postal")
    const [defaultPostal, setDefaultPostal] = useState([]);
    const [additionalPostal, setAdditionalPostal] = useState([]);
    const [selectedPostal, setSelectedPostal] = useState({postal: undefined});

    const areaHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_inst")
    const [defaultAreas, setDefaultAreas] = useState([]);
    const [additionalAreas, setAdditionalAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState({area: undefined});

    const moduleHeader = modules.length > 1 ? GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_mod_cat")
        : GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_comp_cat")

    const [defaultModules, setDefaultModules] = useState([programMods]);
    const [selectedModule, setSelectedModule] = useState({module: programMods});

    const timeHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time")
    const [timePeriods, setTimePeriods] = useState([]);
    const [timePeriodsReset, setTimePeriodsReset] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState({time: undefined});
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);
    const [resetKey, setResetKey] = useState(false);

    const [tableHeaderData, setTableHeaderData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [header, setHeader] = useState("");
    const isDataBeingFetched = useRef(false);
    const subListDivision = 3;
    const [defaultCountriesExists, setDefaultCountriesExists] = useState(false);

    const selectAllRegionsOption = "All"
    const selectAllRegionsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_${administrative_sector.toLowerCase()}`)

    const selectAllPostalCodesOption = "All"
    const selectAllPostalCodesAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_postal`)

    const selectAllAreasOption = "All"
    const selectAllAreasAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_inst`)

    const selectAllYears = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_year`);

    const get_category_alias = (category) => {
        const cat_trans = GET_APP_LOCALIZE_TEXT(pageLc, `baselining__${createLcKey(category)}`, null);
        const country_trans = GET_APP_LOCALIZE_TEXT(pageLc, `dq_country__${createLcKey(category)}`, null);
        const translation = cat_trans || country_trans || category;
        cat_reverse_translations[translation] = category;
        return translation;
    }

    const get_category_reverse_alias = (alias) => {
        const reverse_alias = cat_reverse_translations[alias];
        return reverse_alias || alias;
    }

    const getRegionAlias = (code) => {
        return code === selectAllRegionsOption ? selectAllRegionsAliasedOption : code;
    }

    const getRegionReverseAlias = (alias) => {
        return alias === selectAllRegionsAliasedOption ? selectAllRegionsOption : alias;
    }

    const getPostalAlias = (code) => {
        return code === selectAllPostalCodesOption ? selectAllPostalCodesAliasedOption : code;
    }

    const getPostalReverseAlias = (alias) => {
        return alias === selectAllPostalCodesAliasedOption ? selectAllPostalCodesOption : alias;
    }

    const getAreaAlias = (code) => {
        return code === selectAllAreasOption ? selectAllAreasAliasedOption : code;
    }

    const getAreaReverseAlias = (alias) => {
        return alias === selectAllAreasAliasedOption ? selectAllAreasOption : alias;
    }

    const selectAllMonthsOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length-1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        setSelectedTimePeriod({time: [startDate, endDate]});
    }

    useEffect(() => {
        if(selectorsData)
        {
            const {selectors, current_selection, data} = selectorsData;
            const {countries: _countries} = selectors;
            if(_countries && Array.isArray(_countries))
            {
                // TODO: Need to rmeove constant check later.
                let filtered_countries = _countries.filter(ct => countriesFromConfig.includes(ct));
                // if (filtered_countries.length === 0) filtered_countries = [..._countries];
                setDefaultCountriesExists(filtered_countries && filtered_countries.length > 0);
                const countriesSubList = splitCountries(_countries, filtered_countries && filtered_countries.length > 0 ? filtered_countries : [org_country]);
                setCountries(countriesSubList[0])
                setAdditionalCountries(countriesSubList[1]);
                setSelectedCountry({country: countriesSubList[0][0]})
                // setCountries(filtered_countries);
            }
            setPageData({current_selection, data});
        }
    }, [selectorsData])

    useEffect(() => {
        if(countries.length > 0 && !selectedCountry)
        {
            setSelectedCountry({country: countries[0]});
        }
    }, [countries])

    useEffect(() => {
        if(selectedCountry.country)
        {
            const country = selectedCountry.country;
            if(selectorsData)
            {
                const {selectors} = selectorsData;
                // TODO: Need to rmeove constant check later.
                if(isNUSOrg(authUser.org_code))
                {
                    const {states, postal_codes, institute_codes, years} = postProcessSelectors(selectors, country, authUser.org_code);

                    setTimePeriods(years.map((year) => year.toString()))
                    setInstituteCodes(institute_codes);
                    setPostalCodes(postal_codes)
                    setRegions(states)
                }
                else {
                    const {states, postal_codes, institute_codes, years} = postProcessSelectors(selectors, country);

                    setTimePeriods(years.map((year) => year.toString()))
                    setInstituteCodes(institute_codes);
                    setPostalCodes(postal_codes)
                    setRegions(states)
                }
            }
        }
    }, [selectedCountry])

    useEffect(() => {
        if(timePeriods.length > 0)
        {
            const [firstYear, lastYear] = [timePeriods[0], timePeriods[timePeriods.length - 1]]
            setMinDate(new Date(`${firstYear}-01-01`))
            setMaxDate(new Date(`${lastYear}-12-31`))
            selectAllMonthsOfYears(timePeriods);
            setTimePeriodsReset(true);
        }
    }, [timePeriods])

    useEffect(() => {
        if(regions.length > 0)
        {
            regions.sort().sort(predefinedSort([selectAllRegionsOption]))
            const regionSubLists = splitList(regions, subListDivision)
            setDefaultRegions(regionSubLists[0]);
            setAdditionalRegions(regionSubLists[1])
            setSelectedRegion({region: regionSubLists[0][0]});
        }
    }, [regions])

    useEffect(() => {
        if(selectedRegion.region && postalCodes)
        {
            const postals = postalCodes[selectedRegion.region];
            postals.sort((a, b) => a - b).sort(predefinedSort(["All"]));
            const postalSubLists = splitList(postals, subListDivision)
            setDefaultPostal(postalSubLists[0]);
            setAdditionalPostal(postalSubLists[1]);
            setSelectedPostal({postal: postalSubLists[0][0]});
        }
        else
        {
            setDefaultPostal([])
            setAdditionalPostal([])
            setDefaultAreas([])
            setAdditionalAreas([])
            setTableHeaderData([])
            setTableData([])
            setHeader("");
        }

    }, [selectedRegion])

    useEffect(() => {
        if(selectedPostal.postal && instituteCodes)
        {
            const _codes = instituteCodes[selectedRegion.region][selectedPostal.postal]
            _codes.sort(alphaNumericSort(undefined, true)).sort(predefinedSort(["All"]));
            const areaSubLists = splitList(_codes, subListDivision)
            setDefaultAreas(areaSubLists[0]);
            setAdditionalAreas(areaSubLists[1]);
            setSelectedArea({area: areaSubLists[0][0]});
        }
        else
        {
            setDefaultAreas([])
            setAdditionalAreas([])
            setTableHeaderData([])
            setTableData([])
            setHeader("")
        }
    }, [selectedPostal])

    useEffect(() => {
        if(selectedArea.area && selectedTimePeriod.time)
        {
            if(!(isDataBeingFetched.current))
            {
                loadPageData(selectedCountry.country, selectedRegion.region, selectedPostal.postal, selectedArea.area, selectedTimePeriod.time).catch(console.error);
            }
            setHeader(`${title} - ${getRegionAlias(selectedRegion.region)} - ${getPostalAlias(selectedPostal.postal)} - ${getAreaAlias(selectedArea.area)} - ${selectedModule.module}`);
        }
        else
        {
            setTableHeaderData([])
            setTableData([])
            setHeader("")
        }
    }, [selectedArea, selectedTimePeriod.time])

    useEffect( () => {
        loadSelectorData().then(d => console.log('Enhancement Adoption Module Selectors Fetched', d)).catch(console.error);
    }, []);

    const loadSelectorData = async () => {
        setLoading(true);
        try {
            isDataBeingFetched.current = true;
            const result = await getEnhancementEfficacyv2(authUser.org_code, skill)
            isDataBeingFetched.current = false;
            if (result.data) {
                console.log("selector data fetched")
                setSelectorsData(result.data);
            }
            else {
                setPageData(undefined);
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption module selectors")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const loadPageData = async (country, region, postal_code, institute_code, date_range) => {
        if(pageData) {
            const {current_selection, data} = pageData;
            const {selected_country, selected_state, selected_postal_code, selected_institute_code, selected_date_range} = current_selection;
            if(
                selected_country === country &&
                selected_state === region &&
                selected_postal_code === postal_code &&
                selected_institute_code === institute_code &&
                areDateRangesEqual(selected_date_range, date_range)
            )
            {
                console.log("fetching did not occur")
                populateTable(data);
                return true;
            }
        }
        setLoading(true);
        try
        {
            isDataBeingFetched.current = true;
            const result = await getEnhancementEfficacyv2(authUser.org_code, skill, country, region, postal_code, institute_code, date_range)
            isDataBeingFetched.current = false;
            if (result.data) {
                console.log("fetching occured")
                const {data} = result.data;
                setPageData(result.data);
                populateTable(data);
            }
            else {
                setTableHeaderData([])
                setTableData([])
                setPageData(undefined);
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        }
        catch (e)
        {
            console.log("Some error occurred while fetching enhancement adoption module data")
            setTableHeaderData([])
            setTableData([])
            setPageData(undefined);
            setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function areDateRangesEqual(range1, range2)
    {
        if(!Array.isArray(range1) || !Array.isArray(range2)) return false;
        if(range1.length !== range2.length) return false;
        for(let index in range1)
        {
            if(range1[index] !== range2[index]) return false;
        }
        return true;
    }

    const populateTable = (data) =>
    {
        const categories = Object.keys(data).filter((cat) => Object.keys(data[cat]).length !== 0);

        const modules = getUniqueValues(categories.map((category) => Object.keys(data[category])))
        if(!isNUSOrg(authUser.org_code)) {
            modules.sort(alphaNumericSort(undefined, true)).sort(predefinedSort(["Composite Total"]))
        }

        const subCategories = categories.map((category) => {
            const unique_values = getUniqueValues(modules.map((module) => Object.keys(data[category][module] ? data[category][module]: {}))).sort()
            switch(category.toLowerCase()) {
                case "adii":
                    unique_values.sort(predefinedSort(["Highly Excluded", "Excluded", "Included", "Highly Included"]))
                    break;
                case "age group":
                    unique_values.sort(predefinedSort(["Unspecified", "Below 9 Years Old", "10-12 Years Old", "13-14 Years Old", "15-16 Years Old", "Above 16 Years Old"]))
                    break;
            }
            return unique_values;
        })

        const apiData = {}
        categories.forEach((category, cat_index) => {
            apiData[category] = {}
            modules.forEach((module) => {
                apiData[category][module] = {}
                subCategories[cat_index].forEach((subcat) => {
                    apiData[category][module][subcat] = data[category][module] && data[category][module][subcat] ? data[category][module][subcat] : {count: 0, total: 0}
                })
            })
        })

        //calculating sums

        const sumData = {}
        let totals = {}

        modules.forEach((module) => {
            const category = categories[0];
            const subcategories = subCategories[0];
            let sum = 0;
            subcategories.forEach((subCategory) => sum += apiData[category][module][subCategory].total)
            sumData[module] = sum;
            if(isNUSOrg(authUser.org_code)) {
                totals[module] = apiData[category][module][subcategories[0]].totals;
            }
        })

        //Initializing table data

        let _tableData = categories.map((cat) => {
            return {
                category: cat,
                data: [],
            }
        });

        //filling data from api

        for(let i = 0; i < categories.length; i++)
        {
            let category = categories[i]
            for(let j=0; j < subCategories[i].length; j++)
            {
                let subCategory = subCategories[i][j];
                let module_values = modules.map((module) => apiData[category][module][subCategory].count)
                let module_totals = modules.map((module) => apiData[category][module][subCategory].total)
                let module_percents = modules.map((module) => Math.round((apiData[category][module][subCategory].count/(apiData[category][module][subCategory].total ? apiData[category][module][subCategory].total : 1))*100))
                //Pushing Data into Final Table
                _tableData[i].data.push(
                    {
                        subcategory: subCategory,
                        values: [...module_values],
                        percents: [...module_percents],
                        totals: [...module_totals],
                    }
                )
            }
        }

        const _tableHeaderData = []

        if(isNUSOrg(authUser.org_code)) {
            modules.forEach((module) => {
                _tableHeaderData.push({
                    header: module,
                    total: sumData[module],
                    totals: totals[module]
                })
            })
        } else {
            modules.forEach((module) => {
                _tableHeaderData.push({
                    header: module,
                    total: sumData[module]
                })
            })
        }

        setTableData(_tableData);
        setTableHeaderData(_tableHeaderData);
    }

    const addItemToState = (item, state, setState, sort = false) => {
        if(sort)
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item].sort());
            else
                setState([...state, item].sort());
        }
        else
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item]);
            else
                setState([...state, item]);
        }
    }

    const removeItemFromState = (item, state, setState) => {
        const temp = [...state];
        if(Array.isArray(item)) //in case item is an array, iterate
        {
            item.forEach((i) => {
                const index = temp.indexOf(i);
                if(index !== -1)
                    temp.splice(index, 1);
            })
        }
        else
        {
            const index = temp.indexOf(item);
            if(index !== -1)
                temp.splice(index, 1);
        }
        setState(temp);
        return temp;
    }

    function onSelectYear (item, isChecked, state, setState) {
        if(isChecked)
        {
            if(item === selectAllYears)
            {
                selectAllMonthsOfYears(timePeriods);
            }
            else
            {
                selectAllMonthsOfYears([item]);
            }
            setState(Object.fromEntries([selectAllYears, ...timePeriods].map(
                (year) => year === item ? [year, true] : [year, false]
            )));
            setResetKey(prevReset => !prevReset);
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onClickDatePicker(event, state, setState) {
        setState(Object.fromEntries([selectAllYears, ...timePeriods].map(
            (time) => [time, false]
        )));
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();

        setSelectedTimePeriod({time: [start_date, end_date]});
    }

    function onSelectCountry (item, isChecked, state, setState) {
        if(isChecked)
        {
            setSelectedCountry({country: item});
            setState(Object.fromEntries(countries.map(
                (country) => country === item ? [country, true] : [country, false]
            )));
            setResetKey(prevReset => !prevReset);
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }
    function onAddCountry(event, state, setState) {
        const aliasedItem = event.target.value;
        if(countries.length === 11)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        }
        else
        {
            const country = get_category_reverse_alias(aliasedItem);
            removeItemFromState(country, additionalCountries, setAdditionalCountries);
            addItemToState(country, countries, setCountries);

            setState({
                ...Object.fromEntries(countries.map((c) => [c, false])),
                [country]: true,
            });
            setSelectedCountry({country: country});
        }
    }

    function onRemoveCountry(aliasedItem, state, setState) {

        const country = get_category_reverse_alias(aliasedItem);
        const newCountries = removeItemFromState(country, countries, setCountries)
        addItemToState(country, additionalCountries, setAdditionalCountries, true)

        setState({
            ...Object.fromEntries(newCountries.map((c, index) => index === 0 ? [c, true] : [c, false])),
        });
        setSelectedCountry({country: newCountries[0]});
    }

    function onAddRegion(event, state, setState) {
        const aliasedItem = event.target.value
        const item = getRegionReverseAlias(aliasedItem);
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setState({
            ...Object.fromEntries(defaultRegions.map((r) => getRegionAlias(r)).map((region) => [region, false])),
            [aliasedItem]: true,
        });
        setSelectedRegion({region: item});
    }

    function onRemoveRegion(aliasedItem, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setState({
            ...Object.fromEntries(newDefaultRegions.map((r) => getRegionAlias(r)).map((region, index) => index === 0 ? [region, true] : [region, false])),
        });
        setSelectedRegion({region: newDefaultRegions[0]});
    }

    function onSelectRegion(aliasedItem, isChecked, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        if(isChecked) {
            setSelectedRegion({region: item})
            setState(Object.fromEntries(defaultRegions.map((r) => getRegionAlias(r)).map(
                (region) => region === aliasedItem ? [region, true] : [region, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [aliasedItem]: true
            });
        }
    }

    function onAddPostal(event, state, setState) {
        const aliasedItem = event.target.value;
        const item = getPostalReverseAlias(aliasedItem);
        removeItemFromState(item, additionalPostal, setAdditionalPostal);
        addItemToState(item, defaultPostal, setDefaultPostal);
        setState({
            ...Object.fromEntries(defaultPostal.map((p) => getPostalAlias(p)).map((postal) => [postal, false])),
            [aliasedItem]: true,
        });
        setSelectedPostal({postal: item});
    }

    function onRemovePostal(aliasedItem, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        const newDefaultPostal = removeItemFromState(item, defaultPostal, setDefaultPostal)
        addItemToState(item, additionalPostal, setAdditionalPostal, true)
        setState({
            ...Object.fromEntries(newDefaultPostal.map((p) => getPostalAlias(p)).map((postal, index) => index === 0 ? [postal, true] : [postal, false])),
        });
        setSelectedPostal({postal: newDefaultPostal[0]});
    }

    function onSelectPostal(aliasedItem, isChecked, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        if(isChecked) {
            setSelectedPostal({postal: item})
            setState(Object.fromEntries(defaultPostal.map((p) => getPostalAlias(p)).map(
                (postal) => postal === aliasedItem ? [postal, true] : [postal, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [aliasedItem]: true
            });
        }
    }

    function onAddArea(event, state, setState) {
        const aliasedItem = event.target.value;
        const item = getAreaReverseAlias(aliasedItem);
        removeItemFromState(item, additionalAreas, setAdditionalAreas);
        addItemToState(item, defaultAreas, setDefaultAreas);
        setState({
            ...Object.fromEntries(defaultAreas.map((a) => getAreaAlias(a)).map((area) => [area, false])),
            [aliasedItem]: true,
        });
        setSelectedArea({area: item});
    }

    function onRemoveArea(aliasedItem, state, setState) {
        const item = getAreaReverseAlias(aliasedItem);
        const newDefaultAreas = removeItemFromState(item, defaultAreas, setDefaultAreas)
        addItemToState(item, additionalAreas, setAdditionalAreas, true)
        setState({
            ...Object.fromEntries(newDefaultAreas.map((a) => getAreaAlias(a)).map((area, index) => index === 0 ? [area, true] : [area, false])),
        });
        setSelectedArea({area: newDefaultAreas[0]});
    }

    function onSelectArea(aliasedItem, isChecked, state, setState) {
        const item = getAreaReverseAlias(aliasedItem);
        if(isChecked) {
            setSelectedArea({area: item})
            setState(Object.fromEntries(defaultAreas.map((a) => getAreaAlias(a)).map(
                (area) => area === aliasedItem ? [area, true] : [area, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [aliasedItem]: true
            });
        }
    }

    function onSelectModule(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedModule({module: item})
            setState(Object.fromEntries(defaultModules.map(
                (module) => module === item ? [module, true] : [module, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    const onClickDownload = async () => {
        if(isNUSOrg(authUser.org_code)) {
            setLoading(true);
            try {
                const result = await getDigitalSkillsDownloadData(authUser.org_code);
                if (result.data) {
                    console.log('Result Data of Digital Skills Excel Data --> ', result.data);
                    createExcelOutputFormat(result.data.LEVEL6_DICT, result.data.SCORES);
                } else {
                    setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
                }

            } catch (e) {
                console.log("Some error occurred while fetching digital skills excel data")
            }
            setTimeout(() => {
                setLoading(false)
            }, 200);
        }
        else {
            const workbook = XLSX.utils.book_new();
            let column_widths = []

            const final_array = [
                ["Selected Country:", selectedCountry.country],
                ["Selected Region:", selectedRegion.region],
                ["Selected Postal:", selectedPostal.postal],
                ["Selected Institute Code:", selectedArea.area],
                ["Selected Time:", new Date(selectedTimePeriod.time[0]).toDateString(), new Date(selectedTimePeriod.time[1]).toDateString()],
                [""],
                [...[""].concat(tableHeaderData.map((item) => item.header))],
                [...["Totals"].concat(tableHeaderData.map((item) => item.total))]
            ]

            tableData.filter((item) => item.category !== "ADII" || socio_economic_class).forEach((item) => {
                const category = item.category === "ADII" ? socio_economic_class
                    : item.category === "Region" ? administrative_sector : item.category
                const items_array = item.data.map((subItem) => {
                    return [subItem.subcategory, ...subItem.values.map((_, i) => `${subItem.values[i]}/${subItem.totals[i]} (${isNaN(subItem.percents[i]) ? 0 : subItem.percents[i]}%)`)]
                })
                final_array.push(...[
                    [""],
                    [category],
                    ...items_array
                ])
            })

            final_array.forEach((array) => {
                array.forEach(((item, index) => column_widths[index] = column_widths[index] ? Math.max(item.toString().length, column_widths[index], 10) : item.length))
            })

            const worksheet = XLSX.utils.aoa_to_sheet(final_array);
            worksheet["!cols"] = new Array(column_widths.length).fill(null).map((_, index) => {
                return {wch: column_widths[index]}
            });

            XLSX.utils.book_append_sheet(workbook, worksheet, skill);
            XLSX.writeFile(workbook, `Enhancement Efficacy ${skill}.xlsx`);
        }
    }

    const handleTimePeriodsResetAcknowledged = () => {
        setTimePeriodsReset(false);
    };

    return (
        <Paper container>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[[selectAllYears, ...timePeriods], countries, defaultRegions.map((r) => getRegionAlias(r)), defaultPostal.map((p) => getPostalAlias(p)), defaultAreas.map((a) => getAreaAlias(a)), defaultModules]}
                        onCheckCallbacks = {[onSelectYear, onSelectCountry, onSelectRegion, onSelectPostal, onSelectArea, onSelectModule]}
                        headers = {[timeHeader, countryHeader, regionHeader, postalHeader, areaHeader, moduleHeader]}
                        colourize = {[false, false, false, false, false, false]}
                        actions = {[undefined, !defaultCountriesExists ? X : undefined, X, X, X, undefined]}
                        actionsData = {[undefined, {style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, undefined]}
                        actionsCallbacks = {[undefined, onRemoveCountry, onRemoveRegion, onRemovePostal, onRemoveArea, undefined]}
                        footers = {[MonthRangePicker, !defaultCountriesExists ? CustomSelect : undefined, CustomSelect, CustomSelect, CustomSelect, undefined]}
                        footersData = {[
                            {onChangeDate: onSelectDateRange, minDate, maxDate, resetKey},
                            !defaultCountriesExists ?{
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_country"),
                                data: additionalCountries.map((country) => get_category_alias(country)),
                            } : undefined,
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__add_${administrative_sector.toLowerCase()}`),
                                data: additionalRegions.map((r) => getRegionAlias(r)),
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_postal"),
                                data: additionalPostal,
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_inst"),
                                data: additionalAreas,
                            },
                            undefined
                        ]}
                        footersCallbacks = {[{onOpen: onClickDatePicker}, !defaultCountriesExists ? {onChange: onAddCountry} : undefined, {onChange: onAddRegion}, {onChange: onAddPostal}, {onChange: onAddArea}, undefined]}
                        defaultCheckIndices = {[]}
                        timePeriodsReset={timePeriodsReset}
                        onResetAcknowledged={handleTimePeriodsResetAcknowledged}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                    {
                        pageData ?
                            <Paper container={"true"} mt={10} mb={5}>
                                <ColouredTypography variant={"body1"} size={"16px"} customcolor={"dimgrey"} textAlign={"center"} mb={5} mx={5}>
                                    {description}
                                </ColouredTypography>
                                <Typography mb={5} variant="h4" gutterBottom align={'center'}>
                                    {header === "" ?
                                        (!selectedRegion.region ?
                                                GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__${administrative_sector.toLowerCase()}_need`)
                                                : !selectedPostal.postal ?
                                                    GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__postal_need`)
                                                    : !selectedArea.area ?
                                                        GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__inst_need`)
                                                        : GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__mod_need`)
                                        )
                                        : header
                                    }
                                </Typography>
                                <Box mr={10}>
                                    <Grid container spacing={3} style={{alignItems: "center"}}>
                                        <Grid item xs={12} sm={2} md={2}>
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={10}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    {
                                                        <TableRow>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} customcolor={"white"} size={"12px"}>
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                            {
                                                                tableHeaderData.map(
                                                                    (item) => {
                                                                        return (
                                                                            <CustomTableCell align="center">
                                                                                <DefaultColouredTypography variant={"h6"} customcolor={"white"} size={"12px"}>
                                                                                    {
                                                                                        item.header === "Composite Total" ?
                                                                                            GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__${createLcKey(item.header)}`, item.header)
                                                                                            : item.header
                                                                                    }
                                                                                </DefaultColouredTypography>
                                                                            </CustomTableCell>
                                                                        )
                                                                    })
                                                            }
                                                        </TableRow>
                                                    }
                                                </TableHead>
                                                <TableBody>
                                                    {isNUSOrg(authUser.org_code) &&
                                                        <CustomTableRow key={"totals"}>
                                                            <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__totals")}</CustomTableCell>
                                                            {
                                                                tableHeaderData.map((item, index) => (
                                                                    <CustomTableCell align="center">{item.totals}</CustomTableCell>
                                                                ))
                                                            }
                                                        </CustomTableRow>
                                                    }
                                                    { !isNUSOrg(authUser.org_code) &&
                                                        <CustomTableRow key={"totals"}>
                                                            <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__totals")}</CustomTableCell>
                                                            {
                                                                tableHeaderData.map((item, index) => (
                                                                    <CustomTableCell align="center">{item.total}</CustomTableCell>
                                                                ))
                                                            }
                                                        </CustomTableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    <Spacer my={5}/>
                                    {
                                        tableData.filter((item) => item.category !== "ADII" || socio_economic_class).map((item) => {
                                            const cat = item.category === "ADII" ? socio_economic_class
                                                : item.category === "Region" ? administrative_sector : item.category
                                            return <Grid container spacing={3} style={{alignItems: "center"}}>
                                                <Grid item xs={12} sm={2} md={2}>
                                                    <Typography variant="h4" align={'center'}>
                                                        {GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__${createLcKey(cat)}`, cat)}
                                                    </Typography>
                                                    <Spacer my={10}/>
                                                </Grid>
                                                <Grid item xs={12} sm={10} md={10}>
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableBody>
                                                            {
                                                                item.data.map((subItem, index) => (
                                                                    <CustomTableRow key={index}>
                                                                        <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(subItem.subcategory)}`, subItem.subcategory)}</CustomTableCell>
                                                                        {
                                                                            subItem.percents.map((value, index) => {
                                                                                const content = (
                                                                                    <CustomTableCell align={"center"}>
                                                                                        <LinearProgressWithLabel value={isNaN(value) ? 0 : value}/>
                                                                                    </CustomTableCell>
                                                                                );

                                                                                const shouldShowTooltip = !isNUSOrg(authUser.org_code);

                                                                                return shouldShowTooltip ? (
                                                                                    <Tooltip arrow={true} placement={"top"} title={<Typography>{`${subItem.values[index]}/${subItem.totals[index]}`}</Typography>}>
                                                                                        {content}
                                                                                    </Tooltip>
                                                                                ) : content;
                                                                            })
                                                                        }
                                                                    </CustomTableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                        <Spacer my={10}/>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                    { !isNUSOrg(authUser.org_code) && //TODO: Need to remove constant check later.
                                    <Box align={"right"}>
                                        <Button variant="contained" alignSelf={"right"} onClick={onClickDownload}>
                                            {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                                        </Button>
                                    </Box>
                                    }
                                </Box>
                            </Paper>
                        :
                            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default GenericEfficacyTab;
