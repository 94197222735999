import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box, Button, Grid, Typography} from "../../../../components/StyledComponents/styledComponents";
import {darken} from "polished";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_LINK, GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme)=>({
    logo: {
        textAlign: "center",
        width: "256px",
    },
    title: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        fontFamily: "sans-serif",
        fontSize: "32px",
        lineHeight: "normal"
    },
    subtitle: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        fontFamily: "sans-serif",
        fontSize: "18px"
    },
    description: {
        color: "#004765",
        fontFamily: "sans-serif",
        fontSize: "18px",
    },
    link: {
        display: "block",
        textDecoration: "none",
        padding: "20px",
        margin: "10px",
        borderRadius: "5px",
        backgroundColor: theme.palette.secondary.main,
        color: "#ffffff",
        fontSize: "16px",
        fontWeight: "bold",
        '&:hover': {
            backgroundColor: darken(0.1, theme.palette.secondary.main),
        }
    }
}));

function Skill(props) {

    const classes = useStyles();
    const {title, objective, is_skill, key_points, time, buttons} = props;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    return <Box align={'center'}>
        <img className={classes.logo} src={'https://www.dqworld.net/static/307239050/img/zones/landing/dqworld.png'} alt={'DQ World Logo'}/>
        <Typography className={classes.title} align={'center'} my={5}>
            {GET_APP_LOCALIZE_TEXT(pageLc, title)}
        </Typography>
        <Grid container spacing={5}>
            <Grid item sm={12} md={8}>
                <Typography className={classes.subtitle} align={'left'}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "resources__objective")}
                </Typography>
                <Typography className={classes.description} variant={'body2'} paragraph={true} align={'left'}>
                    {
                        is_skill ? <Typography component={'span'} className={classes.subtitle} font_color={"#004765"}>{`${GET_APP_LOCALIZE_TEXT(pageLc, title)} `}</Typography> : null
                    }
                    {
                        GET_APP_LOCALIZE_TEXT(pageLc, objective)
                    }
                </Typography>
                <Typography className={classes.subtitle} align={'left'}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "resources__learn_points")}
                </Typography>
                <Typography className={classes.description} variant={'body2'} paragraph={true} align={'left'}>
                    {key_points.map((point) => <div>{GET_APP_LOCALIZE_TEXT(pageLc, point)}</div>)}
                </Typography>
            </Grid>
            <Grid item sm={12} md={4} my={5}>
                <Box style={{border: "solid", borderWidth: "thin", borderColor: "#004765"}} p={2}>
                    <Typography className={classes.subtitle} align={'center'} font_color={"#004765"}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, "resources__time_to_complete")}
                    </Typography>
                    <Typography className={classes.subtitle} align={'center'} my={5}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, time)}
                    </Typography>
                    {
                        buttons.map((button) =>
                            <a
                                className={classes.link}
                                href={GET_APP_LOCALIZE_LINK(pageLc, button.link)}
                                target={"_blank"}
                            >
                                {GET_APP_LOCALIZE_TEXT(pageLc, button.title)}
                            </a>
                        )
                    }
                </Box>
            </Grid>
        </Grid>
    </Box>
}

export default Skill;