import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
// import Container from "@material-ui/core/Container";
// import Button from "@material-ui/core/Button";
// import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
// import {MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";
import AddUserModal from '../components/AddUserModal.comp';
import UserPermissionModal from '../components/UserPermissionsModal.comp';

import {addNewUser, listAllUsers, removeAdminPortalUser, updateUserEntity, updateUserPermissions} from "../../../services/users.service";
import {
    USER_ACCESS_LEVEL_VERBOSE,
    NESTED_ACL_KEYS,
    USER_ACCESS_LEVEL
} from "../../../utils/constants";
// import {AccountServices} from "../../../services/organization.service";
import DeleteIcon from "@material-ui/icons/Delete";
import {useAuth} from "../../../context/auth";

import {
    Add as AddIcon,
} from "@material-ui/icons";

import {
    Button,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {AccountServices} from "../../../services/organization.service";
import {toastWarning} from "../../../utils/utils";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;


const useStyles = makeStyles((theme)=>({
    tr_act: {

    },
    tr_d_act: {
        background: '#f8dede!important'
    },
    act_btn: {
        marginTop: '-20px',
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        marginTop: '-20px',
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '10px 0px'
    },
    deleteBtn: {
        marginLeft: '6px',
        marginTop: '6px!important',
        color: '#ee3434',
        cursor: 'pointer',
        '&:hover': {
            color: '#f55353',
        }
    }
}));


function UserListView() {

    const classes = useStyles();

    const { authUser } = useAuth();

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);

    const [updatingPermissionOfUser, setUpdatingPermissionOfUser] = useState(undefined);

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showPermissionsModal, setShowPermissionsModal] = useState(false);

    const [showCreateBtn, setShowCreateBtn] = useState(false);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [showDeleteBtn, setShowDeleteBtn] = useState(false);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    useEffect( () => {
        loadPageData().then(d => console.log('table data loaded!', d));

        // following code block is till proper ACL is applied
        setShowCreateBtn(true)
        setShowUpdateBtn(true)
        setShowDeleteBtn(true)

        // if (authUser) {
        //     if (authUser.access_level === USER_ACCESS_LEVEL.PARTIAL_ACCESS) {
        //         const perms = authUser.permissions;
        //         perms[NESTED_ACL_KEYS.USERS_ADD] ? setShowCreateBtn(true) : setShowCreateBtn(false);
        //         perms[NESTED_ACL_KEYS.USERS_UPDATE_ENTITY] ? setShowUpdateBtn(true) : setShowUpdateBtn(false);
        //         perms[NESTED_ACL_KEYS.USERS_REMOVE] ? setShowDeleteBtn(true) : setShowDeleteBtn(false);
        //     } else if (authUser.access_level === USER_ACCESS_LEVEL.NO_ACCESS) {
        //         setShowCreateBtn(false)
        //         setShowUpdateBtn(false)
        //         setShowDeleteBtn(false)
        //     } else {
        //         setShowCreateBtn(true)
        //         setShowUpdateBtn(true)
        //         setShowDeleteBtn(true)
        //     }
        // }
    }, []);

    const loadPageData = async () => {
        setLoading(true);

        try {

            const result = await AccountServices.list_accounts(authUser.org_code);
            if (result.data && result.data.accounts) {
                setPageData(result.data.accounts);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const openCreateModal = () => {
        setShowAddUserModal(true);
    }

    const enableUserService = async (index) => {
        const is_active = true;
        let data_copy = pageData;
        const member_id = data_copy[index].member_id

        setLoading(true);
        toastWarning('Not Implemented !!');
        // try {
        //     await updateUserEntity(member_id, is_active);
        //     data_copy[index].is_active = is_active;
        //     setPageData([...data_copy]);
        // } catch (e) {
        //     // already toasted error
        // }
        setLoading(false);
    }

    const disableUserService = async (index) => {
        const is_active = false;
        let data_copy = pageData;
        const member_id = data_copy[index].member_id

        setLoading(true);
        toastWarning('Not Implemented !!');
        // try {
        //     await updateUserEntity(member_id, is_active);
        //     data_copy[index].is_active = is_active;
        //     setPageData([...data_copy]);
        // } catch (e) {
        //     // already toasted error
        // }
        setLoading(false);
    }

    const clickActionAddUser = () => {
        // setShowAddUserModal(true);
        toastWarning('Not Implemented !!');
    }

    const addAdminPortalUserService = async (email) => {
        setLoading(true);
        // try {
        //     const result = await addNewUser(email);
        //     if (result.data && result.data.new_user) {
        //         let dataCopy = pageData;
        //         dataCopy.push(result.data.new_user);
        //         setPageData([...dataCopy]);
        //     }
        // } catch (e) {
        //     // error already toasted
        //     console.log('User-Services -- exception occurred ==> ', e);
        // }
        setLoading(false);
        setShowAddUserModal(false);
    }

    const invokeDeleteUserService = async (member_id) => {
        setLoading(true);
        toastWarning('Not Implemented !!');
        // try {
        //     const result = await removeAdminPortalUser(member_id);
        //     if (result.data && result.data.deleted) {
        //         let updateData = pageData.filter(account => account.member_id !== member_id);
        //         setPageData([...updateData]);
        //     }
        // } catch (e) {
        //     // error already toasted
        //     console.log('AccountServices -- exception occurred ==> ', e);
        // }
        setLoading(false);
    }

    const openUserPermissionModal = (user) => {
        // setUpdatingPermissionOfUser(user);
        //
        // setShowPermissionsModal(true);

        toastWarning('Not Implemented !!');
    }

    const updateUserPermissionsService = async (data) => {
        setLoading(true);
        try {
            const result = await updateUserPermissions(updatingPermissionOfUser.member_id,data);
            // updated: true,
            //     updated_user: user
            if (result.data && result.data.updated_user) {
                const new_user = result.data.updated_user
                let dataCopy = pageData;
                dataCopy = dataCopy.map( usr => {
                    if(usr.member_id === new_user.member_id) {
                        return new_user;
                    } else {
                        return usr;
                    }
                });
                setPageData([...dataCopy]);
            }
        } catch (e) {
            // error already toasted
            console.log('User-Services -- exception occurred ==> ', e);
        }
        setLoading(false);

        // close modal when done
        setShowPermissionsModal(false);
        setUpdatingPermissionOfUser(undefined);
    }

    return (
        <React.Fragment>
            <div className={classes.headerInputGroup}>
                <Button variant="contained" color="primary"
                    onClick={clickActionAddUser}
                >
                    <AddIcon />
                    Add Portal User
                </Button>
            </div>

            <AddUserModal
                open={showAddUserModal}
                createAccountCallback={addAdminPortalUserService}
                handleClose={ () => setShowAddUserModal(false) }
            />

            <Card mb={6}>

                <Paper>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>Sr#</CustomTableCell>
                                <CustomTableCell align="center">Email</CustomTableCell>
                                <CustomTableCell align="center">Member-ID</CustomTableCell>
                                <CustomTableCell align="center">Active</CustomTableCell>
                                <CustomTableCell align="center">Access Level</CustomTableCell>
                                <CustomTableCell align="center">Actions</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                pageData.map( (item, index) => (
                                    <CustomTableRow key={index} className={item.is_active ? classes.tr_act : classes.tr_d_act}>
                                        <CustomTableCell component="th" scope="row">{index + 1}</CustomTableCell>
                                        <CustomTableCell align="center">{item.email}</CustomTableCell>
                                        <CustomTableCell align="center">{item.member_id}</CustomTableCell>
                                        <CustomTableCell align="center">{item.is_active ? 'YES' : 'NO'}</CustomTableCell>
                                        <CustomTableCell align="center">{USER_ACCESS_LEVEL_VERBOSE(pageLc)[item.access_level]}</CustomTableCell>
                                        <CustomTableCell align="center">
                                            {
                                                showUpdateBtn && <>
                                                    {
                                                        item.is_active ?
                                                            <Button onClick={()=>disableUserService(index)}
                                                                    className={classes.d_act_btn}>Disable</Button> :
                                                            <Button onClick={()=>enableUserService(index)}
                                                                    className={classes.act_btn}>Enable</Button>
                                                    }
                                                    <Button onClick={()=>openUserPermissionModal(item)}
                                                            style={{
                                                                marginTop: '-20px',
                                                                marginLeft: '5px',
                                                            }}
                                                            variant="contained"  color="primary">Permissions</Button>
                                                </>
                                            }
                                            {
                                                showDeleteBtn && <DeleteIcon
                                                    onClick={()=> {
                                                        invokeDeleteUserService(item.member_id);
                                                    }}
                                                    className={classes.deleteBtn}
                                                    fontSize={'large'} color={'error'}/>
                                            }
                                        </CustomTableCell>
                                    </CustomTableRow>
                                ))
                            }
                        </TableBody>
                    </Table>

                    <LoaderWithBackDrop loading={loading} />

                </Paper>
            </Card>

        </React.Fragment>

    );
}

export default UserListView;