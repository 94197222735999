import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const listAllOrders = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/orders/list_o',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const createOrder = async (org_code, order_details, license_count, license_type) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/orders/place_o',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        order_details,
        license_count,
        license_type
    };
    return invokeApi(requestObj);
}

export const withdrawOrder = async (org_code, id) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/orders/withdraw_o',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        id
    };
    return invokeApi(requestObj);
}