import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const getAssessmentAdoption = async (org_code, schools, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/assessment/adoption',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        schools,
        date_range
    };
    return invokeApi(requestObj);
}

export const getAssessmentEfficacy = async (org_code, skill, schools, classes, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/assessment/efficacy',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        skill,
        schools,
        classes,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionSchool = async (org_code, schools, classes, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/enhancement/adoption/school',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        schools,
        classes,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionMonth = async (org_code, schools, classes, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/enhancement/adoption/month',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        schools,
        classes,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionModule = async (org_code, schools, classes, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/enhancement/adoption/module',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        schools,
        classes,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionGender = async (org_code, schools, classes, modules, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/enhancement/adoption/gender',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        schools,
        classes,
        modules,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionAge = async (org_code, schools, classes, modules, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/enhancement/adoption/age',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        schools,
        classes,
        modules,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionSchoolLevel = async (org_code, schools, classes, modules, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/enhancement/adoption/school_level',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        schools,
        classes,
        modules,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementEfficacy = async (org_code, skill, schools, classes, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/enhancement/efficacy',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        skill,
        schools,
        classes,
        date_range
    };
    return invokeApi(requestObj);
}

export const getSchools = async (org_code, page_no, rows_per_page, order, order_by) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/progress/schools',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        page_no,
        rows_per_page,
        order,
        order_by
    };
    return invokeApi(requestObj);
}

export const getClasses = async (org_code, schools, page_no, rows_per_page, order, order_by) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/progress/classes',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        schools,
        page_no,
        rows_per_page,
        order,
        order_by
    };
    return invokeApi(requestObj);
}

export const getStudents = async (org_code, schools, classes, page_no, rows_per_page, order, order_by) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/progress/students',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        schools,
        classes,
        page_no,
        rows_per_page,
        order,
        order_by
    };
    return invokeApi(requestObj);
}

export const showImprovement = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/school/misc/show_improvement',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
    };
    return invokeApi(requestObj);
}