import React from "react";
import styled from "styled-components/macro";

import {
    Box,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
} from "@material-ui/core";


import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px;
  border-radius: 20px;
  aspect-ratio: 1 / 1;
  max-height: 200px;
  max-width: 200px;
`

const H4 = styled(Box)`
  display: flex;
  height: 90%;
  justify-content: center;
  align-items: flex-end;
`

const H2 = styled(Box)`
  display: flex;
  height: 10%;
  justify-content: center;
  align-items: flex-end;
`


export const Badge = ({ title, value }) => {
    return (
        <ColouredCard mb={3} style={{minWidth: "fit-content"}}>
            <CardContent>
                <H2>
                    <DefaultColouredTypography variant="h4" mb={5} fontWeight="fontWeightBold">
                        {title}
                    </DefaultColouredTypography>
                </H2>
                <H4>
                    <DefaultColouredTypography variant="h1" color={"white"}>
                        <Box fontWeight="fontWeightBold">{value}</Box>
                    </DefaultColouredTypography>
                </H4>
            </CardContent>
        </ColouredCard>
    );
};
