import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {Copyright} from '../../../components';

import { store } from 'react-notifications-component';
import { withRouter } from "react-router";

import { useFormik } from 'formik';

import {registerUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import Helmet from "react-helmet";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Register = (props) => {
    const classes = useStyles();

    const { setAuthToken, setAuthUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    useEffect( () => {
        // no registration allowed for this portal,
        // redirect back to login page, if someone access this page by direct url
        props.history.push('/login');
    }, [] )

    // const formik = useFormik({
    //     initialValues: {
    //         email: '',
    //         password: '',
    //         confirm_password: '',
    //         remember_me: false
    //     },
    //     onSubmit: async (values) => {
    //         console.log('Form data => ', values);
    //         if (values.password !== values.confirm_password) {
    //             return store.addNotification({
    //                 title: "Password MissMatch",
    //                 message: "Please provide the same password in both password fields!",
    //                 type: "warning",
    //                 insert: "bottom",
    //                 container: "top-right",
    //                 animationIn: ["animated", "fadeIn"],
    //                 animationOut: ["animated", "fadeOut"],
    //                 dismiss: {
    //                     duration: 2000,
    //                     showIcon: true
    //                 }
    //             });
    //         }
    //
    //         try {
    //             const result = await registerUser(values.email, values.password, values.remember_me);
    //             if (result.data && result.data.user) {
    //                 await setAuthToken(result.data.auth_token);
    //                 await setAuthUser(result.data.user);
    //                 props.history.push('/');
    //             }
    //         } catch (e) {
    //             // already toasted the error
    //         }
    //     }
    // });

    return (
        <Container component="main" maxWidth="xs">
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "auth__register")}`} />
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {GET_APP_LOCALIZE_TEXT(pageLc, "auth__sign_up")}
                </Typography>
                {/*<form className={classes.form} noValidate onSubmit={formik.handleSubmit}>*/}
                {/*    <Grid container spacing={2}>*/}
                {/*        <Grid item xs={12}>*/}
                {/*            <TextField*/}
                {/*                variant="outlined"*/}
                {/*                required*/}
                {/*                fullWidth*/}
                {/*                id="email"*/}
                {/*                label="Email Address"*/}
                {/*                name="email"*/}
                {/*                autoComplete="email"*/}
                {/*                onChange={formik.handleChange}*/}
                {/*                value={formik.values.email}*/}
                {/*            />*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={12}>*/}
                {/*            <TextField*/}
                {/*                variant="outlined"*/}
                {/*                required*/}
                {/*                fullWidth*/}
                {/*                name="password"*/}
                {/*                label="Password"*/}
                {/*                type="password"*/}
                {/*                id="password"*/}
                {/*                autoComplete="current-password"*/}
                {/*                onChange={formik.handleChange}*/}
                {/*                value={formik.values.password}*/}
                {/*            />*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={12}>*/}
                {/*            <TextField*/}
                {/*                variant="outlined"*/}
                {/*                required*/}
                {/*                fullWidth*/}
                {/*                name="confirm_password"*/}
                {/*                label="Confirm Password"*/}
                {/*                type="password"*/}
                {/*                id="confirm_password"*/}
                {/*                autoComplete="current-password"*/}
                {/*                onChange={formik.handleChange}*/}
                {/*                value={formik.values.confirm_password}*/}
                {/*            />*/}
                {/*        </Grid>*/}
                {/*        <FormControlLabel*/}
                {/*            control={<Checkbox*/}
                {/*                name="remember_me"*/}
                {/*                id="remember_me"*/}
                {/*                value={formik.values.remember_me}*/}
                {/*                onChange={formik.handleChange}*/}
                {/*                color="primary"*/}
                {/*            />}*/}
                {/*            label="Keep me logged In"*/}
                {/*        />*/}
                {/*    </Grid>*/}
                {/*    <Button*/}
                {/*        type="submit"*/}
                {/*        fullWidth*/}
                {/*        variant="contained"*/}
                {/*        color="primary"*/}
                {/*        className={classes.submit}*/}
                {/*    >*/}
                {/*        Sign Up*/}
                {/*    </Button>*/}
                {/*    <Grid container justify="flex-end">*/}
                {/*        <Grid item>*/}
                {/*            <Link href="/login" variant="body2">*/}
                {/*                Already have an account? Sign in*/}
                {/*            </Link>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</form>*/}
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withRouter(Register);