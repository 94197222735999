import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Typography,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    container: {
        margin: '15px',
        marginBottom: '0px',
    },
    badge_image: {
        height: '90px',
        //padding: '10px 10px',
        //background: theme.palette.grey[200],
        display: 'block',
        marginRight: 'auto',
    },
    short_name: {
        textAlign: 'left',
        marginTop: '6px',
    },
    full_name: {
        textAlign: 'left',
        marginTop: '6px',
        //fontSize: '16px',
        //fontWeight: 'bold',
    },
}));

const DqModuleBadge = ({short_name, full_name, logo}) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            {/*{*/}
            {/*    logo && <img src={logo} className={classes.badge_image}/>*/}
            {/*}*/}
            {
                full_name && <Typography variant="h6" className={classes.full_name}>{full_name}</Typography>
            }
        </div>
    )
}

export default DqModuleBadge;