import React, {useEffect, useState} from "react";

import {Paper, Box, Grid, Card, CardContent, Typography, Spacer,} from "../../../../components/StyledComponents/styledComponents"
import {GenericSidePanel} from "../../../../components/SidePanel/GenericSidePanel.comp";
import {
    addItemToState,
    removeItemFromState,
    predefinedSort,
    splitList, setSingleKeyTrueInState, addTrueKeyInState,
} from "../../../../utils/utils";
import {X} from "react-feather";
import {useAuth} from "../../../../context/auth";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {CustomSelect} from "../components/Select.comp";
import ProgressTable from "../components/ProgressTable";
import {Helmet} from "react-helmet";
import {getClasses} from "../../../../services/school.service";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

function Progress() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const {org_code, institute_code} = authUser;

    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);
    const [graphData, setGraphData] = useState(undefined);

    const subListDivision = 3;
    const [regions, setRegions] = useState([]);

    const regionHeader = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__select_school`);
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(undefined);

    const [regionState, setRegionState] = useState(undefined);

    const selectAllRegionsOption = "All"
    const selectAllRegionsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_school")

    const getRegionAlias = (code) => {
        return code === selectAllRegionsOption ? selectAllRegionsAliasedOption : code;
    }

    const getRegionReverseAlias = (alias) => {
        return alias === selectAllRegionsAliasedOption ? selectAllRegionsOption : alias;
    }

    useEffect(() => {
        if(!selectorsData && !firstLoad)
        {
            setGraphData(undefined);
            setSelectedRegion(undefined);
            setRegions([]);
        }
    }, [selectorsData])

    useEffect(() => {
        if(firstLoad) {
            loadSelectors().then(d => console.log('Enhancement Adoption Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const loadSelectors = async (page = 0, rowsPerPage = 10, order = "asc", orderBy = undefined) => {
        setLoading(true);
        try {
            const result = await getClasses(org_code, institute_code, page, rowsPerPage, order, orderBy);
            if(result.data)
            {
                const {selectors = {schools: [institute_code]}, data} = result.data;
                if(selectors)
                {
                    const {schools: _regions} = selectors;
                    //Initialize Schools
                    const _region = initializeRegions(_regions);
                }
                //Initialize Graph Data
                loadGraphData(data.data);
                //Set Selectors Data and Page Data
                setSelectorsData(selectors);
                setPageData(data.data);
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching enhancement adoption selectors", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadDataList = (page = 0, rowsPerPage = 10, order = "asc", orderBy = undefined) => {
        const selected_school = institute_code ? institute_code : selectedRegion;
        loadPageData(selected_school, page, rowsPerPage, order, orderBy);
    }

    const loadPageData = async (_region, page = 0, rowsPerPage = 10, order = "asc", orderBy = undefined) => {
        setLoading(true);
        try {
            setPageData(undefined);
            const result = await getClasses(org_code, _region, page, rowsPerPage, order, orderBy);
            if (result.data) {
                const {data} = result.data;
                setPageData(data.data);
                loadGraphData(data.data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption data", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function loadGraphData(data)
    {
        console.log("Data incoming", data);
        setGraphData(data);
    }

    function initializeRegions(_regions) {
        _regions.sort().sort(predefinedSort([selectAllRegionsOption]));
        const regionSubLists = splitList(_regions, subListDivision)
        setDefaultRegions(regionSubLists[0]);
        setAdditionalRegions(regionSubLists[1])
        setRegions(_regions);
        selectRegion(regionSubLists[0][0], false);
        return regionSubLists[0][0];
    }

    function selectRegion(_region, fetch = false)
    {
        setSelectedRegion(_region);
        if(_region)
        {
            if(fetch)
            {
                loadPageData(_region).then(d => console.log('Enhancement Adoption By Month Data Fetched - By Selection of Region', d));
            }
        }
        else
        {
            if(!pageData)
            {
                setDefaultRegions([]);
                setAdditionalRegions([]);
            }
            setGraphData(undefined)
        }
    }

    function onAddRegion(event, state, setState) {
        const aliasedItem = event.target.value
        const item = getRegionReverseAlias(aliasedItem);
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        selectRegion(item, true);
    }

    function onRemoveRegion(aliasedItem, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setSingleKeyTrueInState(newDefaultRegions.map((r) => getRegionAlias(r)), getRegionAlias(newDefaultRegions[0]), setState)
        if(selectedRegion !== newDefaultRegions[0])
        {
            selectRegion(newDefaultRegions[0], true);
        }
    }

    function onSelectRegion(aliasedItem, isChecked, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        if(isChecked) {
            selectRegion(item, true);
            setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    return (
        <Paper container>
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__progress")} | ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__classes")}`}/>
            <Grid container alignItems={'stretch'} spacing={2}>
                {
                    !institute_code ?
                        <Grid item xs={12} sm={12} md={3}>
                            <GenericSidePanel
                                data={[defaultRegions.map((r) => getRegionAlias(r))]}
                                onCheckCallbacks = {[onSelectRegion]}
                                headers = {[regionHeader]}
                                colourize = {[false]}
                                actions = {[X]}
                                actionsData = {[{style: {cursor: "pointer"}}]}
                                actionsCallbacks = {[onRemoveRegion]}
                                footers = {[CustomSelect]}
                                footersData = {[
                                    {
                                        title: GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__add_school`),
                                        data: additionalRegions.map((r) => getRegionAlias(r)),
                                    },
                                ]}
                                footersCallbacks = {[{onChange: onAddRegion}]}
                                defaultCheckIndices = {[]}
                                stateCallbacks = {[
                                    {
                                        state: regionState,
                                        setState: setRegionState
                                    },
                                ]}
                            />
                        </Grid>
                        : null
                }
                <Grid item xs={12} sm={12} md={!institute_code ? 9 : 12}>
                    {
                        graphData ?
                            <Paper container>
                                <ProgressTable
                                    org_code={org_code}
                                    school={selectedRegion}
                                    data={graphData}
                                    fetchSortedData={loadDataList}
                                />
                            </Paper>
                            :
                            <Typography primary variant={"h3"} align={'center'} py={5}>{errorMessage}</Typography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default Progress;