import React from "react";
import {Redirect, Switch} from "react-router-dom";
import {PrivateRouteWithLayout} from "../components";
import {Main as MainLayout} from "../layouts";

import {
    BaseliningSkills as School_Baselining_Skills,
    BaseliningCyber as School_Baselining_Cyber,
    AssessmentAdoption as School_Assessment_Adoption,
    AssessmentEfficacy as School_Assessment_Efficacy,
    EnhancementAdoption as School_Enhancement_Adoption,
    EnhancementEfficacy as School_Enhancement_Efficacy,
    Analysis as School_Analysis,
    Summary as School_Summary,
    Progress as Combined_Progress,
    SchoolProgress as Schools_Progress,
    ClassProgress as Classes_Progress,
    StudentsProgress as Students_Progress,
    Resources as School_Resources
} from "../pages/SchoolDashboard";

import {
    Classes as EmployeeClassesPage,
    Employees as EmployeesPage,
    EmployeesPendingActivation as EmployeesPendingActivationPage,
    EmployeesPendingApprovals as EmployeesPendingApprovalsPage,
    Schools as EmployeeSchoolsPage
} from "../pages";

export default [
    <Redirect
        exact
        from={`/:lc/home`}
        to={`/:lc/school/home`}
    />,

    <Redirect
        exact
        from={`/:lc/school/home`}
        to={`/:lc/school/home/competencies`}
    />,

    <PrivateRouteWithLayout
        component={School_Baselining_Skills}
        exact
        layout={MainLayout}
        path={`/:lc/school/home/competencies`}
    />,

    <PrivateRouteWithLayout
        component={School_Baselining_Cyber}
        exact
        layout={MainLayout}
        path={`/:lc/school/home/cyber`}
    />,

    <Redirect
        exact
        from={`/:lc/school/assessment`}
        to={`/:lc/school/assessment/reach`}
    />,

    <PrivateRouteWithLayout
        component={School_Assessment_Adoption}
        layout={MainLayout}
        exact
        path={`/:lc/school/assessment/reach`}
    />,

    <PrivateRouteWithLayout
        component={School_Assessment_Efficacy}
        layout={MainLayout}
        exact
        path={`/:lc/school/assessment/efficacy`}
    />,

    <Redirect
        exact
        from={`/:lc/school/enhancement`}
        to={`/:lc/school/enhancement/reach`}
    />,

    <PrivateRouteWithLayout
        component={School_Enhancement_Adoption}
        layout={MainLayout}
        exact
        path={`/:lc/school/enhancement/reach`}
    />,

    <PrivateRouteWithLayout
        component={School_Enhancement_Efficacy}
        layout={MainLayout}
        exact
        path={`/:lc/school/enhancement/efficacy`}
    />,

    <PrivateRouteWithLayout
        component={EmployeeClassesPage}
        exact
        layout={MainLayout}
        path={`/:lc/students/classes`}
    />,

    <PrivateRouteWithLayout
        component={EmployeeSchoolsPage}
        exact
        layout={MainLayout}
        path={`/:lc/students/schools`}
    />,

    <PrivateRouteWithLayout
        component={EmployeesPage}
        exact
        layout={MainLayout}
        path={`/:lc/students/list`}
    />,

    <PrivateRouteWithLayout
        component={EmployeesPendingActivationPage}
        exact
        layout={MainLayout}
        path={`/:lc/students/activation`}
    />,

    <PrivateRouteWithLayout
        component={EmployeesPendingApprovalsPage}
        exact
        layout={MainLayout}
        path={`/:lc/students/approval`}
    />,

    <PrivateRouteWithLayout
        component={School_Analysis}
        exact
        layout={MainLayout}
        path={`/:lc/analysis`}
    />,

    <PrivateRouteWithLayout
        component={School_Summary}
        exact
        layout={MainLayout}
        path={`/:lc/analysis/summary`}
    />,

    <PrivateRouteWithLayout
        component={Combined_Progress}
        exact
        layout={MainLayout}
        path={`/:lc/progress`}
    />,

    <PrivateRouteWithLayout
        component={Schools_Progress}
        exact
        layout={MainLayout}
        path={`/:lc/progress/schools`}
    />,

    <PrivateRouteWithLayout
        component={Classes_Progress}
        exact
        layout={MainLayout}
        path={`/:lc/progress/classes`}
    />,

    <PrivateRouteWithLayout
        component={Students_Progress}
        exact
        layout={MainLayout}
        path={`/:lc/progress/students`}
    />,

    <PrivateRouteWithLayout
        component={School_Resources}
        exact
        layout={MainLayout}
        path={`/:lc/resources`}
    />,

]