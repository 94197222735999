import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const dashboardModulesInfoService = async (org_code, locale_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/stats/org_modules_dashboard',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        locale_code
    };
    return invokeApi(requestObj);
}

export const nonOrgDqStatsService = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/stats/non_org_dq_stats',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}