import React from "react";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

import {
    Settings,
    Aperture,
    User,
    Users,
    PieChart,
    Mail,
    Package,
    Repeat,
    Home,
    Key,
    BarChart2,
    TrendingUp,
    BookOpen,
    Activity,
    CheckSquare,
    Book
} from "react-feather";
import {LOCAL_STORAGE_KEYS, ORG_TYPE, ORG_TYPE_VERBOSE, USER_ACCESS_LEVEL} from "../../../utils/constants";
import {isDQWEnabled, isEnvDev, isNUSOrg, showAnalysis, showCyberRisk} from "../../../utils/utils";
import {useAuth} from "../../../context/auth";

const dashboardSection = (locale) => { return {
    id: GET_APP_LOCALIZE_TEXT(locale, 'main__overview'),
    path: "/home",
    icon: <Home />,
    containsHome: true,
}};

const overviewSection = (locale) => { return {
    // id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__overview')}`,
    id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__program_overview'),
    path: "/program_overview",
    icon: <BookOpen />,
    containsHome: false,
}};

const baselineSection = (locale, org_code, org_type) => {
    const org_type_verbose = (ORG_TYPE_VERBOSE(locale)[org_type]).toLowerCase()
    const obj = {
        id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__baselining'),
        path: `/${org_type_verbose}/home`,
        icon: <Home />,
        containsHome: true,
        children: [
            {
                path: `/${org_type_verbose}/home/competencies`,
                name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__digital_competencies'),
            },
        ],
    }

    if((org_type_verbose === "school") || (org_type_verbose === "partner" && showCyberRisk(org_code)))
    {
        obj.children.push({
            path: `/${org_type_verbose}/home/cyber`,
            name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__cyber_risks'),
        })
    }
    return obj;
};

const assessmentSection = (locale, org_code, org_type) => {
    const org_type_verbose = (ORG_TYPE_VERBOSE(locale)[org_type]).toLowerCase()
    const obj = {
        id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__assessment'),
        path: `/${org_type_verbose}/assessment`,
        icon: <BarChart2 />,
        containsHome: false,
        children: []
    }
    // TODO: Need to rmeove constant check later.
    if(org_type_verbose === "partner" && !isNUSOrg(org_code))
    {
        obj.children.push({
            path: `/${org_type_verbose}/assessment/content`,
            name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__content'),
        })
    }
    if(org_type_verbose === "partner" && isNUSOrg(org_code))
    {
        obj.children.push(...[
            {
                path: `/${org_type_verbose}/assessment/reach`,
                name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__reach'),
            },
        ])
    } else {
        obj.children.push(...[
            {
                path: `/${org_type_verbose}/assessment/reach`,
                name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__reach'),
            },
            {
                path: `/${org_type_verbose}/assessment/efficacy`,
                name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__efficacy'),
            },
        ])
    }
    return obj;
}

const enhancementSection = (locale, org_code, org_type) => {
    const org_type_verbose = (ORG_TYPE_VERBOSE(locale)[org_type]).toLowerCase()
    const obj = {
        id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__enhancement'),
        path: `/${org_type_verbose}/enhancement`,
        icon: <TrendingUp />,
        containsHome: false,
        children: []
    }
    // TODO: Need to rmeove constant check later.
    if(org_type_verbose === "partner" && !isNUSOrg(org_code))
    {
        obj.children.push({
            path: `/${org_type_verbose}/enhancement/content`,
            name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__content'),
        })
    }
    if(org_type_verbose === "partner" && isNUSOrg(org_code))
    {
        obj.children.push(...[
            {
                path: `/${org_type_verbose}/enhancement/efficacy`,
                name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__efficacy'),
            },
        ])
    } else {
        obj.children.push(...[
            {
                path: `/${org_type_verbose}/enhancement/reach`,
                name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__reach'),
            },
            {
                path: `/${org_type_verbose}/enhancement/efficacy`,
                name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__efficacy'),
            },
        ])
    }
    return obj;
}

const analysisSection = (locale) => {
    const obj = {
        id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__in_depth_analysis'),
        path: "/analysis",
        icon: <Activity/>
    }
    return obj;
}

const progressSection = (locale, include_school) => {
    const obj = {
        id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__progress'),
        path: "/progress",
        icon: <CheckSquare/>,
    }

    obj.children = []
    if(include_school)
    {
        obj.children.push({
            path: "/progress/schools",
            name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__schools'),
        })
    }
    obj.children.push(...[
        {
            path: "/progress/classes",
            name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__classes'),
        },
        {
            path: "/progress/students",
            name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__students')
        }
    ])

    return obj;
}

const licensesSection = (locale) => { return {
    id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__licenses'),
    path: "/licenses",
    icon: <Key />,
    children: [
        {
            path: "/licenses",
            name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__add_licenses')
        },
        {
            path: "/students/activation",
            name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__pending_activations'),
        },
    ],
}};

const licensesSectionDefaultOrg = (locale) => { return {
    id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__licenses'),
    path: "/licenses",
    icon: <Key />,
    children: [
        {
            path: "/licenses",
            name: GET_APP_LOCALIZE_TEXT(locale, 'navigation__licenses_info')
        },
    ],
}};



const resourcesSection = (locale) => { return {
    id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__program_resources'),
    path: "/resources",
    icon: <Book />
}}

const contactUsSection = (locale) => { return {
    id: GET_APP_LOCALIZE_TEXT(locale, 'navigation__contact_us'),
    path: "/contact_us",
    icon: <Mail />,
}};

const employeesSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'stats_users')}`,
    path: "/employees",
    icon: <User />,
    children: [
        {
            path: "/employees/progress",
            name: "Users Progress",
        },
        {
            path: "/employees/scores",
            name: "Users Scores",
        },
        {
            path: "/employees_approval",
            name: `${GET_APP_LOCALIZE_TEXT(locale, 'main__pending_approvals')}`,
        },
    ],
}};

const ordersSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'navigation__orders')}`,
    path: "/orders",
    icon: <Package />,
}};


const studentsSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__students')}`,
    path: "/students",
    icon: <User />,
    children: [
        {
            path: "/students/classes",
            name: "Classes",
        },
        {
            path: "/students/list",
            name: "Students List",
        },
        {
            path: "/students/activation",
            name: "Pending Activations",
        },
        // {
        //     path: "/students/approval",
        //     name: `${GET_APP_LOCALIZE_TEXT(locale, 'main__pending_approvals')}`,
        // },
    ],
}}

const nonOrgSection = (locale) => { return {
    id: `${GET_APP_LOCALIZE_TEXT(locale, 'main__dq_stats')}`,
    path: "/non_org",
    icon: <PieChart />,
    children: [
        {
            path: "/non_org_profiles",
            name: `${GET_APP_LOCALIZE_TEXT(locale, 'main__profiles')}`,
        },
        {
            path: "/non_org_stats",
            name: `${GET_APP_LOCALIZE_TEXT(locale, 'main__stats')}`,
        }
    ],
}};

const usersSection = {
    id: "Portal Users",
    path: "/users",
    header: "Portal Extras",
    icon: <Users />,
    children: [
        {
            path: "/users/list",
            name: "List",
        }
    ],
};

const uiConfigSection = {
    id: "UI Config",
    path: "/ui_config",
    icon: <Aperture />,
    children: [
        {
            path: "/ui_config/area",
            name: "Areas",
        },
        {
            path: "/ui_config/badge",
            name: "Badges",
        }
    ],
};

const callbacksSection = {
    id: "Callbacks",
    path: "/callbacks",
    icon: <Repeat />,
};


const settingsSection = {
    id: "Settings",
    path: "/settings",
    icon: <Settings />,
};

// Routes visible in the sidebar
export const sidebarSections = (location) => {

    const pageLc = location.pathname.split('/')[1];
    const org_type = parseInt(localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type));
    const org_code = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_code);
    const auth_user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_user));
    const is_multi_institute = auth_user.access_level === USER_ACCESS_LEVEL.FULL_ACCESS_ANALYTICS_PORTAL_FULL_ORG;
    const {org_obj} = auth_user;

    let sections = [];

    if (org_type+'' === ORG_TYPE.COMPANY+'') {
        sections = [
            overviewSection(pageLc),
            baselineSection(pageLc, org_code, org_type),
            employeesSection(pageLc),
        ];
        if (is_multi_institute) sections.push(licensesSection(pageLc));
        if (is_multi_institute) sections.push(ordersSection(pageLc));
        sections.push(contactUsSection(pageLc));
    }
    if (org_type+'' === ORG_TYPE.SCHOOL+'') {
        sections = [
            overviewSection(pageLc),
            baselineSection(pageLc, org_code, org_type),
            assessmentSection(pageLc, org_code, org_type),
            enhancementSection(pageLc, org_code, org_type),
        ]
        if(showAnalysis() && isDQWEnabled(org_obj)) {
            sections.push(analysisSection(pageLc));
        }
        sections.push(progressSection(pageLc, is_multi_institute));
        if (is_multi_institute) sections.push(licensesSection(pageLc));
        if(isDQWEnabled(org_obj)) {
            sections.push(resourcesSection(pageLc))
        }
        if (is_multi_institute) sections.push(ordersSection(pageLc));
        sections.push(contactUsSection(pageLc))
    }

    if (org_type+'' === ORG_TYPE.DEFAULT+'') {
        //if (org_code != 'KSA') {
            sections.push(overviewSection(pageLc));
        //}
        sections.push(baselineSection(pageLc, org_code, org_type));
        sections.push(assessmentSection(pageLc, org_code, org_type));
        sections.push(enhancementSection(pageLc, org_code, org_type));
        sections.push(...[
            //progressSection(pageLc, is_multi_institute),
            licensesSectionDefaultOrg(pageLc),
        ])
        /*
        sections = [
            overviewSection(pageLc),
            baselineSection(pageLc, org_code, org_type),
            assessmentSection(pageLc, org_code, org_type),
            enhancementSection(pageLc, org_code, org_type),
            // usersSection,
            // callbacksSection,
            // settingsSection,
        ];*/
    }

    if (org_code && org_code.toLowerCase() === 'dq') {
        sections.push(nonOrgSection(pageLc));
    }

    if (sections.length === 0) {
        sections.push(dashboardSection(pageLc));
    }

    return sections;
};
