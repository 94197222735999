import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box, Button, Grid, Typography} from "../../../../components/StyledComponents/styledComponents";
import {darken} from "polished";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme)=>({
    logo: {
        textAlign: "center",
        width: "256px",
    },
    title: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        fontFamily: "sans-serif",
        fontSize: "32px"
    },
    description: {
        color: "#004765",
        fontFamily: "sans-serif",
        fontSize: "18px",
    },
    link: {
        display: "block",
        width: "35%",
        textDecoration: "none",
        padding: "20px",
        margin: "10px",
        borderRadius: "5px",
        backgroundColor: theme.palette.secondary.main,
        color: "#ffffff",
        fontSize: "24px",
        fontWeight: "bold",
        '&:hover': {
            backgroundColor: darken(0.1, theme.palette.secondary.main),
        }
    }
}));

function ParentalConsent(props) {

    const classes = useStyles();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    return <Box align={'center'} my={5} mx={5}>
        <img className={classes.logo} src={'https://www.dqworld.net/static/307239050/img/zones/landing/dqworld.png'} alt={'DQ World Logo'}/>
        <Typography className={classes.title} align={'center'} my={5}>
            {GET_APP_LOCALIZE_TEXT(pageLc, "resources__parental_consent")}
        </Typography>
        <Typography className={classes.description} variant={'body2'} paragraph={true} align={'justify'} my={5}>
            {GET_APP_LOCALIZE_TEXT(pageLc, "resources__parental_consent_desc_p1")}
            <br/>
            <br/>
            {GET_APP_LOCALIZE_TEXT(pageLc, "resources__parental_consent_desc_p2")}
        </Typography>
        <a
            className={classes.link}
            href={`https://storage.googleapis.com/izhero-public/resources/${pageLc}/5-1%5D%20Parental%20Consent%20Letter.pdf`}
            target={"_blank"}
        >
            <span style={{color: "yellow"}}>{GET_APP_LOCALIZE_TEXT(pageLc, "general__download")}</span>
            <br/>
            <span>{GET_APP_LOCALIZE_TEXT(pageLc, "resources__parent_consent_letter")}</span>
        </a>
    </Box>
}

export default ParentalConsent;