import React from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import {Radar} from "react-chartjs-2";
import {calculateRgba} from "react-spinners/helpers";

const ChartWrapper = styled.div`
    height: 300px;
`;

const colors = [
    "#9ACD32",
    "#4e78eb",
    "#f2177e",
    "#87CEEB",
    "#0D98BA",
    "#2E8B57",
    "#14138b",
    "#FFCC00",
    "#FFA500",
    "#FF5349",
    "#FF0000",
]

const RadarChart = React.forwardRef((props, ref) => {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {data, className} = props;

    let colorIndex = 0;

    const final_data = {
        labels: data.labels.map((label) => label.toString()),
        datasets: data.graphData ? data.graphData.map((dataItem) => {
            const color = dataItem.index < 0 || dataItem.index > (colors.length-1) ? "" : colors[dataItem.index];
            return {
                label: dataItem.country,
                backgroundColor: calculateRgba(color, dataItem.background_opacity),
                borderColor: calculateRgba(color, dataItem.border_opacity),
                pointBackgroundColor: calculateRgba(color, dataItem.border_opacity),
                pointBorderColor: "#ffffff",
                pointHoverBackgroundColor: "#ffffff",
                pointHoverBorderColor: calculateRgba(color, dataItem.border_opacity),
                pointRadius: 4,
                data: dataItem.data,
                hidden: !dataItem.show,
            }
        }) : [],
    };

    const options = {
        maintainAspectRatio: false,
        scale: {
            ticks: {
                suggestedMin: 0,
                beginAtZero: false,
            },
            pointLabels: {
                fontColor: data.selected.map((bool) => bool ? '#25b7dbff' : undefined),
            }
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                fontColor: '#666',
                fontStyle: 'bold',
                boxWidth: 40,
                usePointStyle: false,
            }
        },
    };

    return (
        <React.Fragment>
            <ChartWrapper className={className}>
                <Radar data={final_data} options={options} ref={ref} />
            </ChartWrapper>
        </React.Fragment>
    );
})

export default RadarChart;
