import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box, Button, Grid, Typography} from "../../../../components/StyledComponents/styledComponents";
import {darken} from "polished";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme)=>({
    logo: {
        textAlign: "center",
        width: "256px",
    },
    curriculum: {
        width: "300px"
    },
    primary: {
        fontFamily: "sans-serif",
        color: theme.palette.secondary.main,
    },
    secondary: {
        fontFamily: "sans-serif",
        color: "#004765",
    },
    title: {
        fontWeight: "bold",
        fontSize: "32px",
    },
    subtitle: {
        fontWeight: "bold",
        fontSize: "18px",
    },
    description: {
        fontSize: "12px",
        lineHeight: "1.2"
    },
}));

const skills = [
    {
        header: "resources__dci",
        description: "resources__dci_desc",
    },
    {
        header: "resources__stm",
        description: "resources__stm_desc",
    },
    {
        header: "resources__cbm",
        description: "resources__cbm_desc",
    },
    {
        header: "resources__csm",
        description: "resources__csm_desc"
    },
    {
        header: "resources__pm",
        description: "resources__pm_desc",
    },
    {
        header: "resources__ct",
        description: "resources__ct_desc",
    },
    {
        header: "resources__df",
        description: "resources__df_desc",
    },
    {
        header: "resources__de",
        description: "resources__de_desc"
    },
]

function DQWorldCurriculum(props) {

    const classes = useStyles();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    function SkillTypography(props) {
        const {header, description, ...rest} = props;
        return <>
            <Typography className={`${classes.secondary} ${classes.subtitle}`} {...rest}>
                {GET_APP_LOCALIZE_TEXT(pageLc, header)}
            </Typography>
            <Typography className={`${classes.primary} ${classes.description}`} variant={'body2'} paragraph={true} {...rest}>
                {GET_APP_LOCALIZE_TEXT(pageLc, description)}
            </Typography>
        </>
    }

    return <Box align={'center'}>
        <img className={classes.logo} src={'https://www.dqworld.net/static/307239050/img/zones/landing/dqworld.png'} alt={'DQ World Logo'}/>
        <Typography className={`${classes.primary} ${classes.title}`} align={'center'} my={5}>
            {GET_APP_LOCALIZE_TEXT(pageLc, "resources__dqw_curriculum")}
        </Typography>
        <Grid container justify={'space-between'} alignItems={'center'}>
            <Grid item xs>
                {
                    skills.slice(0, 4).map((skill, i) => <SkillTypography align={'right'} {...skill} mr={i === 1 || i === 2 ? 5 : 0}/>)
                }
            </Grid>
            <Grid item xs>
                <img className={classes.curriculum} src={'https://www.dqworld.net/static/307239050/img/zones/menu/curriculum.png'} alt={'DQ World Curriculum'}/>
            </Grid>
            <Grid item xs>
                {
                    skills.slice(4, 8).map((skill, i) => <SkillTypography align={'left'} {...skill} ml={i === 1 || i === 2 ? 5 : 0}/>)
                }
            </Grid>
        </Grid>
    </Box>
}

export default DQWorldCurriculum;