import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import BarChart from "../../components/BarChart";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import {X} from "react-feather";
import {CustomSelect} from "../../components/Select.comp";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {useAuth} from "../../../../../context/auth";
import {
    addItemToState,
    alphaNumericSort,
    predefinedSort,
    removeItemFromState,
    setSingleKeyTrueInState,
    addTrueKeyInState,
    splitList, createLcKey, isDQWEnabled
} from "../../../../../utils/utils";
import MonthRangePicker from "../../../../../components/DatePickers/MonthRangePicker.comp";
import {getEnhancementAdoptionSchoolLevel} from "../../../../../services/school.service";
import {DQW_DQC_MODULES} from "../../../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

let dq_modules = {};

function BySchoolLevelTab() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const {org_code, institute_code, org_obj} = authUser;
    const dqwEnabled = isDQWEnabled(org_obj);

    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    const subListDivision = 3;
    const [regions, setRegions] = useState([]);
    const [postalCodes, setPostalCodes] = useState(undefined);
    const [modules, setModules] = useState(undefined);

    const regionHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_school");
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(undefined);

    const postalHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_class");
    const [defaultPostal, setDefaultPostal] = useState([]);
    const [additionalPostal, setAdditionalPostal] = useState([]);
    const [selectedPostal, setSelectedPostal] = useState(undefined);

    const moduleHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_mod");
    const [defaultModules, setDefaultModules] = useState([]);
    const [additionalModules, setAdditionalModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState(undefined);

    const timeHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time");
    const [timePeriods, setTimePeriods] = useState([]);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState([undefined, undefined]);
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);

    const [graphData, setGraphData] = useState([]);

    const [regionState, setRegionState] = useState(undefined);
    const [postalState, setPostalState] = useState(undefined);
    const [moduleState, setModuleState] = useState(undefined);
    const [timeState, setTimeState] = useState(undefined);

    const selectAllRegionsOption = "All"
    const selectAllRegionsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_school")

    const selectAllPostalsOption = "All"
    const selectAllPostalsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_class")

    const selectAllModulesOption = "All"
    const selectAllModulesAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_mod")

    const modules_translation_map = {
        [selectAllModulesOption]: selectAllModulesAliasedOption,
        ...Object.fromEntries(Object.keys(dq_modules).map((key) => [key, dq_modules[key].code]))
    }

    const modules_reverse_translation_map = {
        [selectAllModulesAliasedOption]: selectAllModulesOption,
        ...Object.fromEntries(Object.keys(dq_modules).map((key) => [dq_modules[key].code, key]))
    }

    const selectAllYears = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_year");

    const getDateRangeOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length - 1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        return [startDate, endDate]
    }

    const getRegionAlias = (code) => {
        return code === selectAllRegionsOption ? selectAllRegionsAliasedOption : code;
    }

    const getRegionReverseAlias = (alias) => {
        return alias === selectAllRegionsAliasedOption ? selectAllRegionsOption : alias;
    }

    const getPostalAlias = (code) => {
        return code === selectAllPostalsOption ? selectAllPostalsAliasedOption : code;
    }

    const getPostalReverseAlias = (alias) => {
        return alias === selectAllPostalsAliasedOption ? selectAllPostalsOption : alias;
    }

    const getModuleAlias = (code) => {
        return modules_translation_map[code] ? modules_translation_map[code] : code;
    }

    const getModuleReverseAlias = (alias) => {
        return modules_reverse_translation_map[alias] ? modules_reverse_translation_map[alias] : alias;
    }

    useEffect(() => {
        if(!selectorsData && !firstLoad)
        {
            setGraphData([])
            setSelectedTimePeriod([undefined, undefined]);
            setSelectedRegion(undefined);
            setSelectedPostal(undefined);
            setSelectedModule(undefined);

            setModules(undefined);
            setPostalCodes(undefined);
            setRegions([]);
            setTimePeriods([]);
            setMinDate(undefined);
            setMaxDate(undefined);
        }
    }, [selectorsData])

    useEffect(() => {
        if(firstLoad) {
            initializeDQModules();
            loadSelectors().then(d => console.log('Enhancement Adoption Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const initializeDQModules = () => {
        dq_modules = DQW_DQC_MODULES(pageLc);
    }

    const loadSelectors = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementAdoptionSchoolLevel(org_code, institute_code);
            if(result.data)
            {
                const {selectors, data} = result.data;
                const {schools: _regions, classes: _postals, modules: _modules} = selectors;
                //Initialize Schools
                setRegions(_regions);
                const _region = initializeRegions(_regions);
                //Initialize Classes
                setPostalCodes(_postals);
                const _postal = initializePostals(_region, selectors);
                //Initialize Modules
                setModules(_modules);
                const _module = initializeModules(_region, _postal, selectors);
                //Initialize Time Periods
                initializeTimePeriods(_region, selectors);
                //Initialize Graph Data
                loadGraphData(data.data);
                //Set Selectors Data and Page Data
                setSelectorsData(selectors);
                setPageData(data.data);
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching enhancement adoption selectors", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadPageData = async (_region = selectedRegion, _postal = selectedPostal, _module = selectedModule, _time = selectedTimePeriod) => {
        setLoading(true);
        try {
            setPageData(undefined);
            const result = await getEnhancementAdoptionSchoolLevel(org_code, _region, _postal, _module, _time);
            if (result.data) {
                const {data} = result.data;
                setPageData(data.data);
                loadGraphData(data.data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption data")
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function loadGraphData(data)
    {
        setGraphData(data.sort(alphaNumericSort("title")).sort(predefinedSort(["Total"], "title", true)))
    }

    function initializeRegions(_regions) {
        let _region;
        _regions.sort().sort(predefinedSort([selectAllRegionsOption]));
        const regionSubLists = splitList(_regions, subListDivision)
        setDefaultRegions(regionSubLists[0]);
        setAdditionalRegions(regionSubLists[1])
        _region = regionSubLists[0][0];
        selectRegion(_region, false);
        return _region;
    }

    function initializePostals(_region, selectors = selectorsData) {
        let _postal = undefined;
        if(_region && selectors)
        {
            const {classes: _all_postals} = selectors;
            const _postals = _all_postals[_region];
            _postals.sort((a, b) => a - b).sort(predefinedSort([selectAllPostalsOption]));
            const subLists = splitList(_postals, subListDivision)
            setDefaultPostal(subLists[0]);
            setAdditionalPostal(subLists[1]);
            _postal = subLists[0][0];
            selectPostal(_postal, false);
            setSingleKeyTrueInState(subLists[0].map((p) => getPostalAlias(p)), getPostalAlias(_postal), setPostalState) //ensuring checkbox consistency
        }
        return _postal;
    }

    function initializeModules(_region, _postal, selectors = selectorsData)
    {
        let _module = undefined;
        if(_region && _postal && selectors)
        {
            const {modules: _all_modules} = selectors;
            const _modules = _all_modules[_region][_postal].filter((mod) => dqwEnabled || !mod.toLowerCase().includes("dqw"));
            _modules.sort(alphaNumericSort(undefined, true)).sort(predefinedSort([...Object.keys(modules_translation_map)]))
            setDefaultModules(_modules);
            _module = _modules[0];
            selectModule(_module, false);
            setSingleKeyTrueInState(_modules.map((m) => getModuleAlias(m)), getModuleAlias(_module), setModuleState) //ensuring checkbox consistency
        }
        return _module;
    }

    function initializeTimePeriods(_region, selectors = selectorsData) {
        let date_range = [undefined, undefined];
        if(_region && selectors)
        {
            const {years: _years} = selectors;
            const _timePeriods = _years[_region].map((year) => year.toString());
            setTimePeriods(_timePeriods);
            setSingleKeyTrueInState([selectAllYears, ..._timePeriods], selectAllYears, setTimeState);
            if(_timePeriods.length > 0) {
                date_range = getDateRangeOfYears(_timePeriods);
                const [start_date_iso, end_date_iso] = date_range;
                setMinDate(new Date(start_date_iso));
                setMaxDate(new Date(end_date_iso));
                selectTimePeriod(date_range, false);
            }
        }
        return date_range;
    }

    function selectRegion(_region, fetch = false, _postal, _module, _time)
    {
        setSelectedRegion(_region);
        if(_region)
        {
            if(fetch)
            {
                loadPageData(_region, _postal, _module, _time).then(d => console.log('Enhancement Adoption By Gender Data Fetched - By Selection of Region', d));
            }
        }
        else
        {
            if(!pageData)
            {
                setDefaultRegions([]);
                setAdditionalRegions([]);
            }
            setDefaultPostal([])
            setAdditionalPostal([])
            setDefaultModules([])
            setGraphData([])
        }
    }

    function selectPostal(_postal, fetch = false, _module)
    {
        setSelectedPostal(_postal);
        if(_postal)
        {
            if(fetch)
            {
                loadPageData(selectedRegion, _postal, _module).then(d => console.log('Enhancement Adoption By Gender Data Fetched - By Selection of Postal', d));
            }
        }
        else
        {
            setDefaultModules([])
            setGraphData([]);
        }
    }

    function selectModule(_module, fetch = false)
    {
        setSelectedModule(_module);
        if(_module)
        {
            if(fetch)
            {
                loadPageData(selectedRegion, selectedPostal, _module).then(d => console.log('Enhancement Adoption By Gender Data Fetched - By Selection of Module', d));
            }
        }
        else
        {
            setGraphData([]);
        }
    }

    function selectTimePeriod(_timePeriods, fetch = false)
    {
        setSelectedTimePeriod(_timePeriods);
        if(fetch)
        {
            loadPageData(selectedRegion, selectedPostal, selectedModule, _timePeriods).then(d => console.log('Enhancement Adoption By Gender Data Fetched - By Selection of Time Period', d));
        }
    }

    function onAddRegion(event, state, setState) {
        const aliasedItem = event.target.value
        const item = getRegionReverseAlias(aliasedItem);
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        const _postal = initializePostals(item);
        const _module = initializeModules(item, _postal);
        const _time = initializeTimePeriods(item);
        selectRegion(item, true, _postal, _module, _time);
    }

    function onRemoveRegion(aliasedItem, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setSingleKeyTrueInState(newDefaultRegions.map((r) => getRegionAlias(r)), getRegionAlias(newDefaultRegions[0]), setState)
        if(selectedRegion !== newDefaultRegions[0])
        {
            const _postal = initializePostals(newDefaultRegions[0]);
            const _module = initializeModules(newDefaultRegions[0], _postal);
            const _time = initializeTimePeriods(newDefaultRegions[0]);
            selectRegion(newDefaultRegions[0], true, _postal, _module, _time);
        }
    }

    function onSelectRegion(aliasedItem, isChecked, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        if(isChecked) {
            const _postal = initializePostals(item);
            const _module = initializeModules(item, _postal);
            const _time = initializeTimePeriods(item);
            selectRegion(item, true, _postal, _module, _time);
            setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    function onAddPostal(event, state, setState) {
        const aliasedItem = event.target.value;
        const item = getPostalReverseAlias(aliasedItem);
        removeItemFromState(item, additionalPostal, setAdditionalPostal);
        addItemToState(item, defaultPostal, setDefaultPostal);
        setSingleKeyTrueInState(defaultPostal.map((p) => getPostalAlias(p)), aliasedItem, setState);
        const _module = initializeModules(selectedRegion, item);
        selectPostal(item, true, _module);
    }

    function onRemovePostal(aliasedItem, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        const newDefaultPostal = removeItemFromState(item, defaultPostal, setDefaultPostal)
        addItemToState(item, additionalPostal, setAdditionalPostal, true)
        setSingleKeyTrueInState(newDefaultPostal.map((p) => getPostalAlias(p)), getPostalAlias(newDefaultPostal[0]), setState);
        const _module = initializeModules(selectedRegion, newDefaultPostal[0]);
        selectPostal(newDefaultPostal[0], true, _module);
    }

    function onSelectPostal(aliasedItem, isChecked, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        if(isChecked) {
            const _module = initializeModules(selectedRegion, item);
            selectPostal(item, true, _module);
            setSingleKeyTrueInState(defaultPostal.map((p) => getPostalAlias(p)), aliasedItem, setState);
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    function onSelectModule(aliasedItem, isChecked, state, setState) {
        const item = getModuleReverseAlias(aliasedItem);
        if(isChecked) {
            selectModule(item, true);
            setSingleKeyTrueInState(defaultModules.map((m) => getModuleAlias(m)), aliasedItem, setState);
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    function onSelectYear (item, isChecked, state, setState) {
        if(isChecked)
        {
            if(item === selectAllYears)
            {
                const date_range = getDateRangeOfYears(timePeriods);
                selectTimePeriod(date_range, true)
            }
            else
            {
                const date_range = getDateRangeOfYears([item]);
                selectTimePeriod(date_range, true)
            }
            setSingleKeyTrueInState([selectAllYears, ...timePeriods], item, setState);
        }
        else
        {
            addTrueKeyInState(item, state, setState);
        }
    }

    function onClickDatePicker(event, state, setState) {
        setSingleKeyTrueInState([selectAllYears, ...timePeriods], undefined, setState);
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();
        selectTimePeriod([start_date, end_date], true)
    }

    const header = selectedRegion && selectedPostal && selectedModule ? `${getRegionAlias(selectedRegion)} - ${getPostalAlias(selectedPostal)} - ${getModuleAlias(selectedModule)}` : ""

    let sums = {
        participants: 0,
    }
    if(graphData)
    {
        graphData.forEach((item) => {
            if(item.title === "Total")
                sums.participants = item.participants;
        })
    }

    return (
        <Paper container>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[defaultRegions.map((r) => getRegionAlias(r)), defaultPostal.map((p) => getPostalAlias(p)), defaultModules.map((m) => getModuleAlias(m)), [selectAllYears, ...timePeriods]]}
                        onCheckCallbacks = {[onSelectRegion, onSelectPostal, onSelectModule, onSelectYear]}
                        headers = {[regionHeader, postalHeader, moduleHeader, timeHeader]}
                        colourize = {[false, false, false, false]}
                        actions = {[X, X, undefined, undefined]}
                        actionsData = {[{style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, undefined, undefined]}
                        actionsCallbacks = {[onRemoveRegion, onRemovePostal, undefined, undefined]}
                        footers = {[CustomSelect, CustomSelect, undefined, MonthRangePicker]}
                        footersData = {[
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_school"),
                                data: additionalRegions.map((r) => getRegionAlias(r)),
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_class"),
                                data: additionalPostal,
                            },
                            undefined,
                            {onChangeDate: onSelectDateRange, minDate, maxDate}
                        ]}
                        footersCallbacks = {[{onChange: onAddRegion}, {onChange: onAddPostal}, undefined, {onOpen: onClickDatePicker}]}
                        defaultCheckIndices = {[]}
                        stateCallbacks = {[
                            {
                                state: regionState,
                                setState: setRegionState
                            },
                            {
                                state: postalState,
                                setState: setPostalState
                            },
                            {
                                state: moduleState,
                                setState: setModuleState
                            },
                            {
                                state: timeState,
                                setState: setTimeState
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                    {
                        pageData ?
                            <Paper container mt={10} mb={5}>
                                <Typography mb={5} variant="h4" gutterBottom align={'center'}>
                                    {header === "" ?
                                        (!selectedRegion ?
                                                GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__school_need")
                                                : !selectedPostal ?
                                                    GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__class_need")
                                                    : GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__mod_need")
                                        )
                                        : header
                                    }
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <BarChart
                                                    labels={[...graphData.filter((item) => item.title !== "Total").map((item) => GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item.title)}`, item.title))]}
                                                    values={[...graphData.filter((item) => item.title !== "Total").map((item) => item.participants)]}
                                                    color={false}
                                                    threshold={50}
                                                    titleX={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__school_level")}
                                                    titleY={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_count")}
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                    {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__school_level")}
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                    {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_count")}
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                    {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_percent")}
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            graphData.map((item, index) => (
                                                                item.title !== "Total" ?
                                                                    <CustomTableRow key={index}>
                                                                        <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item.title)}`, item.title)}</CustomTableCell>
                                                                        <CustomTableCell align="center">{item.participants}</CustomTableCell>
                                                                        <CustomTableCell align="center">
                                                                            {Math.round(item.participants/(sums.participants != 0 ? sums.participants : 1)*100)}%
                                                                        </CustomTableCell>
                                                                    </CustomTableRow>
                                                                    :
                                                                    <CustomTableRow key={"sum"}>
                                                                        <CustomTableCell align="center">
                                                                            <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "general__total")}</Bold>
                                                                        </CustomTableCell>
                                                                        <CustomTableCell align="center">
                                                                            <Bold>{item.participants}</Bold>
                                                                        </CustomTableCell>
                                                                        <CustomTableCell align="center">
                                                                            <Bold>
                                                                                {Math.round(item.participants/(sums.participants != 0 ? sums.participants : 1)*100)}%
                                                                            </Bold>
                                                                        </CustomTableCell>
                                                                    </CustomTableRow>
                                                            ))
                                                        }
                                                        {/*<CustomTableRow key={"sum"}>*/}
                                                        {/*    <CustomTableCell align="center">*/}
                                                        {/*        <Bold>Total</Bold>*/}
                                                        {/*    </CustomTableCell>*/}
                                                        {/*    <CustomTableCell align="center">*/}
                                                        {/*        <Bold>{sums.participants}</Bold>*/}
                                                        {/*    </CustomTableCell>*/}
                                                        {/*    <CustomTableCell align="center">*/}
                                                        {/*        <Bold>*/}
                                                        {/*            {Math.round(sums.participants/(sums.participants !== 0 ? sums.participants : 1)*100)}%*/}
                                                        {/*        </Bold>*/}
                                                        {/*    </CustomTableCell>*/}
                                                        {/*</CustomTableRow>*/}
                                                    </TableBody>
                                                </Table>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Paper>
                            :
                            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default BySchoolLevelTab;