import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const getEnhancementAdoptionRegionv2 = async (org_code, country, state, postal_code, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/enhancement/adoption/region',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country,
        state,
        postal_code,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionMonthv2 = async (org_code, country, state, postal_code, institute_code, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/enhancement/adoption/month',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country,
        state,
        postal_code,
        institute_code,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionModulev2 = async (org_code, country, state, postal_code, institute_code, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/enhancement/adoption/module',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country,
        state,
        postal_code,
        institute_code,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionGenderv2 = async (org_code, country, state, postal_code, institute_code, module, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/enhancement/adoption/gender',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country,
        state,
        postal_code,
        institute_code,
        module,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionAgev2 = async (org_code, country, state, postal_code, institute_code, module, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/enhancement/adoption/age',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country,
        state,
        postal_code,
        institute_code,
        module,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionADIIv2 = async (org_code, country, state, postal_code, institute_code, module, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/enhancement/adoption/adii',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country,
        state,
        postal_code,
        institute_code,
        module,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionIndigenousv2 = async (org_code, country, state, postal_code, institute_code, module, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/enhancement/adoption/indigenous',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country,
        state,
        postal_code,
        institute_code,
        module,
        date_range
    };
    return invokeApi(requestObj);
}

export const getEnhancementEfficacyv2 = async (org_code, skill, country, state, postal_code, institute_code, date_range) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/enhancement/efficacy',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        skill,
        country,
        state,
        postal_code,
        institute_code,
        date_range
    };
    return invokeApi(requestObj);
}