import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Box, Button, Grid, Typography} from "../../../../components/StyledComponents/styledComponents";
import {darken} from "polished";
import {skills} from "../data/skills";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme)=>({
    logo: {
        textAlign: "center",
        width: "256px",
    },
    title: {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
        fontFamily: "sans-serif",
        fontSize: "32px"
    },
    description: {
        color: "#004765",
        fontFamily: "sans-serif",
        fontSize: "18px",
    },
    link: {
        minWidth: "150px",
        maxWidth: "150px",
        minHeight: "75px",
        maxHeight: "75px",
        display: "block",
        textDecoration: "none",
        margin: "5px",
        borderRadius: "5px",
        backgroundColor: theme.palette.secondary.main,
        color: "#ffffff",
        fontSize: "16px",
        fontWeight: "bold",
        '&:hover': {
            backgroundColor: darken(0.1, theme.palette.secondary.main),
        }
    }
}));

function SkillPacks(props) {

    const {setStep} = props;

    const classes = useStyles();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    return <Box align={'center'}>
        <img className={classes.logo} src={'https://www.dqworld.net/static/307239050/img/zones/landing/dqworld.png'} alt={'DQ World Logo'}/>
        <Typography className={classes.title} align={'center'} my={5}>
            {GET_APP_LOCALIZE_TEXT(pageLc, "resources__skill_packs")}
        </Typography>
        <Button className={classes.link} onClick={() => setStep(1)}>{GET_APP_LOCALIZE_TEXT(pageLc, "resources__get_started_guide")}</Button>
        <Typography className={classes.description} variant={'body2'} paragraph={true} align={'justify'} my={5}>
            {GET_APP_LOCALIZE_TEXT(pageLc, "resources__skill_packs_desc")}
        </Typography>
        <Grid container justify={'space-evenly'} alignItems={'center'} flexWrap={true}>
            {
                skills.map((skill, i) => <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Button className={classes.link} onClick={() => setStep(i+2)}>{GET_APP_LOCALIZE_TEXT(pageLc, skill.title)}</Button>
                </Grid>)
            }
        </Grid>
    </Box>
}

export default SkillPacks;