import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Button as MuiButton, Tooltip,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {SidePanel} from "../../../../../components/SidePanel/SidePanel.comp";
import {Badge} from "../../components/Badge.comp";
import HorizontalBarChart from "../../components/HorizontalBarChart";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {getDqModules, getEnhancementContent} from "../../../../../services/partner.service";
import styled from "styled-components/macro";
import {useAuth} from "../../../../../context/auth";
import {DQ24_DEFINITIONS} from "../../../../../utils/constants";
import XLSX from "xlsx";
import {micro_badges} from "../../../../../utils/microbadges";
import {GET_APP_LOCALIZE_TEXT, MISSING_TRANSLATION_TEXT} from "../../../../../utils/dq_lc_service/lc_service";
import {getLevel3Service, getLevel6Service} from "../../../../../services/analysis.service";


const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>

const TooltipContent = (object, field1, field2) => object ?
    <>
        <Typography>
            {object[field1] ? object[field1] : ''}
        </Typography>
        <hr/>
        <Typography>
            {object[field2] ? object[field2] : ''}
        </Typography>
    </> :
    <>
    </>

let dq_24_definitions = {};

function Enhancement() {

    const {authUser} = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [selectedDQ, setSelectedDQ] = useState(undefined);
    const [DQs, setDQs] = useState([]);
    const [modules, setModules] = useState([]);
    const [pageData, setPageData] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');
    const [LEVEL6, setLEVEL6] = useState({});
    const [LEVEL3, setLEVEL3] = useState({});
    const [isLevel3Set, setIsLevel3Set] = useState(false);

    useEffect( () => {
        loadLevel3()
            .then(r => {
                console.log('LEVEL3 Data Fetched!', r);
                setIsLevel3Set(true);
            });
        loadPageData().then(d => console.log('Enhancement Content Data Fetched!', d));
    }, []);

    useEffect(() => {
        if (isLevel3Set) {
            initializeDQDefs();
        }
    }, [isLevel3Set]);

    const initializeDQDefs = () => {
        if(LEVEL3) {
            dq_24_definitions = LEVEL3;//DQ24_DEFINITIONS(pageLc)
        }
    }

    const transformMetaData = (objects) => {
        return objects.reduce((acc, obj) => {
            let modifiedDqcNo = obj.dqc_no;
            if (obj.dqc_no.startsWith("DQC") && obj.dqc_no.length === 5) {
                modifiedDqcNo = obj.dqc_no.replace('C0', '').replace('C', '');
            }
            acc[modifiedDqcNo] = {
                title: obj.full_name,
                definition: obj.definition,
                color: obj.color_code
            };
            return acc;
        }, {});
    };


    const loadLevel3 = async () => {
        try {
            const result = await getLevel3Service();
            if(!result.error) {
                const transformedMetaData = transformMetaData(result.data.metaData);
                setLEVEL3(transformedMetaData);
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementContent(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Enhancement Content --> ', result.data);
                const result2 = await getDqModules(authUser.org_code); //fetching modules related to org
                if(result2.data) {
                    setModules(result2.data)
                }
                initializeData(result.data)
                await loadLevel6();
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching enhancement content data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function initializeData(data) {
        setSelectedDQ(data[0].title)
        setDQs(data.map((item) => item.title))
        setPageData(data);
    }

    const loadLevel6 = async () => {
        try {
            const result = await getLevel6Service();
            if(!result.error) {
                setLEVEL6(result.data.metaData);
            }
        }
        catch (e) {
            console.error(e);
            // already toasted the error
        }
    }

    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_dq_comp")

    function onSelectDQ (selected_item, is_checked, state, setState) {
        if (is_checked) {
            setSelectedDQ(selected_item)
            setState(Object.fromEntries(DQs.map(
                (dq) => dq == selected_item ? [dq, true] : [dq, false]
            )));
        } else {
            setState((prevState) => ({
                ...prevState,
                [selected_item]: true,
            }));
        }
    }

    const data = pageData ? pageData.filter((item) => item.title == selectedDQ)[0] : undefined;
    if (data) {
        data.graphData.sort((a, b) => b.value - a.value);
    }
    const mainChart = {
        header: "",
        labels: pageData ? data.graphData.map(
            (dataItem) => {
                return dataItem.microBadge;
            }
        ) : [],
        data: pageData ? data.graphData.map(
            (dataItem) => {
                return dataItem.value * 100;
            }
        ) : [],
        modules: pageData ? data.graphData.map(
            (dataItem) => {
                return dataItem.associatedModules;
            }
        ) : [],
    }
    // mainChart.sort((a, b) => b.value - a.value);

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        const columns = ["Microbadge", "Associated Modules", "Comprehensiveness"]
        if(pageData)
        {
            pageData.forEach((category) => {
                const final_array = []; //[[`Alignment: ${category.alignment}%`], []]
                final_array.push(columns);
                let microBadgeTitle = ""
                category.graphData.forEach((item) => {
                    const {microBadge = "", associatedModules = "", value = 0.0} = item;
                    const matchingEntry = LEVEL6.find(entry => entry["gsm_no"] === microBadge);
                    if (matchingEntry) {
                        microBadgeTitle = `${microBadge}: ${matchingEntry.full_name}`;
                    } else {
                        microBadgeTitle = `${microBadge}`;
                    }
                    final_array.push([microBadgeTitle, associatedModules, value])
                })
                const worksheet = XLSX.utils.aoa_to_sheet(final_array);
                worksheet["!cols"] = columns.map((column, index) => {
                    return { wch: Math.max(15, column.length) }
                });
                XLSX.utils.book_append_sheet(workbook, worksheet, category.title);
            })
        }
        XLSX.writeFile(workbook, "Enhancement Content.xlsx");
    }

    const convertTitleToKey = (title) => {
        return `dq_code__${title.toLowerCase().replace(/ /g, '_').replace(/-/g, '_')}`;
    };

    const getLocalizedText = (pageLc, title) => {
        const key = convertTitleToKey(title);
        const localizedText = GET_APP_LOCALIZE_TEXT(pageLc, key);
        return localizedText !== MISSING_TRANSLATION_TEXT ? localizedText : title;
    };

    return (
        pageData ?
            <React.Fragment>
                <Paper container>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={2}>
                            <SidePanel
                                primaryHeader={primarySideHeader}
                                primaryData={DQs}
                                secondaryData={[]}
                                onCheckPrimary={onSelectDQ}
                                onCheckSecondary={() => {}}
                                primarySingleSelectIndex={0}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={10} align={"right"}>
                            <Card>
                                <CardContent>
                                    <Box component="container" sx={{ flexGrow: 1 }}>
                                        <Grid container spacing={3} align={"center"} alignItems={"center"}>
                                            <Grid item sm={12} md={3} lg={2}>
                                                <ColouredTypography mt={5} mb={5} variant={"body1"} align={"center"} size={"16px"} wrapped={"true"} textAlign={"center"}>
                                                    <Bold>{data.title}: </Bold>{getLocalizedText(pageLc, dq_24_definitions[data.title].title)}
                                                </ColouredTypography>
                                                {/*<Badge*/}
                                                {/*    title={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__total_align")}*/}
                                                {/*    value={`${data.alignment}%`}*/}
                                                {/*/>*/}
                                            </Grid>
                                            {/*<Divider orientation="vertical" flexItem />*/}
                                            <Grid item sm={12} md={9} lg={10}>
                                                <Card>
                                                    <CardContent>
                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                                                {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__degree_align")}
                                                            </ColouredTypography>
                                                            <Spacer my={10}/>
                                                            <Grid container>
                                                                <Grid item xs={3} sm={2} md={2} lg={2}/>
                                                                <Grid item xs={6} sm={7} md={7} lg={7}/>
                                                                {/*<Grid item xs={3} sm={3} md={3} lg={3}>*/}
                                                                {/*    <ColouredTypography variant={"h4"} size={"10px"} align={"center"}>*/}
                                                                {/*        {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__associate_mod")}*/}
                                                                {/*    </ColouredTypography>*/}
                                                                {/*</Grid>*/}
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item xs={4} sm={2} md={2} lg={2}>
                                                                    <Grid container direction={'column'} justify={"space-around"} alignItems={"flex-end"} style={{height: "300px", paddingTop: "5px", paddingBottom: "20px"}}>
                                                                        {
                                                                            mainChart.labels.map((label) =>
                                                                                <Grid item>
                                                                                    <ColouredTypography variant={"body1"} color={"grey"}>
                                                                                        <Tooltip arrow={true} title={TooltipContent(micro_badges[label], "full_name", "definition")} placement="bottom-end">
                                                                                            <span>{label}</span>
                                                                                        </Tooltip>
                                                                                    </ColouredTypography>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={6} sm={7} md={7} lg={7}>
                                                                    <HorizontalBarChart
                                                                        labels={mainChart.labels}
                                                                        values={mainChart.data}
                                                                        color={false}
                                                                        threshold={50}
                                                                        suggestedMax={100}
                                                                    />
                                                                </Grid>
                                                                {/*<Grid item xs={2} sm={3} md={3} lg={3}>*/}
                                                                {/*    <Grid container direction={'column'} justify={"space-around"} alignItems={"flex-start"} style={{height: "300px", paddingTop: "5px", paddingBottom: "20px"}}>*/}
                                                                {/*        {*/}
                                                                {/*            mainChart.modules.map((list) =>*/}
                                                                {/*                <Grid item>*/}
                                                                {/*                        {*/}
                                                                {/*                            list.split(', ').map((item, index) => {*/}
                                                                {/*                                const module_details = modules.find((module) => module.module_id == item.toString())*/}
                                                                {/*                                return (*/}
                                                                {/*                                <Tooltip arrow={true} placement={"bottom-center"} title={*/}
                                                                {/*                                    TooltipContent(module_details, "title", "desc")*/}
                                                                {/*                                }>*/}
                                                                {/*                                    <ColouredTypography variant={"body1"} color={"grey"} display={'inline'}>*/}
                                                                {/*                                        {`${index > 0 ? ', ' : ''}${item}`}*/}
                                                                {/*                                    </ColouredTypography>*/}
                                                                {/*                                </Tooltip>*/}
                                                                {/*                                )*/}
                                                                {/*                            })*/}
                                                                {/*                        }*/}
                                                                {/*                </Grid>*/}
                                                                {/*            )*/}
                                                                {/*        }*/}
                                                                {/*    </Grid>*/}
                                                                {/*</Grid>*/}
                                                            </Grid>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Button mt={5} variant="contained" onClick={onClickDownload}>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
                <LoaderWithBackDrop loading={loading} />
            </React.Fragment>
            :
            <Paper container>
                <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
    );
}

export default Enhancement;
