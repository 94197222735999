import React, {useEffect, useState} from "react";
import {
    DatePicker,
    LocalizationProvider,
} from "@material-ui/pickers";

import {
    TextField,
    Card as MuiCard,
    Typography as MuiTypography,
    CardContent as MuiCardContent,

} from "@material-ui/core";

import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isValid from "date-fns/isValid";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import enLocale from "date-fns/locale/en-GB";
import koLocale from "date-fns/locale/ko";
import thLocale from "date-fns/locale/th";
import jaLocale from "date-fns/locale/ja";

import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";
import Grid from "@material-ui/core/Grid";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  background-color: white;
`;


const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
  height: 100%;
`

const localeMap = {
    en: enLocale,
    ko: koLocale,
    th: thLocale,
    ja: jaLocale
}

export function getCurrentDate(separator=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}

export default function CustomDateRangePicker(props) {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];
    const localeObject = localeMap[pageLc];

    const {onChangeDate, minDate, maxDate, resetKey, onOpen = () => {}, ...rest} = props;
    const [startingDate, setStartingDate] = useState(null);
    const [endingDate, setEndingDate] = useState(null);

    useEffect( () => {
        if(startingDate && endingDate && isValid(startingDate) && isValid(endingDate) && isAfter(startingDate, minDate) && isBefore(endingDate, maxDate) && !isAfter(startingDate, endingDate))
        {
            onChangeDate([startOfMonth(startingDate), endOfMonth(endingDate)]);
        }
    }, [startingDate, endingDate])

    useEffect(() => {
        setStartingDate(null);
        setEndingDate(null);
    }, [resetKey]);


    return (
        <LocalizationProvider dateAdapter={DateFnsUtils} locale={localeObject}>
            <Grid container>
                <Grid item sm={12} md={12} lg={12} xl={6}>
                    <DatePicker
                        value={startingDate}
                        onChange={(date) => setStartingDate(date)}
                        views={["year", "month"]}
                        openTo={"month"}
                        minDate={minDate}
                        maxDate={endingDate ? endingDate : maxDate}
                        inputFormat={'MMM yyyy'}
                        renderInput={(props) =>
                            <TextField
                                label="Size"
                                id="outlined-size-small"
                                defaultValue="Small"
                                variant="outlined"
                                size="small"
                                {...props}
                                helperText={GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__start_month")}
                                onClick={(event) => onOpen(event)}
                            />
                        }
                        onOpen={onOpen}
                        {...rest}
                    />
                </Grid>
                <Grid item sm={12} md={12} lg={12} xl={6}>
                    <DatePicker
                        value={endingDate}
                        onChange={(date) => setEndingDate(date)}
                        views={["year", "month"]}
                        openTo={"month"}
                        minDate={startingDate ? startingDate : minDate}
                        maxDate={maxDate}
                        inputFormat={'MMM yyyy'}
                        renderInput={(props) =>
                            <TextField
                                label="Size"
                                id="outlined-size-small"
                                defaultValue="Small"
                                variant="outlined"
                                size="small"
                                {...props}
                                helperText={GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__end_month")}
                                onClick={(event) => onOpen(event)}
                            />
                        }
                        onOpen={onOpen}
                        {...rest}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
}