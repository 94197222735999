import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {LinearProgress} from '@material-ui/core';

const ColoredLinearProgress = withStyles(({
    colorPrimary: {
        backgroundColor: (props) => props.backgroundColor || "lightblue",
    },
    barColorPrimary: {
        backgroundColor: (props) => props.foregroundColor || "#25b7db",
    }
}))(LinearProgress);

export default ColoredLinearProgress;

//old implementation

// class ColoredLinearProgress extends Component {
//     render() {
//         const { classes } = this.props;
//         return <LinearProgress {...this.props} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>;
//     }
// }
//
// const styles = ({
//     colorPrimary: {
//         backgroundColor: (props) => props.backgroundColor,
//     },
//     barColorPrimary: {
//         backgroundColor: (props) => props.foregroundColor,
//     }
// });
//
// export default  withStyles(styles)(ColoredLinearProgress);