import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { AuthContext } from "./context/auth";
import { GlobalStateContext } from "./context/global_state";

import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";
import {
    StylesProvider,
    ThemeProvider as MuiThemeProvider,
    jssPreset,
} from "@material-ui/core/styles";

import createTheme from "./theme";

import Routes from './Routes';
import {LOCAL_STORAGE_KEYS} from "./utils/constants";

const browserHistory = createBrowserHistory();

const jss = create({
    ...jssPreset(),
    insertionPoint: document.getElementById("jss-insertion-point"),
});

function App(props) {

    /*
	* ***********
	* Auth Context Data
	* ***********
	*/
    const existingToken = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    const existingUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_user));

    const [authToken, setAuthToken] = useState(existingToken);
    const [authUser, setAuthUser] = useState(existingUser);

    const setToken = (data) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.auth_token, JSON.stringify(data));
        setAuthToken(data);
    }

    const setUser = (data) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.auth_user, JSON.stringify(data));
        setAuthUser(data);
    }

    /*
	* ***********
	* Global Context Data
	* ***********
	*/
    const [settings, _setSettings] = useState({
        dark_mode: false
    });

    const setSettings = (st_obj) => {
        _setSettings(st_obj);
    }

    /*
    * ***********
    * Main App Body
    * ***********
    */
    return (
        <StylesProvider jss={jss}>
            <MuiThemeProvider theme={createTheme(settings.dark_mode)}>
                <ThemeProvider theme={createTheme(settings.dark_mode)}>
                    <AuthContext.Provider value={{
                        authToken,
                        setAuthToken: setToken,
                        authUser,
                        setAuthUser: setUser
                    }}>
                        <GlobalStateContext.Provider value={{
                            settings,
                            setSettings
                        }}>
                            <Router history={browserHistory}>
                                <React.Fragment>
                                    <ReactNotification />
                                    <Routes />
                                </React.Fragment>
                            </Router>
                        </GlobalStateContext.Provider>
                    </AuthContext.Provider>
                </ThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>


    );
}

export default App;
