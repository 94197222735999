export const guide = {
    is_skill: false,
    title: "resources__guide_title",
    objective: "resources__guide_obj",
    key_points: [
        "resources__guide_point_1",
        "resources__guide_point_2",
        "resources__guide_point_3",
        "resources__guide_point_4",
        "resources__guide_point_5",
        "resources__guide_point_6",
        "resources__guide_point_7",
        "resources__guide_point_8",
        "resources__guide_point_9",
    ],
    time: "resources__time_30",
    buttons: [
        {
            title: "resources__lesson_pdf",
            link: "links__resource_guide_pdf"
        },
    ]
}

export const skills = [
    {
        is_skill: true,
        title: "resources__stm",
        objective: "resources__stm_obj",
        key_points: [
            "resources__upon_completion",
            "resources__stm_point_1",
            "resources__stm_point_2",
            "resources__stm_point_3",
        ],
        time: "resources__time_90_120",
        buttons: [
            {
                title: "resources__lesson_pdf",
                link: "links__resource_stm_pdf"
            },
            {
                title: "resources__ppt_slides",
                link: "links__resource_stm_ppt"
            }
        ]
    },
    {
        is_skill: true,
        title: "resources__pm",
        objective: "resources__pm_obj",
        key_points: [
            "resources__upon_completion",
            "resources__pm_point_1",
            "resources__pm_point_2",
            "resources__pm_point_3",
        ],
        time: "resources__time_50_75",
        buttons: [
            {
                title: "resources__lesson_pdf",
                link: "links__resource_pm_pdf"
            },
        ]
    },
    {
        is_skill: true,
        title: "resources__cbm",
        objective: "resources__cbm_obj",
        key_points: [
            "resources__upon_completion",
            "resources__cbm_point_1",
            "resources__cbm_point_2",
            "resources__cbm_point_3",
        ],
        time: "resources__time_50_75",
        buttons: [
            {
                title: "resources__lesson_pdf",
                link: "links__resource_cbm_pdf"
            },
        ]
    },
    {
        is_skill: true,
        title: "resources__dci",
        objective: "resources__dci_obj",
        key_points: [
            "resources__upon_completion",
            "resources__dci_point_1",
            "resources__dci_point_2",
            "resources__dci_point_3",
        ],
        time: "resources__time_50_75",
        buttons: [
            {
                title: "resources__lesson_pdf",
                link: "links__resource_dci_pdf"
            },
        ]
    },
    {
        is_skill: true,
        title: "resources__dfm_title",
        objective: "resources__dfm_obj",
        key_points: [
            "resources__upon_completion",
            "resources__dfm_point_1",
            "resources__dfm_point_2",
            "resources__dfm_point_3",
            "resources__dfm_point_4",
        ],
        time: "resources__time_40_60",
        buttons: [
            {
                title: "resources__lesson_pdf",
                link: "links__resource_dfm_pdf"
            },
        ]
    },
    {
        is_skill: true,
        title: "resources__csm",
        objective: "resources__csm_obj",
        key_points: [
            "resources__upon_completion",
            "resources__csm_point_1",
            "resources__csm_point_2",
            "resources__csm_point_3",
        ],
        time: "resources__time_50_75",
        buttons: [
            {
                title: "resources__lesson_pdf",
                link: "links__resource_csm_pdf"
            },
        ]
    },
    {
        is_skill: true,
        title: "resources__ct",
        objective: "resources__ct_obj",
        key_points: [
            "resources__upon_completion",
            "resources__ct_point_1",
            "resources__ct_point_2",
            "resources__ct_point_3",
        ],
        time: "resources__time_60_90",
        buttons: [
            {
                title: "resources__lesson_pdf",
                link: "links__resource_ct_pdf"
            },
        ]
    },
    {
        is_skill: true,
        title: "resources__de",
        objective: "resources__de_obj",
        key_points: [
            "resources__upon_completion",
            "resources__de_point_1",
            "resources__de_point_2",
            "resources__de_point_3",
            "resources__de_point_4"
        ],
        time: "resources__time_60_90",
        buttons: [
            {
                title: "resources__lesson_pdf",
                link: "links__resource_de_pdf"
            },
        ]
    }
]

export const pages = [
    guide,
    ...skills
]