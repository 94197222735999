import styled from "styled-components/macro";
import ColoredLinearProgress from "./ColouredLinearProgress.comp";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const LinearProgress = styled(ColoredLinearProgress)`
  height: 7px;
  width: 32px;
  border-radius: 0px;
`

export function LinearProgressWithLabel(props) {
    return (

        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <LinearProgress variant="determinate" {...props} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}
                </Typography>
            </Grid>
        </Grid>
    );
}