import React, {useEffect, useState} from 'react';
import styled from "styled-components/macro";

import {
    GRADE_LABELS,
    USER_ACCESS_LEVEL_VERBOSE,
} from "../../../utils/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import {useAuth} from "../../../context/auth";

import {
    Add as AddIcon,
} from "@material-ui/icons";

import {
    Button,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow, TableContainer, TablePagination,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {AccountServices} from "../../../services/organization.service";
import {toastWarning} from "../../../utils/utils";
import {getEmployeesClassesList} from "../../../services/employees.service";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import EnhancedTableCell from "./EnhancedTableCell.comp";
import {useLocation} from "react-router-dom";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;


const useStyles = makeStyles((theme)=>({
    tr_act: {

    },
    tr_d_act: {
        background: '#f8dede!important'
    },
    act_btn: {
        marginTop: '0px',
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        marginTop: '0px',
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '10px 0px'
    },
    deleteBtn: {
        marginLeft: '6px',
        marginTop: '6px!important',
        color: '#ee3434',
        cursor: 'pointer',
        '&:hover': {
            color: '#f55353',
        }
    }
}));

const headCells = [
    { id: "class_name", alignment: "center", label: "Class Name", sort: true },
    { id: "student_count", alignment: "center", label: "# of Students", sort: true },
    { id: "completion_rate", alignment: "center", label: "Completion Rate", sort: true },
    { id: "dq_avg_score", alignment: "center", label: "Avg DQ Score", sort: true },
    { id: "grade", alignment: "center", label: "Grade", sort: false },
];


function ListClasses() {

    const classes = useStyles();

    const { authUser } = useAuth();
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [pageData, setPageData] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [recordCount, setRecordCount] = React.useState(0);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState(headCells[0].id);

    useEffect( () => {
        let refresh = false;
        if(firstLoad) {
            refresh = true;
            setFirstLoad(false);
        }
        loadPageData(refresh).then(d => console.log('table data loaded!', d));
    }, [page, rowsPerPage, order, orderBy]);

    const loadPageData = async (refresh) => {
        if(refresh) {
            setLoading(true);
        }
        try {

            const result = await getEmployeesClassesList(authUser.org_code, page, rowsPerPage, order, orderBy);
            if (result.data && result.data.classes) {
                console.log('result.data.classes --> ', result.data.classes);
                setPageData(result.data.classes);
                setRecordCount(result.data.total_count);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <React.Fragment>
            <Card mb={6}>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {
                                        headCells.map((item) => (
                                            <EnhancedTableCell
                                                id={item.id}
                                                alignment={item.alignment}
                                                disablePadding={item.disablePadding}
                                                sort={item.sort}
                                                orderBy={orderBy}
                                                order={order}
                                                onRequestSort={handleRequestSort}
                                            >
                                                {item.label}
                                            </EnhancedTableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    pageData.map( (item, index) => {
                                    let grade;
                                    const counts = Object.keys(GRADE_LABELS).map((item) => Number(item));
                                    const goal = item.dq_avg_score === null ? 0 : item.dq_avg_score;
                                    const output = counts.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
                                    grade = GRADE_LABELS[output.toString()]
                                     return (
                                         <CustomTableRow key={index}>
                                             <CustomTableCell align="center">{item.class_name}</CustomTableCell>
                                             <CustomTableCell align="center">{item.student_count}</CustomTableCell>
                                             <CustomTableCell align="center">{item.completion_rate === 0 || item.completion_rate === null ? 0 : item.completion_rate.toFixed(2)} %</CustomTableCell>
                                             <CustomTableCell align="center">{item.dq_avg_score === 0 || item.dq_avg_score === null ? 0 : item.dq_avg_score.toFixed(2)}</CustomTableCell>
                                             <CustomTableCell align="center">{grade}</CustomTableCell>
                                         </CustomTableRow>
                                     );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={recordCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={`${GET_APP_LOCALIZE_TEXT(pageLc, 'employees__rows_per_page')}`}
                    />
                </Paper>
            </Card>
            <LoaderWithBackDrop loading={loading} />
        </React.Fragment>

    );
}

export default ListClasses;