import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../utils/constants";
import {invokeApi} from "../bl_libs/invokeApi";

export const getImpactRatingData = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/impact_rating',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getProgramOverviewData = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/program_overview',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getDqModules = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/dq_modules',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getDqSealModules = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/dq_seal_modules',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getDigitalSkillsData = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/digital_skills',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getDigitalSkillsData_v2 = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/digital_skills_v2',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getDigitalSkillsDownloadData = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/digital_skills_download',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getCyberRisksData = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/cyber_risks',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getComprehensivenessData = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/comprehensiveness',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getCredibilityData = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/credibility',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getAppropriatenessData = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/appropriateness',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getAssessmentAdoption = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/assessment/adoption',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getAssessmentAcquisition = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/assessment/acquisition',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getAssessmentImprovement = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/assessment/improvement',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getAssessmentIntention = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/assessment/intention',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getEnhancementContent = async (org_code) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/enhancement/content',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionRegion = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/enhancement/adoption/region',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionMonth = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/enhancement/adoption/month',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionModule = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/enhancement/adoption/module',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionGender = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/enhancement/adoption/gender',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionAge = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/enhancement/adoption/age',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionADII = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/enhancement/adoption/adii',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getEnhancementAdoptionIndigenous = async (org_code, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/enhancement/adoption/indigenous',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        country
    };
    return invokeApi(requestObj);
}

export const getEnhancementEfficacy = async (org_code, skill, country) => {
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
    let requestObj = {
        path   : '/analytics/partner/enhancement/efficacy',
        method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
    };
    requestObj['postData'] = {
        org_code,
        skill,
        country
    };
    return invokeApi(requestObj);
}
