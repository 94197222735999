import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet";

import {
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography as MuiTypography, CardContent,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import BarChart from "./components/BarChart";
import {useAuth} from "../../context/auth";
import RadarChart from "./components/RadarChart";
import TopSectionBadgeDashboard from "./components/topSectionBadgeDashboard.comp";
import MicroBadgeInfoTable from './components/MicroBadgeInfoTable.comp';
import {dashboardModulesInfoService} from "../../services/stats.service";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);


const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Dashboard() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [modulesInfo, setModulesInfo] = useState([]);

    useEffect( () => {
        loadModulesInfoData().then(d => console.log('licenses packs data loaded!', d));
    }, []);

    const loadModulesInfoData = async () => {
        setLoading(true);

        try {

            const result = await dashboardModulesInfoService(authUser.org_code);
            console.log('api::d10__resp -> ', result.data)
            if (result.data && result.data.modules) {
                setModulesInfo(result.data.modules);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    return (
        <React.Fragment>
            <Helmet title="Analytics Dashboard" />


            <Grid container spacing={6}>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <TopSectionBadgeDashboard
                        title={`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__company_avg')}`}
                        value="117.2"
                        title_color={'red'}
                        value_color={'red'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <TopSectionBadgeDashboard
                        title={`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__national_avg')}`}
                        value="110.4"
                        title_color={'red'}
                        value_color={'red'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <TopSectionBadgeDashboard
                        title={`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__global_avg')}`}
                        value="101.1"
                        title_color={'red'}
                        value_color={'red'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} xl>
                    <TopSectionBadgeDashboard
                        title= {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__company_percentile')}`}
                        value={`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__top')} 25%`}
                        title_color={'red'}
                        value_color={'red'}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={6}>
                <Grid item xs={12} lg={4}>
                    <RadarChart />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <BarChart />
                </Grid>
            </Grid>


            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <MicroBadgeInfoTable
                        modulesData={modulesInfo}
                    />
                </Grid>
            </Grid>

            <LoaderWithBackDrop loading={loading} />

        </React.Fragment>
    );
}

export default Dashboard;
