import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components/macro";
import {makeStyles} from "@material-ui/core/styles";
import { CSVLink } from 'react-csv';
import {useLocation} from "react-router-dom";

import Helmet from "react-helmet";

import {
    Grid,
    Divider as MuiDivider,
    Typography,
    TextField, Checkbox, FormControlLabel,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import TopSectionBadge from "./components/topSectionBadge.comp";
import {
    GetUsersCSV,
    LicensesInfoService,
    LicensesPacksListService,
    PostUsersCVS
} from "../../services/licenses.service";
import {useAuth} from "../../context/auth";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import Link from "@material-ui/core/Link";
import {toastDanger, toastSuccess, toastWarning} from "../../utils/utils";
import {ExcelRenderer, ExcelSheetNames} from "../../utils/excel_parser";
import {LOCAL_STORAGE_KEYS, ORG_TYPE} from "../../utils/constants";
import ProfilesDataPreviewModal from "./components/profilesDataPreviewModal.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";
import XLSX from "xlsx";
import breakpoints from "../../theme/breakpoints";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme)=>({
    imageDiv: {
        height: 60,
        marginTop: -10,
        cursor: "pointer"
    },
    section2_header: {
        textAlign: "left",
        margin: "20px 0px"
    },
    section3_header: {
        textAlign: "left",
        margin: "20px 0px",
        color: 'darkred',
    },
    error_logs: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: 'darkred',
            },
        },
    },
    csv_buttons: {
        marginLeft: '12px'
    }
}));

const head_csv_columns = [
    {name: 'Head Teacher Email', key: 0, json_key: 'head_email'},
    {name: 'First Name', key: 1, json_key: 'head_first_name'},
    {name: 'Last Name', key: 2, json_key: 'head_last_name'},
    {name: 'Country', key: 3, json_key: 'head_country'},
    {name: 'City', key: 4, json_key: 'head_city'},
    {name: 'Postal Code', key: 5, json_key: 'head_postal_code'},
    {name: 'School Name', key: 6, json_key: 'institute_code'}
]

const employees_csv_columns = [
    {name: 'First Name', key: 0, json_key: 'first_name'},
    {name: 'Last Name', key: 1, json_key: 'last_name'},
    {name: 'Department', key: 2, json_key: 'department'},
    {name: 'Job Title', key: 3, json_key: 'job_title'},
    {name: 'Birthdate (DD/MM/YYYY)', key: 4, json_key: 'birthdate', data_parser: dateParser},
    {name: 'Email Address', key: 5, json_key: 'email_address'},
    {name: 'Gender (Male /Female /Other)', key: 6, json_key: 'gender'},
    {name: "Postal Code", key: 7, json_key: 'postal_code'},
    {name: "Username (Optional / Required to Update)", key: 8, json_key: 'username'}
]

const students_csv_columns = [
    {name: 'First Name', key: 0, json_key: 'first_name'},
    {name: 'Last Name', key: 1, json_key: 'last_name'},
    {name: 'Class Name', key: 2, json_key: 'class_name'},
    {name: 'Grade Level', key: 3, json_key: 'grade_level'},
    {name: 'School Level', key: 4, json_key: 'school_level'},
    {name: 'Birthdate (DD/MM/YYYY)', key: 5, json_key: 'birthdate', data_parser: dateParser},
    {name: `Parent's Email Address`, key: 6, json_key: 'email_address'},
    {name: 'Gender (Male /Female /Other)', key: 7, json_key: 'gender'},
    {name: "Postal Code", key: 8, json_key: 'postal_code'},
    {name: "Teacher First Name", key: 9, json_key: 'incharge_first_name'},
    {name: "Teacher Last Name", key: 10, json_key: 'incharge_last_name'},
    {name: "Teacher Email Address", key: 11, json_key: 'incharge_email'},
    {name: "License (DQP/DQW/Both)", key: 12, json_key: 'license_type'},
    {name: "Username (Optional / Required to Update)", key: 13, json_key: 'username'},
    {name: "Password (Optional)", key: 14, json_key: 'password'}
]

function dateParser(date) {
    if(typeof date === "string") {
        return date;
    }
    else if(typeof date === "object") {
        return `${date.getDate().toString().padStart(2,'0')}/${(date.getMonth()+1).toString().padStart(2,'0')}/${date.getFullYear()}`
    }
    return "";
}

const head_example = ["teacher0@dq.com", "Jasmine", "Wood", "Japan", "Tokyo", "58933", "School A"]
const employee_example = ["Adam", "Mike", "IT", "Manager","15/10/2002","mike@dq.com", 'male',"00000"]
const student_example = ["Adam", "Mike", "IT", "Grade A", "Senior","15/10/2011","mike@dq.com","male", "00000", "Jasmine", "Wood", "teacher0@dq.com", "DQP"]

function Licenses() {

    const classes = useStyles();

    const { authUser } = useAuth();
    const fileInput = useRef();
    // const csvLink = useRef()

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [licensesInfo, setLicensesInfo] = useState(undefined);
    const [showDqwLicenses, setShowDqwLicenses] = useState(false);
    const [errors, setErrors] = useState([]);
    const [csvHeaders, setCsvHeaders] = useState({});

    const [openDataPreview, setOpenDataPreview] = useState(false);
    const [previewData, setPreviewData] = useState([]);
    const [excelDataJson, setExcelDataJson] = useState({});
    const org_type = localStorage.getItem(LOCAL_STORAGE_KEYS.active_org_type);
    const [uploadBtnHint, setUploadBtnHint] = useState('');

    const [ignoreEVerify, setIgnoreEVerify] = useState(false);
    const [useDefaultPass, setUseDefaultPass] = useState(false);

    const { email = '' } = authUser;
    const [local_part, domain] = email.split("@");
    const enable_additional_upload_settings = ["dqforall.com", "dqlab.net"].includes(domain);
    const options = {ignore_email_verification: ignoreEVerify, use_default_password: useDefaultPass};

    useEffect( () => {
        loadLicensesInfoData().then(d => console.log('licenses info data loaded!', d));
    }, []);

    const downloadSampleCsv = () => {
        //data preparation
        const csvData = [];
        if(csvHeaders.head) {
            csvData.push(csvHeaders.head.map((col) => col.name));
            csvData.push(head_example);
            csvData.push([], [], [])
        }
        if(csvHeaders.license) {
            csvData.push(csvHeaders.license.map((col) => col.name));
            if(org_type+'' === ORG_TYPE.SCHOOL+'')
                csvData.push(student_example);
            else
                csvData.push(employee_example);
        }
        //xlsx preparation
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(csvData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
        XLSX.writeFile(workbook, "profiles_data.xlsx");
    }

    const downloadLicensesCsv = async () => {
        const keys = csvHeaders.license.map((o) => o.json_key);
        setLoading(true);
        try {
            const result = await GetUsersCSV(authUser.org_code, keys)
            console.log('response from csv download service -> ', result);
            if (result.data) {
                //result should be [{head: {}, license: []}, {head: {}, license: []}]
                const workbook = XLSX.utils.book_new();
                for(const sheet_data of result.data) {
                    const csvData = [];
                    if(csvHeaders.head) {
                        csvData.push(csvHeaders.head.map((col) => col.name));
                        csvData.push(csvHeaders.head.map((col) => sheet_data.head[col.json_key]))
                        csvData.push([], [], []);
                    }
                    if(csvHeaders.license) {
                        csvData.push(csvHeaders.license.map((col) => col.name));
                        for(const license of sheet_data.license) {
                            csvData.push(csvHeaders.license.map((col) => license[col.json_key]))
                        }
                    }
                    const worksheet = XLSX.utils.aoa_to_sheet(csvData);
                    const file_name = csvHeaders.head ? sheet_data.head.institute_code : "Licenses" ;
                    XLSX.utils.book_append_sheet(workbook, worksheet, file_name);
                }
                XLSX.writeFile(workbook, "existing_licenses.xlsx");
            }
        } catch (e) {
            // error already toasted
        }
        setLoading(false);
    }

    const renderFile = async (fileObj) => {

        const preview_data = [];
        const json_data = [];

        //fetching sheet list
        try {
            const sheet_list = await ExcelSheetNames(fileObj, () => {});
            //iterating sheet list
            for(const sheet_name of sheet_list) {
                const resp = await ExcelRenderer(fileObj, sheet_name, () => {});
                const jsonObj = {};

                let row_start_index = 0;
                let consecutive_empty_rows_count = 0;
                for(const [key, columns] of Object.entries(csvHeaders)) {

                    const previewObj = {rows: [], columns} //for preview
                    jsonObj[key] = []; //for post

                    if(key === "head") previewObj.title = `Sheet ${sheet_name} - Head Information`
                    else previewObj.title = `Sheet ${sheet_name} - Licenses Information`

                    for (let i=row_start_index; i<resp.rows.length; i++) {
                        if (resp.rows[i].length > 0 && !!resp.rows[i].join("").trim()) {
                            if(resp.rows[i][0] === columns[0].name) continue; //header

                            let preview_row = []; //for preview
                            const json_row = {}; //for post

                            for (let col of columns) {
                                let colData = '';
                                if (resp.rows[i][col.key]) {
                                    if (col.data_parser) {
                                        colData = col.data_parser(resp.rows[i][col.key]);
                                    } else {
                                        if (typeof resp.rows[i][col.key] === 'string') {
                                            colData = resp.rows[i][col.key].trim();
                                        } else {
                                            colData = resp.rows[i][col.key];
                                        }
                                    }
                                }

                                preview_row.push(colData);
                                json_row[col.json_key] = colData;
                            }
                            previewObj.rows.push(preview_row);
                            jsonObj[key].push(json_row);

                            consecutive_empty_rows_count = 0;
                        }
                        else {
                            consecutive_empty_rows_count++;
                            if(consecutive_empty_rows_count >= 3 && key === "head") {
                                consecutive_empty_rows_count = 0;
                                row_start_index = i + 2; //+2 explanation: 1 for headers row, 1 for jumping onto next row
                                break; //move onto the next csv header key
                            }
                        }
                    }
                    preview_data.push(previewObj);
                }
                json_data.push(jsonObj);
            }

            setPreviewData(preview_data);
            setExcelDataJson(json_data);

            console.log('data to post to server -> ', json_data);

            setOpenDataPreview(true);
        }
        catch(error) {
            console.log("Failed to load excel, error: ", error);
        }

        setLoading(false);
    }

    const processUploadData = async () => {
        setLoading(true);
        try {
            const rslt = await PostUsersCVS(authUser.org_code, excelDataJson, options)
            console.log('response from csv upload sevice -> ', rslt);
            if (rslt.data) {
                const {dqp_info, dqw_info, errors} = rslt.data;
                setLicensesInfo({dqp_info, dqw_info});
                setErrors(errors);
                if(errors.length > 0)
                {
                    toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__server_response"), GET_APP_LOCALIZE_TEXT(pageLc, "licenses__invalid_records"));
                }
                else
                {
                    toastSuccess(GET_APP_LOCALIZE_TEXT(pageLc, "licenses__upload_success"));
                }
            }
        } catch (e) {
            // error already toasted
        }

        setOpenDataPreview(false);
        setLoading(false);
    }

    const fileHandler = (event) => {
        if(event.target.files.length){
            let fileObj = event.target.files[0];
            let fileName = fileObj.name;

            setLoading(true);

            //check for file extension and pass only if it is .xlsx and display error message otherwise
            if(['xlsx', 'csv'].includes(fileName.slice(fileName.lastIndexOf('.')+1))){
                renderFile(fileObj)
            }
            else{
                setLoading(false);
                toastWarning(GET_APP_LOCALIZE_TEXT(pageLc, "licenses__upload_invalid_type"));
            }
        }
    }

    const openFileBrowser = () => {
        fileInput.current.click();
    }

    const loadLicensesInfoData = async () => {
        setLoading(true);

        try {

            let extra_columns = [];
            const result = await LicensesInfoService(authUser.org_code);
            if (result.data) {
                const info = result.data;
                setLicensesInfo(info);

                if (info.profile_auto_link && info.profile_auto_link.enable) {
                    if (info.profile_auto_link.strategy_name === 'domain') {

                        if (info.profile_auto_link.strategy_params && info.profile_auto_link.strategy_params.domains) {
                            const allowed_domains = info.profile_auto_link.strategy_params.domains;
                            setUploadBtnHint(`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__allowed_domain_hint')}: ${allowed_domains.join(', ')}`);
                        }

                        if (org_type+'' === ORG_TYPE.SCHOOL+'') {
                            extra_columns.push({name: "OpenID Email (optional)", key: 15, json_key: 'openid_email'});
                        } else {
                            extra_columns.push({name: "OpenID Email (optional)", key: 9, json_key: 'openid_email'})
                        }

                    }
                }

                const headers_obj = {}
                if (org_type+'' === ORG_TYPE.SCHOOL+'') {
                    headers_obj.head = head_csv_columns;
                    headers_obj.license = [...students_csv_columns, ...extra_columns];
                } else {
                    headers_obj.license = [...employees_csv_columns, ...extra_columns];
                }
                setCsvHeaders(headers_obj);

                console.log('about to check dq-world licenses info...', info);
                if (info.dqw_access) {
                    setShowDqwLicenses(true);
                    console.log('we are all set to show the dq-world licenses info...')
                }
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const getInfoText = (context) => {
        if (licensesInfo) {
            switch (context) {
                case 'total':
                    return {
                        dqp: licensesInfo.dqp_info.total,
                        dqw: licensesInfo.dqw_info.total
                    };
                case 'used':
                    return {
                        dqp: licensesInfo.dqp_info.used,
                        dqw: licensesInfo.dqw_info.used

                    };
                case 'unused':
                    return {
                        dqp: licensesInfo.dqp_info.total - licensesInfo.dqp_info.used,
                        dqw: licensesInfo.dqw_info.total - licensesInfo.dqw_info.used
                    };
            }
        }
        return {
            dqp: 0,
            dqw: 0
        };

    }

    let add_user_sec_display_flg = true;

    if ( org_type+'' === ORG_TYPE.DEFAULT+'') {
       add_user_sec_display_flg = false;
    }

    return (
        <React.Fragment>
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__licenses")}`}/>

            <ProfilesDataPreviewModal
                open={openDataPreview}
                processData={processUploadData}
                previewData={previewData}
                handleClose={ () => setOpenDataPreview(false) }
            />

            <Grid container spacing={6}>
                <Grid item xs={4} sm={4} md={2} lg={1} >
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} >
                    <TopSectionBadge
                        title={`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__total_licenses')}`}
                        value={getInfoText('total')}
                        varient={'license'}
                        showDqwInfo={showDqwLicenses}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} >
                    <TopSectionBadge
                        title={`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__used_licenses')}`}
                        value={getInfoText('used')}
                        varient={'license'}
                        showDqwInfo={showDqwLicenses}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={3} >
                    <TopSectionBadge
                        title={`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__unused_licenses')}`}
                        value={getInfoText('unused')}
                        varient={'license'}
                        showDqwInfo={showDqwLicenses}
                    />
                </Grid>

            </Grid>


            <Divider my={6} />

            {
                add_user_sec_display_flg && <div>

                    <Typography variant="h3" className={classes.section2_header}>
                        {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__add_users')}`}
                    </Typography>

                    <Typography variant="h5" className={classes.section2_header}>
                        {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__download_the_csv_file')}`}
                        <Button
                            className={classes.csv_buttons}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={downloadSampleCsv}
                        >
                            {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__download_csv')}`}
                        </Button>
                        {/*<CSVLink*/}
                        {/*    data={csvData}*/}
                        {/*    filename='profiles_data.csv'*/}
                        {/*    className='hidden'*/}
                        {/*    ref={csvLink}*/}
                        {/*    target='_blank'*/}
                        {/*/>*/}
                    </Typography>

                    <Typography variant="h5" className={classes.section2_header}>
                        {
                            org_type + '' === ORG_TYPE.SCHOOL + '' ?
                                `${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__fill_out_the_csv_file_completely__school')}`
                                :
                                `${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__fill_out_the_csv_file_completely')}`
                        }
                    </Typography>

                    <Typography variant="h5" className={classes.section2_header}>

                        {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__upload_the_csv_file')}`}
                        <Tooltip title={uploadBtnHint} arrow>
                            <Button
                                className={classes.csv_buttons}
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={openFileBrowser}
                            >
                                {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__upload_csv')}`}
                            </Button>
                        </Tooltip>
                        <input type="file" hidden onChange={fileHandler} ref={fileInput} onClick={(event) => {
                            event.target.value = null
                        }} style={{"padding": "10px"}}/>
                        {
                            enable_additional_upload_settings &&
                            <>
                                <br/>
                                <FormControlLabel
                                    control={<Checkbox checked={ignoreEVerify} onChange={(event) => {
                                        setIgnoreEVerify(event.target.checked)
                                    }} name="ignoreEmailVerifyCheck"/>}
                                    label="Ignore Email Verification"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={useDefaultPass} onChange={(event) => {
                                        setUseDefaultPass(event.target.checked)
                                    }} name="useDefaultPassCheck"/>}
                                    label="Use Default Pass"
                                />
                            </>
                        }
                    </Typography>

                    <Typography variant="h5" className={classes.section2_header}>
                        {
                            org_type + '' === ORG_TYPE.SCHOOL + '' ?
                                `${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__the_system_will_then_send_out_activation_emails__school')}`
                                :
                                `${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__the_system_will_then_send_out_activation_emails')}`
                        }
                    </Typography>

                    <Typography variant="h5" className={classes.section2_header}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__as_long_as_you_have_unused_licenses_p1')} <a
                        href="mailto:contact@dqforall.com">contact@dqforall.com</a> {GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__as_long_as_you_have_unused_licenses_p2')}
                    </Typography>

                    <Typography variant="h5" className={classes.section2_header}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__in_case_you_no_longer_possess')}
                        <Button
                            className={classes.csv_buttons}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={downloadLicensesCsv}
                        >
                            {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__download_csv')}`}
                        </Button>
                    </Typography>

                    {
                        errors.length > 0 ?
                            <>
                                <Divider my={6}/>
                                <Typography variant="h3" className={classes.section3_header}>
                                    {`${GET_APP_LOCALIZE_TEXT(pageLc, 'licenses__errors')}`}
                                </Typography>
                                <TextField
                                    className={classes.error_logs}
                                    id="error-logs"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={errors.join('\n')}
                                    multiline={true}
                                    variant="outlined"
                                    style={{width: '100%'}}
                                />
                            </>
                            :
                            null
                    }
                </div>
            }
            <LoaderWithBackDrop loading={loading}/>

        </React.Fragment>
    );
}

export default Licenses;
