import React from "react";
import {useLocation} from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";

import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { spacing } from "@material-ui/system";

import {Bar, Radar} from "react-chartjs-2";
import Button from "@material-ui/core/Button";
import {grey} from "@material-ui/core/colors";
import {toastWarning} from "../../../utils/utils";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 300px;
`;

function RadarChart({ theme }) {

  const location = useLocation();
  const pageLc = location.pathname.split('/')[1];

  const data = {
    labels: ["DQMB 1.1", "DQMB 1.2", "DQMB 1.3", "DQMB 2.1", "DQMB 2.2", "DQMB 2.3", "DQMB 2.4"],
    datasets: [
      {
        label: `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__company_avg')}`,
        backgroundColor: 'rgba(251, 140, 1, 0.1)',
        borderColor: orange[600],
        pointBackgroundColor: orange[600],
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: orange[600],
        data: [110, 105, 112, 101, 123, 129, 119],
      },
      {
        label: `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__national_avg')}`,
        backgroundColor: 'rgba(71, 130, 218, 0.3)',
        borderColor: theme.palette.secondary.main,
        pointBackgroundColor: theme.palette.secondary.main,
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: theme.palette.secondary.main,
        data: [120, 125, 103, 109, 108, 119, 112],
      },
      {
        label: `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__global_avg')}`,
        backgroundColor: 'rgba(186, 186, 186, 0.1)',
        borderColor: grey[600],
        pointBackgroundColor: grey[600],
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: grey[600],
        data: [102, 125, 122, 111, 103, 109, 129],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scale: {
      ticks: {
        beginAtZero: false,
        max: 135,
        min: 95,
      }
    }
  };

  return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom align={'center'}>
          {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__overall_dq_score')}`}
        </Typography>

        <Card mb={1}>
          <CardContent>

            <Spacer mb={6} />

            <ChartWrapper>
              <Radar data={data} options={options} />
            </ChartWrapper>

          </CardContent>
        </Card>

      </React.Fragment>




  );
}

export default withTheme(RadarChart);
