import React, {useEffect, useState} from "react";

import {Paper, Box, Grid, Card, CardContent, Typography, Spacer,} from "../../../../components/StyledComponents/styledComponents"
import {GenericSidePanel} from "../../../../components/SidePanel/GenericSidePanel.comp";
import {
    addItemToState, addTrueKeyInState,
    predefinedSort, removeItemFromState,
    setSingleKeyTrueInState, splitList,
} from "../../../../utils/utils";
import {X} from "react-feather";
import {useAuth} from "../../../../context/auth";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {CustomSelect} from "../components/Select.comp";
import ProgressTable from "../components/ProgressTable";
import {Helmet} from "react-helmet";
import {getStudents} from "../../../../services/school.service";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

function Progress() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const {org_code, institute_code} = authUser;

    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);
    const [graphData, setGraphData] = useState(undefined);

    const subListDivision = 3;
    const [regions, setRegions] = useState([]);
    const [postalCodes, setPostalCodes] = useState(undefined);

    const regionHeader = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__select_school`);
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(undefined);

    const postalHeader = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__select_class`);
    const [defaultPostal, setDefaultPostal] = useState([]);
    const [additionalPostal, setAdditionalPostal] = useState([]);
    const [selectedPostal, setSelectedPostal] = useState(undefined);

    const [regionState, setRegionState] = useState(undefined);
    const [postalState, setPostalState] = useState(undefined);

    const selectAllRegionsOption = "All"
    const selectAllRegionsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_school")

    const selectAllPostalsOption = "All"
    const selectAllPostalsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_class")

    const getRegionAlias = (code) => {
        return code === selectAllRegionsOption ? selectAllRegionsAliasedOption : code;
    }

    const getRegionReverseAlias = (alias) => {
        return alias === selectAllRegionsAliasedOption ? selectAllRegionsOption : alias;
    }

    const getPostalAlias = (code) => {
        return code === selectAllPostalsOption ? selectAllPostalsAliasedOption : code;
    }

    const getPostalReverseAlias = (alias) => {
        return alias === selectAllPostalsAliasedOption ? selectAllPostalsOption : alias;
    }

    useEffect(() => {
        if(!selectorsData && !firstLoad)
        {
            setGraphData(undefined)
            setSelectedRegion(undefined);
            setSelectedPostal(undefined);
            setPostalCodes(undefined);
            setRegions([]);
        }
    }, [selectorsData])

    useEffect(() => {
        if(firstLoad) {
            loadSelectors().then(d => console.log('Enhancement Adoption Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const loadSelectors = async (page = 0, rowsPerPage = 10, order = "asc", orderBy = undefined) => {
        setLoading(true);
        try {
            const result = await getStudents(org_code, institute_code, undefined, page, rowsPerPage, order, orderBy);
            if(result.data)
            {
                const {selectors, data} = result.data;
                console.log(selectors, data);
                if(selectors)
                {
                    const {schools: _regions, classes: _postals} = selectors;
                    //Initialize Schools
                    const _region = initializeRegions(_regions);
                    //Initialize Classes
                    setPostalCodes(_postals);
                    initializePostals(_region, selectors);
                }
                //Initialize Graph Data
                loadGraphData(data.data);
                //Set Selectors Data and Page Data
                setSelectorsData(selectors);
                setPageData(data.data);
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching enhancement adoption selectors", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadDataList = (page = 0, rowsPerPage = 10, order = "asc", orderBy = undefined) => {
        loadPageData(selectedRegion, selectedPostal, page, rowsPerPage, order, orderBy);
    }

    const loadPageData = async (_region = selectedRegion, _postal = selectedPostal, page = 0, rowsPerPage = 10, order = "asc", orderBy = undefined) => {
        setLoading(true);
        try {
            setPageData(undefined);
            const result = await getStudents(org_code, _region, _postal, page, rowsPerPage, order, orderBy);
            if (result.data) {
                const {data} = result.data;
                setPageData(data.data);
                loadGraphData(data.data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption data", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function loadGraphData(data)
    {
        console.log("Data incoming", data);
        setGraphData(data);
    }

    function initializeRegions(_regions) {
        _regions.sort().sort(predefinedSort([selectAllRegionsOption]));
        const regionSubLists = splitList(_regions, subListDivision)
        setDefaultRegions(regionSubLists[0]);
        setAdditionalRegions(regionSubLists[1])
        setRegions(_regions);
        selectRegion(regionSubLists[0][0], selectedPostal, false);
        return regionSubLists[0][0];
    }

    function initializePostals(_region, selectors = selectorsData) {
        let _postal = undefined;
        if(_region && selectors)
        {
            const {classes: _all_postals} = selectors;
            const _postals = _all_postals[_region];
            _postals.sort((a, b) => a - b).sort(predefinedSort([selectAllPostalsOption]));
            const subLists = splitList(_postals, subListDivision)
            setDefaultPostal(subLists[0]);
            setAdditionalPostal(subLists[1]);
            _postal = subLists[0][0]
            selectPostal(_postal, false);

            setPostalState({
                ...Object.fromEntries(subLists[0].map((postal, index) => index === 0 ? [postal, true] : [postal, false])),
            });
        }
        return _postal;
    }

    function selectRegion(_region, fetch = false, _postal = selectedPostal)
    {
        setSelectedRegion(_region);
        if(_region)
        {
            if(fetch)
            {
                loadPageData(_region, _postal).then(d => console.log('Enhancement Adoption By Month Data Fetched - By Selection of Region', d));
            }
        }
        else
        {
            if(!pageData)
            {
                setDefaultRegions([]);
                setAdditionalRegions([]);
            }
            setGraphData(undefined)
        }
    }

    function selectPostal(_postal, fetch = false)
    {
        setSelectedPostal(_postal);
        if(_postal)
        {
            if(fetch)
            {
                loadPageData(selectedRegion, _postal).then(d => console.log('Enhancement Adoption By Month Data Fetched - By Selection of Postal', d));
            }
        }
        else
        {
            setGraphData(undefined);
        }
    }

    function onAddRegion(event, state, setState) {
        const aliasedItem = event.target.value
        const item = getRegionReverseAlias(aliasedItem);
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        const _postal = initializePostals(item);
        selectRegion(item, true, _postal);
    }

    function onRemoveRegion(aliasedItem, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setSingleKeyTrueInState(newDefaultRegions.map((r) => getRegionAlias(r)), getRegionAlias(newDefaultRegions[0]), setState)
        if(selectedRegion !== newDefaultRegions[0])
        {
            const _postal = initializePostals(newDefaultRegions[0]);
            selectRegion(newDefaultRegions[0], true, _postal);
        }
    }

    function onSelectRegion(aliasedItem, isChecked, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        if(isChecked) {
            const _postal = initializePostals(item);
            selectRegion(item, true, _postal);
            setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    function onAddPostal(event, state, setState) {
        const aliasedItem = event.target.value;
        const item = getPostalReverseAlias(aliasedItem);
        removeItemFromState(item, additionalPostal, setAdditionalPostal);
        addItemToState(item, defaultPostal, setDefaultPostal);
        setSingleKeyTrueInState(defaultPostal.map((p) => getPostalAlias(p)), aliasedItem, setState);
        selectPostal(item, true);
    }

    function onRemovePostal(aliasedItem, state, setState) {

        const item = getPostalReverseAlias(aliasedItem);
        const newDefaultPostal = removeItemFromState(item, defaultPostal, setDefaultPostal)
        addItemToState(item, additionalPostal, setAdditionalPostal, true)
        setSingleKeyTrueInState(newDefaultPostal.map((p) => getPostalAlias(p)), getPostalAlias(newDefaultPostal[0]), setState);
        selectPostal(newDefaultPostal[0], true);
    }

    function onSelectPostal(aliasedItem, isChecked, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        if(isChecked) {
            selectPostal(item, true);
            setSingleKeyTrueInState(defaultPostal.map((p) => getPostalAlias(p)), aliasedItem, setState);
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    return (
        <Paper container>
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__progress")} | ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__students")}`}/>
            <Grid container alignItems={'stretch'} spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        visible={[!institute_code, true]}
                        data={[defaultRegions.map((r) => getRegionAlias(r)), defaultPostal.map((p) => getPostalAlias(p))]}
                        onCheckCallbacks = {[onSelectRegion, onSelectPostal]}
                        headers = {[regionHeader, postalHeader]}
                        colourize = {[false, false]}
                        actions = {[X, X]}
                        actionsData = {[{style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}]}
                        actionsCallbacks = {[onRemoveRegion, onRemovePostal]}
                        footers = {[CustomSelect, CustomSelect]}
                        footersData = {[
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__add_school`),
                                data: additionalRegions.map((r) => getRegionAlias(r)),
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__add_class`),
                                data: additionalPostal.map((p) => getPostalAlias(p)),
                            },
                        ]}
                        footersCallbacks = {[{onChange: onAddRegion}, {onChange: onAddPostal}]}
                        defaultCheckIndices = {[0, 0]}
                        stateCallbacks = {[
                            {
                                state: regionState,
                                setState: setRegionState
                            },
                            {
                                state: postalState,
                                setState: setPostalState
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    {
                        graphData ?
                            <Paper container>
                                <ProgressTable
                                    org_code={org_code}
                                    data={graphData}
                                    fetchSortedData={loadDataList}
                                />
                            </Paper>
                            :
                            <Typography primary variant={"h3"} align={'center'} py={5}>{errorMessage}</Typography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default Progress;