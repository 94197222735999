import React, {useEffect, useState} from "react";
import {Paper, Spacer, Typography, Grid, Box} from "../../../components/StyledComponents/styledComponents";
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    useTheme
} from "@material-ui/core";
import styled from "styled-components";
import {withStyles} from "@material-ui/core/styles";
import {Helmet} from "react-helmet";
import {Link, useLocation, useParams, withRouter} from "react-router-dom";
import {useAuth} from "../../../context/auth";
import {USER_ACCESS_LEVEL} from "../../../utils/constants";
import {getClasses, getInstitutes} from "../../../services/analysis.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import ExecutiveSummary from "./components/ExecutiveSummary.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const CustomTableCell = withStyles((theme) => ({
    root: {
        borderStyle: "solid",
        borderWidth: "thin",
        borderColor: "#233044",
    },
    head: {
        backgroundColor: "#3778b4",
        color: theme.palette.background.paper,
    },
    body: {
        backgroundColor: theme.palette.background.paper,
        color: "black"
    }
}))(TableCell);

const TableButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.background.paper};
  color: #3778b4;
  font-weight: bold;
  border: thin solid grey;
  &:hover {
    background-color: lightskyblue;
  }
`

const CustomTableContainer = styled(TableContainer)`
  background-color: #233044;
  padding: 10px;
`

const Summary = (props) => {

    const { authUser } = useAuth();
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];
    const institute = new URLSearchParams(location.search).get("institute");

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [summaryRows, setSummaryRows] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [checkedIndices, setCheckedIndices] = useState([]);

    const [pageData, setPageData] = useState(undefined);
    const is_multi_institute = authUser.access_level === USER_ACCESS_LEVEL.FULL_ACCESS_ANALYTICS_PORTAL_FULL_ORG;
    const institute_code = is_multi_institute ? institute : authUser.institute_code;

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getClasses(authUser.org_code, institute_code);
            if (result.data) {
                console.log('Result Data of Summary Analysis Classes --> ', result.data);
                setPageData(result.data)
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching analysis of institute")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    useEffect(() => {
        if(institute_code)
        {
            loadPageData().then(d => console.log('Analysis Fetched', d));
        }
    }, [])

    useEffect(() => {
        if(pageData)
        {
            setCheckedIndices(Object.fromEntries(new Array(pageData.length).fill(null).map((_, i) => [i, true])))
        }
    }, [pageData])

    const updateCheckboxState = index => {
        const state_object = {...checkedIndices};
        state_object[index] = !state_object[index];
        setCheckedIndices(state_object);
    }

    const generateSummary = () => {
        setChartData(pageData.dq_scores);
        setSummaryRows(pageData.classes.filter((_, index) => checkedIndices[index]))
    }

    const triggerPrint = () => {
        window.print();
    }

    return <Paper p={15}>
        {
            pageData ?
                <>
                    <CustomTableContainer className={'no-print'}>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography font_weight={'bold'} display={'inline'} font_color={'white'}> {"Selected School: "}
                                    <Typography display={'inline'} font_weight={'bold'} font_color={'#3778b4'}>
                                        {`${institute_code}`}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TableButton component={Link} to={{pathname: '/analysis'}}>Change School</TableButton>
                            </Grid>
                        </Grid>
                        <Spacer my={2}/>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell align={'center'}>#</CustomTableCell>
                                    <CustomTableCell align={'center'}>Class Name</CustomTableCell>
                                    <CustomTableCell align={'center'}>Class Code</CustomTableCell>
                                    <CustomTableCell align={'center'}>Grade</CustomTableCell>
                                    <CustomTableCell align={'center'}>Completed</CustomTableCell>
                                    <CustomTableCell align={'center'}>Students</CustomTableCell>
                                    <CustomTableCell align={'center'}>Include?</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    pageData.classes.map((row, index) => <TableRow>
                                        <CustomTableCell align={'center'}>{index+1}</CustomTableCell>
                                        <CustomTableCell align={'center'}>{row.name}</CustomTableCell>
                                        <CustomTableCell align={'center'}>{row.code}</CustomTableCell>
                                        <CustomTableCell align={'center'}>{row.grade}</CustomTableCell>
                                        <CustomTableCell align={'center'}>{row.completed}</CustomTableCell>
                                        <CustomTableCell align={'center'}>{row.students}</CustomTableCell>
                                        <CustomTableCell align={'center'}><Checkbox defaultChecked={true} onChange={() => updateCheckboxState(index)}/></CustomTableCell>
                                    </TableRow>)
                                }
                            </TableBody>
                        </Table>
                        <Spacer my={2}/>
                        <TableButton onClick={generateSummary}>Generate Report</TableButton>
                        <TableButton onClick={triggerPrint}>Print PDF</TableButton>

                    </CustomTableContainer>
                    {
                        summaryRows.length > 0 ?
                            <div className={'divToPrint'}>
                                <ExecutiveSummary chart_values={chartData} rows={summaryRows}/>
                            </div>
                        : null
                    }
                </>
                :
                <Typography primary variant={"h3"} align={'center'} py={5}>{errorMessage}</Typography>
        }
        <LoaderWithBackDrop loading={loading}/>
    </Paper>
}

export default Summary;