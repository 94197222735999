import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import {useAuth} from "../../../../../context/auth"
import {getDigitalSkillsData_v2, getDigitalSkillsDownloadData} from "../../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";


import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Grid,
    Paper,
    Typography as MuiTypography,
} from "@material-ui/core";

import {Badge} from "../Badge.comp"

import {spacing} from "@material-ui/system";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";
import RadarChart from "../RadarChart";
import LineChart from "../LineChart";
import {SidePanel} from "../../../../../components/SidePanel/SidePanel.comp";
import XLSX from "xlsx";
import {useLocation} from "react-router-dom";
import {createExcelOutputFormat} from "../../../Enhancement/utils/excel_output_format";
import {createLcKey, isNUSOrg, splitCountries, toastDanger} from "../../../../../utils/utils";
import {X} from "react-feather";
import {CustomSelect} from "../Select.comp";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const cat_reverse_translations = {}

function DigitalSkillsTab() {

    const {authUser} = useAuth();
    const {org_country} = authUser.org_obj;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    const [defaultCountries, setDefaultCountries] = useState([]);
    const [additionalCountries, setAdditionalCountries] = useState([]);
    const [indicators, setIndicators] = useState([]);

    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [defaultCountriesExists, setDefaultCountriesExists] = useState(false);

    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const secondarySideHeader= GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_dig_comp")

    useEffect(() => {
        loadPageData().then(d => console.log('Digital Competencies Data Fetched!', d));
    }, []);


    function initializeData(data) {
        setSelectedCategories(["Global"])
        setDefaultCountriesExists(data.default_countries && data.default_countries.length > 0);
        const countriesSubList = splitCountries(data.countries, [org_country]);
        setDefaultCountries(countriesSubList[0])
        setAdditionalCountries(countriesSubList[1]);
        setSelectedCountries([countriesSubList[0][0]])
        const sorted_indicators = data.categories.sort();
        setSelectedIndicators(sorted_indicators);
        setIndicators(sorted_indicators);
        setPageData(data);
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getDigitalSkillsData_v2(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Digital Competencies --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching digital competencies data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const onClickDownload = async () => {
        setLoading(true);
        try {
            const result = await getDigitalSkillsDownloadData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Digital Skills Excel Data --> ', result.data);
                createExcelOutputFormat(result.data.LEVEL6_DICT, result.data.SCORES);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching digital skills excel data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const get_indicator_alias = (code) => {
        return code;
    }

    const get_indicator_reverse_alias = (alias) => {
        return alias;
    }

    const badge_data = [
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__nation_avg"),
            subtitle: selectedCountries[0] ? `(${selectedCountries[0]})` : undefined,
            value: pageData && selectedCountries[0] ? pageData.data.countries_data[selectedCountries[0]].average : 0
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__glob_avg"),
            value: pageData ? pageData.data.global_data.average : 0,

        }
    ]

    function onSelectIndicator(item, isChecked, state, setState) {

        const itemID = get_indicator_reverse_alias(item);
        if(isChecked) {
            setSelectedIndicators([itemID])
            setState(Object.fromEntries(indicators.map((indicator) => get_indicator_alias(indicator)).map(
                (indicator) => indicator == item ? [indicator, true] : [indicator, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [item]: true,
            }));
        }
    }

    function onSelectCountry(item, isChecked) {

        const itemID = get_category_reverse_alias(item);
        if(isChecked) {
            if(!selectedCountries.includes(itemID)) {
                addItemToState(setSelectedCountries, itemID)
            }
        }
        else
        {
            if(selectedCountries.includes(itemID)) {
                removeItemFromState(setSelectedCountries, itemID)
            }
        }
    }

    const radarRef = React.createRef();
    const lineRef = React.createRef();

    function sumSelectedIndicatorsAnnualScores(category, country = "")
    {
        let temp = []
        selectedIndicators.forEach((indicator) => {
            const objs = []
            //Objects Initialization
            switch(category) {
                case "Global":
                    objs.push(...pageData.data.global_data.annual_scores)
                    break;
                // case "National":
                //     if (selectedCountries && selectedCountries.length > 0) {
                //         objs.push(...pageData.data.countries_data[selectedCountries[0]].annual_scores);
                //     }
                //     break;
                case "Country":
                    objs.push(...pageData.data.countries_data[country].annual_scores)
            }
            //Adding Count: 1 to Each Object
            if(temp.length == 0)
            {
                objs.forEach((obj) => temp.push({count: 1, ...obj}))
            }
            else
            {
                objs.forEach((obj) => {
                    const index = temp.findIndex((element) => element.year+'' === obj.year+'')
                    if(index === -1) {
                        temp.push({count: 1, ...obj})
                    } else {
                        const new_val = Number(parseFloat(temp[index].val) + parseFloat(obj.val))
                        const old_year = temp[index].year;
                        const old_count = temp[index].count;
                        temp.splice(index, 1, {year: old_year, val: new_val, count: old_count+1});
                    }
                })
            }
        })
        return temp;
    }

    let years = []
    const summed_scores = {};
    const indicator_scores = {};

    if(pageData)
    {
        //Data Sum For Annual Scores of Countries
        selectedCountries.forEach((country) => {
            summed_scores[country] = {};
            indicator_scores[country] = indicators.map((indicator) => pageData.data.countries_data[country][indicator].indicator_score ? pageData.data.countries_data[country][indicator].indicator_score : 0);
            const temp = sumSelectedIndicatorsAnnualScores("Country", country)
            summed_scores[country].annual_scores = temp.map((obj) => {
                return {
                    year: obj.year,
                    val: Number((obj.val / obj.count).toFixed(2))
                }
            })
        })
        //Data Sum For Annual Scores of Global and National
        selectedCategories.forEach((category) => {
            switch(category) {
                case "Global":
                    indicator_scores[category] = indicators.map((i) => pageData.data.global_data.indicator_scores[i]);
                    break;
                case "National":
                    indicator_scores[category] = indicators.map((i) => selectedCountries[0] ? pageData.data.countries_data[selectedCountries[0]][i].indicator_score : 0);
                    break;
                default:
                    indicator_scores[category] = []
            }
            summed_scores[category] = {}
            const temp = sumSelectedIndicatorsAnnualScores(category)
            summed_scores[category].annual_scores = temp.map((obj) => {
                return {
                    year: obj.year,
                    val: Number((obj.val / obj.count).toFixed(2))
                }
            })
        })

        //Getting Years to Display on Line Chart Depending on Available Years from Summed_Scores
        years = Object.keys(summed_scores).map((key) => summed_scores[key].annual_scores).reduce(
            (final, current) => {
                const items_to_add = current.filter((item) => !final.includes(item.year)).map((item) => item.year)
                return final.concat(items_to_add);
        }, [])
        years.sort(function(a, b){return a - b})
    }

    const get_category_alias = (category) => {
        const cat_trans = GET_APP_LOCALIZE_TEXT(pageLc, `baselining__${createLcKey(category)}`, null);
        const country_trans = GET_APP_LOCALIZE_TEXT(pageLc, `dq_country__${createLcKey(category)}`, null);
        const translation = cat_trans || country_trans || category;
        cat_reverse_translations[translation] = category;
        return translation;
    }

    const get_category_reverse_alias = (alias) => {
        const reverse_alias = cat_reverse_translations[alias];
        return reverse_alias || alias;
    }

    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    function onAddCountry(event, setState) {
        const aliasedItem = event.target.value;
        if(selectedCategories.concat(defaultCountries).length === 11)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        }
        else
        {
            const country = get_category_reverse_alias(aliasedItem);
            removeItemFromState(setAdditionalCountries, country)
            addItemToState(setDefaultCountries, country)
            onSelectCountry(aliasedItem, true)
            setState((prevState) => ({
                ...prevState,
                [aliasedItem]: true,
            }));
        }
    }

    function onRemoveCountry(aliasedItem, setState) {

        const country = get_category_reverse_alias(aliasedItem);
        removeItemFromState(setDefaultCountries, country)
        addItemToState(setAdditionalCountries, country, true)
        onSelectCountry(aliasedItem, false)

        setState((prevState) => ({
            ...prevState,
            [aliasedItem]: false,
        }));
    }


    return (
        pageData ?
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <SidePanel
                            primaryHeader={primarySideHeader}
                            // secondaryHeader={secondarySideHeader}
                            primaryData={defaultCountries.map((country) => country)}
                            secondaryData={[]}
                            onCheckPrimary={onSelectCountry}
                            onCheckSecondary={onSelectIndicator}
                            primaryColourize={false}
                            primaryColourizeIndex={selectedCategories.length}
                            primaryFooter={!defaultCountriesExists ? CustomSelect : undefined}
                            primaryFooterData={!defaultCountriesExists ? {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_country"),
                                data: additionalCountries.map((country) => country),
                            } : undefined}
                            primaryFooterCallback={!defaultCountriesExists ? onAddCountry : undefined}
                            primaryAction={!defaultCountriesExists ? X : undefined}
                            primaryActionData={{style: {cursor: "pointer"}}}
                            primaryActionCallback={onRemoveCountry}
                            secondarySingleSelectIndex={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card>
                            <CardContent>
                                <Box sx={{flexGrow: 1}} align={"right"}>
                                    <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                        Digital Wellbeing Score %
                                    </ColouredTypography>
                                    <Grid container spacing={0} align={"center"}>
                                        {badge_data.
                                            map((badge,_, arr) => (
                                                <Grid item xs={6} sm={6} md={6} lg={12/arr.length}>
                                                    <Badge
                                                        title={badge.title}
                                                        subtitle={badge.subtitle}
                                                        value={badge.value.toFixed(2)}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    <Spacer mb={5}/>
                                    <Grid container spacing={6} justifyContent={"space-between"}>
                                        <Grid item xs={12} lg={6}>
                                            <ColouredTypography align={"center"} variant={"h4"}>
                                                Domain Score % Breakdown
                                            </ColouredTypography>
                                            <Spacer mb={10}/>
                                            <RadarChart
                                                ref={radarRef}
                                                suggestedMin={50}
                                                data={{
                                                    labels: [...indicators.map((indicator) => get_indicator_alias(indicator))],
                                                    selected: [...indicators.map((indicator) => selectedIndicators.includes(indicator))],
                                                    graphData: [...selectedCategories.concat(selectedCountries).map((country, index) => {
                                                        return {
                                                            index: [...selectedCategories.concat(defaultCountries)].indexOf(country),
                                                            country: country,
                                                            data: selectedIndicators.length != 0 ? indicator_scores[country] : [],
                                                            border_opacity: selectedCategories.includes(country) ? 1.0 : selectedCountries.includes(country) ? 1.0 : 1.0,
                                                            background_opacity: selectedCategories.includes(country) ? 0.0 : selectedCountries.includes(country) ? 0.2 : 0.0,
                                                            show: selectedCategories.includes(country) ? true : selectedCountries.includes(country) ? true : false,
                                                        }
                                                    })]
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <ColouredTypography align={"center"} variant={"h4"}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__annual_score")}
                                            </ColouredTypography>
                                            <Spacer mb={10}/>
                                            <LineChart
                                                ref={lineRef}
                                                radarRef={radarRef}
                                                suggestedMin={60}
                                                data={{
                                                    labels: [...years],
                                                    graphData: [...selectedCategories.concat(selectedCountries).map((country) => {
                                                        const annual_scores_objs = []
                                                        if(summed_scores[country])
                                                        {
                                                            annual_scores_objs.push(...summed_scores[country].annual_scores)
                                                        }
                                                        const annual_scores_years = annual_scores_objs.map((obj) => obj.year)
                                                        const score_values = years.map((year) => {
                                                            const index = annual_scores_years.indexOf(year)
                                                            if(index === -1)
                                                                return undefined;
                                                            else
                                                                return annual_scores_objs[index].val ? annual_scores_objs[index].val : 0;
                                                        })
                                                        return {
                                                            index: [...selectedCategories.concat(defaultCountries)].indexOf(country),
                                                            country: country,
                                                            data: [...score_values],
                                                            opacity: selectedCategories.includes(country) ? 1 : selectedCountries.includes(country) ? 1 : 1,
                                                            show: selectedCategories.includes(country) ? true : selectedCountries.includes(country) ? true : false,
                                                        }
                                                    })]
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    { !isNUSOrg(authUser.org_code) && //TODO: Need to remove constant check later.
                                    <Button mt={5} variant="contained" onClick={onClickDownload}>
                                        Download Data
                                    </Button>
                                    }
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
            :
            <Paper container>
                <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
    );
}

export default DigitalSkillsTab;