import styled from "styled-components/macro";
import ColoredLinearProgress from "../../../../components/LinearProgress/ColouredLinearProgress.comp";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const LinearProgress = styled(ColoredLinearProgress)`
  height: 14px;
  border-radius: 3px;
`

export function LinearProgressWithLabel({value, label = value, symbol = '', ...rest}) {
    const bg = value < 0 ? "#ffcccb" : "lightblue";
    const fg = value < 0 ? "#ff726f" : "#25b7db";
    return (
        <Grid container spacing={4}>
            <Grid item xs={8}>
                <LinearProgress variant="determinate" value={Math.abs(value)} backgroundColor={bg} foregroundColor={fg} {...rest} />
            </Grid>
            <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary">{`${label}${symbol}`}</Typography>
            </Grid>
        </Grid>
    );
}