import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../../context/auth"
import {getDigitalSkillsData} from "../../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {
    createLcKey,
    getCountriesAverage,
    predefinedSort,
    splitCountries,
    toastDanger
} from "../../../../../utils/utils";
import {COUNTRY_CONFIGS, DQC24_TITLES} from "../../../../../utils/constants";


import {
    Paper,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography, Button as MuiButton,
} from "@material-ui/core";

import {
    X
} from "react-feather";


import {CustomSelect} from "../Select.comp"

import {Badge} from "../Badge.comp"

import { spacing } from "@material-ui/system";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";
import RadarChart from "../RadarChart";
import LineChart from "../LineChart";
import {SidePanel} from "../../../../../components/SidePanel/SidePanel.comp";
import XLSX from "xlsx";
import writeFileXLSX from "xlsx";
import {useLocation} from "react-router-dom";

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Button = styled(MuiButton)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const ColouredCard = styled(Card)`
  background-color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  padding: 10px 10px 10px 10px;
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

let dq_24_aliases = {}
let dq_24_reverse_aliases = {}
const cat_reverse_translations = {};

function DigitalSkillsTab() {

    const {authUser} = useAuth();
    const {org_country} = authUser.org_obj;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    const [defaultCountries, setDefaultCountries] = useState([]);
    const [additionalCountries, setAdditionalCountries] = useState([]);
    const [indicators, setIndicators] = useState([]);

    const [selectedIndicators, setSelectedIndicators] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [defaultCountriesExists, setDefaultCountriesExists] = useState(false);
    const countryConfigKeyForIncludedCountries = COUNTRY_CONFIGS[org_country] && COUNTRY_CONFIGS[org_country].included_countries ? org_country : "default";
    const countriesFromConfig = [org_country, ...COUNTRY_CONFIGS[countryConfigKeyForIncludedCountries].included_countries]

    const selectAllIndicatorsOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__dq_comp_score")
    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const secondarySideHeader= GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_dig_comp")

    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect(() => {
        initializeDQCodes();
        loadPageData().then(d => console.log('Digital Competencies Data Fetched!', d));
    }, []);

    const initializeDQCodes = () => {
        const dq24_titles = DQC24_TITLES(pageLc);
        dq_24_aliases = {...Object.fromEntries(Object.keys(dq24_titles).map((key) => [key, dq24_titles[key]]))}
        dq_24_reverse_aliases = {...Object.fromEntries(Object.keys(dq24_titles).map((key) => [dq24_titles[key], key]))}
    }

    function initializeData(data) {
        let filtered_countries = data.countries.filter(ct => countriesFromConfig.includes(ct));
        // if (filtered_countries.length === 0) filtered_countries = [..._countries];
        setDefaultCountriesExists(filtered_countries && filtered_countries.length > 0);
        setSelectedCategories(["Program", "National", "Global"])
        const countriesSubList = splitCountries(data.countries, filtered_countries && filtered_countries.length > 0 ? filtered_countries : [org_country]);
        setDefaultCountries(countriesSubList[0])
        setAdditionalCountries(countriesSubList[1]);
        setSelectedCountries([countriesSubList[0][0]])
        const all_indicators_code = data.categories.find((i) => /.*\D$/.test(i));
        const sorted_indicators = data.categories.sort(predefinedSort([all_indicators_code], undefined, false));
        setSelectedIndicators([sorted_indicators[0]]);
        setIndicators(sorted_indicators);
        setPageData(data);
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getDigitalSkillsData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Digital Competencies --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching digital competencies data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const onClickDownload = (event) => {
        const workbook = XLSX.utils.book_new();
        pageData.categories.forEach((category, category_index) => {
            const final_array = []
            let max_column_width = 10;
            years = pageData.countries.map((country) => pageData.data[category].countries_data[country].annual_scores).reduce(
                (final, current) => {
                    const items_to_add = current.filter((item) => !final.includes(item.year)).map((item) => item.year)
                    return final.concat(items_to_add);
                }, [])
            years.sort(function(a, b){return a - b})
            const columns = ["Country", "Country Average", "Program Average", "National Average", "Global Average", `${category} Indicator Score`]
            columns.push(...years.map((year) => "Annual Score " + String(year)))
            final_array.push(columns)
            pageData.countries.forEach((country) => {
                const country_array = []
                country_array.push(country)
                country_array.push(pageData.data[category].countries_data[country].average)
                country_array.push(pageData.data[category].program_average)
                country_array.push(pageData.data[category].national_average)
                country_array.push(pageData.data[category].global_average)
                country_array.push(pageData.data[category].countries_data[country].indicator_score)
                years.forEach((year) => {
                    const index = pageData.data[category].countries_data[country].annual_scores.findIndex(x => x.year === year);
                    country_array.push( index === -1 ? 0 : pageData.data[category].countries_data[country].annual_scores[index].val)
                })
                final_array.push(country_array)
                max_column_width = Math.max(max_column_width, country.length)
            })
            const worksheet = XLSX.utils.aoa_to_sheet(final_array);
            worksheet["!cols"] = columns.map((column, index) => {
                return { wch: index == 0 ? max_column_width : column.length }
            });
            XLSX.utils.book_append_sheet(workbook, worksheet, category);
        })
        XLSX.writeFile(workbook, "Digital Competencies.xlsx");
    }

    const get_indicator_alias = (code) => {
        if(/.*\D$/.test(code)) return selectAllIndicatorsOption;
        else if(dq_24_aliases[code]) return dq_24_aliases[code];
        return code;
    }

    const get_indicator_reverse_alias = (alias) => {
        if(alias === selectAllIndicatorsOption)
        {
            const indicator = indicators.find((i) => /.*\D$/.test(i))
            if(indicator) return indicator;
        }
        else if(dq_24_reverse_aliases[alias]) return dq_24_reverse_aliases[alias];
        return alias;
    }

    const get_category_alias = (category) => {
        const cat_trans = GET_APP_LOCALIZE_TEXT(pageLc, `baselining__${createLcKey(category)}`, null);
        const country_trans = GET_APP_LOCALIZE_TEXT(pageLc, `dq_country__${createLcKey(category)}`, null);
        const translation = cat_trans || country_trans || category;
        cat_reverse_translations[translation] = category;
        return translation;
    }

    const get_category_reverse_alias = (alias) => {
        const reverse_alias = cat_reverse_translations[alias];
        return reverse_alias || alias;
    }

    const badge_data = [
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__prog_avg"),
            value: pageData && selectedIndicators.length > 0 ? selectedIndicators.reduce((total, current) => {
                return parseFloat(total) + parseFloat(pageData.data[current].program_data.average);
            }, 0.0) / selectedIndicators.length : 0,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__nation_avg"),
            subtitle: pageData ? `(${pageData.country})` : undefined,
            value: pageData && selectedIndicators.length > 0 ? selectedIndicators.reduce((total, current) => {
                return parseFloat(total) + parseFloat(pageData.data[current].national_data.average);
            }, 0.0) / selectedIndicators.length : 0,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__countries_avg"),
            value:
                pageData && selectedIndicators.length > 0 && selectedCountries.length > 0 ?
                getCountriesAverage(selectedCountries, selectedCountries.map(
                    (country) => selectedIndicators.reduce(
                        (total, current) => {
                            return parseFloat(total) + parseFloat(pageData.data[current].countries_data[country].average);
                        }, 0.0) / selectedIndicators.length
                    )
                ) : 0,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__glob_avg"),
            value: pageData && selectedIndicators.length > 0 ? selectedIndicators.reduce((total, current) => {
                return parseFloat(total) + parseFloat(pageData.data[current].global_data.average);
            }, 0.0)/ selectedIndicators.length : 0,
        }
    ]

    function onAddCountry(event, setState) {
        const aliasedItem = event.target.value;
        if(selectedCategories.concat(defaultCountries).length === 11)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        }
        else
        {
            const country = get_category_reverse_alias(aliasedItem);
            removeItemFromState(setAdditionalCountries, country)
            addItemToState(setDefaultCountries, country)
            onSelectCountry(aliasedItem, true)
            setState((prevState) => ({
                ...prevState,
                [aliasedItem]: true,
            }));
        }
    }

    function onRemoveCountry(aliasedItem, setState) {

        const country = get_category_reverse_alias(aliasedItem);
        removeItemFromState(setDefaultCountries, country)
        addItemToState(setAdditionalCountries, country, true)
        onSelectCountry(aliasedItem, false)

        setState((prevState) => ({
            ...prevState,
            [aliasedItem]: false,
        }));
    }

    function onSelectCountry(item, isChecked) {

        const itemID = get_category_reverse_alias(item);
        if(isChecked) {
            if(!selectedCountries.includes(itemID)) {
                addItemToState(setSelectedCountries, itemID)
            }
        }
        else
        {
            if(selectedCountries.includes(itemID)) {
                removeItemFromState(setSelectedCountries, itemID)
            }
        }
    }

    function onSelectIndicator(item, isChecked, state, setState) {

        const itemID = get_indicator_reverse_alias(item);
        if(isChecked) {
            setSelectedIndicators([itemID])
            setState(Object.fromEntries(indicators.map((indicator) => get_indicator_alias(indicator)).map(
                (indicator) => indicator == item ? [indicator, true] : [indicator, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [item]: true,
            }));
        }
    }

    const radarRef = React.createRef();
    const lineRef = React.createRef();

    function sumSelectedIndicatorsAnnualScores(category, country = "")
    {
        let temp = []
        selectedIndicators.forEach((indicator) => {
            const objs = []
            //Objects Initialization
            switch(category) {
                case "Global":
                    objs.push(...pageData.data[indicator].global_data.annual_scores)
                    break;
                case "Program":
                    objs.push(...pageData.data[indicator].program_data.annual_scores)
                    break;
                case "National":
                    objs.push(...pageData.data[indicator].national_data.annual_scores)
                    break;
                case "Country":
                    objs.push(...pageData.data[indicator].countries_data[country].annual_scores)
            }
            //Adding Count: 1 to Each Object
            if(temp.length == 0)
            {
                objs.forEach((obj) => temp.push({count: 1, ...obj}))
            }
            else
            {
                objs.forEach((obj) => {
                    const index = temp.findIndex((element) => element.year+'' === obj.year+'')
                    if(index === -1) {
                        temp.push({count: 1, ...obj})
                    } else {
                        const new_val = Number(parseFloat(temp[index].val) + parseFloat(obj.val))
                        const old_year = temp[index].year;
                        const old_count = temp[index].count;
                        temp.splice(index, 1, {year: old_year, val: new_val, count: old_count+1});
                    }
                })
            }
        })
        return temp;
    }

    let years = []
    const summed_scores = {};
    const indicator_scores = {};

    if(pageData)
    {
        //Data Sum For Annual Scores of Countries
        selectedCountries.forEach((country) => {
            summed_scores[country] = {}
            indicator_scores[country] = indicators.map((i) => pageData.data[i].countries_data[country].indicator_score);
            const temp = sumSelectedIndicatorsAnnualScores("Country", country)
            summed_scores[country].annual_scores = temp.map((obj) => {
                return {
                    year: obj.year,
                    val: Number((obj.val / obj.count).toFixed(2))
                }
            })
        })
        //Data Sum For Annual Scores of Program, Global and National
        selectedCategories.forEach((category) => {
            switch(category) {
                case "Global":
                    indicator_scores[category] = indicators.map((i) => pageData.data[i].global_data.indicator_score);
                    break;
                case "Program":
                    indicator_scores[category] = indicators.map((i) => pageData.data[i].program_data.indicator_score);
                    break;
                case "National":
                    indicator_scores[category] = indicators.map((i) => pageData.data[i].national_data.indicator_score);
                    break;
                default:
                    indicator_scores[category] = []
            }
            summed_scores[category] = {}
            const temp = sumSelectedIndicatorsAnnualScores(category)
            summed_scores[category].annual_scores = temp.map((obj) => {
                return {
                    year: obj.year,
                    val: Number((obj.val / obj.count).toFixed(2))
                }
            })
        })

        //Getting Years to Display on Line Chart Depending on Available Years from Summed_Scores
        years = Object.keys(summed_scores).map((key) => summed_scores[key].annual_scores).reduce(
            (final, current) => {
                const items_to_add = current.filter((item) => !final.includes(item.year)).map((item) => item.year)
                return final.concat(items_to_add);
        }, [])
        years.sort(function(a, b){return a - b})
    }

    const indicator_header = selectedIndicators.length == indicators.length ? selectAllIndicatorsOption : (selectedIndicators.length == 0 ? "No Indicators" : selectedIndicators.map((indicator) => get_indicator_alias(indicator)));

    return (
        pageData ?
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <SidePanel
                            primaryHeader={primarySideHeader}
                            secondaryHeader={secondarySideHeader}
                            primaryData={defaultCountries.map((country) => get_category_alias(country))}
                            secondaryData={indicators.map((indicator) => get_indicator_alias(indicator))}
                            onCheckPrimary={onSelectCountry}
                            onCheckSecondary={onSelectIndicator}
                            primaryColourize={true}
                            primaryColourizeIndex={selectedCategories.length}
                            primaryFooter={!defaultCountriesExists ? CustomSelect : undefined}
                            primaryFooterData={ !defaultCountriesExists ? {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_country"),
                                data: additionalCountries.map((country) => get_category_alias(country)),
                            } : undefined}
                            primaryFooterCallback={!defaultCountriesExists ? onAddCountry : undefined}
                            primaryAction={!defaultCountriesExists ? X : undefined}
                            primaryActionData={{style: {cursor: "pointer"}}}
                            primaryActionCallback={onRemoveCountry}
                            secondarySingleSelectIndex={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card>
                            <CardContent>
                                <Box sx={{flexGrow: 1}} align={"right"}>
                                    <ColouredTypography variant={"body1"} size={"16px"} color={"grey"} align={"center"} alignText={"justify"} pt={10}>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__dig_comp_desc")}
                                    </ColouredTypography>
                                    <ColouredTypography variant={"body1"} size={"16px"} align={"center"} alignText={"justify"} pb={5}>
                                        <Italicize>
                                            ({GET_APP_LOCALIZE_TEXT(pageLc, "baselining__dig_comp_range")})
                                        </Italicize>
                                    </ColouredTypography>
                                    <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                        {`${indicator_header}`} {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__covered_by")}&nbsp;
                                        <ColouredTypography component="span" variant={"h4"} color="orange">
                                            {pageData.overview ? pageData.overview.program : ''}
                                        </ColouredTypography>
                                    </ColouredTypography>
                                    <Grid container spacing={0} align={"center"}>
                                        {badge_data.filter((b) =>
                                            authUser.org_code.toLowerCase() !== "amf" || b.title !== GET_APP_LOCALIZE_TEXT(pageLc, "baselining__nation_avg"))
                                            .map((badge,_, arr) => (
                                                <Grid item xs={6} sm={6} md={6} lg={12/arr.length}>
                                                    <Badge
                                                        title={badge.title}
                                                        subtitle={badge.subtitle}
                                                        value={badge.value.toFixed(2)}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    <Spacer mb={5}/>
                                    <Grid container spacing={6} justifyContent={"space-between"}>
                                        <Grid item xs={12} lg={6}>
                                            <ColouredTypography align={"center"} variant={"h4"}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__dq_comp_scores")}
                                            </ColouredTypography>
                                            <Spacer mb={10}/>
                                            <RadarChart
                                                ref={radarRef}
                                                suggestedMin={50}
                                                data={{
                                                    labels: [...indicators.map((indicator) => get_indicator_alias(indicator))],
                                                    selected: [...indicators.map((indicator) => selectedIndicators.includes(indicator))],
                                                    graphData: [...selectedCategories.concat(selectedCountries.filter((country) => country !== org_country)).map((country, index) => {
                                                        return {
                                                            index: [...selectedCategories.concat(defaultCountries)].indexOf(country),
                                                            country: get_category_alias(country),
                                                            data: selectedIndicators.length != 0 ? [...indicator_scores[country]] : [],
                                                            border_opacity: selectedCategories.includes(country) ? 1.0 : selectedCountries.includes(country) ? 0.2 : 1.0,
                                                            background_opacity: selectedCategories.includes(country) ? 0.0 : selectedCountries.includes(country) ? 0.2 : 0.0,
                                                            show: selectedCategories.includes(country) ? true : selectedCountries.includes(country) ? true : false,
                                                        }
                                                    })]
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <ColouredTypography align={"center"} variant={"h4"}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__annual_score")}
                                            </ColouredTypography>
                                            <Spacer mb={10}/>
                                            <LineChart
                                                ref={lineRef}
                                                radarRef={radarRef}
                                                suggestedMin={60}
                                                data={{
                                                    labels: [...years],
                                                    graphData: [...selectedCategories.concat(selectedCountries.filter((country) => country !== org_country)).map((country) => {
                                                        const annual_scores_objs = []
                                                        if(summed_scores[country])
                                                        {
                                                            annual_scores_objs.push(...summed_scores[country].annual_scores)
                                                        }
                                                        const annual_scores_years = annual_scores_objs.map((obj) => obj.year)
                                                        const score_values = years.map((year) => {
                                                            const index = annual_scores_years.indexOf(year)
                                                            if(index === -1)
                                                                return undefined;
                                                            else
                                                                return annual_scores_objs[index].val ? annual_scores_objs[index].val : 0;
                                                        })
                                                        return {
                                                            index: [...selectedCategories.concat(defaultCountries)].indexOf(country),
                                                            country: get_category_alias(country),
                                                            data: [...score_values],
                                                            opacity: selectedCategories.includes(country) ? 1 : selectedCountries.includes(country) ? 0.3 : 0,
                                                            show: selectedCategories.includes(country) ? true : selectedCountries.includes(country) ? true : false,
                                                        }
                                                    })]
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    {/*<Button mt={5} variant="contained" onClick={onClickDownload}>*/}
                                    {/*    Download Data*/}
                                    {/*</Button>*/}
                                </Box>
                            </CardContent>
                        </Card>
                        <Box mx={"3vw"} my={"2vw"}>
                            <ColouredTypography variant={"body1"} size={"16px"} align={"center"} color={"grey"}>
                                Digital competency score (65-135) is a result of aggregating the score (0-100) received by each respondent at the end of your Assessment, benchmarked against national and global database.
                            </ColouredTypography>
                        </Box>
                    </Grid>
                </Grid>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
            :
            <Paper container>
                <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
    );
}

export default DigitalSkillsTab;