import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import {X} from "react-feather"
import {IconButton} from "@material-ui/core";

const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '70%',
        height: '85%',
        overflowY: "auto"
    },
    btn: {
        marginTop: 16
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ContainerModal(props) {

    const { open, handleClose, children } = props

    const classes = useStyles();

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.modalPaper}>
                        <Box align={'right'}>
                            <IconButton onClick={() => handleClose()}>
                                <X/>
                            </IconButton>
                        </Box>
                        {
                            children
                        }
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

export default ContainerModal;