import {GET_APP_LOCALIZE_TEXT} from "./dq_lc_service/lc_service";

export const LOCAL_STORAGE_KEYS = {
    auth_token: 'dq_analytics_auth_token',
    refresh_token: 'dq_analytics_refresh_token',
    auth_user: 'dq_analytics_auth_user',
    active_org_type: 'dq_analytics_active_org_type',
    active_org_code: 'dq_analytics_active_org_code',
    ACTIVE_LOCALE: 'active_locale'
}

export const THEMES = {
    DEFAULT: "DEFAULT",
    DARK: "DARK",
    LIGHT: "LIGHT",
    BLUE: "BLUE",
    GREEN: "GREEN",
    INDIGO: "INDIGO",
};

export const USER_ACCESS_LEVEL = {
    NOT_APPLICABLE: 0,
    NO_ACCESS: 1,
    PARTIAL_ACCESS_ADMIN_PORTAL: 2,
    FULL_ACCESS_ADMIN_PORTAL: 3,
    FULL_ACCESS_ANALYTICS_PORTAL_FULL_ORG: 4,
    PARTIAL_ACCESS_ANALYTICS_PORTAL: 5,
    FULL_ACCESS_ANALYTICS_PORTAL_SINGLE_INSTITUTE: 6,
};

export const USER_ACCESS_LEVEL_VERBOSE = (locale) => { return {
    0: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_na')}`,
    1: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_no_access')}`,
    2: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_partial_access_admin')}`,
    3: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_full_access_admin')}`,
    4: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_partial_access_analytics')}`,
    5: `${GET_APP_LOCALIZE_TEXT(locale, 'const__user_access_level_full_access_analytics')}`
}};

export const NESTED_ACL_KEYS = {
    USERS_UPDATE_ENTITY: 'admin_users_update_entity',
    USERS_ADD: 'admin_users_add',
    USERS_REMOVE: 'admin_users_remove',
}

export const ORG_TYPE = {
    DEFAULT: 1,
    COMPANY: 2,
    SCHOOL: 3
}

export const ORG_TYPE_VERBOSE = (locale) => { return {
    1: `${GET_APP_LOCALIZE_TEXT(locale, 'const__org_type_partner')}`,
    2: `${GET_APP_LOCALIZE_TEXT(locale, 'const__org_type_company')}`,
    3: `${GET_APP_LOCALIZE_TEXT(locale, 'const__org_type_school')}`
}};

export const LICENSES_ORDER_STATUS_VERBOSE = (locale) => { return {
    0: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_not_set')}`,
    1: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_pending')}`,
    2: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_approved')}`,
    3: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_rejected')}`,
    4: `${GET_APP_LOCALIZE_TEXT(locale, 'const__licenses_order_status_withdrawn')}`
}};

export const LICENSES_ORDER_STATUS = {
    NOT_SET: 0,
    PENDING: 1,
    APPROVED: 2,
    REJECTED: 3,
    WITHDRAWN: 4,
};

export const LOCALES_ENABLED = [
    'en',
    'ko',
    'th',
    'ja',
    'vi'
]
export const DEFAULT_LOCALE = 'en';

export const LOCALE_ROUTE_PARAMS = `(${LOCALES_ENABLED.join('|')})`

export const DQ24_DEFINITIONS = (locale) => ({
    "DQ1": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__digital_citizen_identity'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__digital_citizen_identity')
    },
    "DQ2": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__balanced_use_of_technology'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__balanced_use_of_technology')
    },
    "DQ3": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__behavioural_cyber_risk_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__behavioural_cyber_risk_management')
    },
    "DQ4": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__personal_cyber_security_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__personal_cyber_security_management')
    },
    "DQ5": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__digital_empathy'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__digital_empathy')
    },
    "DQ6": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__digital_footprint_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__digital_footprint_management')
    },
    "DQ7": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__media_and_information_literacy'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__media_and_information_literacy')
    },
    "DQ8": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__privacy_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__privacy_management')
    },
    "DQ9": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__digital_co_creator_identity'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__digital_co_creator_identity')
    },
    "DQ10": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__healthy_use_of_technology'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__healthy_use_of_technology')
    },
    "DQ11": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__content_cyber_risk_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__content_cyber_risk_management')
    },
    "DQ12": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__network_security_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__network_security_management')
    },
    "DQ13": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__self_awareness_and_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__self_awareness_and_management')
    },
    "DQ14": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__online_communication_and_collaboration'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__online_communication_and_collaboration')
    },
    "DQ15": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__content_creation_and_computational_literacy'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__content_creation_and_computational_literacy')
    },
    "DQ16": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__intellectual_property_rights_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__intellectual_property_rights_management')
    },
    "DQ17": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__digital_changemaker_identity'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__digital_changemaker_identity')
    },
    "DQ18": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__civic_use_of_technology'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__civic_use_of_technology')
    },
    "DQ19": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__commercial_and_community_cyber_risk_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__commercial_and_community_cyber_risk_management')
    },
    "DQ20": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__organisational_cyber_security_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__organisational_cyber_security_management')
    },
    "DQ21": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__relationship_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__relationship_management')
    },
    "DQ22": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__public_and_mass_communication'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__public_and_mass_communication')
    },
    "DQ23": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__data_and_ai_literacy'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__data_and_ai_literacy')
    },
    "DQ24": {
        "title": GET_APP_LOCALIZE_TEXT(locale, 'dq_code__participatory_rights_management'),
        "definition": GET_APP_LOCALIZE_TEXT(locale, 'dq_def__participatory_rights_management')
    }
});

// export const DQ24_TITLES = {
//     ...Object.fromEntries(Object.keys(DQ24_DEFINITIONS).map((key) => [key, `${key}: ${DQ24_DEFINITIONS[key].title}`]))
// }

export const DQC24_TITLES = (locale) => {
    const defs = DQ24_DEFINITIONS(locale);
    return {
        "DQC01": `DQ1: ${defs["DQ1"].title}`,
        "DQC02": `DQ2: ${defs["DQ2"].title}`,
        "DQC03": `DQ3: ${defs["DQ3"].title}`,
        "DQC04": `DQ4: ${defs["DQ4"].title}`,
        "DQC05": `DQ5: ${defs["DQ5"].title}`,
        "DQC06": `DQ6: ${defs["DQ6"].title}`,
        "DQC07": `DQ7: ${defs["DQ7"].title}`,
        "DQC08": `DQ8: ${defs["DQ8"].title}`,
        "DQC09": `DQ9: ${defs["DQ9"].title}`,
        "DQC10": `DQ10: ${defs["DQ10"].title}`,
        "DQC11": `DQ11: ${defs["DQ11"].title}`,
        "DQC12": `DQ12: ${defs["DQ12"].title}`,
        "DQC13": `DQ13: ${defs["DQ13"].title}`,
        "DQC14": `DQ14: ${defs["DQ14"].title}`,
        "DQC15": `DQ15: ${defs["DQ15"].title}`,
        "DQC16": `DQ16: ${defs["DQ16"].title}`,
        "DQC17": `DQ17: ${defs["DQ17"].title}`,
        "DQC18": `DQ18: ${defs["DQ18"].title}`,
        "DQC19": `DQ19: ${defs["DQ19"].title}`,
        "DQC20": `DQ20: ${defs["DQ20"].title}`,
        "DQC21": `DQ21: ${defs["DQ21"].title}`,
        "DQC22": `DQ22: ${defs["DQ22"].title}`,
        "DQC23": `DQ23: ${defs["DQ23"].title}`,
        "DQC24": `DQ24: ${defs["DQ24"].title}`,
    }
};

export const DQW_DQC_MODULES = (locale, dqw_enabled = true) => {
    const titles = DQC24_TITLES(locale);
    return {
        "DCT-DQCAll": {
            code: "DCT",
            zone: "DCT",
            title: dqw_enabled ? `${GET_APP_LOCALIZE_TEXT(locale, 'dq_code__before_the_dq_world_edu')}: ` : ""
                + `${GET_APP_LOCALIZE_TEXT(locale, 'side_panel__dq_comp_score')}`
        },
        "DQW-DQCAll": {
            code: "DQW",
            zone: "DQW",
            title: `${GET_APP_LOCALIZE_TEXT(locale, 'dq_code__after_the_dq_world_edu')}: ${GET_APP_LOCALIZE_TEXT(locale, 'side_panel__dq_comp_score')}`
        },
        'DQW-DQC01': {
            code: "DQ1",
            zone: "DQW.Z4",
            title: titles["DQC01"]
        },
        'DQW-DQC02': {
            code: "DQ2",
            zone: "DQW.Z1",
            title: titles["DQC02"]
        },
        'DQW-DQC03': {
            code: "DQ3",
            zone: "DQW.Z3",
            title: titles["DQC03"]
        },
        'DQW-DQC04': {
            code: "DQ4",
            zone: "DQW.Z6",
            title: titles["DQC04"]
        },
        'DQW-DQC05': {
            code: "DQ5",
            zone: "DQW.Z8",
            title: titles["DQC05"]
        },
        'DQW-DQC06': {
            code: "DQ6",
            zone: "DQW.Z5",
            title: titles["DQC06"]
        },
        'DQW-DQC07': {
            code: "DQ7",
            zone: "DQW.Z7",
            title: titles["DQC07"]
        },
        'DQW-DQC08': {
            code: "DQ8",
            zone: "DQW.Z2",
            title: titles["DQC08"]
        },
    }
}

export const COUNTRY_CONFIGS = {
    "Australia": {
        administrative_sector: "Region",
        socio_economic_class: "ADII",
        indigenous: true,
        included_countries: [
            "New Zealand",
            "Philippines",
            "Indonesia"
        ]
    },
    "New Zealand": {
        administrative_sector: "Region",
        socio_economic_class: undefined,
        indigenous: true,
    },
    "Saudi Arabia": {
        administrative_sector: "Province",
        socio_economic_class: "SES",
        indigenous: false,
    },
    default: {
        administrative_sector: "Region",
        socio_economic_class: undefined,
        indigenous: false,
        included_countries: []
    }
}

export const GRADE_LABELS = {
    0: "Requires Attention",
    85: "Below Average",
    100: "Above Average",
    115: "Excellent",
}

export const SCALE = [
    "1 Strongly disagree 2 Disagree 3 Slightly disagree 4 Neither agree nor disagree 5 Slightly agree 6 Agree 7 Strongly agree"
]

export const DOMAIN_INTERESTS = {
    "question": ["nus-ctic-dwif.q1", "nus-ctic-dwif.q2"],
    "interests": ["nus-ctic-dwif.q2.1", "nus-ctic-dwif.q2.2", "nus-ctic-dwif.q2.3", "nus-ctic-dwif.q2.4", "nus-ctic-dwif.q2.5"]
}
export const DEMOGRAPHIC_QUESTIONS = ["nus-ctic-dwif.q51", "nus-ctic-dwif.q52", "nus-ctic-dwif.q53"]
export const DOMAIN_MAPPING = {
    "Digital Social Relations": "D1",
    "Digital Health": "D2",
    "Digital Consumption": "D3",
    "Digital Employment": "D4",
    "Digital Civic Participation": "D5"
};


export const ITEM_MAPPING = {
    'nus-ctic-dwif.q3': "D1_Q1",
    'nus-ctic-dwif.q4': "D1_Q2",
    'nus-ctic-dwif.q5': "D1_Q3",
    'nus-ctic-dwif.q6': "D1_Q4",
    'nus-ctic-dwif.q7': "D1_Q5",
    'nus-ctic-dwif.q8': "D1_Q6",
    'nus-ctic-dwif.q9': "D1_Q7",
    'nus-ctic-dwif.q10': "D1_Q8",
    'nus-ctic-dwif.q11': "D1_Q9",
    'nus-ctic-dwif.q12': "D2_Q1",
    'nus-ctic-dwif.q13': "D2_Q2",
    'nus-ctic-dwif.q14': "D2_Q3",
    'nus-ctic-dwif.q15': "D2_Q4",
    'nus-ctic-dwif.q16': "D2_Q5",
    'nus-ctic-dwif.q17': "D2_Q6",
    'nus-ctic-dwif.q18': "D2_Q7",
    'nus-ctic-dwif.q19': "D2_Q8",
    'nus-ctic-dwif.q20': "D2_Q9",
    'nus-ctic-dwif.q21': "D3_Q1",
    'nus-ctic-dwif.q22': "D3_Q2",
    'nus-ctic-dwif.q23': "D3_Q3",
    'nus-ctic-dwif.q24': "D3_Q4",
    'nus-ctic-dwif.q25': "D3_Q5",
    'nus-ctic-dwif.q26': "D3_Q6",
    'nus-ctic-dwif.q27': "D3_Q7",
    'nus-ctic-dwif.q28': "D3_Q8",
    'nus-ctic-dwif.q29': "D3_Q9",
    'nus-ctic-dwif.q30': "D4_Q1",
    'nus-ctic-dwif.q31': "D4_Q2",
    'nus-ctic-dwif.q32': "D4_Q3",
    'nus-ctic-dwif.q33': "D4_Q4",
    'nus-ctic-dwif.q34': "D4_Q5",
    'nus-ctic-dwif.q35': "D4_Q6",
    'nus-ctic-dwif.q36': "D4_Q7",
    'nus-ctic-dwif.q37': "D4_Q8",
    'nus-ctic-dwif.q38': "D4_Q9",
    'nus-ctic-dwif.q39': "D4_Q10",
    'nus-ctic-dwif.q40': "D4_Q11",
    'nus-ctic-dwif.q41': "D4_Q12",
    'nus-ctic-dwif.q42': "D5_Q1",
    'nus-ctic-dwif.q43': "D5_Q2",
    'nus-ctic-dwif.q44': "D5_Q3",
    'nus-ctic-dwif.q45': "D5_Q4",
    'nus-ctic-dwif.q46': "D5_Q5",
    'nus-ctic-dwif.q47': "D5_Q6",
    'nus-ctic-dwif.q48': "D5_Q7",
    'nus-ctic-dwif.q49': "D5_Q8",
    'nus-ctic-dwif.q50': "D5_Q9",
};