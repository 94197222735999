import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import Helmet from "react-helmet";

import {
    CardContent,
    Grid,
    Card as MuiCard,
    Divider as MuiDivider,
    Typography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);


function EmptyCard() {
    return (
        <Card mb={6}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Empty card
                </Typography>
                <Typography variant="body2" gutterBottom>
                    Empty card
                </Typography>
            </CardContent>
        </Card>
    );
}

function Callbacks() {
    return (
        <React.Fragment>
            <Helmet title="DQ-PRO | Callbacks" />
            <Typography variant="h3" gutterBottom display="inline">
                Callbacks
            </Typography>


            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <EmptyCard />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Callbacks;
