import React, {useEffect, useState} from "react";
import {Paper, Spacer, Typography, Grid, Box} from "../../../components/StyledComponents/styledComponents";
import {Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from "@material-ui/core";
import styled from "styled-components";
import {Helmet} from "react-helmet";
import {useAuth} from "../../../context/auth";
import {USER_ACCESS_LEVEL} from "../../../utils/constants";
import {Link, useLocation} from "react-router-dom"
import {getDQWReportRedirectLink, getInstitutes} from "../../../services/analysis.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";


const BorderLessTableCell = styled(TableCell)`
  border: none;
`

const LinkButton = styled(Link)`
  background-color: deepskyblue;
  color: white;
  font-weight: bold;
  &:hover {
    color: white;
    background-color: lightskyblue;
  }
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
`

const Analysis = () => {

    const { authUser } = useAuth();
    const { org_code } = authUser;
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [institutes, setInstitutes] = useState([]);
    const is_multi_institute = authUser.access_level === USER_ACCESS_LEVEL.FULL_ACCESS_ANALYTICS_PORTAL_FULL_ORG;
    const [errorMessage, setErrorMessage] = useState('');

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getInstitutes(org_code);
            console.log('Result Data of Institutes --> ', result.data);
            if (result.data && result.data.length > 0) {
                setInstitutes(result.data)
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching institutes", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    useEffect(() => {
        if(is_multi_institute)
        {
            loadPageData().then(d => console.log('Institutes Fetched', d));
        }
    }, [])

    async function redirectToDQWReport(institute_code) {
        setLoading(true);
        try {
            const result = await getDQWReportRedirectLink(org_code, institute_code, window.location.href);
            if (result.data) {
                const {redirect_link} = result.data;
                if(redirect_link) {
                    window.open(redirect_link, "_blank")
                }
            }
        } catch (e) {
            console.log("Some error occurred while fetching dqw report redirect link", e)
        }
        setLoading(false);
    }

    return <Paper p={15}>
        <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "analysis__in_depth")}`}/>
        {
            !is_multi_institute || institutes.length > 0 ? //The first condition enables us to avoid error checking in case of single institute
                //second condition allows normal behaviour for multi-institutional org
            <>
                <Typography font_weight={"bold"} font_color={"deepskyblue"} font_size={'16px'}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "analysis__in_depth").toUpperCase()}
                </Typography>
                <Spacer py={3}/>
                <Typography>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "analysis__in_depth_desc")}<br/>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "analysis__in_depth_desc_2")}
                </Typography>
                {
                    is_multi_institute ?
                        <Spacer py={3} mr={'50%'}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <BorderLessTableCell align={'center'}>
                                                <Typography font_weight={"bold"}>
                                                    {GET_APP_LOCALIZE_TEXT(pageLc, "progress__school_name")}
                                                </Typography>
                                            </BorderLessTableCell>
                                            <BorderLessTableCell align={'center'}></BorderLessTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            institutes.map((institute) => <TableRow>
                                                <BorderLessTableCell align={'center'}>
                                                    <Typography >
                                                        {institute}
                                                    </Typography>
                                                </BorderLessTableCell>
                                                <BorderLessTableCell align={'center'}>
                                                    <LinkButton to={{
                                                        // pathname: `/analysis/summary`,
                                                        // search: `?institute=${institute}`,
                                                        // state: { test: true }
                                                    }} onClick={() => redirectToDQWReport(institute)}>
                                                        {GET_APP_LOCALIZE_TEXT(pageLc, "analysis__view")}
                                                    </LinkButton>
                                                </BorderLessTableCell>
                                            </TableRow>)
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Spacer>
                        :
                        <Spacer py={3}>
                            <LinkButton to={{
                                // pathname: `/analysis/summary`,
                                // search: `?institute=${authUser.institute_code}`,
                                // state: { test: true }
                            }} onClick={() => redirectToDQWReport(authUser.institute_code)}>
                                {GET_APP_LOCALIZE_TEXT(pageLc, "analysis__view")}
                            </LinkButton>
                        </Spacer>
                }
            </> :
            <>
                <Typography primary variant={"h3"} align={'center'} py={5}>{errorMessage}</Typography>
            </>
        }
        <LoaderWithBackDrop loading={loading}/>
    </Paper>
}

export default Analysis;