import React from "react";
import {useLocation} from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";

import orange from "@material-ui/core/colors/orange";
import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Bar } from "react-chartjs-2";
import Button from "@material-ui/core/Button";
import {toastWarning} from "../../../utils/utils";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 300px;
  width: 100%;
`;

function BarChart({ theme }) {

  const location = useLocation();
  const pageLc = location.pathname.split('/')[1];

  const data = {
    labels: [
      `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__risk')} 1`,
      `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__risk')} 2`,
      `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__risk')} 3`,
      `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__risk')} 4`,
      `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__risk')} 5`,
      `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__risk')} 6`,
    ],
  // #FF1B0A
    datasets: [
      {
        label: `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__singtel_avg')}`,
        backgroundColor: orange[600],
        borderColor:orange[600],
        hoverBackgroundColor: orange[600],
        hoverBorderColor: orange[600],
        data: [72, 87, 51, 71, 77, 65, 69, 73, 60, 76, 48, 79],
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
      {
        label: `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__national_avg')}`,
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        hoverBackgroundColor: theme.palette.secondary.main,
        hoverBorderColor: theme.palette.secondary.main,
        data: [54, 67, 41, 55, 62, 45, 55, 73, 60, 76, 48, 79],
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
      {
        label: `${GET_APP_LOCALIZE_TEXT(pageLc, 'home__global_avg')}`,
        backgroundColor: theme.palette.grey[300],
        borderColor: theme.palette.grey[300],
        hoverBackgroundColor: theme.palette.grey[300],
        hoverBorderColor: theme.palette.grey[300],
        data: [69, 66, 24, 48, 52, 51, 44, 53, 62, 79, 51, 68],
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          // display: false,
          stacked: false,
          ticks: {
            // stepSize: 40,
            beginAtZero: true
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
  };

  return (
      <React.Fragment>
        <Typography variant="h3" gutterBottom align={'center'}>
          {`${GET_APP_LOCALIZE_TEXT(pageLc, 'home__cyber_risk_vulnerability')}`}
        </Typography>

        <Card mb={3}>
          <CardContent>

            <Spacer mb={6} />

            <ChartWrapper>
              <Bar data={data} options={options} />
            </ChartWrapper>



          </CardContent>
        </Card>

      </React.Fragment>

  );
}

export default withTheme(BarChart);
