import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";

const Copyright = () => {
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {`${GET_APP_LOCALIZE_TEXT(pageLc, "general__copyright")} © `}
            {new Date().getFullYear()}
            {' '}
            <Link color="inherit" href="https://www.poweredbydq.com/">
                DQ LAB Pte Ltd.
            </Link>
            {' '}
            {'All Rights Reserved.'}
        </Typography>
    );
}

export default Copyright;