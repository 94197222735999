import {alphaNumericSort, getRndInteger, getUniqueValues} from "../../../../utils/utils";

const getCompletionRates = () => {
    return [
        {
            program: "DCT",
            rate: getRndInteger(0, 100),
        },
        {
            program: "DQWZ 1",
            rate: getRndInteger(0, 100),
        },
        {
            program: "DQWZ 2",
            rate: getRndInteger(0, 100),
        },
        {
            program: "DQWZ 3",
            rate: getRndInteger(0, 100),
        },
        {
            program: "DQWZ 4",
            rate: getRndInteger(0, 100),
        }
    ];
}

const getDummySchools = (count) => {
    return new Array(count).fill(null).map((_, i) => ({
        school_name: `School ${i+1}`,
        school_level: "Junior",
        students_count: getRndInteger(500, 2500),
        completion_rates: getCompletionRates(),
        extras: [
            {
                field: "School Head Name",
                value: "Lisa Lee",
            },
            {
                field: "School Head Email",
                value: "example@gmail.com"
            },
        ],
    }));
}

const getDummyClasses = (count) => {
    return new Array(count).fill(null).map((_, i) => ({
        class_name: `Class ${i+1}`,
        class_level: "P1",
        students_count: getRndInteger(0, 50),
        completion_rates: getCompletionRates(),
    }))
}

const getDummyStudents = (count) => {
    return new Array(count).fill(null).map((_, i) => ({
        student_id: i+1,
        username: `User${i+1}`,
        last_name: "Lim",
        first_name: "Jane",
        completion_rates: getCompletionRates(),
        extras: [
            {
                field: "Email",
                value: "example@gmail.com",
            },
            {
                field: "Gender",
                value: "Male"
            },
            {
                field: "Dob",
                value: "dd/mm/yy",
            },
            {
                field: "Password",
                value: "ex1234"
            }
        ],
    }))
}

export const allSchoolsSelector = "All Schools"
export const allClassesSelector = "All Classes"
export const schools = ["School A", "School B"]
export const classes = ["Class A", "Class B"]

const combinedData = {
    "All Schools": getDummySchools(50),
    "School A": {
        "All Classes": getDummyClasses(50),
        "Class A": getDummyStudents(50),
        "Class B": getDummyStudents(50),
    },
    "School B": {
        "All Classes": getDummyClasses(50),
        "Class A": getDummyStudents(50),
        "Class B": getDummyStudents(50),
    },
}

export function fetchSelectors() {
    const result = {
        schools: ["All Schools", "School A", "School B"],
        classes: {
            "School A": ["All Classes", "Class A", "Class B"],
            "School B": ["All Classes", "Class A", "Class B"]
        },
    }
    return result;
}

export function fetchData(selectedSchool, selectedClass, page = 0, rowsPerPage = 10, order = "asc", orderBy = undefined)
{

    const defaultSchool = schools[0];

    let total_records = 0;
    let selector = "school";
    let data = [];
    let programs = []
    if(selectedSchool)
    {
        if(selectedClass)
        {
            selector = selectedClass === allClassesSelector ? "class" : "student"
            if(!combinedData[selectedSchool])
            {
                data.push(...combinedData[defaultSchool][selectedClass])
            }
            else
            {
                data.push(...combinedData[selectedSchool][selectedClass])
            }
        }
        else
        {
            console.log(combinedData[selectedSchool], selectedSchool)
            if(!combinedData[selectedSchool])
            {
                data.push(...combinedData[allSchoolsSelector])
            }
            else
            {
                data.push(...combinedData[selectedSchool])
            }
        }
    }
    total_records = data.length;
    //Calculating Programs
    const completion_rates = data.map((entry) => entry.completion_rates)
    const program_arrays = completion_rates.map((array) => array.map((object) => object.program))
    programs.push(...getUniqueValues(program_arrays))
    //Sorting
    if(orderBy)
    {
        // console.log("order", orderBy, data)
        data.sort(alphaNumericSort(orderBy, true))
        if(order === "desc")
        {
            data = data.reverse();
        }
    }
    //Slicing
    const startingRowIndex = page*rowsPerPage
    const endingRowIndex = startingRowIndex + rowsPerPage;
    data = data.slice(startingRowIndex, endingRowIndex);
    return { selector, page, rowsPerPage, order, orderBy, programs, total_records, data};
}