const buttonRadius = 12;
const circleRadius = 3;
const clickableRadius = buttonRadius + circleRadius;

export const scroll = {
    id: 'scroll',
    afterInit: function(chart) {
        // console.log("afterInit", chart)
        // const {ctx, data: {labels}, options: {scales: {xAxes}, plugins: {scroll: plugin_options}}} = chart;
        // const {values, datasets, division} = plugin_options;
        //
        // const min = 0;
        // const max = division;
        //
        // console.log(values.length, chart.chart.data.labels, min, max);
        //
        // chart.data.labels = values.slice(min, max);
        // // datasets.forEach((dataset, index) => {
        // //     chart.data.datasets[index].data = dataset.data.slice(min, max);
        // // })
        //
        // xAxes[0].ticks.min = min;
        // xAxes[0].ticks.max = max;
    },
    afterEvent: function (chart, args) {
        const {canvas, chartArea: {top, bottom, left, right}, options: {scales: {xAxes}, plugins: {scroll: plugin_options}}} = chart;
        const {values, division, datasets} = plugin_options;
        const lastIndex = values.length - 1;
        let min = xAxes[0].ticks.min;
        let max = xAxes[0].ticks.max;

        const {x, y, type} = args;

        const is_cursor_on_left_button = (min && min > 0 && x >= left - clickableRadius && x <= left + clickableRadius
                && y >= top + (bottom/2) - clickableRadius && y <= top + (bottom/2) + clickableRadius)

        const is_cursor_on_right_button = (max && max < lastIndex && x >= right - clickableRadius && x <= right + clickableRadius
            && y >= top + (bottom/2) - clickableRadius && y <= top + (bottom/2) + clickableRadius)

        if(type === 'mousemove')
        {
            if(is_cursor_on_left_button || is_cursor_on_right_button)
            {
                canvas.style.cursor = 'pointer';
            }
            else
            {
                canvas.style.cursor = 'default';
            }
        }
        else if(type === 'click')
        {
            if(is_cursor_on_left_button || is_cursor_on_right_button)
            {
                let additive_number = 1;
                if(is_cursor_on_left_button) additive_number = -1;
                min += additive_number;
                max += additive_number;
                chart.data.labels = values.slice(min, max);
                datasets.forEach((dataset, index) => {
                    chart.data.datasets[index].data = dataset.data.slice(min, max);
                })
                xAxes[0].ticks.min = min;
                xAxes[0].ticks.max = max;
                chart.update();
            }

        }
    },
    afterDatasetDraw: function (chart) {
        const {ctx, data, chartArea: {top, bottom, left, right}, height, options: {scales: {xAxes}, plugins: {scroll: plugin_options}}} = chart;
        const {values, division} = plugin_options;
        const lastIndex = values.length - 1;
        const min = xAxes[0].ticks.min;
        const max = xAxes[0].ticks.max;

        ctx.save();
        const angle = Math.PI / 180;
        const radius = buttonRadius;

        function createButton(x, y, r, aS, aE, text) {
            ctx.beginPath();
            ctx.lineWidth = circleRadius;
            ctx.strokeStyle = 'rgba(100,100,100,1)';
            ctx.fillStyle = 'white';
            ctx.arc(x, y, r,  aS, aE, false);
            ctx.closePath();
            ctx.stroke();
            ctx.fill();

            ctx.font = 'bold 15px sans-serif';
            ctx.textAlign = 'center';
            ctx.fillStyle = 'rgb(151,151,151, 1)';
            ctx.fillText(text, x, y);
        }

        if(min && min > 0)
        {
            createButton(left, top + (bottom/2), radius, 0, angle * 360, '<');
        }
        if(max && max < lastIndex)
        {
            createButton(right, top + (bottom/2), radius, 0, angle * 360, '>');
        }
    }
}