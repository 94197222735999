import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { withRouter } from "react-router";

import {Copyright} from '../../../components';

import { useFormik } from 'formik';

import {initResetPassword} from '../../../services/auth.service';
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {store} from "react-notifications-component";
import Helmet from "react-helmet";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const InitPasswordReset = (props) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: async (values) => {
            console.log('Form data => ', values);
            try {
                const result = await initResetPassword(values.email);
                console.log('result.data -> ', result.data);
                // redirect to login page here
                store.addNotification({
                    title: GET_APP_LOCALIZE_TEXT(pageLc, "auth__token_sent"),
                    message: GET_APP_LOCALIZE_TEXT(pageLc, "auth__token_sent_success"),
                    type: "success",
                    insert: "bottom",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 2000,
                        showIcon: true
                    }
                });

                props.history.push('/auth/reset/password');
            } catch (e) {
                // already toasted the error
            }
        }
    });

    return (
        <Container component="main" maxWidth="xs">
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "progress__reset_pass")}`} />
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {GET_APP_LOCALIZE_TEXT(pageLc, "auth__req_reset_pass")}
                </Typography>
                <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={GET_APP_LOCALIZE_TEXT(pageLc, "general__email_address")}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {GET_APP_LOCALIZE_TEXT(pageLc, "auth__send_reset_code")}
                    </Button>

                    <Grid container>
                        <Grid item xs>
                            <Link href="/login" variant="body2">
                                {GET_APP_LOCALIZE_TEXT(pageLc, "auth__back_to_login")}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/auth/reset/password" variant="body2">
                                {GET_APP_LOCALIZE_TEXT(pageLc, "auth__already_receive_code")}
                            </Link>
                        </Grid>
                    </Grid>

                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>

            <LoaderWithBackDrop loading={loading} />

        </Container>
    );
}

export default withRouter(InitPasswordReset);