import React, {useEffect, useState} from "react";
import {Paper, Typography, Box, Grid, Spacer} from "../../../components/StyledComponents/styledComponents"
import {withStyles} from "@material-ui/core/styles";
import {Table, TableHead, TableBody, TableRow, TableCell, TableContainer, CardContent, Card} from "@material-ui/core";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {useAuth} from "../../../context/auth";
import {isDQWEnabled} from "../../../utils/utils";
import {ModulesLists} from "../../../services/organization.service"
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const CustomTable = withStyles((theme) => ({
    root: {
        border: "thick solid darkgrey"
    }
}))(Table);

const CustomTableCell = withStyles((theme) => ({
    root: {
        border: "thin solid darkgrey",
        fontSize: 9,
    },
    head: {
        color: theme.palette.common.white,
    },
}))(TableCell);

function ProgramOverviewSchoolTab() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];
    const {authUser} = useAuth();
    const {org_obj} = authUser;
    const [loading, setLoading] = useState(false);
    const [programs, setPrograms] = useState([]);

    useEffect( () => {
        loadPageData().then(d => console.log('Program Overview Data Fetched!', d));
    }, []);

    async function loadPageData() {
        setLoading(true);
        try {
            const defaultPrograms = [];

            if (isDQWEnabled(org_obj)) {
                defaultPrograms.push({
                    image_url: 'https://www.dqworld.net/static/307239050/img/zones/landing/dqworld.png',
                    image_size: 25,
                    title: GET_APP_LOCALIZE_TEXT(pageLc, "overview__dqw_title"),
                    description: GET_APP_LOCALIZE_TEXT(pageLc, "overview__dqw_desc"),
                    type: GET_APP_LOCALIZE_TEXT(pageLc, "overview__dqw_type"),
                    time: GET_APP_LOCALIZE_TEXT(pageLc, "overview__dqw_time")
                });
            }

            const result = await ModulesLists.get_org_modules(org_obj.org_code);

            let fetchedPrograms = [];

            if (!result.error && result.data) {
                fetchedPrograms = result.data.org_modules
                    .filter(itemA => {
                        if (itemA.pre_test_module_id) {
                            return true;
                        }
                        return !result.data.org_modules.some(itemB => itemA.id === itemB.pre_test_module_id);
                    })
                    .map(item => ({
                    image_url: item.icon_url,
                    image_size: 50,
                    title: item.module_name,
                    description: item.module_description,
                    type: item.type ? item.type: GET_APP_LOCALIZE_TEXT(pageLc, "overview__dct_type"),
                    time: item.duration,
                    order_no: item.order_no
                }));

            }
            const sortedPrograms = [...defaultPrograms, ...fetchedPrograms].sort((a, b) => {
                // Place defaultPrograms at the top
                if (defaultPrograms.includes(a)) return -1;
                if (defaultPrograms.includes(b)) return 1;

                // Sort the rest by order_no
                return a.order_no - b.order_no;
            });
            setPrograms(sortedPrograms);
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    }




    const dq_score_meanings = [
        {
            heading: GET_APP_LOCALIZE_TEXT(pageLc, "overview__knowledge"),
            excellent: GET_APP_LOCALIZE_TEXT(pageLc, "overview__knowledge_excellent"),
            satisfactory: GET_APP_LOCALIZE_TEXT(pageLc, "overview__knowledge_satisfy"),
            less_than_satisfactory: GET_APP_LOCALIZE_TEXT(pageLc, "overview__knowledge_less_satisfy"),
            requires_attention: GET_APP_LOCALIZE_TEXT(pageLc, "overview__knowledge_attention")
        },
        {
            heading: GET_APP_LOCALIZE_TEXT(pageLc, "overview__skills"),
            excellent: GET_APP_LOCALIZE_TEXT(pageLc, "overview__skills_excellent"),
            satisfactory: GET_APP_LOCALIZE_TEXT(pageLc, "overview__skills_satisfy"),
            less_than_satisfactory: GET_APP_LOCALIZE_TEXT(pageLc, "overview__skills_less_satisfy"),
            requires_attention: GET_APP_LOCALIZE_TEXT(pageLc, "overview__skills_attention")
        },
        {
            heading: GET_APP_LOCALIZE_TEXT(pageLc, "overview__attitude"),
            excellent: GET_APP_LOCALIZE_TEXT(pageLc, "overview__attitude_excellent"),
            satisfactory: GET_APP_LOCALIZE_TEXT(pageLc, "overview__attitude_satisfy"),
            less_than_satisfactory: GET_APP_LOCALIZE_TEXT(pageLc, "overview__attitude_less_satisfy"),
            requires_attention: GET_APP_LOCALIZE_TEXT(pageLc, "overview__attitude_attention")
        }
    ]

    return <Card p={10}>
        <CardContent>
            <Box>
        <Grid container spacing={5} px={5} py={10}>
            <Grid item xs={12} sm={5} py={5}>
                <Typography align={'center'} font_size={"14px"} font_color={"grey"} px={3}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__following_programs")}
                </Typography>
                <Spacer py={2}/>
                <Grid container direction={'column'} spacing={3}>
                    {
                        programs.map((program) => <Grid item>
                            <Box border={1} borderRadius={"15px"} borderColor={"lightGrey"} p={3}>
                                <Box align={'center'}>
                                    <img src={program.image_url} height={program.image_size}/>
                                </Box>
                                <Typography primary align={'center'} font_size={"16px"} font_weight={'bold'}>
                                    {`${GET_APP_LOCALIZE_TEXT(pageLc, "overview__about")} ${program.title}:`}
                                </Typography>
                                <Typography font_size={'10px'} font_style={'italic'}>
                                    {program.description}
                                </Typography>
                                <Spacer py={2}/>
                                <Box>
                                    <Typography font_weight={'bold'} display={'inline'}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__type")}: </Typography>
                                    <Typography display={'inline'}>{program.type}</Typography>
                                </Box>
                                <Box>
                                    <Typography font_weight={'bold'} display={'inline'}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__time_to_complete")}: </Typography>
                                    <Typography display={'inline'}>{program.time}</Typography>
                                </Box>
                            </Box>
                        </Grid>)
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading} />
            <Grid item xs={12} sm={7}>
                <Typography align={'center'} font_size={"24px"} font_weight={'bold'} font_color={'dodgerblue'} pb={5}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__about_dq_score")}:
                </Typography>
                <Typography align={'center'} font_size={"14px"} font_color={"grey"} pb={5}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__following_breakdown")}
                </Typography>
                <TableContainer component={Paper}>
                    <CustomTable size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <CustomTableCell align="center"></CustomTableCell>
                                <CustomTableCell align="center" bgcolor={'deepskyblue'}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__excellent")}<br/>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__excellent_criteria")}</CustomTableCell>
                                <CustomTableCell align="center" bgcolor={'gold'}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__satisfy")}<br/>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__satisfy_criteria")}</CustomTableCell>
                                <CustomTableCell align="center" bgcolor={'darkorange'}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__less_satisfy")}<br/>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__less_satisfy_criteria")}</CustomTableCell>
                                <CustomTableCell align="center" bgcolor={'red'}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__attention")}<br/>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__attention_criteria")}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dq_score_meanings.map((meaning, index) => <TableRow key={index}>
                                    <CustomTableCell>
                                        <Typography font_weight={'bold'} font_size={'inherit'}>{meaning.heading}</Typography>
                                    </CustomTableCell>
                                    <CustomTableCell>{meaning.excellent}</CustomTableCell>
                                    <CustomTableCell>{meaning.satisfactory}</CustomTableCell>
                                    <CustomTableCell>{meaning.less_than_satisfactory}</CustomTableCell>
                                    <CustomTableCell>{meaning.requires_attention}</CustomTableCell>
                                </TableRow>)
                            }
                        </TableBody>
                    </CustomTable>
                </TableContainer>
            </Grid>
        </Grid>
            </Box></CardContent></Card>

}

export default ProgramOverviewSchoolTab;