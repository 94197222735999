import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Table,
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    makeStyles,
    withStyles, Button as MuiButton,
} from "@material-ui/core";

import {LinearProgressWithLabel} from "../../components/LinearProgressWithLabel.comp";

import { spacing } from "@material-ui/system";
import {useAuth} from "../../../../../context/auth";
import XLSX from "xlsx";
import {
    addItemToState,
    alphaNumericSort, isDQWEnabled,
    predefinedSort,
    removeItemFromState,
    splitList
} from "../../../../../utils/utils";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import getDummyEfficacyData from "../../data/v2/efficacy_dq_scores_data";
import {getAssessmentEfficacy} from "../../../../../services/school.service";
import MonthRangePicker from "../../../../../components/DatePickers/MonthRangePicker.comp";
import {X} from "react-feather";
import {CustomSelect} from "../../components/Select.comp";
import {DQW_DQC_MODULES} from "../../../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-right: ${(props) => props.theme.spacing(12)}px;

  &:first-child {
    padding-top: ${(props) => props.theme.spacing(12)}px;
  }
  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(12)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function setSingleKeyTrueInState(keys, true_key, setState) {
    setState({
        ...Object.fromEntries(keys.map((key) => [key, false])),
        [true_key]: true
    });
}

let dq_modules = {};

function EfficacyTab({title, skill, description, header1, header2}) {

    const {authUser} = useAuth();
    const { org_code, institute_code, org_obj } = authUser;
    const dqwEnabled = isDQWEnabled(org_obj);
    const subListDivision = 3;

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    const schoolHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_school")
    const [schools, setSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(undefined);

    const [defaultSchools, setDefaultSchools] = useState([]);
    const [additionalSchools, setAdditionalSchools] = useState([]);

    const classHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_class")
    const [classes, setClasses] = useState([]);
    const [selectedClasses, setSelectedClasses] = useState([]);

    const timeHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time")
    const [timePeriods, setTimePeriods] = useState([]);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState([undefined, undefined]);

    const [schoolState, setSchoolState] = useState(undefined);
    const [classState, setClassState] = useState(undefined);
    const [timeState, setTimeState] = useState(undefined);

    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const selectAllYears = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_year");

    useEffect(() => {
        if(!selectorsData && !firstLoad)
        {
            setSelectedSchool(undefined);
            setSelectedClasses([]);
            setSelectedTimePeriod([undefined, undefined]);
            setSchools([]);
            setClasses([]);
            setDefaultSchools([]);
            setAdditionalSchools([]);
            setTimePeriods([]);
            setMinDate(undefined);
            setMaxDate(undefined);
        }
    }, [selectorsData])

    useEffect(() => {
        if(firstLoad) {
            initializeDQModules();
            loadSelectors().then(d => console.log('Assessment Efficacy Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const initializeDQModules = () => {
        dq_modules = DQW_DQC_MODULES(pageLc, dqwEnabled);
    }

    const loadSelectors = async () => {
        setLoading(true);
        try {
            const result = await getAssessmentEfficacy(org_code, skill, institute_code)
            if(result.data)
            {
                console.log(result.data);
                const {selectors, data} = result.data;
                const {selected_school} = data;
                const {schools: _schools} = selectors;
                setSelectorsData(selectors);
                setPageData(data.data);
                initializeSchools(_schools);
                selectSchool(selected_school, selectors, false);
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching assessment efficacy selectors")
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadPageData = async (_school = selectedSchool, _class = selectedClasses, _time = selectedTimePeriod) => {
        setLoading(true);
        try {
            setPageData(undefined);
            const result = await getAssessmentEfficacy(org_code, skill, _school, _class, _time)
            console.log(result.data);
            if (result.data) {
                const {data} = result.data;
                setPageData(data.data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching assessment efficacy data")
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function initializeSchools(_schools)
    {
        setSchools(_schools);
        const subLists = splitList(_schools, subListDivision)
        setDefaultSchools(subLists[0]);
        setAdditionalSchools(subLists[1]);
    }

    function selectSchool(school, selectors = selectorsData, fetch = false)
    {
        setSelectedSchool(school);
        const {classes: _all_classes, years: _years} = selectors;
        if(_all_classes && _years && _all_classes[school] && _years[school])
        {
            //Initialize Regions
            const _classes = _all_classes[school]
            setClasses(_classes);
            selectClass([_classes[0]], false);
            setSingleKeyTrueInState(_classes, _classes[0], setClassState) //ensuring checkbox consistency

            //Initialize TimePeriod
            const _timePeriods = _years[school].map((year) => year.toString());
            setTimePeriods(_timePeriods);
            setSingleKeyTrueInState([selectAllYears, ...timePeriods], selectAllYears, setTimeState) //ensuring checkbox consistency
            if(_timePeriods.length > 0)
            {
                const date_range = getDateRangeOfYears(_timePeriods);
                const [start_date_iso, end_date_iso] = date_range;

                setMinDate(new Date(start_date_iso));
                setMaxDate(new Date(end_date_iso));
                selectTimePeriod(date_range, false);
                //Whether to load page data
                if(fetch)
                {
                    loadPageData(school, _classes[0], date_range).then(d => console.log(`Assessment Efficacy ${title} Data Fetched`, d));
                }
            }
        }
    }

    function selectClass(_classes, fetch = false) {
        setSelectedClasses(_classes);
        if(fetch)
        {
            loadPageData(selectedSchool, _classes).then(d => console.log(`Assessment Efficacy ${title} Data Fetched - By Selection of Region`, d));
        }
    }

    function selectTimePeriod(_timePeriods, fetch = false)
    {
        setSelectedTimePeriod(_timePeriods);
        if(fetch)
        {
            loadPageData(selectedSchool, selectedClasses, _timePeriods).then(d => console.log(`Assessment Efficacy ${title} Data Fetched - By Selection of Time Period`, d));
        }
    }

    function onAddSchool(event, state, setState) {
        const item = event.target.value
        removeItemFromState(item, additionalSchools, setAdditionalSchools);
        addItemToState(item, defaultSchools, setDefaultSchools);
        onSelectSchool(item, true, state, setState);
    }

    function onRemoveSchool(item, state, setState) {
        const newDefaultSchools = removeItemFromState(item, defaultSchools, setDefaultSchools)
        addItemToState(item, additionalSchools, setAdditionalSchools, true);
        if(selectedSchool === item)
        {
            const newSelectedItem = newDefaultSchools[0];
            onSelectSchool(newSelectedItem, true, state, setState);
        }
    }

    function onSelectSchool (selected_item, is_checked, state, setState) {
        if(is_checked) {
            selectSchool(selected_item, selectorsData, true);
            setSingleKeyTrueInState(defaultSchools, selected_item, setState);
        }
        else
        {
            setState({
                ...state,
                [selected_item]: true
            });
        }
    }

    function onSelectClass (selected_item, is_checked, state, setState) {
        if(is_checked) {
            const newClasses = [...selectedClasses, selected_item];
            selectClass(newClasses, true);
            // setSingleKeyTrueInState(classes, selected_item, setState);
        } else {
            const removal_index = selectedClasses.indexOf(selected_item);
            if(removal_index !== -1) //index found
            {
                const newClasses = [...selectedClasses];
                newClasses.splice(removal_index, 1);
                selectClass(newClasses, true)
            }
        }
        // else
        // {
        //     setState({
        //         ...state,
        //         [selected_item]: true
        //     });
        // }
    }

    function onSelectYear (item, isChecked, state, setState) {
        if(isChecked)
        {
            if(item === selectAllYears)
            {
                const date_range = getDateRangeOfYears(timePeriods);
                selectTimePeriod(date_range, true)
            }
            else
            {
                const date_range = getDateRangeOfYears([item]);
                selectTimePeriod(date_range, true)
            }
            setSingleKeyTrueInState([selectAllYears, ...timePeriods], item, setState);
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onClickDatePicker(event, state, setState) {
        setState(Object.fromEntries([selectAllYears, ...timePeriods].map(
            (time) => [time, false]
        )));
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();
        // console.log(start_date, end_date);
        selectTimePeriod([start_date, end_date], true)
    }

    const getDateRangeOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length - 1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        return [startDate, endDate]
    }

    const getAlias = (code) => {
        const alias = dq_modules[code] ? dq_modules[code].title : undefined;
        if(alias) return alias;
        return code;
    }

    // const header = {
    //     region: selectedSchool? selectedSchool : "No School" ,
    //     category: selectedClass? selectedClass : "No Class",
    // }

    return (
        <Paper container>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[defaultSchools, classes, [selectAllYears, ...timePeriods]]}
                        headers = {[schoolHeader, classHeader, timeHeader]}
                        onCheckCallbacks = {[onSelectSchool, onSelectClass, onSelectYear]}
                        actions = {[X, undefined, undefined]}
                        actionsData = {[{style: {cursor: "pointer"}}, undefined, undefined]}
                        actionsCallbacks = {[onRemoveSchool, undefined, undefined]}
                        footers = {[CustomSelect, undefined, MonthRangePicker]}
                        footersData = {[ {title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_school"), data: additionalSchools}, undefined, {minDate, maxDate}]}
                        footersCallbacks = {[{onChange: onAddSchool}, undefined, {onChangeDate: onSelectDateRange}]}
                        defaultCheckIndices = {[]}
                        stateCallbacks = {[
                            {
                                state: schoolState,
                                setState: setSchoolState
                            },
                            {
                                state: classState,
                                setState: setClassState
                            },
                            {
                                state: timeState,
                                setState: setTimeState
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                    {
                        pageData ?
                            <Card mb={3}>
                                <CardContent>
                                    <ColouredTypography variant={"body1"} size={"16px"} color={"dimgrey"} align={"center"} alignText={"justify"}>
                                        {description}
                                    </ColouredTypography>
                                    {
                                        pageData.map(({header, values}) => (
                                            <>
                                                <ColouredTypography mt={10} mb={10} variant="h4">
                                                    {`${header}`}
                                                </ColouredTypography>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                                    {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__mods")}
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                                    {GET_APP_LOCALIZE_TEXT(pageLc, "assessment__participants")}
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                            {
                                                                header1 &&
                                                                <CustomTableCell align="center">
                                                                    <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                                        {header1}
                                                                    </DefaultColouredTypography>
                                                                </CustomTableCell>
                                                            }
                                                            {
                                                                header2 &&
                                                                <CustomTableCell align="center">
                                                                    <DefaultColouredTypography variant={"h6"} color={"white"}>
                                                                        {header2}
                                                                    </DefaultColouredTypography>
                                                                </CustomTableCell>
                                                            }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            values
                                                            .sort(alphaNumericSort("topic", true))
                                                            .sort(predefinedSort([...Object.keys(dq_modules)], "topic", false))
                                                            .sort(predefinedSort(["Total"], "topic", true))
                                                            .filter((item) => dqwEnabled || !item.topic.toLowerCase().includes("dqw"))
                                                            .map((item, index) => (
                                                                <CustomTableRow key={index}>
                                                                    {
                                                                        item.topic === "Total" ?
                                                                            <>
                                                                                <CustomTableCell align="center">
                                                                                    <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "general__total")}</Bold>
                                                                                </CustomTableCell>
                                                                                <CustomTableCell align="center">
                                                                                    <Bold>{item.participants}</Bold>
                                                                                </CustomTableCell>
                                                                                {
                                                                                    header1 &&
                                                                                    <CustomTableCell align="center">
                                                                                        <Bold>{item.skills}</Bold>
                                                                                    </CustomTableCell>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <CustomTableCell align="center">{getAlias(item.topic)}</CustomTableCell>
                                                                                <CustomTableCell align="center">{item.participants}</CustomTableCell>
                                                                                {
                                                                                    header1 && <CustomTableCell align="center">{item.skills}</CustomTableCell>
                                                                                }
                                                                            </>

                                                                    }
                                                                    <CustomTableCell align="center">
                                                                        {
                                                                            skill === "Score" ?
                                                                                <LinearProgressWithLabel value={Math.round(item.skills/150*100)} label={item.skills.toFixed(2)}/>
                                                                            :
                                                                                <LinearProgressWithLabel value={Math.round(item.skills/item.participants*100)} symbol={'%'}/>
                                                                        }
                                                                    </CustomTableCell>
                                                                </CustomTableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </>
                                        ))
                                    }
                                </CardContent>
                            </Card>
                        :
                            <DefaultColouredTypography variant={"h3"} align={'center'}>{errorMessage}</DefaultColouredTypography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading} />
        </Paper>
    );
}

export default EfficacyTab;