import React from "react";
import {useLocation} from "react-router-dom";
import styled, { withTheme } from "styled-components/macro";

import orange from "@material-ui/core/colors/orange";
import { CardContent, Card as MuiCard, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { HorizontalBar } from "react-chartjs-2";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  //padding-top: 20px;
  //padding-bottom: 20px;
  height: 300px;
  width: 100%;
`;

function BarChart({theme, labels, values, threshold = undefined, color = false, suggestedMax = undefined, header = ""}) {

  const location = useLocation();
  const pageLc = location.pathname.split('/')[1];

  const colors = []

  values.forEach((value) => {
    if(color && value < ( threshold ? threshold : (Math.max(...values)/2) ))
      colors.push("#ff69b4")
    else
      colors.push("#25b7db")
  })

  const data = {
    labels: labels,
  // #FF1B0A
    datasets: [
      {
        label: '',
        backgroundColor: colors,
        borderColor: colors,
        hoverBackgroundColor: colors,
        hoverBorderColor: colors,
        data: values,
        barPercentage: 1,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    animation: {
      onComplete: function () {
        const chartInstance = this.chart;
        const ctx = chartInstance.ctx;
        ctx.font = 'bold 12px Arial';
        ctx.textAlign = 'left';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = 'grey';

        this.data.datasets.forEach(function (dataset, i) {
          const meta = chartInstance.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            const data = dataset.data[index];
            const xPosition = Math.min(bar._model.x + 7, chartInstance.chartArea.right - 20);
            ctx.fillText(`${data}%`, xPosition, bar._model.y);
          });
        });
      },
    },
    hover: {
      animationDuration: 0,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            color: "transparent",
          },
          // display: false,
          stacked: false,
          barThickness: "25",
          ticks: {
            display: false,
          }
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            display: true,
          },
          ticks: {
            // stepSize: 10,
            beginAtZero: true,
            suggestedMax: suggestedMax,
          },
        },
      ],
    },
  };

  return (
      <>
        <ChartWrapper>
          <Typography variant="h4" gutterBottom align={'center'}>
            {header}
          </Typography>
          <HorizontalBar data={data} options={options}/>
        </ChartWrapper>
      </>


  );
}

export default withTheme(BarChart);
