import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { ReactComponent as Logo } from "../../../assets/images/dq-icon-200x200.svg";
import { useAuth } from "../../../context/auth"
import {getImpactRatingData} from "../../../services/partner.service";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
    Icon,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {Badge} from "./Badge.comp";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const Item = styled(Paper)(({ theme }) => ({
    alignItems: "center",
    backgroundColor: theme.sidebar.background,
    ...theme.typography.body2,
    padding: theme.spacing(1),
    borderRadius: 20,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    width: '75%',
}));

const CustomCard = styled(MuiCard)(({ theme }) => ({
    backgroundColor: theme.sidebar.background,
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    border: "1px solid grey",
    borderRadius: "20px",
    aspectRatio: "0.8 / 1",
    maxHeight: "400px",
}));

const CustomCardContent = styled(MuiCardContent)(({ theme }) => ({
    position: "relative",
    color: theme.palette.text.secondary,
}));


const BrandIcon = styled(Logo)`
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 75px;
  height: 75px;
`;

const Card = styled(MuiCard)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const OrangeTypography = styled(Typography)`
  color: darkorange;
`

const OrgDetails = styled(Box)`
  color: grey;
  padding-top: ${(props) => props.theme.spacing(4)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;
  font-size: 1rem;
`

const ScoresCard = styled(Card)`
  
`

const RatingPaper = styled(Paper)`
  padding: 20px 10px 20px 10px;
  border-width: 2px;
  min-width: min-content;
`


const WhiteTypography = styled(Typography)`
  color: whitesmoke;
`
const PurpleTypography = styled(Typography)`
  color: purple;
`

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-family: "Lemon/Milk";
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

const StarComponent = (props) => {
    const {rating, total, range, ...rest} = props;
    let whole, decimal = 0;
    if(range === 3)
    {
        whole = rating;
        decimal = 0;
    } else if (range === 5)
    {
        whole = Math.floor(rating);
        decimal = rating % 1;
    }
    // else {
    //     if (rating < 2) whole = 0;
    //     else if (rating < 3) whole = 1;
    //     else if (rating < 4) whole = 2;
    //     else whole = 3;
    // }

    let remaining = total - whole - (decimal >= 0.5 ? 1 : 0);
    let star_tags = [];
    //whole part
    //, clipPath: "polygon(50% 10%, 40% 35%, 10% 40%, 32.5% 57.5%, 25% 87.5%, 50% 70%, 75% 87.5%, 67.5% 57.5%, 90% 40%, 60% 35%)"
    for(let i = 0; i < whole; i++)
        star_tags.push(
            <div
                style={{backgroundColor: "#ffc400", display: "inline-flex", width: "50px", height: "50px", clipPath: "polygon(50% 10%, 40% 35%, 10% 40%, 32.5% 57.5%, 25% 87.5%, 50% 70%, 75% 87.5%, 67.5% 57.5%, 90% 40%, 60% 35%)"}}
                rest
            />
        )
    //decimal part
    if(decimal >= 0.5)
    {
        star_tags.push(
            <div
                style={{backgroundColor: "grey", display: "inline-flex", width: "50px", height: "50px", clipPath: "polygon(50% 10%, 40% 35%, 10% 40%, 32.5% 57.5%, 25% 87.5%, 50% 70%, 75% 87.5%, 67.5% 57.5%, 90% 40%, 60% 35%)"}}
                rest
            >
                <div
                    style={{backgroundColor: "#ffc400", display: "inline-flex", width: "50px", height: "50px", clipPath: "polygon(50% 10%, 40% 35%, 10% 40%, 32.5% 57.5%, 25% 87.5%, 50% 70%)"}}
                />
            </div>
        )
        remaining -= 1;
    }
    if(whole !== 0)
    for(let i = 0; i < remaining; i++)
        star_tags.push(
            <div
                style={{backgroundColor: "grey", display: "inline-flex", width: "50px", height: "50px", clipPath: "polygon(50% 10%, 40% 35%, 10% 40%, 32.5% 57.5%, 25% 87.5%, 50% 70%, 75% 87.5%, 67.5% 57.5%, 90% 40%, 60% 35%)"}}
                rest
            />
        )
    return (
        <>
            {star_tags}
        </>
    )
}

function DqImpactRatingTab() {

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect( () => {
        loadPageData().then(d => console.log('Impact Rating Data Fetched!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getImpactRatingData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Impact Rating --> ', result.data);
                setPageData({total: 3,...result.data});
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching impact rating data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    // const badge_data = [
    //     {
    //         title: GET_APP_LOCALIZE_TEXT(pageLc, "overview__content_quality"),
    //         value: pageData ? pageData.content_quality : "0",
    //     },
    //     {
    //         title: GET_APP_LOCALIZE_TEXT(pageLc, "overview__program_reach"),
    //         value: pageData ? pageData.program_adoption : "0",
    //     },
    //     {
    //         title: GET_APP_LOCALIZE_TEXT(pageLc, "overview__comp_improve"),
    //         value: pageData ? pageData.skill_improvement : "0",
    //     },
    // ]

    return (
        pageData ?
        <Card p={10}>
            <CardContent>
                <Box>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={12} md={6} align={"center"}>
                            <CustomCard>
                                <CustomCardContent>
                                    <BrandIcon/>
                                    <WhiteTypography
                                        variant="h4" align={"center"}>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__impact_rating")}
                                    </WhiteTypography>
                                    <WhiteTypography mb={5} align={"center"}>
                                        {/*<Italicize >{pageData.area}</Italicize>*/}
                                    </WhiteTypography>
                                    <WhiteTypography variant="h5">
                                        <Bold>{pageData.license}</Bold>
                                    </WhiteTypography>
                                    <WhiteTypography
                                        mb={5} align={"center"} wrapped="true">
                                        {pageData.org_name !== "" ? pageData.org_name : pageData.org}
                                    </WhiteTypography>
                                    {/*<WhiteTypography*/}
                                    {/*    mb={5} align={"center"}>*/}
                                    {/*    {pageData.country}*/}
                                    {/*</WhiteTypography>*/}
                                    <StarComponent
                                        rating={
                                            // authUser.org_code === 'AMF' ? 1 :
                                            pageData.external_rating !== undefined && pageData.external_rating !== null
                                                ? Number(pageData.external_rating)
                                                : Number(pageData.rating)
                                        }
                                        total={
                                            authUser.org_code === 'AMF' ? 5 : pageData.total
                                        }
                                        range={
                                            // authUser.org_code === 'AMF' ? 5 :
                                            pageData.external_rating !== undefined && pageData.external_rating !== null
                                                ? 3
                                                : 5
                                        }
                                    />
                                    <WhiteTypography
                                        mb={4} variant="h2" align={"center"} wrapped = "true">
                                        <Bold>{
                                            // authUser.org_code === 'AMF' ? 2.71 :
                                            pageData.external_rating !== undefined && pageData.external_rating !== null
                                            ? pageData.internal_rating.toFixed(2)
                                                : pageData.rating.toFixed(2)
                                        }
                                        </Bold>
                                    </WhiteTypography>
                                </CustomCardContent>
                            </CustomCard>
                            <Typography pt={2} variant={'h6'}>
                                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__out_of")} 5.00
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <OrgDetails>
                                <ul>
                                    <li>
                                        <span><span style={{fontWeight: "bold"}}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__program")}: </span>{pageData.license}</span>
                                    </li>
                                    <li>
                                        <span><span style={{fontWeight: "bold"}}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__area")}: </span>
                                            {pageData.objectives ? (
                                                <ul>
                                                    {pageData.objectives.map((objective, index) => (
                                                        <li key={index}>{objective}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                pageData.area
                                                )}
                                        </span>
                                    </li>
                                    <li>
                                        <span><span style={{fontWeight: "bold"}}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__organisation")}: </span>{pageData.org_name !== "" ? `${pageData.org_name}, ${pageData.country}` : pageData.org}</span>
                                    </li>
                                    <li>
                                    <span>
                                        <span style={{fontWeight: "bold"}}>{GET_APP_LOCALIZE_TEXT(pageLc, "overview__target_pop")}: </span>
                                        <ul>
                                            { pageData.target_group &&
                                            <li>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__group")}: {pageData.target_group}
                                            </li>
                                            }
                                            { pageData.target_age &&
                                            <li>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__target_audiences")}: {pageData.target_audiences}
                                            </li>
                                            }
                                            { pageData.target_countries &&
                                            <li>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "overview__countries")}: {pageData.target_countries}
                                            </li>
                                            }
                                        </ul>
                                    </span>
                                    </li>
                                </ul>
                            </OrgDetails>
                            {/*<Grid container spacing={3} align={"center"} alignItems={"center"}>*/}
                            {/*    {badge_data.map((badge) => (*/}
                            {/*            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>*/}
                            {/*                <Badge*/}
                            {/*                    title={badge.title}*/}
                            {/*                    value={badge.value.toFixed(2)}*/}
                            {/*                />*/}
                            {/*                <Typography pt={2}>*/}
                            {/*                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__out_of")} 5.00*/}
                            {/*                </Typography>*/}
                            {/*            </Grid>*/}
                            {/*        )*/}
                            {/*    )}*/}
                            {/*</Grid>*/}
                        </Grid>
                    </Grid>
                    <LoaderWithBackDrop loading={loading} />
                </Box>
            </CardContent>
        </Card>
        :
        <Paper container>
            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default DqImpactRatingTab;