import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import { Helmet } from "react-helmet";

import { Tab } from "../../../../../components"

import {useAuth} from "../../../../../context/auth";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";


import ByRegionTab from "../../tabs/v2/ByRegion.tab";
import ByMonthlyTab from "../../tabs/v2/ByMonthly.tab";
import ByModuleTab from "../../tabs/v2/ByModule.tab";
import ByGenderTab from "../../tabs/v2/ByGender.tab";
import ByAgeTab from "../../tabs/v2/ByAge.tab";
import ByADIITab from "../../tabs/v2/ByADII.tab";
import ByIndigenousTab from "../../tabs/v2/ByIndigenous.tab";
import {Box, Paper, Typography as MuiTypography} from "@material-ui/core";
import styled from "styled-components/macro";
import {spacing} from "@material-ui/system";
import {COUNTRY_CONFIGS} from "../../../../../utils/constants";



const Typography = styled(MuiTypography)(spacing);

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.palette.tertiary.main};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

function Adoption() {

    const {authUser} = useAuth();
    const { org_country } = authUser.org_obj;
    const countryConfigKey = COUNTRY_CONFIGS[org_country] ? org_country : "default";
    const {socio_economic_class, indigenous} = COUNTRY_CONFIGS[countryConfigKey];

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const TabsComponents = [
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__by_country"),
            tag: <ByRegionTab/>,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__by_period"),
            tag: <ByMonthlyTab/>,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__by_module"),
            tag: <ByModuleTab/>,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__by_gender"),
            tag: <ByGenderTab/>,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "assessment__by_age"),
            tag: <ByAgeTab/>,
        },
        {
            title: socio_economic_class ? GET_APP_LOCALIZE_TEXT(pageLc, `assessment__by_${socio_economic_class.toLowerCase()}`) : undefined,
            tag: <ByADIITab/>,
        },
        {
            title: indigenous ? GET_APP_LOCALIZE_TEXT(pageLc, "assessment__by_indigenous") : undefined,
            tag: <ByIndigenousTab/>,
        },
    ]

    return (
        <React.Fragment>
            <Helmet title={`${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__enhancement")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__reach")}`}/>
            <Box mx={"3vw"}>
                <Box mb={5}>
                    <DefaultColouredTypography variant="h2">
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__section_3")}: {GET_APP_LOCALIZE_TEXT(pageLc, "navigation__enhancement")}
                    </DefaultColouredTypography>
                    <ColouredTypography variant={"body1"} size={"16px"}>
                        {GET_APP_LOCALIZE_TEXT(pageLc, "overview__enhancement_desc")}
                    </ColouredTypography>
                </Box>
                <Box mb={7}>
                    <ColouredTypography variant="h6" color={"#25b7db"} size={"16px"} weight={"bold"}>
                        {`3.2 ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__reach")} ${GET_APP_LOCALIZE_TEXT(pageLc, "navigation__enhancement")}: `}
                        <ColouredTypography variant="body1" fontWeight="fontWeightRegular" component={"span"} size={"16px"}>
                            {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__reach_desc")}
                        </ColouredTypography>
                    </ColouredTypography>
                </Box>
                <Tab
                    titles={TabsComponents.filter((component) => component.title).map((component) => component.title)}
                >
                    {TabsComponents.filter((component) => component.title).map((component) => component.tag)}
                </Tab>
            </Box>
        </React.Fragment>
    );
}

export default Adoption;