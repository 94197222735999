import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import BarChart from "../../components/BarChart";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import {X} from "react-feather";
import {CustomSelect} from "../../components/Select.comp";
import XLSX from "xlsx";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {useAuth} from "../../../../../context/auth";
import {
    alphaNumericSort,
    createLcKey,
    getUniqueValues,
    predefinedSort,
    splitCountries,
    toastDanger
} from "../../../../../utils/utils";
import {COUNTRY_CONFIGS} from "../../../../../utils/constants";
import MonthRangePicker from "../../../../../components/DatePickers/MonthRangePicker.comp";
import {postProcessSelectors} from "../../utils/post_process_selectors";
import {getEnhancementAdoptionIndigenousv2} from "../../../../../services/enhancement.service";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function splitList(list, min_count)
{
    const sublist = []
    const length = list.length;

    sublist.push(list.slice(0, Math.min(min_count, list.length)));
    sublist.push(list.slice(Math.min(min_count, list.length), length));
    return sublist;
}

const cat_reverse_translations = {}

function ByIndigenousTab() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const { org_country } = authUser.org_obj;
    const countryConfigKey = COUNTRY_CONFIGS[org_country] ? org_country : "default";
    const countryConfigKeyForIncludedCountries = COUNTRY_CONFIGS[org_country] && COUNTRY_CONFIGS[org_country].included_countries ? org_country : "default";
    const countriesFromConfig = [org_country, ...COUNTRY_CONFIGS[countryConfigKeyForIncludedCountries].included_countries]
    const {administrative_sector} = COUNTRY_CONFIGS[countryConfigKey];

    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [postalCodes, setPostalCodes] = useState(undefined);
    const [instituteCodes, setInstituteCodes] = useState(undefined);
    const [modules, setModules] = useState(undefined);
    const [additionalCountries, setAdditionalCountries] = useState([]);

    const countryHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const [selectedCountry, setSelectedCountry] = useState({country: undefined});

    const regionHeader = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__select_${administrative_sector.toLowerCase()}`);
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({region: undefined});

    const postalHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_postal")
    const [defaultPostal, setDefaultPostal] = useState([]);
    const [additionalPostal, setAdditionalPostal] = useState([]);
    const [selectedPostal, setSelectedPostal] = useState({postal: undefined});

    const areaHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_inst")
    const [defaultAreas, setDefaultAreas] = useState([]);
    const [additionalAreas, setAdditionalAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState({area: undefined});

    const moduleHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_mod")
    const [defaultModules, setDefaultModules] = useState([]);
    const [additionalModules, setAdditionalModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState({module: undefined});

    const timeHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time")
    const [timePeriods, setTimePeriods] = useState([]);
    const [timePeriodsReset, setTimePeriodsReset] = useState(false);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState({time: undefined});
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);
    const [resetKey, setResetKey] = useState(false);

    const [graphData, setGraphData] = useState([]);
    const [defaultCountriesExists, setDefaultCountriesExists] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [header, setHeader] = useState("");
    const subListDivision = 3;

    const selectAllRegionsOption = "All"
    const selectAllRegionsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_${administrative_sector.toLowerCase()}`)

    const selectAllPostalCodesOption = "All"
    const selectAllPostalCodesAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_postal`)

    const selectAllAreasOption = "All"
    const selectAllAreasAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_inst`)

    const selectAllModulesOption = "All"
    const selectAllModulesAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_mod`)

    const selectAllYears = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_year`);

    const get_category_alias = (category) => {
        const cat_trans = GET_APP_LOCALIZE_TEXT(pageLc, `baselining__${createLcKey(category)}`, null);
        const country_trans = GET_APP_LOCALIZE_TEXT(pageLc, `dq_country__${createLcKey(category)}`, null);
        const translation = cat_trans || country_trans || category;
        cat_reverse_translations[translation] = category;
        return translation;
    }

    const get_category_reverse_alias = (alias) => {
        const reverse_alias = cat_reverse_translations[alias];
        return reverse_alias || alias;
    }

    const getRegionAlias = (code) => {
        return code === selectAllRegionsOption ? selectAllRegionsAliasedOption : code;
    }

    const getRegionReverseAlias = (alias) => {
        return alias === selectAllRegionsAliasedOption ? selectAllRegionsOption : alias;
    }

    const getPostalAlias = (code) => {
        return code === selectAllPostalCodesOption ? selectAllPostalCodesAliasedOption : code;
    }

    const getPostalReverseAlias = (alias) => {
        return alias === selectAllPostalCodesAliasedOption ? selectAllPostalCodesOption : alias;
    }

    const getAreaAlias = (code) => {
        return code === selectAllAreasOption ? selectAllAreasAliasedOption : code;
    }

    const getAreaReverseAlias = (alias) => {
        return alias === selectAllAreasAliasedOption ? selectAllAreasOption : alias;
    }

    const getModuleAlias = (code) => {
        return code === selectAllModulesOption ? selectAllModulesAliasedOption : code;
    }

    const getModuleReverseAlias = (alias) => {
        return alias === selectAllModulesAliasedOption ? selectAllModulesOption : alias;
    }

    const selectAllMonthsOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length-1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        setSelectedTimePeriod({time: [startDate, endDate]});
    }

    useEffect(() => {
        if(selectorsData)
        {
            const {selectors, current_selection, data} = selectorsData;
            const {countries: _countries} = selectors;
            if(_countries && Array.isArray(_countries))
            {
                let filtered_countries = _countries.filter(ct => countriesFromConfig.includes(ct));
                // if (filtered_countries.length === 0) filtered_countries = [..._countries];
                setDefaultCountriesExists(filtered_countries && filtered_countries.length > 0);
                const countriesSubList = splitCountries(_countries, filtered_countries && filtered_countries.length > 0 ? filtered_countries : [org_country]);
                setCountries(countriesSubList[0]);
                setAdditionalCountries(countriesSubList[1]);
                setSelectedCountry({country: countriesSubList[0][0]});
            }
            setPageData({current_selection, data});
        }
    }, [selectorsData])

    useEffect(() => {
        if(countries.length > 0 && !selectedCountry)
        {
            setSelectedCountry({country: countries[0]});
        }
    }, [countries])

    useEffect(() => {
        if(selectedCountry.country)
        {
            const country = selectedCountry.country;
            if(selectorsData)
            {
                const {selectors} = selectorsData;
                const {states, postal_codes, institute_codes, modules, years} = postProcessSelectors(selectors, country);

                setTimePeriods(years.map((year) => year.toString()))
                setModules(modules)
                setInstituteCodes(institute_codes)
                setPostalCodes(postal_codes)
                setRegions(states)
            }
        }
    }, [selectedCountry])

    useEffect(() => {
        if(regions.length > 0)
        {
            regions.sort().sort(predefinedSort([selectAllRegionsOption]))
            const regionSubLists = splitList(regions, subListDivision)
            setDefaultRegions(regionSubLists[0]);
            setAdditionalRegions(regionSubLists[1])
            setSelectedRegion({region: regionSubLists[0][0]});
        }
    }, [regions])

    useEffect(() => {
        if(timePeriods.length > 0)
        {
            const [firstYear, lastYear] = [timePeriods[0], timePeriods[timePeriods.length - 1]]
            setMinDate(new Date(`${firstYear}-01-01`))
            setMaxDate(new Date(`${lastYear}-12-31`))
            selectAllMonthsOfYears(timePeriods);
            setTimePeriodsReset(true);
        }
    }, [timePeriods])

    useEffect(() => {
        if(selectedRegion.region && postalCodes)
        {
            const postals = postalCodes[selectedRegion.region];
            postals.sort((a, b) => a - b).sort(predefinedSort(["All"]));
            const postalSubLists = splitList(postals, subListDivision)
            setDefaultPostal(postalSubLists[0]);
            setAdditionalPostal(postalSubLists[1]);
            setSelectedPostal({postal: postalSubLists[0][0]});
        }
        else
        {
            setDefaultPostal([])
            setAdditionalPostal([])
            setDefaultAreas([])
            setAdditionalAreas([])
            setDefaultModules([])
            setGraphData([])
            setHeader("");
        }

    }, [selectedRegion])

    useEffect(() => {
        if(selectedPostal.postal && instituteCodes)
        {
            const _codes = instituteCodes[selectedRegion.region][selectedPostal.postal]
            _codes.sort(alphaNumericSort(undefined, true)).sort(predefinedSort(["All"]));
            const areaSubLists = splitList(_codes, subListDivision)
            setDefaultAreas(areaSubLists[0]);
            setAdditionalAreas(areaSubLists[1]);
            setSelectedArea({area: areaSubLists[0][0]});
        }
        else
        {
            setDefaultAreas([])
            setAdditionalAreas([])
            setDefaultModules([])
            setGraphData([]);
            setHeader("")
        }
    }, [selectedPostal])

    useEffect(() => {
        if(selectedArea.area && modules)
        {
            const _modules = modules[selectedRegion.region][selectedPostal.postal][selectedArea.area]
            _modules.sort(alphaNumericSort(undefined, true)).sort(predefinedSort(["All"]))
            setDefaultModules(_modules);
            setSelectedModule({module: _modules[0]});
        }
        else
        {
            setDefaultModules([])
            setGraphData([]);
            setHeader("")
        }
    }, [selectedArea])

    useEffect(() => {
        if(selectedModule.module && selectedTimePeriod.time && pageData)
        {
            loadPageData(selectedCountry.country, selectedRegion.region, selectedPostal.postal, selectedArea.area, selectedModule.module, selectedTimePeriod.time).catch(console.error);
            setHeader(`${getRegionAlias(selectedRegion.region)} - ${getPostalAlias(selectedPostal.postal)} - ${getAreaAlias(selectedArea.area)} - ${getModuleAlias(selectedModule.module)}`);
        }
        else
        {
            setGraphData([]);
            setHeader("")
        }
    }, [selectedModule, selectedTimePeriod])

    useEffect( () => {
        loadSelectorData().then(d => console.log('Enhancement Adoption Indigenous Selectors Fetched', d)).catch(console.error);
    }, []);

    const loadSelectorData = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementAdoptionIndigenousv2(authUser.org_code)
            if (result.data) {
                console.log("selector data fetched")
                setSelectorsData(result.data);
            }
            else {
                setPageData(undefined);
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption indigenous selectors")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const loadPageData = async (country, region, postal_code, institute_code, module, date_range) => {
        const order = ["Yes", "No", "Unknown"]
        if(pageData) {
            const {current_selection, data} = pageData;
            const {selected_country, selected_state, selected_postal_code, selected_institute_code, selected_module, selected_date_range} = current_selection;
            if(
                selected_country === country &&
                selected_state === region &&
                selected_postal_code === postal_code &&
                selected_institute_code === institute_code &&
                selected_module === module &&
                areDateRangesEqual(selected_date_range, date_range)
            )
            {
                console.log("fetching did not occur")
                setGraphData(data.sort(predefinedSort(order, "title")).sort(predefinedSort(["Total"], "title", true)));
                return true;
            }
        }
        setLoading(true);
        try
        {
            const result = await getEnhancementAdoptionIndigenousv2(authUser.org_code, country, region, postal_code, institute_code, module, date_range)
            if (result.data) {
                console.log("fetching occured")
                const {data} = result.data;
                setPageData(result.data);
                setGraphData(data.sort(predefinedSort(order, "title")).sort(predefinedSort(["Total"], "title", true)));
            }
            else {
                setGraphData([]);
                setPageData(undefined);
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        }
        catch (e)
        {
            console.log("Some error occurred while fetching enhancement adoption indigenous data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function areDateRangesEqual(range1, range2)
    {
        if(!Array.isArray(range1) || !Array.isArray(range2)) return false;
        if(range1.length !== range2.length) return false;
        for(let index in range1)
        {
            if(range1[index] !== range2[index]) return false;
        }
        return true;
    }

    const addItemToState = (item, state, setState, sort = false) => {
        if(sort)
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item].sort());
            else
                setState([...state, item].sort());
        }
        else
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item]);
            else
                setState([...state, item]);
        }
    }

    const removeItemFromState = (item, state, setState) => {
        const temp = [...state];
        if(Array.isArray(item)) //in case item is an array, iterate
        {
            item.forEach((i) => {
                const index = temp.indexOf(i);
                if(index !== -1)
                    temp.splice(index, 1);
            })
        }
        else
        {
            const index = temp.indexOf(item);
            if(index !== -1)
                temp.splice(index, 1);
        }
        setState(temp);
        return temp;
    }

    function onSelectYear (item, isChecked, state, setState) {
        if(isChecked)
        {
            if(item === selectAllYears)
            {
                selectAllMonthsOfYears(timePeriods);
            }
            else
            {
                selectAllMonthsOfYears([item]);
            }
            setState(Object.fromEntries([selectAllYears, ...timePeriods].map(
                (year) => year === item ? [year, true] : [year, false]
            )));
            setResetKey(prevReset => !prevReset);
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onClickDatePicker(event, state, setState) {
        setState(Object.fromEntries([selectAllYears, ...timePeriods].map(
            (time) => [time, false]
        )));
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();

        setSelectedTimePeriod({time: [start_date, end_date]});
    }

    function onSelectCountry (item, isChecked, state, setState) {
        if(isChecked)
        {
            setSelectedCountry({country: item});
            setState(Object.fromEntries(countries.map(
                (country) => country === item ? [country, true] : [country, false]
            )));
            setResetKey(prevReset => !prevReset);
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onAddCountry(event, state, setState) {
        const aliasedItem = event.target.value;
        if(countries.length === 11)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        }
        else
        {
            const country = get_category_reverse_alias(aliasedItem);
            removeItemFromState(country, additionalCountries, setAdditionalCountries);
            addItemToState(country, countries, setCountries);

            setState({
                ...Object.fromEntries(countries.map((c) => [c, false])),
                [country]: true,
            });
            setSelectedCountry({country: country});
        }
    }

    function onRemoveCountry(aliasedItem, state, setState) {

        const country = get_category_reverse_alias(aliasedItem);
        const newCountries = removeItemFromState(country, countries, setCountries)
        addItemToState(country, additionalCountries, setAdditionalCountries, true)

        setState({
            ...Object.fromEntries(newCountries.map((c, index) => index === 0 ? [c, true] : [c, false])),
        });
        setSelectedCountry({country: newCountries[0]});
    }

    function onAddRegion(event, state, setState) {
        const aliasedItem = event.target.value
        const item = getRegionReverseAlias(aliasedItem);
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setState({
            ...Object.fromEntries(defaultRegions.map((r) => getRegionAlias(r)).map((region) => [region, false])),
            [aliasedItem]: true,
        });
        setSelectedRegion({region: item});
    }

    function onRemoveRegion(aliasedItem, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setState({
            ...Object.fromEntries(newDefaultRegions.map((r) => getRegionAlias(r)).map((region, index) => index === 0 ? [region, true] : [region, false])),
        });
        setSelectedRegion({region: newDefaultRegions[0]});
    }

    function onSelectRegion(aliasedItem, isChecked, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        if(isChecked) {
            setSelectedRegion({region: item})
            setState(Object.fromEntries(defaultRegions.map((r) => getRegionAlias(r)).map(
                (region) => region === aliasedItem ? [region, true] : [region, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [aliasedItem]: true
            });
        }
    }

    function onAddPostal(event, state, setState) {
        const aliasedItem = event.target.value;
        const item = getPostalReverseAlias(aliasedItem);
        removeItemFromState(item, additionalPostal, setAdditionalPostal);
        addItemToState(item, defaultPostal, setDefaultPostal);
        setState({
            ...Object.fromEntries(defaultPostal.map((p) => getPostalAlias(p)).map((postal) => [postal, false])),
            [aliasedItem]: true,
        });
        setSelectedPostal({postal: item});
    }

    function onRemovePostal(aliasedItem, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        const newDefaultPostal = removeItemFromState(item, defaultPostal, setDefaultPostal)
        addItemToState(item, additionalPostal, setAdditionalPostal, true)
        setState({
            ...Object.fromEntries(newDefaultPostal.map((p) => getPostalAlias(p)).map((postal, index) => index === 0 ? [postal, true] : [postal, false])),
        });
        setSelectedPostal({postal: newDefaultPostal[0]});
    }

    function onSelectPostal(aliasedItem, isChecked, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        if(isChecked) {
            setSelectedPostal({postal: item})
            setState(Object.fromEntries(defaultPostal.map((p) => getPostalAlias(p)).map(
                (postal) => postal === aliasedItem ? [postal, true] : [postal, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [aliasedItem]: true
            });
        }
    }

    function onAddArea(event, state, setState) {
        const aliasedItem = event.target.value;
        const item = getAreaReverseAlias(aliasedItem);
        removeItemFromState(item, additionalAreas, setAdditionalAreas);
        addItemToState(item, defaultAreas, setDefaultAreas);
        setState({
            ...Object.fromEntries(defaultAreas.map((a) => getAreaAlias(a)).map((area) => [area, false])),
            [aliasedItem]: true,
        });
        setSelectedArea({area: item});
    }

    function onRemoveArea(aliasedItem, state, setState) {
        const item = getAreaReverseAlias(aliasedItem);
        const newDefaultAreas = removeItemFromState(item, defaultAreas, setDefaultAreas)
        addItemToState(item, additionalAreas, setAdditionalAreas, true)
        setState({
            ...Object.fromEntries(newDefaultAreas.map((a) => getAreaAlias(a)).map((area, index) => index === 0 ? [area, true] : [area, false])),
        });
        setSelectedArea({area: newDefaultAreas[0]});
    }

    function onSelectArea(aliasedItem, isChecked, state, setState) {
        const item = getAreaReverseAlias(aliasedItem);
        if(isChecked) {
            setSelectedArea({area: item})
            setState(Object.fromEntries(defaultAreas.map((a) => getAreaAlias(a)).map(
                (area) => area === aliasedItem ? [area, true] : [area, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [aliasedItem]: true
            });
        }
    }

    function onSelectModule(aliasedItem, isChecked, state, setState) {
        const item = getModuleReverseAlias(aliasedItem);
        if(isChecked) {
            setSelectedModule({module: item})
            setState(Object.fromEntries(defaultModules.map((m) => getModuleAlias(m)).map(
                (module) => module === aliasedItem ? [module, true] : [module, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [aliasedItem]: true
            });
        }
    }

    let sums = {
        participants: 0,
    }
    if(graphData)
    {
        graphData.forEach((item) => {
            if(item.title === "Total")
                sums.participants = item.participants;
        })
    }

    const onClickDownload = (event) => {

        const workbook = XLSX.utils.book_new();
        let column_widths = []

        const final_array = [
            ["Selected Country:", selectedCountry.country],
            ["Selected Region:", selectedRegion.region],
            ["Selected Postal:", selectedPostal.postal],
            ["Selected Institute Code:", selectedArea.area],
            ["Selected Module:", selectedModule.module],
            ["Selected Time:", new Date(selectedTimePeriod.time[0]).toDateString(), new Date(selectedTimePeriod.time[1]).toDateString()],
            [""],
            ["Indigenous Status", "# of participants", "% of participants"]
        ]

        const items_array = graphData.map((item) => {
            return [item.title, item.participants.toString(), `${Math.round(item.participants/(sums.participants !== 0 ? sums.participants : 1)*100)}%`]
        })

        final_array.push(...items_array);

        final_array.forEach((array) => {
            array.forEach(((item, index) => column_widths[index] =  column_widths[index] ? Math.max(item.toString().length, column_widths[index], 10) : item.length))
        })

        const worksheet = XLSX.utils.aoa_to_sheet(final_array);
        worksheet["!cols"] = new Array(column_widths.length).fill(null).map((_, index) => {
            return { wch: column_widths[index] }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "By Indigenous");
        XLSX.writeFile(workbook, "Enhancement Reach By Indigenous.xlsx");
    }

    const handleTimePeriodsResetAcknowledged = () => {
        setTimePeriodsReset(false);
    };

    return (
        <Paper container>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[[selectAllYears, ...timePeriods], countries, defaultRegions.map((r) => getRegionAlias(r)), defaultPostal.map((p) => getPostalAlias(p)), defaultAreas.map((a) => getAreaAlias(a)), defaultModules.map((m) => getModuleAlias(m))]}
                        onCheckCallbacks = {[onSelectYear, onSelectCountry, onSelectRegion, onSelectPostal, onSelectArea, onSelectModule]}
                        headers = {[timeHeader, countryHeader, regionHeader, postalHeader, areaHeader, moduleHeader]}
                        colourize = {[false, false, false, false, false, false]}
                        actions = {[undefined, !defaultCountriesExists ? X : undefined, X, X, X, undefined]}
                        actionsData = {[undefined, {style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, undefined]}
                        actionsCallbacks = {[undefined, !defaultCountriesExists ? onRemoveCountry : undefined, onRemoveRegion, onRemovePostal, onRemoveArea, undefined]}
                        footers = {[MonthRangePicker, !defaultCountriesExists ? CustomSelect : undefined, CustomSelect, CustomSelect, CustomSelect, undefined]}
                        footersData = {[
                            {onChangeDate: onSelectDateRange, minDate, maxDate, resetKey},
                            !defaultCountriesExists ? {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_country"),
                                data: additionalCountries.map((country) => get_category_alias(country)),
                            } : undefined,
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__add_${administrative_sector.toLowerCase()}`),
                                data: additionalRegions.map((r) => getRegionAlias(r)),
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_postal"),
                                data: additionalPostal.map((p) => getPostalAlias(p)),
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_inst"),
                                data: additionalAreas.map((a) => getAreaAlias(a)),
                            },
                            undefined
                        ]}
                        footersCallbacks = {[{onOpen: onClickDatePicker}, !defaultCountriesExists ? {onChange: onAddCountry} : undefined, {onChange: onAddRegion}, {onChange: onAddPostal}, {onChange: onAddArea}, undefined]}
                        defaultCheckIndices = {[]}
                        timePeriodsReset={timePeriodsReset}
                        onResetAcknowledged={handleTimePeriodsResetAcknowledged}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                    {
                        pageData ?
                            <Paper container mt={10} mb={5}>
                                <Typography mb={5} variant="h4" gutterBottom align={'center'}>
                                    {header === "" ?
                                        (!selectedRegion.region ?
                                                GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__${administrative_sector.toLowerCase()}_need`)
                                                : !selectedPostal.postal ?
                                                    GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__postal_need`)
                                                    : !selectedArea.area ?
                                                        GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__inst_need`)
                                                        : GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__mod_need`)
                                        )
                                        : header
                                    }
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <BarChart
                                                    labels={[...graphData.filter((item) => item.title !== "Total").map((item) => GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item.title)}`, item.title))]}
                                                    values={[...graphData.filter((item) => item.title !== "Total").map((item) => item.participants)]}
                                                    color={true}
                                                    threshold={50}
                                                    titleX={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__indigenous")}
                                                    titleY={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_count")}
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                    {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__indigenous")}
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                    {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_count")}
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                    {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_percent")}
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            graphData.map((item, index) => (
                                                                item.title !== "Total" ?
                                                                    <CustomTableRow key={index}>
                                                                        <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(item.title)}`, item.title)}</CustomTableCell>
                                                                        <CustomTableCell align="center">{item.participants}</CustomTableCell>
                                                                        <CustomTableCell align="center">
                                                                            {Math.round(item.participants/(sums.participants !== 0 ? sums.participants : 1)*100)}%
                                                                        </CustomTableCell>
                                                                    </CustomTableRow>
                                                                    :
                                                                    <CustomTableRow key={"sum"}>
                                                                        <CustomTableCell align="center">
                                                                            <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "general__total")}</Bold>
                                                                        </CustomTableCell>
                                                                        <CustomTableCell align="center">
                                                                            <Bold>{item.participants}</Bold>
                                                                        </CustomTableCell>
                                                                        <CustomTableCell align="center">
                                                                            <Bold>
                                                                                {Math.round(item.participants/(sums.participants !== 0 ? sums.participants : 1)*100)}%
                                                                            </Bold>
                                                                        </CustomTableCell>
                                                                    </CustomTableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <ColouredTypography variant="body1" color={"#ff69b4"} weight={"bold"} textAlign={"center"}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__attention")}
                                    <ColouredTypography variant="body1" component={"span"}>
                                        , {GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__by_subgroup_footer`)}
                                    </ColouredTypography>
                                </ColouredTypography>
                                <Box mt={5} mr={5} align={"right"}>
                                    <Button variant="contained" alignSelf={"right"} onClick={onClickDownload}>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                                    </Button>
                                </Box>
                            </Paper>
                        :
                            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default ByIndigenousTab;