import {DEFAULT_LOCALE, LOCALES_ENABLED} from "../constants";
import {LC_VALUES_DASH} from "./lc_values_dashboard";
import {LC_VALUES_BASELINING} from "./lc_values_baselining";
import {LC_VALUES_LINKS} from "./lc_values_links";

export const MISSING_TRANSLATION_TEXT = '**Missing Text**'

const __fetch_value = (locale, lc_key, value_map, fallback = MISSING_TRANSLATION_TEXT) => {
    let lc = ''
    if (!LOCALES_ENABLED.includes(locale)) {
        lc = DEFAULT_LOCALE;
    } else {
        lc = locale
    }

    let localize_text = value_map[lc][lc_key];
    let default_text = value_map[DEFAULT_LOCALE][lc_key];
    if (localize_text && localize_text !== '') {
        return localize_text
    } else if (default_text && default_text !== '') {
        // return "%" + default_text + "%"
        return default_text
    } else {
        return fallback
    }
}

export const GET_APP_LOCALIZE_TEXT = (locale, lc_key, fallback) => {
    return __fetch_value(locale, lc_key, LC_VALUES_DASH, fallback);
}

export const GET_BASELINING_LOCALIZE_TEXT = (locale, lc_key) => {
    return __fetch_value(locale, lc_key, LC_VALUES_BASELINING);
}

export const GET_APP_LOCALIZE_LINK = (locale, lc_key) => {
    return __fetch_value(locale, lc_key, LC_VALUES_LINKS, "**Missing Link**");
}
