import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {CustomSelect} from "../../components/Select.comp";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import BarChart from "../../components/BarChart";
import {alphaNumericSort, createLcKey, predefinedSort, splitCountries, toastDanger} from "../../../../../utils/utils";
import {X} from "react-feather";
import XLSX from "xlsx";
import {useAuth} from "../../../../../context/auth";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {COUNTRY_CONFIGS} from "../../../../../utils/constants";
import {getEnhancementAdoptionRegionv2} from "../../../../../services/enhancement.service";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 12,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color :props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

function splitList(list, min_count)
{
    const sublist = []
    const length = list.length;

    sublist.push(list.slice(0, Math.min(min_count, list.length)));
    sublist.push(list.slice(Math.min(min_count, list.length), length));
    return sublist;
}

const cat_reverse_translations = {}

function ByRegionTab() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const { org_country } = authUser.org_obj;
    const countryConfigKey = COUNTRY_CONFIGS[org_country] ? org_country : "default";
    const countryConfigKeyForIncludedCountries = COUNTRY_CONFIGS[org_country] && COUNTRY_CONFIGS[org_country].included_countries ? org_country : "default";
    const countriesFromConfig = [org_country, ...COUNTRY_CONFIGS[countryConfigKeyForIncludedCountries].included_countries]
    const {administrative_sector} = COUNTRY_CONFIGS[countryConfigKey];

    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [postalCodes, setPostalCodes] = useState(undefined);
    const [additionalCountries, setAdditionalCountries] = useState([]);

    const countryHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const [selectedCountry, setSelectedCountry] = useState({country: undefined});

    const regionHeader = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__select_${administrative_sector.toLowerCase()}`);
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({region: undefined});

    const postalHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_postal")
    const [defaultPostal, setDefaultPostal] = useState([]);
    const [additionalPostal, setAdditionalPostal] = useState([]);
    const [selectedPostal, setSelectedPostal] = useState({postal: undefined});

    const [graphData, setGraphData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [header, setHeader] = useState("");
    const subListDivision = 3;
    const [defaultCountriesExists, setDefaultCountriesExists] = useState(false);

    const selectAllRegionsOption = "All"
    const selectAllRegionsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__all_${administrative_sector.toLowerCase()}`)

    const get_category_alias = (category) => {
        const cat_trans = GET_APP_LOCALIZE_TEXT(pageLc, `baselining__${createLcKey(category)}`, null);
        const country_trans = GET_APP_LOCALIZE_TEXT(pageLc, `dq_country__${createLcKey(category)}`, null);
        const translation = cat_trans || country_trans || category;
        cat_reverse_translations[translation] = category;
        return translation;
    }

    const get_category_reverse_alias = (alias) => {
        const reverse_alias = cat_reverse_translations[alias];
        return reverse_alias || alias;
    }

    const getRegionAlias = (code) => {
        return code === selectAllRegionsOption ? selectAllRegionsAliasedOption : code;
    }

    const getRegionReverseAlias = (alias) => {
        return alias === selectAllRegionsAliasedOption ? selectAllRegionsOption : alias;
    }

    useEffect(() => {
        if(selectorsData)
        {
            const {selectors, current_selection, data} = selectorsData;
            const {countries: _countries} = selectors;
            if(_countries && Array.isArray(_countries))
            {
                let filtered_countries = _countries.filter(ct => countriesFromConfig.includes(ct));
                // if (filtered_countries.length === 0) filtered_countries = [..._countries];
                setDefaultCountriesExists(filtered_countries && filtered_countries.length > 0);
                const countriesSubList = splitCountries(_countries, filtered_countries && filtered_countries.length > 0 ? filtered_countries : [org_country]);
                setCountries(countriesSubList[0]);
                setAdditionalCountries(countriesSubList[1]);
                setSelectedCountry({country: countriesSubList[0][0]});
            }
            setPageData({current_selection, data});
        }
    }, [selectorsData])

    useEffect(() => {
        if(countries.length > 0 && !selectedCountry)
        {
            setSelectedCountry({country: countries[0]});
        }
    }, [countries])

    useEffect(() => {
        if(selectedCountry.country)
        {
            const country = selectedCountry.country;
            if(selectorsData)
            {
                const {selectors} = selectorsData;
                const {states, postal_codes} = selectors;
                if(states[country] && postal_codes[country]) {

                    const local_states = states[country];
                    const all_postal_codes = [];
                    for (let state of local_states) {
                        all_postal_codes.push(...postal_codes[country][state]);
                    }
                    setPostalCodes({"All": all_postal_codes, ...postal_codes[country]})
                    setRegions(["All", ...local_states])
                }
            }
        }
    }, [selectedCountry])

    useEffect(() => {
        if(regions.length > 0)
        {
            regions.sort().sort(predefinedSort([selectAllRegionsOption]))
            const regionSubLists = splitList(regions, subListDivision)
            setDefaultRegions(regionSubLists[0]);
            setAdditionalRegions(regionSubLists[1])
            setSelectedRegion({region: regionSubLists[0][0]});
        }
    }, [regions])

    useEffect(() => {
        if(selectedRegion.region && postalCodes)
        {
            setSelectedPostal({postal: undefined});
            const postals = postalCodes[selectedRegion.region];
            postals.sort((a, b) => a - b);
            const postalSubLists = splitList(postals, subListDivision)
            setDefaultPostal(postalSubLists[0]);
            setAdditionalPostal(postalSubLists[1]);

            loadPageData(selectedCountry.country, selectedRegion.region).catch(console.error);
            setHeader(selectedRegion.region)
        }
        else {
            // setDefaultRegions([]);
            // setAdditionalRegions([]);
            setDefaultPostal([])
            setAdditionalPostal([])
            setGraphData([])
            setHeader("");
        }
    }, [selectedRegion])

    useEffect(() => {
        if(selectedPostal.postal)
        {
            loadPageData(selectedCountry.country, selectedRegion.region, selectedPostal.postal).catch(console.error);
            setHeader(selectedPostal.postal);
        }
        else {
            if(selectedRegion.region) {
                loadPageData(selectedCountry.country, selectedRegion.region).catch(console.error);
                setHeader(selectedRegion.region)
            }
            else {
                setGraphData([]);
                setHeader("")
            }
        }
    }, [selectedPostal])

    useEffect( () => {
        loadSelectorData().then(d => console.log('Enhancement Adoption Region Data Fetched', d)).catch(console.error);
    }, []);

    const loadSelectorData = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementAdoptionRegionv2(authUser.org_code)
            if (result.data) {
                console.log("selector data fetched")
                setSelectorsData(result.data);
            }
            else {
                setPageData(undefined);
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption region data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const loadPageData = async (country, region, postal_code) => {
        if(pageData) {
            const {current_selection, data} = pageData;
            const {selected_country, selected_state, selected_postal_code} = current_selection;
            if(selected_country === country && selected_state === region && selected_postal_code === postal_code)
            {
                console.log("fetching did not occur")
                setGraphData(data.sort(alphaNumericSort("title", true)));
                return true;
            }
        }
        setLoading(true);
        try
        {
            const result = await getEnhancementAdoptionRegionv2(authUser.org_code, country, region, postal_code)
            if (result.data) {
                console.log("fetching occured")
                const {current_selection, data} = result.data;
                setPageData(result.data);
                setGraphData(data.sort(alphaNumericSort("title", true)));
            }
            else {
                setGraphData([]);
                setPageData(undefined);
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        }
        catch (e)
        {
            console.log("Some error occurred while fetching enhancement adoption region data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const addItemToState = (item, state, setState, sort = false) => {
        if(sort)
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item].sort());
            else
                setState([...state, item].sort());
        }
        else
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item]);
            else
                setState([...state, item]);
        }
    }

    const removeItemFromState = (item, state, setState) => {
        const temp = [...state];
        if(Array.isArray(item)) //in case item is an array, iterate
        {
            item.forEach((i) => {
                const index = temp.indexOf(i);
                if(index !== -1)
                    temp.splice(index, 1);
            })
        }
        else
        {
            const index = temp.indexOf(item);
            if(index !== -1)
                temp.splice(index, 1);
        }
        setState(temp);
        return temp;
    }

    function onSelectCountry (item, isChecked, state, setState) {
        if(isChecked)
        {
            setSelectedCountry({country: item});
            setState(Object.fromEntries(countries.map(
                (country) => country === item ? [country, true] : [country, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true,
            });
        }
    }

    function onAddCountry(event, state, setState) {
        const aliasedItem = event.target.value;
        if(countries.length === 11)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        }
        else
        {
            const country = get_category_reverse_alias(aliasedItem);
            removeItemFromState(country, additionalCountries, setAdditionalCountries);
            addItemToState(country, countries, setCountries);

            setState({
                ...Object.fromEntries(countries.map((c) => [c, false])),
                [country]: true,
            });
            setSelectedCountry({country: country});
        }
    }

    function onRemoveCountry(aliasedItem, state, setState) {

        const country = get_category_reverse_alias(aliasedItem);
        const newCountries = removeItemFromState(country, countries, setCountries)
        addItemToState(country, additionalCountries, setAdditionalCountries, true)

        setState({
            ...Object.fromEntries(newCountries.map((c, index) => index === 0 ? [c, true] : [c, false])),
        });
        setSelectedCountry({country: newCountries[0]});
    }

    function onAddRegion(event, state, setState) {
        const aliasedItem = event.target.value
        const item = getRegionReverseAlias(aliasedItem);
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setState({
            ...Object.fromEntries(defaultRegions.map((r) => getRegionAlias(r)).map((region) => [region, false])),
            [aliasedItem]: true,
        });
        setSelectedRegion({region: item});
    }

    function onRemoveRegion(aliasedItem, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setState({
            ...Object.fromEntries(newDefaultRegions.map((r) => getRegionAlias(r)).map((region, index) => index === 0 ? [region, true] : [region, false])),
        });
        setSelectedRegion({region: newDefaultRegions[0]});
    }

    function onSelectRegion(aliasedItem, isChecked, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        if(isChecked) {
            setSelectedRegion({region: item})
            setState(Object.fromEntries(defaultRegions.map((r) => getRegionAlias(r)).map(
                (region) => region === aliasedItem ? [region, true] : [region, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [aliasedItem]: true
            });
        }
    }

    function onAddPostal(event, state, setState) {
        const item = event.target.value;
        removeItemFromState(item, additionalPostal, setAdditionalPostal);
        addItemToState(item, defaultPostal, setDefaultPostal);
        setState({
            ...Object.fromEntries(defaultPostal.map((postal) => [postal, false])),
            [item]: true,
        });
        setSelectedPostal({postal: item});
    }

    function onRemovePostal(item, state, setState) {

        const newDefaultPostal = removeItemFromState(item, defaultPostal, setDefaultPostal)
        addItemToState(item, additionalPostal, setAdditionalPostal, true)
        setState({
            ...Object.fromEntries(newDefaultPostal.map((postal, index) => index === 0 ? [postal, true] : [postal, false])),
        });
        setSelectedPostal({postal: newDefaultPostal[0]});
    }

    function onSelectPostal(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedPostal({postal: item})
            setState(Object.fromEntries(defaultPostal.map(
                (region) => region === item ? [region, true] : [region, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    let sums = {
        participants: 0,
    }
    if(graphData)
    {
        graphData.forEach((item, index) => {
            sums.participants += item.participants;
        })
    }

    const title = selectedPostal.postal ? GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__inst") : selectedRegion.region ?
        selectedRegion.region === selectAllRegionsOption ? GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__${administrative_sector.toLowerCase()}`) : GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__postal")
        : GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__title");

    const onClickDownload = (event) => {

        const workbook = XLSX.utils.book_new();
        let column_widths = []

        const final_array = [
            ["Selected Country:", selectedCountry.country],
            ["Selected Region:", selectedRegion.region],
        ]

        if(selectedPostal.postal)
        {
            final_array.push(["Selected Postal:", selectedPostal.postal])
        }
        final_array.push(...[
            [""],
            [title, "# of participants", "% of participants"]
        ]);

        const items_array = graphData.map((item) => {
            return [item.title, item.participants.toString(), `${Math.round(item.participants/(sums.participants !== 0 ? sums.participants : 1)*100)}%`]
        })

        final_array.push(...items_array);

        final_array.forEach((array) => {
            array.forEach(((item, index) => column_widths[index] =  column_widths[index] ? Math.max(item.toString().length, column_widths[index], 10) : item.length))
        })

        const worksheet = XLSX.utils.aoa_to_sheet(final_array);
        worksheet["!cols"] = new Array(column_widths.length).fill(null).map((_, index) => {
            return { wch: column_widths[index] }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "By Country");
        XLSX.writeFile(workbook, "Enhancement Reach By Country.xlsx");
    }

    return (
        <Paper container>
            <Grid container alignItems={'stretch'}>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[countries, defaultRegions.map((r) => getRegionAlias(r)), defaultPostal]}
                        onCheckCallbacks = {[onSelectCountry, onSelectRegion, onSelectPostal]}
                        headers = {[countryHeader, regionHeader, postalHeader]}
                        colourize = {[false, false, false]}
                        actions = {[!defaultCountriesExists ? X : undefined, X, X]}
                        actionsData = {[{style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}]}
                        actionsCallbacks = {[onRemoveCountry, onRemoveRegion, onRemovePostal]}
                        footers = {[!defaultCountriesExists ? CustomSelect : undefined, CustomSelect, CustomSelect]}
                        footersData = {[
                            !defaultCountriesExists ? {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_country"),
                                data: additionalCountries.map((country) => get_category_alias(country)),
                            } : undefined,
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, `side_panel__add_${administrative_sector.toLowerCase()}`),
                                data: additionalRegions.map((r) => getRegionAlias(r)),
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_postal"),
                                data: additionalPostal,
                            },
                        ]}
                        footersCallbacks = {[!defaultCountriesExists ? {onChange: onAddCountry} : undefined, {onChange: onAddRegion}, {onChange: onAddPostal}]}
                        defaultCheckIndices = {[0, 0, undefined]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                    {
                        pageData ?
                            <Paper container mt={10} mb={5}>
                                <Typography mb={5} variant="h4" gutterBottom align={'center'}>
                                    {header === "" ? GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__${administrative_sector.toLowerCase()}_need`) : getRegionAlias(header)}
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Card>
                                            <CardContent>
                                                <BarChart
                                                    labels={[...graphData.map((item) => item.title)]}
                                                    values={[...graphData.map((item) => item.participants)]}
                                                    color={true}
                                                    threshold={sums.participants*0.1}
                                                    titleX={title}
                                                    titleY={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_count")}
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Card>
                                            <CardContent>
                                                {
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <CustomTableCell align="center">
                                                                    <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                        {title}
                                                                    </DefaultColouredTypography>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                        {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_count")}
                                                                    </DefaultColouredTypography>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    <DefaultColouredTypography variant={"h6"} color={"white"} size={"12px"}>
                                                                        {GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_percent")}
                                                                    </DefaultColouredTypography>
                                                                </CustomTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                graphData.map((item, index) => (
                                                                    <CustomTableRow key={index}>
                                                                        <CustomTableCell align="center">{item.title}</CustomTableCell>
                                                                        <CustomTableCell align="center">{item.participants}</CustomTableCell>
                                                                        <CustomTableCell align="center">
                                                                            {Math.round(item.participants/(sums.participants != 0 ? sums.participants : 1)*100)}%
                                                                        </CustomTableCell>
                                                                    </CustomTableRow>
                                                                ))
                                                            }
                                                            <CustomTableRow key={"sum"}>
                                                                <CustomTableCell align="center">
                                                                    <Bold>{GET_APP_LOCALIZE_TEXT(pageLc, "general__total")}</Bold>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    <Bold>{sums.participants}</Bold>
                                                                </CustomTableCell>
                                                                <CustomTableCell align="center">
                                                                    <Bold>{Math.round(sums.participants/(sums.participants != 0 ? sums.participants : 1)*100)}%</Bold>
                                                                </CustomTableCell>
                                                            </CustomTableRow>
                                                        </TableBody>
                                                    </Table>
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <ColouredTypography variant="body1" color={"#ff69b4"} weight={"bold"} textAlign={"center"}>
                                    {GET_APP_LOCALIZE_TEXT(pageLc, "overview__attention")}
                                    <ColouredTypography variant="body1" component={"span"}>
                                        , {GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__by_${administrative_sector.toLowerCase()}_footer`)}
                                    </ColouredTypography>
                                </ColouredTypography>
                                <Box mt={5} mr={5} align={"right"}>
                                    <Button variant="contained" alignSelf={"right"} onClick={onClickDownload}>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                                    </Button>
                                </Box>
                            </Paper>
                        :
                            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default ByRegionTab;