import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, Button as MuiButton,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import BarChart from "../../components/ExtendedBarChart";
import {X} from "react-feather";
import {CustomSelect} from "../../components/Select.comp";
import MonthRangePicker from "../../../../../components/DatePickers/MonthRangePicker.comp";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import {useAuth} from "../../../../../context/auth";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {
    addItemToState, addTrueKeyInState,
    alphaNumericSort, createLcKey, isDQWEnabled,
    predefinedSort,
    removeItemFromState, setSingleKeyTrueInState,
    splitList
} from "../../../../../utils/utils";
import {getEnhancementAdoptionMonth} from "../../../../../services/school.service";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
  font-weight: ${(props) => props.weight ? props.weight : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

function ByMonthlyTab() {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const {org_code, institute_code, org_obj} = authUser;
    const dqwEnabled = isDQWEnabled(org_obj);

    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);

    const subListDivision = 3;
    const [regions, setRegions] = useState([]);
    const [postalCodes, setPostalCodes] = useState(undefined);

    const regionHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_school");
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(undefined);

    const postalHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_class");
    const [defaultPostal, setDefaultPostal] = useState([]);
    const [additionalPostal, setAdditionalPostal] = useState([]);
    const [selectedPostal, setSelectedPostal] = useState(undefined);

    const timeHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time");
    const [timePeriods, setTimePeriods] = useState([]);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState([undefined, undefined]);
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);

    const [participantTypes, setParticipantTypes] = useState([]);
    const [graphData, setGraphData] = useState([]);

    const [regionState, setRegionState] = useState(undefined);
    const [postalState, setPostalState] = useState(undefined);
    const [timeState, setTimeState] = useState(undefined);

    const selectAllRegionsOption = "All"
    const selectAllRegionsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_school")

    const selectAllPostalsOption = "All"
    const selectAllPostalsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_class")

    const selectAllYears = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_year");

    const getDateRangeOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length - 1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        return [startDate, endDate]
    }

    const getRegionAlias = (code) => {
        return code === selectAllRegionsOption ? selectAllRegionsAliasedOption : code;
    }

    const getRegionReverseAlias = (alias) => {
        return alias === selectAllRegionsAliasedOption ? selectAllRegionsOption : alias;
    }

    const getPostalAlias = (code) => {
        return code === selectAllPostalsOption ? selectAllPostalsAliasedOption : code;
    }

    const getPostalReverseAlias = (alias) => {
        return alias === selectAllPostalsAliasedOption ? selectAllPostalsOption : alias;
    }

    useEffect(() => {
        if(!selectorsData && !firstLoad)
        {
            setGraphData([])
            setSelectedTimePeriod([undefined, undefined]);
            setSelectedRegion(undefined);
            setSelectedPostal(undefined);

            setPostalCodes(undefined);
            setRegions([]);
            setTimePeriods([]);
            setMinDate(undefined);
            setMaxDate(undefined);
        }
    }, [selectorsData])

    useEffect(() => {
        if(firstLoad) {
            // setTimeout(() => {loadSelectors().then(d => console.log('Assessment Adoption Selectors Fetched', d))}, 200)
            loadSelectors().then(d => console.log('Enhancement Adoption Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const loadSelectors = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementAdoptionMonth(org_code, institute_code);
            // const result = {data: getDummyByMonthData(org_code)};
            if(result.data)
            {
                const {selectors, data} = result.data;
                const {schools: _regions, classes: _postals, years, participant_types} = selectors;
                //Initialize Schools
                const _region = initializeRegions(_regions);
                //Initialize Classes
                setPostalCodes(_postals);
                initializePostals(_region, selectors);
                //Initialize Time Periods
                initializeTimePeriods(_region, selectors);
                //Initialize Participants Types
                setParticipantTypes(participant_types.filter((type) => dqwEnabled || !type.toLowerCase().includes("dqw")));
                //Initialize Graph Data
                loadGraphData(data.data);
                //Set Selectors Data and Page Data
                setSelectorsData(selectors);
                setPageData(data.data);
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching enhancement adoption selectors", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadPageData = async (_region = selectedRegion, _postal = selectedPostal, _time = selectedTimePeriod) => {
        setLoading(true);
        try {
            setPageData(undefined);
            const result = await getEnhancementAdoptionMonth(org_code, _region, _postal, _time);
            // const result = {data: getDummyByMonthData(org_code, _region, _postal, _time)};
            if (result.data) {
                const {data} = result.data;
                setPageData(data.data);
                loadGraphData(data.data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching enhancement adoption data")
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function loadGraphData(data)
    {
        setGraphData(data)
    }

    function initializeRegions(_regions) {
        _regions.sort().sort(predefinedSort([selectAllRegionsOption]));
        const regionSubLists = splitList(_regions, subListDivision)
        setDefaultRegions(regionSubLists[0]);
        setAdditionalRegions(regionSubLists[1])
        setRegions(_regions);
        selectRegion(regionSubLists[0][0], false);
        return regionSubLists[0][0];
    }

    function initializePostals(_region, selectors = selectorsData) {
        let _postal = undefined;
        if(_region && selectors)
        {
            const {classes: _all_postals} = selectors;
            const _postals = _all_postals[_region];
            _postals.sort((a, b) => a - b);
            const subLists = splitList(_postals, subListDivision)
            setDefaultPostal(subLists[0]);
            setAdditionalPostal(subLists[1]);
            _postal = subLists[0][0]
            selectPostal(_postal, false);
            setSingleKeyTrueInState(subLists[0].map((p) => getPostalAlias(p)), getPostalAlias(_postal), setPostalState) //ensuring checkbox consistency
        }
        return _postal;
    }

    function initializeTimePeriods(_region, selectors = selectorsData) {
        let date_range = [undefined, undefined];
        if(_region && selectors)
        {
            const {years: _years} = selectors;
            const _timePeriods = _years[_region].map((year) => year.toString());
            setTimePeriods(_timePeriods);
            setSingleKeyTrueInState([selectAllYears, ..._timePeriods], selectAllYears, setTimeState);
            if(_timePeriods.length > 0) {
                date_range = getDateRangeOfYears(_timePeriods);
                const [start_date_iso, end_date_iso] = date_range;
                setMinDate(new Date(start_date_iso));
                setMaxDate(new Date(end_date_iso));
                selectTimePeriod(date_range, false);
            }
        }
        return date_range;
    }

    function selectRegion(_region, fetch = false, _postal, _time)
    {
        setSelectedRegion(_region);
        if(_region)
        {
            if(fetch)
            {
                loadPageData(_region, _postal, _time).then(d => console.log('Enhancement Adoption By Month Data Fetched - By Selection of Region', d));
            }
        }
        else
        {
            if(!pageData)
            {
                setDefaultRegions([]);
                setAdditionalRegions([]);
            }
            setDefaultPostal([])
            setAdditionalPostal([])
            setGraphData([])
        }
    }

    function selectPostal(_postal, fetch = false)
    {
        setSelectedPostal(_postal);
        if(_postal)
        {
            if(fetch)
            {
                loadPageData(selectedRegion, _postal).then(d => console.log('Enhancement Adoption By Month Data Fetched - By Selection of Postal', d));
            }
        }
        else
        {
            setGraphData([]);
        }
    }

    function selectTimePeriod(_timePeriods, fetch = false)
    {
        setSelectedTimePeriod(_timePeriods);
        if(fetch)
        {
            loadPageData(selectedRegion, selectedPostal, _timePeriods).then(d => console.log('Enhancement Adoption By Month Data Fetched - By Selection of Time Period', d));
        }
    }

    function onAddRegion(event, state, setState) {
        const aliasedItem = event.target.value
        const item = getRegionReverseAlias(aliasedItem);
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        const _postal = initializePostals(item);
        const _time = initializeTimePeriods(item);
        selectRegion(item, true, _postal, _time);
    }

    function onRemoveRegion(aliasedItem, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setSingleKeyTrueInState(newDefaultRegions.map((r) => getRegionAlias(r)), getRegionAlias(newDefaultRegions[0]), setState)
        if(selectedRegion !== newDefaultRegions[0])
        {
            const _postal = initializePostals(newDefaultRegions[0]);
            const _time = initializeTimePeriods(newDefaultRegions[0]);
            selectRegion(newDefaultRegions[0], true, _postal, _time);
        }
    }

    function onSelectRegion(aliasedItem, isChecked, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        if(isChecked) {
            const _postal = initializePostals(item);
            const _time = initializeTimePeriods(item);
            selectRegion(item, true, _postal, _time);
            setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    function onAddPostal(event, state, setState) {
        const aliasedItem = event.target.value;
        const item = getPostalReverseAlias(aliasedItem);
        removeItemFromState(item, additionalPostal, setAdditionalPostal);
        addItemToState(item, defaultPostal, setDefaultPostal);
        setSingleKeyTrueInState(defaultPostal.map((p) => getPostalAlias(p)), aliasedItem, setState);
        selectPostal(item, true);
    }

    function onRemovePostal(aliasedItem, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        const newDefaultPostal = removeItemFromState(item, defaultPostal, setDefaultPostal)
        addItemToState(item, additionalPostal, setAdditionalPostal, true)
        setSingleKeyTrueInState(newDefaultPostal.map((p) => getPostalAlias(p)), getPostalAlias(newDefaultPostal[0]), setState);
        selectPostal(newDefaultPostal[0], true);
    }

    function onSelectPostal(aliasedItem, isChecked, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        if(isChecked) {
            selectPostal(item, true);
            setSingleKeyTrueInState(defaultPostal.map((p) => getPostalAlias(p)), aliasedItem, setState);
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    function onSelectYear (item, isChecked, state, setState) {
        if(isChecked)
        {
            if(item === selectAllYears)
            {
                const date_range = getDateRangeOfYears(timePeriods);
                selectTimePeriod(date_range, true)
            }
            else
            {
                const date_range = getDateRangeOfYears([item]);
                selectTimePeriod(date_range, true)
            }
            setSingleKeyTrueInState([selectAllYears, ...timePeriods], item, setState);
        }
        else
        {
            addTrueKeyInState(item, state, setState);
        }
    }

    function onClickDatePicker(event, state, setState) {
        setSingleKeyTrueInState([selectAllYears, ...timePeriods], undefined, setState);
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();
        selectTimePeriod([start_date, end_date], true)
    }

    const header = selectedRegion && selectedPostal ? `${getRegionAlias(selectedRegion)} - ${getPostalAlias(selectedPostal)}` : ""

    return (
        <Paper container>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[defaultRegions.map((r) => getRegionAlias(r)), defaultPostal.map((p) => getPostalAlias(p)), [selectAllYears, ...timePeriods]]}
                        onCheckCallbacks = {[onSelectRegion, onSelectPostal, onSelectYear]}
                        headers = {[regionHeader, postalHeader, timeHeader]}
                        colourize = {[false, false, false]}
                        actions = {[X, X, undefined]}
                        actionsData = {[{style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, undefined]}
                        actionsCallbacks = {[onRemoveRegion, onRemovePostal, undefined]}
                        footers = {[CustomSelect, CustomSelect, MonthRangePicker]}
                        footersData = {[
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_school"),
                                data: additionalRegions.map((r) => getRegionAlias(r)),
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_class"),
                                data: additionalPostal.map((p) => getPostalAlias(p)),
                            },
                            {onChangeDate: onSelectDateRange, minDate, maxDate},
                        ]}
                        footersCallbacks = {[{onChange: onAddRegion}, {onChange: onAddPostal}, {onOpen: onClickDatePicker}]}
                        defaultCheckIndices = {[0, 0, 0]}
                        stateCallbacks = {[
                            {
                                state: regionState,
                                setState: setRegionState
                            },
                            {
                                state: postalState,
                                setState: setPostalState
                            },
                            {
                                state: timeState,
                                setState: setTimeState
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                    {
                        pageData ?
                            <Paper container mt={10} mb={5}>
                                <Typography variant="h4" gutterBottom align={'center'}>
                                    {header === "" ?
                                        !selectedRegion ?
                                            GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__school_need")
                                            : GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__class_need")
                                        : header
                                    }
                                </Typography>
                                <BarChart
                                    labels={graphData.map((item) => `${GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(months[item.month - 1])}`, months[item.month - 1])} '${item.year.toString().slice(-2)}`)}
                                    datasets={
                                        [...participantTypes.map((type) => ({
                                            label: type,
                                            data: graphData.map((item) => item[type])
                                        }))]
                                    }
                                    titleX={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__month")}
                                    titleY={GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__participants_count")}
                                />
                            </Paper>
                        :
                            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default ByMonthlyTab;