import React from "react";
import {Main as MainLayout, Minimal as MinimalLayout} from "../layouts";
import {PreAuthRouteWithLayout, PrivateRouteWithLayout, RouteWithLayout} from "../components";
import {
    AppInfoPage,
    Callbacks as CallbacksPage,
    ChangePassword as ChangePasswordPage,
    ConfirmEmail as ConfirmEmailPage,
    ContactUs as ContactUsPage,
    DqStatsPage,
    Employees as EmployeesPage,
    EmployeesPendingActivation as EmployeesPendingActivationPage,
    EmployeesPendingApprovals as EmployeesPendingApprovalsPage,
    Home as HomePage,
    InitPasswordReset as InitPasswordResetPage,
    Licenses as LicensesPage,
    Login as LoginPage,
    Logout as LogoutPage, NotFound as NotFoundPage,
    Orders as OrdersPage, Overview as OverviewPage,
    PasswordReset as PasswordResetPage,
    PreRegister as PreRegisterPage,
    ProfilesListPage,
    Register as RegisterPage,
    RegisterCompany as RegisterCompanyPage,
    RegisterSchool as RegisterSchoolPage, Settings as SettingsPage,
    UI_Config_Areas as UI_Config_AreasPage,
    UI_Config_Badges as UI_Config_BadgesPage, Users_List as Users_ListPage
} from "../pages";

export default [

    <PreAuthRouteWithLayout
        component={LoginPage}
        exact
        layout={MinimalLayout}
        path={`/:lc/login`}
    />,
    <PreAuthRouteWithLayout
        component={RegisterPage}
        exact
        layout={MinimalLayout}
        path={`/:lc/register`}
    />,
    <PreAuthRouteWithLayout
        component={PreRegisterPage}
        exact
        layout={MinimalLayout}
        path={`/:lc/pre_register`}
    />,
    <PreAuthRouteWithLayout
        component={RegisterSchoolPage}
        exact
        layout={MinimalLayout}
        path={`/:lc/register_school`}
    />,
    <PreAuthRouteWithLayout
        component={RegisterCompanyPage}
        exact
        layout={MinimalLayout}
        path={`/:lc/register_company`}
    />,
    <PreAuthRouteWithLayout
        component={InitPasswordResetPage}
        exact
        layout={MinimalLayout}
        path={`/:lc/account/recover`}
    />,
    <PreAuthRouteWithLayout
        component={PasswordResetPage}
        exact
        layout={MinimalLayout}
        no_redirect={true}
        path={`/:lc/auth/reset/password`}
    />,
    <PrivateRouteWithLayout
        component={ConfirmEmailPage}
        exact
        layout={MinimalLayout}
        path={`/:lc/auth/confirm/email`}
    />,
    <PrivateRouteWithLayout
        component={LogoutPage}
        exact
        layout={MinimalLayout}
        path={`/:lc/logout`}
    />,

    <PrivateRouteWithLayout
        component={HomePage}
        exact
        layout={MainLayout}
        path={`/:lc/home`}
    />,
    <PrivateRouteWithLayout
        component={ChangePasswordPage}
        exact
        layout={MainLayout}
        path={`/:lc/password/change`}
    />,


    <PrivateRouteWithLayout
        component={ContactUsPage}
        exact
        layout={MainLayout}
        path={`/:lc/contact_us`}
    />,

    <PrivateRouteWithLayout
        component={EmployeesPage}
        exact
        layout={MainLayout}
        path={`/:lc/employees`}
    />,

    <PrivateRouteWithLayout
        component={EmployeesPendingApprovalsPage}
        exact
        layout={MainLayout}
        path={`/:lc/employees_approval`}
    />,

    <PrivateRouteWithLayout
        component={EmployeesPendingActivationPage}
        exact
        layout={MainLayout}
        path={`/:lc/employees_activations`}
    />,

    <PrivateRouteWithLayout
        component={LicensesPage}
        exact
        layout={MainLayout}
        path={`/:lc/licenses`}
    />,

    <PrivateRouteWithLayout
        component={OrdersPage}
        exact
        layout={MainLayout}
        path={`/:lc/orders`}
    />,

    <PrivateRouteWithLayout
        component={ProfilesListPage}
        exact
        layout={MainLayout}
        path={`/:lc/non_org_profiles`}
    />,

    <PrivateRouteWithLayout
        component={DqStatsPage}
        exact
        layout={MainLayout}
        path={`/:lc/non_org_stats`}
    />,

    <PrivateRouteWithLayout
        component={UI_Config_AreasPage}
        exact
        layout={MainLayout}
        path={`/:lc/ui_config/area`}
    />,

    <PrivateRouteWithLayout
        component={UI_Config_BadgesPage}
        exact
        layout={MainLayout}
        path={`/:lc/ui_config/badge`}
    />,

    <PrivateRouteWithLayout
        component={Users_ListPage}
        exact
        layout={MainLayout}
        path={`/:lc/users/list`}
    />,

    <PrivateRouteWithLayout
        component={CallbacksPage}
        exact
        layout={MainLayout}
        path={`/:lc/callbacks`}
    />,

    <PrivateRouteWithLayout
        component={SettingsPage}
        exact
        layout={MainLayout}
        path={`/:lc/settings`}
    />,

    <PrivateRouteWithLayout
        component={OverviewPage}
        exact
        layout={MainLayout}
        path={`/:lc/program_overview`}
    />,

    <RouteWithLayout
        component={AppInfoPage}
        exact
        layout={MinimalLayout}
        path="/:lc/app_info"
    />,
]