import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { store } from 'react-notifications-component';

import { withRouter } from "react-router";
import {Redirect, useLocation} from "react-router-dom";

import {Copyright} from '../../../components';

import { useFormik } from 'formik';

import {loginUser, logoutUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import Helmet from "react-helmet";
import {LOCAL_STORAGE_KEYS} from "../../../utils/constants";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Logout = (props) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const classes = useStyles();

    const { setAuthToken, setAuthUser } = useAuth();
    const referer = props.location.referer || '/home';

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    useEffect(  () => {
        logoutAction().then(r=>console.log('user logged out, on component load!'))
    }, [])

    const logoutAction = async () => {
        try {
            await logoutUser();
            setAuthToken(null);
            setAuthUser(null);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_token);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_user);
            localStorage.removeItem(LOCAL_STORAGE_KEYS.refresh_token);
            // history.push('/login');
        } catch (e) {
            // already toasted the error
        }
    }

    return (
        <Container component="main" maxWidth="xs">

            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "auth__logout")}`} />

            <CssBaseline />
            <div className={classes.paper}>

                <p>{GET_APP_LOCALIZE_TEXT(pageLc, "auth__logout_in_process")}</p>

            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withRouter(Logout);