import XLSX from "xlsx";
import {
    DEMOGRAPHIC_QUESTIONS,
    DOMAIN_INTERESTS,
    DOMAIN_MAPPING,
    ITEM_MAPPING,
    SCALE
} from "../../../../utils/constants";

export const createExcelOutputFormat = (level6_dict, scores) =>
{
    const workbook = XLSX.utils.book_new();

    const DATE_NOW = new Date();
    const worksheet = XLSX.utils.aoa_to_sheet([
        ['', '', ''],
        ['', '', ''],
        ['', '', ''],
        ['ResponseDate', 'ResponseID', 'DomainInterests'],
    ]);

    worksheet['!freeze'] = 'D1';
    worksheet['!cols'] = [{ width: 12 }, { width: 12 }, { width: 12 }];

    const applyCellFormatting = (domain, colNum) => {
        switch (domain) {
            case 'Digital Social Relations':
                // Apply formatting for Digital Social Relations
                break;
            case 'Digital Health':
                // Apply formatting for Digital Health
                break;
            // Add cases for other domains
            default:
                // Default formatting
                break;
        }
    };

    let startCol = 3;

    Object.entries(level6_dict).forEach(([level6, pairs], idx) => {
        const endCol = startCol + pairs.length - 1;
        XLSX.utils.sheet_add_aoa(worksheet, [[`Domain ${idx + 1}: ${level6}`]], {
            origin: { r: 0, c: startCol },
        });

        pairs.forEach((pair, colNum) => {
            Object.entries(pair).forEach(([item, question]) => {
                XLSX.utils.sheet_add_aoa(worksheet, [[question]], {
                    origin: { r: 1, c: startCol + colNum },
                });

                XLSX.utils.sheet_add_aoa(worksheet, [SCALE], {
                    origin: { r: 2, c: startCol + colNum },
                });

                applyCellFormatting(level6, startCol + colNum);

                XLSX.utils.sheet_add_aoa(worksheet, [[ITEM_MAPPING[item] || item]], {
                    origin: { r: 3, c: startCol + colNum },
                });
            });
        });

        startCol = endCol + 1;
    });

    XLSX.utils.sheet_add_aoa(worksheet, [
        ["Demographic Questions", "", "", "To be shown to users on the web Assessment tool", "", "", "", "", "Never shown to users"],
    ], { origin: { r: 0, c: startCol }});

    // startCol += 3;

    XLSX.utils.sheet_add_aoa(worksheet, [
        ["Age", "Gender", "Country", "D1_Score%", "D2_Score%", "D3_Score%", "D4_Score%", "D5_Score%", "DW_Score", "DW_Level", "D1_ScoreRaw", "D2_ScoreRaw", "D3_ScoreRaw", "D4_ScoreRaw", "D5_ScoreRaw", "TotalScoreRaw", "TotalScore%"]
    ], { origin: { r: 3, c: startCol }});


    scores.forEach((user, idx) => {
        const startRow = idx + 5;
        const ResponseDate = new Date(user['scoring_at']).toISOString().split('T')[0];
        const ResponseID = user['_id']
        const domainInterests = Object.keys(user.RAW_LEVEL6).map(key => DOMAIN_MAPPING[key]).join(", ");

        let X_DATA = Object.fromEntries(
            Object.entries(user.X_DATA)
                .filter(([key]) => !(
                    DOMAIN_INTERESTS.question.includes(key.replace("_", ".")) ||
                    DOMAIN_INTERESTS.interests.includes(key.replace("_", ".")) ||
                    DEMOGRAPHIC_QUESTIONS.includes(key.replace("_", "."))
                ))
                .map(([key, value]) => [ITEM_MAPPING[key.replace("_", ".")], value])
        );

        let demographicDict = {
            "Age": user.year_of_birth ? DATE_NOW.getFullYear() - parseInt(user.year_of_birth) : null,
            "Gender": user.gender || null,
            "Country": user.country
        };

        let RAW_LEVEL6 = Object.keys(DOMAIN_MAPPING).reduce((accumulator, key) => {
            const newKey = `${DOMAIN_MAPPING[key]}_ScoreRaw`;
            accumulator[newKey] = user.RAW_LEVEL6.hasOwnProperty(key) ? user.RAW_LEVEL6[key] : null;
            return accumulator;
        }, {});
        let totalDomainsWithScore = Object.values(RAW_LEVEL6).filter(score => score !== null).length;
        let totalScoreRaw = Object.values(RAW_LEVEL6).reduce((acc, score) => acc + (score ? Math.round(score) : 0), 0);

        RAW_LEVEL6["TotalScoreRaw"] = totalScoreRaw;
        RAW_LEVEL6["TotalScore%"] = totalDomainsWithScore > 0 ? Math.round((totalScoreRaw / (totalDomainsWithScore * 20)) * 100) : null;

        let RAW_LEVEL6_PERCENTAGE = Object.fromEntries(
            Object.keys(DOMAIN_MAPPING).map(key => [
                `${DOMAIN_MAPPING[key]}_Score%`, user.RAW_LEVEL6_PERCENTAGE ? user.RAW_LEVEL6_PERCENTAGE[key] : null
            ])
        );
        RAW_LEVEL6_PERCENTAGE["DW_Score"] = totalDomainsWithScore > 0 ? `${totalScoreRaw}/${totalDomainsWithScore * 20}` : null;
        RAW_LEVEL6_PERCENTAGE["DW_Level"] = totalScoreRaw <= 20 ? "Digital Novice" :
            totalScoreRaw <= 40 ? "Tech Explorer" :
                totalScoreRaw <= 60 ? "Digital Adventurer" :
                    totalScoreRaw <= 80 ? "Screen Sorcerer" :
                        totalScoreRaw <= 100 ? "Wellbeing Whiz" : null;

        let FINAL_DICT = {...X_DATA, ...demographicDict, ...RAW_LEVEL6, ...RAW_LEVEL6_PERCENTAGE};

        const colNumToLetter = (colNum) => {
            let letter = '', temp;
            while (colNum > 0) {
                temp = (colNum - 1) % 26;
                letter = String.fromCharCode(temp + 65) + letter;
                colNum = (colNum - temp - 1) / 26;
            }
            return letter;
        };

        let row = [ResponseDate, ResponseID, domainInterests];

        for (let col = 4; col <= 68; col++) {
            const headerCellRef = `${colNumToLetter(col)}4`;
            const headerCellValue = worksheet[headerCellRef] ? worksheet[headerCellRef].v : undefined;
            row.push(FINAL_DICT.hasOwnProperty(headerCellValue) ? FINAL_DICT[headerCellValue] : "NA");
        }

        XLSX.utils.sheet_add_aoa(worksheet, [row], {origin: `A${startRow}`});
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, 'nus-ctic.xlsx');

}