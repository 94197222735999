import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from "../../context/auth";

const PreAuthRouteWithLayout = props => {

    const { layout: Layout, component: Component, no_redirect, ...rest } = props;
    const { authToken, authUser } = useAuth();

    return (
        <Route
            {...rest}
            render={matchProps => (
                authToken && authUser && (!no_redirect) ? (
                    <Redirect
                        to={{ pathname: "/home" }}
                    />
                ) : (
                    <Layout>
                        <Component {...matchProps} />
                    </Layout>
                )
            )}
        />
    );

};

export default PreAuthRouteWithLayout;
