import {
  Typography as MuiTypography,
  Paper as MuiPaper,
  Box as MuiBox,
  Grid as MuiGrid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Button as MuiButton,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import styled, {css} from "styled-components";

const spacedTypography = styled(MuiTypography)(spacing)
const spacedPaper = styled(MuiPaper)(spacing)
const spacedBox = styled(MuiBox)(spacing)
const spacedGrid = styled(MuiGrid)(spacing)
const spacedCard = styled(MuiCard)(spacing)
const spacedCardContent = styled(MuiCardContent)(spacing)
const spacedChip = styled(MuiChip)(spacing)
const spacedDiv = styled.div(spacing)
const spacedButton = styled(MuiButton)(spacing)

export const Typography = styled(spacedTypography)`
  
  ${props => props.font_size && css`font-size: ${props.font_size}`};
  ${props => props.font_weight && css`font-weight: ${props.font_weight}`};
  ${props => props.font_style && css`font-style: ${props.font_style}`};
  ${
    props => props.primary && css`color: ${props.theme.sidebar.background};`
    || props.secondary && css`color: ${props.theme.palette.tertiary.main};`
    || props.font_color && css`color: ${props.font_color};`
  };
  ${
    props => props.lemon && css`font-family: "Lemon/Milk",serif`
    || props.font_family && css`font-family: ${props.font_family};`
  };
`

export const Paper = styled(spacedPaper)`
`

export const Box = styled(spacedBox)`
`

export const Grid = styled(spacedGrid)`
`

export const Card = styled(spacedCard)`
`

export const CardContent = styled(spacedCardContent)`
`

export const Chip = styled(spacedChip)`
`

export const Spacer = styled(spacedDiv)`
`

export const Button = styled(spacedButton)`
`