import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Paper as MuiPaper,
    Box,
    Grid as MuiGrid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Typography as MuiTypography,
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton, Tooltip,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import {X} from "react-feather";
import {CustomSelect} from "../../components/Select.comp";
import {GenericSidePanel} from "../../../../../components/SidePanel/GenericSidePanel.comp";
import {useAuth} from "../../../../../context/auth";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {
    addItemToState, addTrueKeyInState,
    alphaNumericSort,
    predefinedSort,
    getUniqueValues,
    removeItemFromState, setSingleKeyTrueInState,
    splitList, createLcKey, isDQWEnabled
} from "../../../../../utils/utils";
import MonthRangePicker from "../../../../../components/DatePickers/MonthRangePicker.comp";
import {LinearProgressWithLabel} from "../../components/LinearProgressWithLabel.comp";
import {getEnhancementEfficacy} from "../../../../../services/school.service";
import {DQW_DQC_MODULES} from "../../../../../utils/constants";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../../utils/dq_lc_service/lc_service";

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
        padding: 10,
        minWidth: 10,
        maxWidth: 50,
    },
    body: {
        padding: 10,
        minWidth: 10,
        maxWidth: 50,
        fontSize: 12,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const Grid = styled(MuiGrid)(spacing);

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Typography = styled(MuiTypography)(spacing);

const Divider = styled(MuiDivider)`
  padding-left: ${(props) => props.theme.spacing(1)}px;
  margin: ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(4)}px ${(props) => props.theme.spacing(2)}px ${(props) => props.theme.spacing(10)}px;
  background-color: darkgrey;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.customcolor ? props.customcolor : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.customcolor ? props.customcolor : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`

const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

let dq_modules = {}

function GenericEfficacyTab({skill, title, description}) {

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const {authUser} = useAuth();
    const {org_code, institute_code, org_obj} = authUser;
    const dqwEnabled = isDQWEnabled(org_obj);

    const [firstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectorsData, setSelectorsData] = useState(undefined);
    const [pageData, setPageData] = useState(undefined);
    const [tableHeaderData, setTableHeaderData] = useState([]);
    const [tableData, setTableData] = useState([]);

    const subListDivision = 3;
    const [regions, setRegions] = useState([]);
    const [postalCodes, setPostalCodes] = useState(undefined);

    const regionHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_school");
    const [defaultRegions, setDefaultRegions] = useState([]);
    const [additionalRegions, setAdditionalRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(undefined);

    const postalHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_class");
    const [defaultPostal, setDefaultPostal] = useState([]);
    const [additionalPostal, setAdditionalPostal] = useState([]);
    const [selectedPostal, setSelectedPostal] = useState(undefined);

    const timeHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_time");
    const [timePeriods, setTimePeriods] = useState([]);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState([undefined, undefined]);
    const [minDate, setMinDate] = useState(undefined);
    const [maxDate, setMaxDate] = useState(undefined);

    const [regionState, setRegionState] = useState(undefined);
    const [postalState, setPostalState] = useState(undefined);
    const [timeState, setTimeState] = useState(undefined);

    const selectAllRegionsOption = "All"
    const selectAllRegionsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_school")

    const selectAllPostalsOption = "All"
    const selectAllPostalsAliasedOption = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_class")

    const selectAllYears = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__all_year");

    const getDateRangeOfYears = (years) => {
        const [startYear, endYear] = [years[0], years[years.length - 1]];
        const [startDate, endDate] = [new Date(`${startYear}-01-01`).toISOString(), new Date(`${endYear}-12-31`).toISOString()]
        return [startDate, endDate]
    }

    const getRegionAlias = (code) => {
        return code === selectAllRegionsOption ? selectAllRegionsAliasedOption : code;
    }

    const getRegionReverseAlias = (alias) => {
        return alias === selectAllRegionsAliasedOption ? selectAllRegionsOption : alias;
    }

    const getPostalAlias = (code) => {
        return code === selectAllPostalsOption ? selectAllPostalsAliasedOption : code;
    }

    const getPostalReverseAlias = (alias) => {
        return alias === selectAllPostalsAliasedOption ? selectAllPostalsOption : alias;
    }

    const getModuleAlias = (code) => {
        const alias = dq_modules[code] ? dq_modules[code].code : undefined;
        if(alias) return alias;
        return code;
    }

    useEffect(() => {
        if(!selectorsData && !firstLoad)
        {
            setTableHeaderData([])
            setTableData([])
            setSelectedTimePeriod([undefined, undefined]);
            setSelectedRegion(undefined);
            setSelectedPostal(undefined);

            setPostalCodes(undefined);
            setRegions([]);
            setTimePeriods([]);
            setMinDate(undefined);
            setMaxDate(undefined);
        }
    }, [selectorsData])

    useEffect(() => {
        if(firstLoad) {
            initializeDQModules();
            loadSelectors().then(d => console.log('Enhancement Efficacy Selectors Fetched', d))
            setFirstLoad(false);
        }
    }, []);

    const initializeDQModules = () => {
        dq_modules = DQW_DQC_MODULES(pageLc);
    }

    const loadSelectors = async () => {
        setLoading(true);
        try {
            const result = await getEnhancementEfficacy(org_code, skill, institute_code);
            if(result.data)
            {
                const {selectors, data} = result.data;
                const {schools: _regions, classes: _postals} = selectors;
                //Initialize Schools
                const _region = initializeRegions(_regions);
                //Initialize Classes
                setPostalCodes(_postals);
                initializePostals(_region, selectors);
                //Initialize Time Periods
                initializeTimePeriods(_region, selectors);
                //Initialize Graph Data
                loadGraphData(data.data);
                //Set Selectors Data and Page Data
                setSelectorsData(selectors);
                setPageData(data.data);
            }
        }
        catch (e) {
            console.log("Some error occurred while fetching enhancement efficacy selectors", e)
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    const loadPageData = async (_region = selectedRegion, _postal = selectedPostal, _time = selectedTimePeriod) => {
        setLoading(true);
        try {
            setPageData(undefined);
            const result = await getEnhancementEfficacy(org_code, skill, _region, _postal, _time);
            if (result.data) {
                const {data} = result.data;
                setPageData(data.data);
                loadGraphData(data.data);
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }
        } catch (e) {
            console.log("Some error occurred while fetching enhancement efficacy data")
        }
        setTimeout(() => {setLoading(false)}, 200);
        return true;
    }

    function loadGraphData(data)
    {
        populateTable(data);
    }

    function initializeRegions(_regions) {
        _regions.sort().sort(predefinedSort([selectAllRegionsOption]));
        const regionSubLists = splitList(_regions, subListDivision)
        setDefaultRegions(regionSubLists[0]);
        setAdditionalRegions(regionSubLists[1])
        setRegions(_regions);
        selectRegion(regionSubLists[0][0], false);
        return regionSubLists[0][0];
    }

    function initializePostals(_region, selectors = selectorsData) {
        let _postal = undefined;
        if(_region && selectors)
        {
            const {classes: _all_postals} = selectors;
            const _postals = _all_postals[_region];
            _postals.sort((a, b) => a - b);
            const subLists = splitList(_postals, subListDivision)
            setDefaultPostal(subLists[0]);
            setAdditionalPostal(subLists[1]);
            _postal = subLists[0][0]
            selectPostal(_postal, false);
            setSingleKeyTrueInState(subLists[0].map((p) => getPostalAlias(p)), getPostalAlias(_postal), setPostalState) //ensuring checkbox consistency
        }
        return _postal;
    }

    function initializeTimePeriods(_region, selectors = selectorsData) {
        let date_range = [undefined, undefined];
        if(_region && selectors)
        {
            const {years: _years} = selectors;
            const _timePeriods = _years[_region].map((year) => year.toString());
            setTimePeriods(_timePeriods);
            setSingleKeyTrueInState([selectAllYears, ..._timePeriods], selectAllYears, setTimeState);
            if(_timePeriods.length > 0) {
                date_range = getDateRangeOfYears(_timePeriods);
                const [start_date_iso, end_date_iso] = date_range;
                setMinDate(new Date(start_date_iso));
                setMaxDate(new Date(end_date_iso));
                selectTimePeriod(date_range, false);
            }
        }
        return date_range;
    }

    function selectRegion(_region, fetch = false, _postal, _time)
    {
        setSelectedRegion(_region);
        if(_region)
        {
            if(fetch)
            {
                loadPageData(_region, _postal, _time).then(d => console.log('Enhancement Efficacy Fetched - By Selection of Region', d));
            }
        }
        else
        {
            if(!pageData)
            {
                setDefaultRegions([]);
                setAdditionalRegions([]);
            }
            setDefaultPostal([])
            setAdditionalPostal([])
            setTableHeaderData([])
            setTableData([])
        }
    }

    function selectPostal(_postal, fetch = false)
    {
        setSelectedPostal(_postal);
        if(_postal)
        {
            if(fetch)
            {
                loadPageData(selectedRegion, _postal).then(d => console.log('Enhancement Efficacy Fetched - By Selection of Postal', d));
            }
        }
        else
        {
            setTableHeaderData([])
            setTableData([])
        }
    }

    function selectTimePeriod(_timePeriods, fetch = false)
    {
        setSelectedTimePeriod(_timePeriods);
        if(fetch)
        {
            loadPageData(selectedRegion, selectedPostal, _timePeriods).then(d => console.log('Enhancement Efficacy Fetched - By Selection of Time Period', d));
        }
    }

    function onAddRegion(event, state, setState) {
        const aliasedItem = event.target.value
        const item = getRegionReverseAlias(aliasedItem);
        removeItemFromState(item, additionalRegions, setAdditionalRegions);
        addItemToState(item, defaultRegions, setDefaultRegions);
        setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        const _postal = initializePostals(item);
        const _time = initializeTimePeriods(item);
        selectRegion(item, true, _postal, _time);
    }

    function onRemoveRegion(aliasedItem, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        const newDefaultRegions = removeItemFromState(item, defaultRegions, setDefaultRegions)
        addItemToState(item, additionalRegions, setAdditionalRegions, true)
        setSingleKeyTrueInState(newDefaultRegions.map((r) => getRegionAlias(r)), getRegionAlias(newDefaultRegions[0]), setState)
        if(selectedRegion !== newDefaultRegions[0])
        {
            const _postal = initializePostals(newDefaultRegions[0]);
            const _time = initializeTimePeriods(newDefaultRegions[0]);
            selectRegion(newDefaultRegions[0], true, _postal, _time);
        }
    }

    function onSelectRegion(aliasedItem, isChecked, state, setState) {
        const item = getRegionReverseAlias(aliasedItem);
        if(isChecked) {
            const _postal = initializePostals(item);
            const _time = initializeTimePeriods(item);
            selectRegion(item, true, _postal, _time);
            setSingleKeyTrueInState(defaultRegions.map((r) => getRegionAlias(r)), aliasedItem, setState)
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    function onAddPostal(event, state, setState) {
        const aliasedItem = event.target.value;
        const item = getPostalReverseAlias(aliasedItem);
        removeItemFromState(item, additionalPostal, setAdditionalPostal);
        addItemToState(item, defaultPostal, setDefaultPostal);
        setSingleKeyTrueInState(defaultPostal.map((p) => getPostalAlias(p)), aliasedItem, setState);
        selectPostal(item, true);
    }

    function onRemovePostal(aliasedItem, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        const newDefaultPostal = removeItemFromState(item, defaultPostal, setDefaultPostal)
        addItemToState(item, additionalPostal, setAdditionalPostal, true)
        setSingleKeyTrueInState(newDefaultPostal.map((p) => getPostalAlias(p)), getPostalAlias(newDefaultPostal[0]), setState);
        selectPostal(newDefaultPostal[0], true);
    }

    function onSelectPostal(aliasedItem, isChecked, state, setState) {
        const item = getPostalReverseAlias(aliasedItem);
        if(isChecked) {
            selectPostal(item, true);
            setSingleKeyTrueInState(defaultPostal.map((p) => getPostalAlias(p)), aliasedItem, setState);
        }
        else
        {
            addTrueKeyInState(aliasedItem, state, setState);
        }
    }

    function onSelectYear (item, isChecked, state, setState) {
        if(isChecked)
        {
            if(item === selectAllYears)
            {
                const date_range = getDateRangeOfYears(timePeriods);
                selectTimePeriod(date_range, true)
            }
            else
            {
                const date_range = getDateRangeOfYears([item]);
                selectTimePeriod(date_range, true)
            }
            setSingleKeyTrueInState([selectAllYears, ...timePeriods], item, setState);
        }
        else
        {
            addTrueKeyInState(item, state, setState);
        }
    }

    function onClickDatePicker(event, state, setState) {
        setSingleKeyTrueInState([selectAllYears, ...timePeriods], undefined, setState);
    }

    function onSelectDateRange(dates) {

        const start_date = new Date(Date.parse(dates[0])).toISOString();
        const end_date = new Date(Date.parse(dates[1])).toISOString();
        selectTimePeriod([start_date, end_date], true)
    }

    const header = selectedRegion && selectedPostal ? `${getRegionAlias(selectedRegion)} - ${getPostalAlias(selectedPostal)}` : ""

    const populateTable = (data) =>
    {
        const categories = Object.keys(data).filter((cat) => Object.keys(data[cat]).length !== 0);

        const modules = getUniqueValues(categories.map((category) => Object.keys(data[category])))
            .filter((mod) => dqwEnabled || !mod.toLowerCase().includes("dqw"))

        modules
            .sort(alphaNumericSort(undefined, true))
            .sort(predefinedSort(["Composite Total", ...Object.keys(dq_modules)]))

        const subCategories = categories.map((category) => {
            return getUniqueValues(modules.map((module) => Object.keys(data[category][module] ? data[category][module]: {}))).sort()
        })

        const apiData = {}
        categories.forEach((category, cat_index) => {
            apiData[category] = {}
            modules.forEach((module) => {
                apiData[category][module] = {}
                subCategories[cat_index].forEach((subcat) => {
                    apiData[category][module][subcat] = data[category][module] && data[category][module][subcat] ? data[category][module][subcat] : {count: 0, total: 0, reach: 0}
                })
            })
        })

        //calculating sums

        const sumData = {}

        modules.forEach((module) => {
            const category = categories[0];
            const subcategories = subCategories[0];
            let sum = 0;
            subcategories.forEach((subCategory) => sum += apiData[category][module][subCategory].reach)
            sumData[module] = sum;
        })

        //Initializing table data

        let _tableData = categories.map((cat) => {
            return {
                category: cat,
                data: [],
            }
        });

        //filling data from api

        for(let i = 0; i < categories.length; i++)
        {
            let category = categories[i]
            for(let j=0; j < subCategories[i].length; j++)
            {
                let subCategory = subCategories[i][j];
                let module_values = modules.map((module) => Math.round(apiData[category][module][subCategory].count))
                let module_totals = modules.map((module) => Math.round(apiData[category][module][subCategory].total))
                let module_reach = modules.map((module) => Math.round(apiData[category][module][subCategory].reach))
                let module_percents = modules.map((module) => Math.round((apiData[category][module][subCategory].count/(apiData[category][module][subCategory].total ? apiData[category][module][subCategory].total : 1)*100)))
                //Pushing Data into Final Table
                _tableData[i].data.push(
                    {
                        subcategory: subCategory,
                        modules: modules.map((module, m) => ({
                            title: module,
                            value: module_values[m],
                            percent: module_percents[m],
                            reach: module_reach[m],
                            reach_total: sumData[module],
                            total: module_totals[m]
                        })),
                        // values: [...module_values],
                        // percents: [...module_percents],
                        // totals: [...module_totals],
                    }
                )
            }
        }

        const _tableHeaderData = []

        modules.forEach((module) => {
            _tableHeaderData.push({
                header: module,
                total: sumData[module]
            })
        })

        setTableData(_tableData);
        setTableHeaderData(_tableHeaderData);
    }

    return (
        <Paper container>
            <Grid container>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[defaultRegions.map((r) => getRegionAlias(r)), defaultPostal.map((p) => getPostalAlias(p)), [selectAllYears, ...timePeriods]]}
                        onCheckCallbacks = {[onSelectRegion, onSelectPostal, onSelectYear]}
                        headers = {[regionHeader, postalHeader, timeHeader]}
                        colourize = {[false, false, false]}
                        actions = {[X, X, undefined]}
                        actionsData = {[{style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}, undefined]}
                        actionsCallbacks = {[onRemoveRegion, onRemovePostal, undefined]}
                        footers = {[CustomSelect, CustomSelect, MonthRangePicker]}
                        footersData = {[
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_school"),
                                data: additionalRegions.map((r) => getRegionAlias(r)),
                            },
                            {
                                title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_class"),
                                data: additionalPostal.map((p) => getPostalAlias(p)),
                            },
                            {onChangeDate: onSelectDateRange, minDate, maxDate},
                        ]}
                        footersCallbacks = {[{onChange: onAddRegion}, {onChange: onAddPostal}, {onOpen: onClickDatePicker}]}
                        defaultCheckIndices = {[0, 0, 0]}
                        stateCallbacks = {[
                            {
                                state: regionState,
                                setState: setRegionState
                            },
                            {
                                state: postalState,
                                setState: setPostalState
                            },
                            {
                                state: timeState,
                                setState: setTimeState
                            },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9} alignItems={!pageData ? 'center' : ''} container={!pageData} justify={'center'}>
                    {
                        pageData ?
                            <Paper container={"true"} mt={10} mb={5}>
                                <ColouredTypography variant={"body1"} size={"16px"} customcolor={"dimgrey"} textAlign={"center"} mb={5} mx={5}>
                                    {description}
                                </ColouredTypography>
                                <Typography mb={5} variant="h4" gutterBottom align={'center'}>
                                    {header === "" ?
                                        !selectedRegion ?
                                            GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__school_need")
                                            : GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__class_need")
                                        : header
                                    }
                                </Typography>
                                <Box mr={10}>
                                    <Grid container spacing={3} style={{alignItems: "center"}}>
                                        <Grid item xs={12} sm={2} md={2}>
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={10}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    {
                                                        <TableRow>
                                                            <CustomTableCell align="center">
                                                                <DefaultColouredTypography variant={"h6"} customcolor={"white"} size={"12px"}>
                                                                </DefaultColouredTypography>
                                                            </CustomTableCell>
                                                            {
                                                                tableHeaderData.map(
                                                                    (item) => {
                                                                        return (
                                                                            <CustomTableCell align="center">
                                                                                <DefaultColouredTypography variant={"h6"} customcolor={"white"} size={"12px"}>
                                                                                    {getModuleAlias(item.header)}
                                                                                </DefaultColouredTypography>
                                                                            </CustomTableCell>
                                                                        )
                                                                    })
                                                            }
                                                        </TableRow>
                                                    }
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        <CustomTableRow key={"totals"}>
                                                            <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, "enhancement__totals")}</CustomTableCell>
                                                            {
                                                                tableHeaderData.map((item, index) => (
                                                                    <Tooltip arrow={true} placement={"top"} title={<Typography>{GET_APP_LOCALIZE_TEXT(pageLc, "assessment__participants")}</Typography>}>
                                                                        <CustomTableCell align="center">{item.total}</CustomTableCell>
                                                                    </Tooltip>
                                                                ))
                                                            }
                                                        </CustomTableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                    <Spacer my={5}/>
                                    {
                                        tableData.map((item) => {
                                            return <Grid container spacing={3} style={{alignItems: "center"}}>
                                                <Grid item xs={12} sm={2} md={2}>
                                                    <Typography variant="h4" align={'center'}>
                                                        {GET_APP_LOCALIZE_TEXT(pageLc, `enhancement__${createLcKey(item.category)}`, item.category)}
                                                    </Typography>
                                                    <Spacer my={10}/>
                                                </Grid>
                                                <Grid item xs={12} sm={10} md={10}>
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableBody>
                                                            {
                                                                item.data.map((subItem, index) => (
                                                                    <CustomTableRow key={index}>
                                                                        <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, `stats__${createLcKey(subItem.subcategory)}`, subItem.subcategory)}</CustomTableCell>
                                                                        {
                                                                            subItem.modules.map((module) => {
                                                                                return <Tooltip arrow={true} placement={"top"} title={<Typography>{`Participants: ${module.reach}/${module.reach_total}`}</Typography>}>
                                                                                    <CustomTableCell align={"center"}>
                                                                                        {
                                                                                            skill === "Score" ?
                                                                                                <LinearProgressWithLabel value={Math.round(module.percent)} label={Math.round(module.value)}/>
                                                                                                :
                                                                                                <LinearProgressWithLabel value={Math.round(module.percent)} symbol={'%'}/>
                                                                                        }
                                                                                        {/*<LinearProgressWithLabel value={isNaN(value) ? 0 : value} percent={percent}/>*/}
                                                                                    </CustomTableCell>
                                                                                </Tooltip>
                                                                            })
                                                                        }
                                                                    </CustomTableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                        <Spacer my={10}/>
                                                    </Table>
                                                </Grid>
                                            </Grid>
                                        })
                                    }
                                    {/*<Box align={"right"}>*/}
                                    {/*    <Button variant="contained" alignSelf={"right"} onClick={onClickDownload}>*/}
                                    {/*        Download Data*/}
                                    {/*    </Button>*/}
                                    {/*</Box>*/}
                                </Box>
                            </Paper>
                        :
                            <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default GenericEfficacyTab;