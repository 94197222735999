import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../../utils/dq_lc_service/lc_service";
import {X, Clipboard} from "react-feather";

import {makeStyles, withStyles} from "@material-ui/core/styles";

import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Popper,
} from "@material-ui/core";

import {Paper, Typography, Box, Card, Spacer} from "../../../../components/StyledComponents/styledComponents"

import {
    copyToClipboard,
    getMapFromObjectListByKey,
    getUniqueValues
} from "../../../../utils/utils";
import {getResetPassToken} from "../../../../services/employees.service";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import EnhancedTableCell from "./EnhancedTableCell.comp";
import {getStudents} from "../../../../services/school.service";
import XLSX from "xlsx";
import Grid from "@material-ui/core/Grid";
import AddSchoolAccountModal from "./AddSchoolAccountModal.comp";
import {addSchoolDashboardAccount} from "../../../../services/misc.service";

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const schoolSelector = "school";
const classSelector = "class";
const studentSelector = "student";

const useStyles = makeStyles((theme) => ({
    paper_style_for_table: {
        display: "block",
        overflowX: "auto",
        whiteSpace: "nowrap"
    },

    d_act_btn: {
        marginTop: '0px',
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },

    copy_act_btn: {
        marginTop: '0px',
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },

    [schoolSelector]: {
        backgroundColor: "#4c7cf4",
        color: "white",
        '&:hover': {
            backgroundColor: "#83a7f7",
        },
    },
    [classSelector]: {
        backgroundColor: "#9ccc7c",
        color: "white",
        '&:hover': {
            backgroundColor: "#bbdbab",
        },
    },
    [studentSelector]: {
        backgroundColor: "#4c7cf4",
        color: "white",
        '&:hover': {
            backgroundColor: "#83a7f7",
        },
    },
    school_add_account: {
        marginLeft: "5px",
        backgroundColor: "#4c7cf4",
        color: "white",
        '&:hover': {
            backgroundColor: "#83a7f7",
        },
    }
}));

const headCells = (locale) => ({
    [schoolSelector]: [
        { id: "school_name", label: GET_APP_LOCALIZE_TEXT(locale, "progress__school_name"), alignment: "left", sort: true },
        { id: "school_level", label: GET_APP_LOCALIZE_TEXT(locale, "progress__school_lvl"), alignment: "left", sort: true },
        { id: "students_count", label: GET_APP_LOCALIZE_TEXT(locale, "progress__student_count"), alignment: "left", sort: true },
    ],
    [classSelector]: [
        { id: "class_name", label: GET_APP_LOCALIZE_TEXT(locale, "progress__class_name"), alignment: "left", sort: true },
        { id: "class_level", label: GET_APP_LOCALIZE_TEXT(locale, "progress__class_lvl"), alignment: "left", sort: true },
        { id: "students_count", label: GET_APP_LOCALIZE_TEXT(locale, "progress__student_count"), alignment: "left", sort: true },
    ],
    [studentSelector]: [
        { id: "student_id", label: GET_APP_LOCALIZE_TEXT(locale, "progress__student_id"), alignment: "left", sort: true },
        { id: "username", label: GET_APP_LOCALIZE_TEXT(locale, "general__username"), alignment: "left", sort: true },
        { id: "last_name", label: GET_APP_LOCALIZE_TEXT(locale, "general__last_name"), alignment: "left", sort: true },
        { id: "first_name", label: GET_APP_LOCALIZE_TEXT(locale, "general__first_name"), alignment: "left", sort: true },
    ]
})

const buttonTitles = (locale) => ({
    [schoolSelector]: GET_APP_LOCALIZE_TEXT(locale, "progress__school_view_detail"),
    [classSelector]: GET_APP_LOCALIZE_TEXT(locale, "progress__class_download_data"),
    [studentSelector]: GET_APP_LOCALIZE_TEXT(locale, "progress__student_view_detail")
})

const temp_reset_pass_links = {}

function ProgressTable(props) {
    const {org_code = undefined, school = undefined, data: apiData = undefined, fetchSortedData} = props;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const memoHeadCells = useMemo(() => headCells(pageLc), [])
    const memoButtonTitles = useMemo(() => buttonTitles(pageLc), []);

    const [selector, setSelector] = useState(undefined);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState(undefined);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [records, setRecords] = useState([]);
    const [recordCount, setRecordCount] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [headers, setHeaders] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [metadata, setMetadata] = useState(undefined);

    const [isPopperOpened, setIsPopperOpened] = useState(false);
    const [popperAnchorEl, setPopperAnchorEl] = useState(null);
    const [popperInfo, setPopperInfo] = useState(null);
    const [popperMsg, setPopperMsg] = useState(null);

    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const active_school_name_for_modal = useRef('');

    const [tooltipText, setTooltipText] = useState(GET_APP_LOCALIZE_TEXT(pageLc, "general__copy"));

    async function onClickButton(event, record) {
        closePopper(); //close previously opened popper
        if(selector === schoolSelector || selector === studentSelector)
        {
            setPopperAnchorEl(event.currentTarget)
            setIsPopperOpened(true);
            setPopperInfo(record);
        }
        else
        {
            const {class_name, students_count} = record;
            setLoading(true);
            try {
                const results = await getStudents(org_code, school, class_name, 0, students_count, "asc");
                if(results.data)
                {
                    const {programs: local_programs, data} = results.data.data.data;
                    generateClassExcel(class_name, local_programs, data);
                }
            }
            catch (e) {
                console.log(e);
            }
            setLoading(false);
        }
    }

    function PopperContent() {
        let component = <></>
        if(selector && popperInfo) {
            const record = popperInfo;
            const extras = record.extras;
            const link_in_memory = temp_reset_pass_links[record.user_id];
            component = <>
                <Typography align={'center'} font_weight={'bold'}>
                    {GET_APP_LOCALIZE_TEXT(pageLc, `progress__${selector}_info`)}
                </Typography>
                <Spacer py={2}>
                    {
                        extras && Array.isArray(extras) && extras.length > 0 ?
                            extras.map((object) => <Typography>{`${object.field}: ${object.value ? object.value : ""}`}</Typography>)
                            : selector === schoolSelector ? <Typography font_color={'grey'} align={'center'}>{GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable")}</Typography> : null
                    }
                </Spacer>
                {
                    selector === studentSelector ?
                            <Box textAlign={'center'}>
                                <Button variant={'contained'} color={'primary'} disabled={Boolean(link_in_memory)} onClick={() => resetStudentPass(record)}>
                                    {!link_in_memory ? GET_APP_LOCALIZE_TEXT(pageLc, "progress__reset_pass") : GET_APP_LOCALIZE_TEXT(pageLc, "progress__link_gen")}
                                </Button>
                                <Tooltip
                                    title={tooltipText}
                                    placement={'top'}
                                    arrow={true}
                                    onClose={() => setTooltipText(GET_APP_LOCALIZE_TEXT(pageLc, "general__copy"))}
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={
                                            () => copyToClipboard(link_in_memory)
                                                .then(() => setTooltipText(GET_APP_LOCALIZE_TEXT(pageLc, "general__copied")))
                                                .catch(() => setTooltipText(GET_APP_LOCALIZE_TEXT(pageLc, "general__unable_copy")))
                                        }
                                        disabled={!Boolean(link_in_memory)}>
                                        <Clipboard/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        : null
                }
                <Spacer>
                    <Typography align={'center'}>{popperMsg}</Typography>
                </Spacer>
            </>
        }
        return component;
    }

    async function resetStudentPass({user_id})
    {
        setLoading(true);
        try {
            const result = await getResetPassToken(org_code, user_id)
            if(result.data) {
                const {link} = result.data;
                temp_reset_pass_links[user_id] = link;
                setPopperMsg(GET_APP_LOCALIZE_TEXT(pageLc, "progress__clipboard_to_copy"))
            }
        }
        catch (e) {
            console.log(e);
        }
        setLoading(false);
    }

    function generateClassExcel(class_name, local_programs, data) {
        const local_headers = memoHeadCells[studentSelector];
        const extra_fields = getUniqueValues(data.map((item) => item.extras.map((e) => e.field)))

        const final_array = [
            ["Class:", class_name],
            [...local_headers.map(h => h.label), ...extra_fields, ...local_programs.map(p => `${p} Completion Rate`)]
        ]

        const items_array = data.map((item) => {
            const programs_object = item.completion_rates
            const extras_object = getMapFromObjectListByKey(item.extras, "field")
            const headers_values = local_headers.map(header => item[header.id]);
            const programs_values = local_programs.map(program => programs_object[program] ? `${programs_object[program]}%` : "0%")
            const extra_values = extra_fields.map(field => extras_object[field] ? extras_object[field].value ? extras_object[field].value : "" : 0)
            return [...headers_values, ...extra_values, ...programs_values]
        })

        final_array.push(...items_array);

        const workbook = XLSX.utils.book_new();
        let column_widths = []

        final_array.forEach((array) => {
            array.forEach(((item, index) => {
                const item_length = item ? item.toString().length : 0;
                column_widths[index] =  column_widths[index] ? Math.max(item_length, column_widths[index], 10) : item_length
            }))
        })

        const worksheet = XLSX.utils.aoa_to_sheet(final_array);
        worksheet["!cols"] = new Array(column_widths.length).fill(null).map((_, index) => {
            return { wch: column_widths[index] }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "Students");
        XLSX.writeFile(workbook, `Students List - ${class_name}.xlsx`);
    }

    function closePopper() {
        setIsPopperOpened(false);
        setPopperAnchorEl(null);
        setPopperInfo(null);
        setPopperMsg(null);
    }

    useEffect(() => {
        closePopper();
        if(apiData)
        {
            const {
                selector: _selector,
                page: _page,
                rowsPerPage: _rowsPerPage,
                order: _order,
                orderBy: _orderBy,
                programs: _programs,
                metadata: _metadata,
                total_records,
                data
            } = apiData;

            setSelector(_selector)
            setPage(_page)
            setRowsPerPage(_rowsPerPage)
            setOrder(_order)
            if(_orderBy === undefined)
            {
                setOrderBy(memoHeadCells[_selector][0].id)
            }
            setHeaders(memoHeadCells[_selector]);
            setPrograms(_programs);
            setRecordCount(total_records);
            setRecords(data);
            setMetadata(_metadata);
        }
    }, [apiData])

    useEffect(() => {
        setLoading(false);
    }, [apiData])

    useEffect( () => {
        if(firstLoad)
        {
            setFirstLoad(false);
        }
        else
        {
            setLoading(true);
        }
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const sortDirection = isAsc ? "desc" : "asc"
        setOrder(sortDirection);
        setOrderBy(property);
        fetchSortedData(page, rowsPerPage, sortDirection, property)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchSortedData(newPage, rowsPerPage, order, orderBy)
    };

    const handleChangeRowsPerPage = (event) => {
        const rpp = parseInt(event.target.value, 10)
        setRowsPerPage(rpp);
        setPage(0);
        fetchSortedData(0, rpp, order, orderBy);
    };

    const addSchoolPortalUserService = async (email) => {

        setLoading(true);
        try {
            const results = await addSchoolDashboardAccount(org_code, email, active_school_name_for_modal.current);
            if(results.data && results.data.created)
            {
                const updatedRecords = records.map(record =>
                    record.school_name === active_school_name_for_modal.current
                        ? { ...record, extras: results.data.school_updated_details }
                        : record
                );
                setRecords(updatedRecords);
            }
        }
        catch (e) {
            console.log(e);
        }
        setLoading(false);

        setShowAddUserModal(false);
    }

    const addSchoolAccountBtnClick = (record) => {
        active_school_name_for_modal.current = record.school_name;
        setShowAddUserModal(true);
    }

    function capitalizeFirstLetter(string) {
        if(typeof string === "string")
        {
            return string.charAt(0).toUpperCase() + string.slice(1)
        }
        return string;
    }

    return (
        <div>
            <Card mb={6}>
                {
                    metadata && Array.isArray(metadata) && metadata.length > 0 ?
                        <Spacer pb={5}>
                            {
                                metadata.map((obj) => <Typography font_weight={'bold'}>{obj.field}: <Typography component={'span'}>{obj.value}</Typography></Typography>)
                            }
                        </Spacer>
                    : null
                }
                <Paper>
                    <Popper open={isPopperOpened} anchorEl={popperAnchorEl} placement={'left'}>
                        <Paper elevation={3} style={{border: `thin solid black`}} >
                            <Box textAlign={'right'} >
                                <X style={{cursor: "pointer"}} onClick={closePopper}/>
                            </Box>
                            <Spacer px={5} pb={5}>
                                <PopperContent/>
                            </Spacer>
                        </Paper>
                    </Popper>
                    <TableContainer>
                        <Table size={"medium"}>
                            <TableHead>
                                <TableRow>
                                    {
                                        headers.map(() => <CustomTableCell/>)
                                    }
                                    <CustomTableCell align={'center'} colSpan={programs.length}>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__completion_rate")}
                                    </CustomTableCell>
                                    <CustomTableCell/>
                                </TableRow>
                                <TableRow>
                                    {
                                        headers.map((item) =>
                                            <EnhancedTableCell
                                                id={item.id}
                                                alignment={item.alignment}
                                                disablePadding={item.disablePadding}
                                                sort={item.sort}
                                                orderBy={orderBy}
                                                order={order}
                                                onRequestSort={handleRequestSort}
                                            >
                                                {item.label}
                                            </EnhancedTableCell>
                                        )
                                    }
                                    {
                                        programs.map((program) =>
                                            <EnhancedTableCell
                                                id={`completion_rates.${program}`}
                                                alignment={"left"}
                                                sort={true}
                                                orderBy={orderBy}
                                                order={order}
                                                onRequestSort={handleRequestSort}
                                            >
                                                {program}
                                            </EnhancedTableCell>
                                        )
                                    }
                                    <CustomTableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    records.map((record) => {
                                        const programs_object = record.completion_rates;
                                        return <CustomTableRow>
                                            {
                                                headers.map((header) => header.id)
                                                    .map((header) => <TableCell>{record[header]}</TableCell>)
                                            }
                                            {
                                                programs.map((program) => programs_object[program] ? programs_object[program] : 0)
                                                    .map((value) => <TableCell>{Math.round(value)}%</TableCell>)
                                            }
                                            <TableCell>
                                                <Button variant="contained" className={classes[selector]} onClick={(event) => onClickButton(event, record)}>
                                                    {
                                                        memoButtonTitles[selector]
                                                    }
                                                </Button>
                                                {
                                                    (selector === schoolSelector) && <Button
                                                        variant="contained" className={classes.school_add_account}
                                                        onClick={ () => addSchoolAccountBtnClick(record) }
                                                    >
                                                        {GET_APP_LOCALIZE_TEXT(pageLc, "progress__school_add_account")}
                                                    </Button>
                                                }

                                            </TableCell>
                                        </CustomTableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={recordCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={`${GET_APP_LOCALIZE_TEXT(pageLc, 'general__rows_per_page')}:`}
                    />
                </Paper>
            </Card>
            <AddSchoolAccountModal
                open={showAddUserModal}
                createAccountCallback={addSchoolPortalUserService}
                handleClose={ () => setShowAddUserModal(false) }
            />
            <LoaderWithBackDrop loading={loading} />
        </div>
    );
}

export default ProgressTable;