import axios from 'axios';
import { store } from 'react-notifications-component';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {refreshToken} from "../services/auth.service";

axios.defaults.headers.post['Content-Type'] = 'application/json';
const base_uri = process.env.REACT_APP_MYDQ_API;

const toastError = (message) => {
    store.addNotification({
        title: "Server Response!",
        message,
        type: "warning",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 2000,
            showIcon: true
        }
    });
}

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(promise => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });

    failedQueue = [];
};

function invokeApiWihToken(reqParams, token) {
    const {headers} = reqParams;
    const modifiedHeaders = {...headers, 'Authorization' : `Bearer ${token}`}
    const modifiedParams = {...reqParams, headers: modifiedHeaders};
    return invokeApi(modifiedParams);
}

function retryRequestWithRefresh(reqParams) {
    return new Promise((resolve, reject) => {
        refreshToken() // Make a request to refresh the token
            .then(response => {
                const {auth_token, user, org_object} = response.data;
                user.org_obj = org_object;
                localStorage.setItem(LOCAL_STORAGE_KEYS.auth_token, JSON.stringify(auth_token));
                localStorage.setItem(LOCAL_STORAGE_KEYS.auth_user, JSON.stringify(user));
                processQueue(null, auth_token); // Handle queued requests
                resolve(invokeApiWihToken(reqParams, auth_token));
            })
            .catch(err => {
                processQueue(err, null); // Handle queued requests
                reject(err);
            })
            .finally(() => {
                isRefreshing = false; // Reset flag
            });
    });
}

function putRequestInQueue(reqParams) {
    return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
    })
        .then(token => invokeApiWihToken(reqParams, token)) // Retry request with new token
        .catch(err => Promise.reject(err));
}

function forceLogout() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_token);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.auth_user);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.refresh_token);
    window.location.reload();
}

function getRefreshToken() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.refresh_token));
}

export async function invokeApi(reqParams) {

    const {
        path,
        method = 'GET',
        headers = {},
        queryParams = {},
        postData = {},
        ...rest
    } = reqParams;

    let reqObj = {
        method  : method,
        url     : base_uri + path,
        headers : headers,
        ...rest
    };

    reqObj['params'] = queryParams;

    if (method === 'POST') {
        reqObj['data'] = postData;
    }

    let results = undefined;

    try {
        results = await axios(reqObj);
        return results.data;
    } catch (error) {

        if (error.response.status === 499) throw new Error('Permission denied');

        if(error.response.status === 401) {
            forceLogout();
            throw new Error('Unauthorized');
        }

        if(error.response.status === 419) {
            if (!getRefreshToken()) {
                forceLogout();
                throw new Error('Unauthorized');
            }
            if (isRefreshing) return putRequestInQueue(reqParams);
            isRefreshing = true;
            return retryRequestWithRefresh(reqParams);
        }

        if (!(error.response && error.response.data && error.response.data.message)) {
            toastError('NETWORK ERROR : Some Thing Went Wrong!');
            throw new Error('NETWORK ERROR : Some Thing Went Wrong!');

        } else {
            toastError(error.response.data.message);
            throw new Error(error.response.data.message);
        }
    }
}
