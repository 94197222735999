export const micro_badges = {
    "DQ1.1": {
        code: "KnowOnlinePersona",
        full_name: "Online Personas",
        definition: "You understand that you are not only a citizen of the physical world but also a digital citizen once you are connected. You know how your online persona is built through your digital activities which can shape not only your digital identity but also how others perceive about you in the real world. You know how to create and manage one or multiple digital identities, to be able to protect one's own reputation."
    },
    "DQ1.2": {
        code: "DCIntegrity",
        full_name: "Digital Integrity",
        definition: "You understand how technology (e.g. social media influencers and ads) can affect your self-image and values. You have healthy self-esteem as a digital citizen without unnecessary social comparisons or objectifying ideals in social media. You build a healthy and congruent identity in both online and offline with integrity."
    },
    "DQ1.3": {
        code: "DCGlobal",
        full_name: "Global Digital Citizenship",
        definition: "You understand that you are a global citizen in the digital world that transcends physical boundaries and that computer networks can be used to connect people to other people, places, information, and ideas.  You have global awareness and netiquette while being socially and emotionally aware and being open to understand different views with cultural sensitivity online. This can include adapting your communications depending on your audience and being mindful of behavioral norms."
    },
    "DQ2.1": {
        code: "PersonalDigitalHabit",
        full_name: "Health Check - Digital Use ",
        definition: "You have healthy digital habits without idly over-spending time on screen for entertainment or illegally access to digital media."
    },
    "DQ2.2": {
        code: "TechAddictRisk",
        full_name: "Health Check - Tech Addiction",
        definition: "You do not show the symptoms of technology addiction such as gaming disorder or social media disorder."
    },
    "DQ2.3": {
        code: "KnowledgeMinUnbal",
        full_name: "Digital Use Effects",
        definition: "You understand the nature and harmful effects of unbalanced digital use such as excessive screen time, multitasking, or technology addiction on your mental and physical health, well-being, relationship, and work performance."
    },
    "DQ2.4": {
        code: "MindfulDigitalUse",
        full_name: "Mindful Digital Use",
        definition: "You exhibit good skills, attitudes and behaviors toward using technology in mindful and purposeful way while prioritizing their health, relationship, real-life activities, and well-being over digital use. "
    },
    "DQ2.5": {
        code: "BalTimeMgmt",
        full_name: "Self-Regulated Time Management",
        definition: "You are able to manage on-/off-line time with self-regulation. You are disciplined with clear strategies to balance digital use using various tools (e.g. screen time limiting app, alarm-setting) and rules (e.g. device-free zones, family media rule) for your well-being."
    },
    "DQ2.6": {
        code: "AttentionEconomy",
        full_name: "Attention and Technology ",
        definition: "You understand technology companies’ tactics in terms of how their business model utilizes users’ attention, and how this can negatively affect your health and well-being. "
    },
    "DQ3.1": {
        code: "KnowledgeCyberBullying",
        full_name: "Knowledge of Behavioral Cyber-Risks",
        definition: "You know about various types of cyberbullying and other behavioral cyber-risks such as online trolls, cyber harassment, stalking and how to identify them. You understand how these risks can be started and escalated in online communities and how they can harm people who are involved in the incidences."
    },
    "DQ3.2": {
        code: "SkillsDealCB",
        full_name: "Skills to Deal with Behavioral Cyber-Risks",
        definition: "You are able to deal with cyberbullying and other behavioral cyber-risks incidences with technical, cognitive, socio-emotional, and communicative skills. You can be socially and emotionally resilient to cope with negative online experiences without escalating the situation."
    },
    "DQ3.3": {
        code: "AttCB",
        full_name: "Attitudes Relating to Behavioral Cyber-Risks",
        definition: "You are not tolerant of cyber-bullying and other behavioral cyber-risks. You don’t consider them enjoyable, funny, or justifiable in any circumstances either in individual circles or in online communities you belong to."
    },
    "DQ3.4": {
        code: "BehCyberRisks",
        full_name: "Health Check -  Behavioral Cyber-Risks",
        definition: "You have not been involved with cyber-bullying or other behavioral cyber-risks such as online trolls, cyber harassment, stalking."
    },
    "DQ3.5": {
        code: "UpstandingCulture",
        full_name: "Upstanding",
        definition: "You understand that your behaviors create an online culture and that you must show a willingness to behave with kindness to others. This includes intentions and skills towards being upstanding when you see other treated badly online."
    },
    "DQ3.6": {
        code: "OrgBCRCulture",
        full_name: "Organzational Culture - Behavioral Cyber-Risks",
        definition: "You understand acceptable use in your organizations (e.g. schools, companies) that includes organizational rules and polices on behavioral cyber-risks (e.g. cyberbullying, rules about what you can and can't do online using a work computer, rules about using social media sites). You also understand the consequences of violating acceptable use."
    },
    "DQ4.1": {
        code: "KnowBasicPCS",
        full_name: "Basic Personal Security",
        definition: "You understand different types of cyber threats (e.g., hacking, scams, and malware), how to identify and distinguish them and know available strategies and tools to avoid them to protect your personal mobile, devices, and data. You can explain what computer viruses are, what Internet security is, and how to secure his data. You understand the risks and threats posed in various digital environments and know how use the Internet safely."
    },
    "DQ4.3": {
        code: "DealPCS",
        full_name: "Dealing with Cyber-Threats",
        definition: "You are skillful in how to identify, avoid, and deal with different personal cyber-threats and protect your data with various strategies, practices, and tools to secure your data and devices."
    },
    // "DQ4.3": {
    //     code: "AwarePersonalCSRisk",
    //     full_name: "Health Check – Cyber Threats",
    //     definition: "You have good digital behaviors and attitudes toward managing your own cyber security which protect you from being exposed to various personal cyber-threats."
    // },
    "DQ4.4": {
        code: "IoTRiskImpact",
        full_name: "Understanding IoT Risks",
        definition: "You have a good understanding of what is meant by the internet of things, what sorts of devices are connected to the internet of things and the associated risks. "
    },
    "DQ4.5": {
        code: "DealIoTRisks",
        full_name: "Dealing with IoT Risks",
        definition: "You understand and show good practice in how to mitigate risks associated with the internet of things. "
    },
    "DQ5.1": {
        code: "UnderEmpathy",
        full_name: "Online Empathy ",
        definition: "You understand what empathy is and how your online interactions might affect others’ feelings online. You are aware of, and sensitive to others’ feelings and needs in online communications even without face-to-face interactions."
    },
    "DQ5.2": {
        code: "SelfEmotion",
        full_name: "Self-Awareness",
        definition: "You are sensitive to and aware of your own emotions and are able to regulate them in online interactions."
    },
    "DQ5.3": {
        code: "PerspectiveTaking",
        full_name: "Perspective Taking",
        definition: "You are capable of considering different perspectives of others and exercising the Golden Rule – “do to others as you want to be treated” in online interactions."
    },
    "DQ5.4": {
        code: "OnlineSoftSkills",
        full_name: "Online Kindness",
        definition: "You are supportive of others’ feelings, needs and concerns by being kind and empathetic online. You can wisely speak up for others with compassion and courage without escalating cyber-risks."
    },
    "DQ6.1": {
        code: "KnowDFMLife",
        full_name: "Basic Digital Footprints",
        definition: "You understand what the digital footprints are, and how they are created, shaped and used."
    },
    "DQ6.2": {
        code: "ImpactDFMLife",
        full_name: "Digital Footprints Impact",
        definition: "You know your digital footprints can be permanent, tracked, collected, and go viral, even against of your will. You understand that your digital footprints can affect your reputation in real-life."
    },
    "DQ6.3": {
        code: "ControlDFMLife",
        full_name: "Controlling Digital Footprints ",
        definition: "You are aware that emotions may affect people’s online behaviors and stop and think before reacting to negative online information. You are aware of your own digital footprint management abilities and intend to further improve your skills to check, monitor, and combat negative digital footprints through various tactics."
    },
    "DQ6.4": {
        code: "AttDFMLife",
        full_name: "Caring About Digital Reputation",
        definition: "You consider it important to build positive digital footprints, so that you can build positive reputation online. You intend to control your negative digital footprints and build up your positive digital footprints. "
    },
    "DQ6.5": {
        code: "ImpactDFMWork",
        full_name: "Digital Footprints Impact (Company)",
        definition: "You understand your that online activities using your company’s IT infrastructure can be traceable and your digital footprints can shape and affect your company’s reputation. You don’t conduct personal online activities or risky behaviour on company computers and networks."
    },
    "DQ6.6": {
        code: "MitNegDFWork",
        full_name: "Mitigating Social Media Crisis (Company)",
        definition: "You know what to and what not to share in a professional context while being able to identify online information that can affect your company’s reputation. When you encounter negative digital footprints of your company, you know and follow company policies to deal with them and effectively mitigate potential social media crises."
    },
    "DQ6.7": {
        code: "AttDFMWork",
        full_name: "Positive Digital Reputation (Company)",
        definition: "You care about your company’s digital footprints. You have actively managed your company’s digital footprints and intend to maintain high standards even in difficult situations. Alongside your co-workers you support the positive digital reputation of your company."
    },
    "DQ7.1": {
        code: "SearchInfo",
        full_name: "Searching  Information",
        definition: "You have the ability to discuss, navigate and perform basic and advanced internet search strategies and find information from various sources. You know how to search for information on the Internet through search engines, electronic encyclopedias, information services to locate information and other resources for intellectual or creative pursuits."
    },
    "DQ7.2": {
        code: "EvaluateInfoSkills",
        full_name: "Evaluating Information ",
        definition: "You are able to analyze, compare, and critically evaluate digital information such as media content, news and video by understanding their reliability and credibility while discerning bias and dis-/mis-information. You can assess the relevancy and usefulness of information and its sources. \n"
    },
    "DQ7.3": {
        code: "InfluenceDigitalInfo",
        full_name: "Influence of Digital Information ",
        definition: "You know how digital information are created on online platforms, how the use of digital information influences the construction of knowledge and public opinion, and how digital information can be potentially misused. You have the ability to prevent spreading mis-/dis-information and use digital information positively influence the online communities."
    },
    "DQ7.4": {
        code: "KnowBizModel",
        full_name: "Business Models of Digital Platforms",
        definition: "You understand business models of digital platforms and psychological tricks such as purchasing freemium models and speeding up progress by using in-game currency which is real money."
    },
    "DQ8.1": {
        code: "PersonalInfo",
        full_name: "Personal Information",
        definition: "You understand what personal information is, how personal information and privacy need to be protected as your human right, and how your personal information is used, stored, processed, and shared online. You are able to \n protect yourself and others from related damage and harm."
    },
    "DQ8.2": {
        code: "KnowPISafe",
        full_name: "Privacy Protection",
        definition: "You are capable of keeping your own and others’ personal information safe, and can protect your privacy through various strategies, tools and technology such as privacy settings, limiting risky social exposure and anonymity."
    },
    "DQ8.3": {
        code: "AttPrivacy",
        full_name: "Privacy Attitudes",
        definition: "You care about your own and others’ personal data and privacy online. While having critical eyes on privacy options provided by technology platforms, you are willing to take steps to protect your own and others’ personal information at all cost. You understand that digital services use a privacy policy and use this to inform your own online behaivors. "
    },
    "DQ8.4": {
        code: "AttProPrivacy",
        full_name: "Health Check: Privacy",
        definition: "You are can safeguard your own and others’ personal information online while limiting risky social exposure or violations of other’s privacy online."
    },
    "DQ8.5": {
        code: "CompConf",
        full_name: "Confidential Information (Company)",
        definition: "You understand the difference between your own personal information and company-related confidential information. You know and follow company policies for dealing with confidential information"
    },
    "DQ8.6": {
        code: "PrivEthics",
        full_name: "Privacy and Society",
        definition: "You understand the trade-offs between allowing information to be public and keeping information private and secure. You can explain the privacy concerns related to the collection and generation of data through automated processes that may not be evident to users. The country has adopted legistration related to privacy and data protection."
    },
    "DQ9.1": {
        code: "TechAdopt",
        full_name: "Technology Trends and Adoption",
        definition: "You understand how to keep up with advancements in information and communication technology and can integrate digital technologies into your everyday life. You are open to experimentation but can be discerning in when to reject technologies. "
    },
    "DQ9.2": {
        code: "CogCreateProcessing",
        full_name: "Cognitive and Creative Processing",
        definition: "You use digital tools and technologies to create knowledge and to innovate processes and products. You engage individually and collectively in cognitive processing to understand and resolve conceptual problems and problem situations in digital environments. In addition, you develop and build higher-order thinking and reasoning skills that further aid your capacity to connect with others."
    },
    "DQ9.3": {
        code: "SolveProblems",
        full_name: "Solving Real-World Problems",
        definition: "You can identify complex, interdisciplinary, real-world problems that can be solved computationally and can integrate digital technologies into everyday life in a way that is complementary and productive rather than disruptive. You build on existing ideas and further co-develop new ideas to solve them using technology. In addition, you decompose complex real-world problems into manageable subproblems and can integrate existing solutions or procedures. You can evaluate whether it is appropriate and feasible to solve a problem computationally. You can reimagine and rethink existing digital tools and content; produce creative solutions and ideas that positively impact communities as well as spur meaningful experience through digital means."
    },
    "DQ9.4": {
        code: "LifeLearningDL",
        full_name: "Life-Long Digital Learning",
        definition: "You see yourself as a life-long learner and continuously learn and generate new ideas to solve problems efficiently. You express self-motivation and resourcefulness when using technology and know when and how to deploy and allocate your time, efforts, and resources. You have ability to learn and develop economic competencies to accomplish goals in professional life."
    },
    "DQ9.5": {
        code: "R&D",
        full_name: "Research & Development",
        definition: "You are actively involved in the research and development for technology advancement with the proper investment and support from government and industries. "
    },
    "DQ10.1": {
        code: "DigitalWellBeing",
        full_name: "Technology and Well-Being",
        definition: "You understand wellbeing with regard to your digital use and understand the contexts that shape discourses about the impact of technology on well-being.  Ability to access information and understand the consequences of digital consumption to ensure better physical and mental health."
    },
    "DQ10.2": {
        code: "ErgonomicTechUse",
        full_name: "Ergonomic Use of Technology",
        definition: "You use technology ergonomically and understand that physiological awareness helps users identify safe, comfortable practices and equipment for mentally and physically beneficial work processes"
    },
    "DQ10.3": {
        code: "ValuingHealth",
        full_name: "Valuing Health Through Digital Use",
        definition: "You value mental and physical health and actively self-regulate use of technology in a healthy way. You are able to discern how to effectively use technology for your own benefit. "
    },
    "DQ10.4": {
        code: "HealthCare",
        full_name: "Healthcare and social wellbeing",
        definition: "Assert organizational and community practices that promote and ensure healthy use of digital tools and online platforms for improving individual and social wellbeing"
    },
    "DQ11.1": {
        code: "UnderRiskyContact",
        full_name: "Understanding Contact Cyber-Risks",
        definition: "You understand risky contacts faced online (e.g. online grooming, extremists, unwanted sexual contact)."
    },
    "DQ11.2": {
        code: "DealRiskyCC",
        full_name: "Dealing with Risky Contact",
        definition: "You are able to identify, evaluate, and manage and risky contacts (e.g. online grooming, extremists, unwanted sexual contact) with various strategies and technical tools while understanding their negative impact on your well-being, safety, and mental health."
    },
    "DQ11.3": {
        code: "ExpRiskyContact",
        full_name: "Health Check – Risky Contact ",
        definition: "You have not actively been involved nor intend to be involved with risky contacts such as online grooming, extremism or unwanted sexual contact online."
    },
    "DQ12.1": {
        code: "BasicNetSec",
        full_name: "Basic Network Security Technology",
        definition: "\tYou can explain what encryption is, different types and the most commonly used types of encryption, how it works and how it can be bypassed. You understand the connection between passwords and their encrypted formats. You can create encryption keys and use them to encrypt and decrypt data. You understand when encryption is necessary and grasp the history and previous guises of encryption. "
    },
    "DQ12.2": {
        code: "SecureProtocol",
        full_name: "Secure Internet Protocols",
        definition: "You can explain the basics of the organization and functioning of computer networks and  distinguish between the types of Internet protocols and their purpose. You can understand and explain what secure protocols on the Internet are and how they work. You understand that secure protocols are at the heart of all tasks within IT and are capable of bringing secure protocols to all aspects of work. "
    },
    "DQ12.3": {
        code: "HumanTechSystem",
        full_name: "Human-Tech System",
        definition: "You understand how the interaction between humans and computing devices presents advantages, disadvantages, and unintended consequences. You understand that computing devices are often integrated with other systems, including biological, mechanical, and social systems. You understand that the usability, dependability, security, and accessibility of these devices, and the systems they are integrated with are important considerations in their design as they evolve."
    },
    "DQ12.4": {
        code: "NetworkSec",
        full_name: "Network Security",
        definition: "You understand that network security depends on a combination of hardware, software, and practices that control access to data and systems and can use existing network security systems. You understand the needs of users and the sensitivity of data determine the level of security implemented. You know how policies and processes are implemented to achieve security or prevent unauthorised access. You possess an awareness of the most well-known types and most famous successful cyber-attacks and the best practices for responses at the network level. "
    },
    "DQ12.5": {
        code: "NetWeak",
        full_name: "Identifying Network Weaknesses",
        definition: "You can predict and identify weaknesses and risks in networks that leave them vulnerable to cyber threats. You evaluate vulnerabilities, quantify associated risks, employ tools, strategies, and protocols to help ensure and improve the confidentiality and security of your collaborative work. Additionally, you monitor networks and systems and implement support systems to allow for optimum productivity and performance. Your security tools and strategies are aligned with your organization’s security framework, guidelines, and technical requirements to help ensure minimal impact to your business. "
    },
    "DQ13.1": {
        code: "EmotionTech",
        full_name: "Technology and Emotion",
        definition: "You understand how technology can affect users’ emotional state and moods as well as  their behaviors and values. "
    },
    "DQ13.2": {
        code: "EmotionReg",
        full_name: "Technology and Emotional Regulation",
        definition: "You exhibit an awareness of your moods and are actively able to manage impulses. You create strategies for managing your emotions and demonstrate self-regulation while respecting others during online communication. "
    },
    "DQ13.3": {
        code: "RefFeelings",
        full_name: "Reflecting on Feelings and Values",
        definition: "You understand how their your own value systems influence and are influenced by their your digital life, and become equipped to explain how one’s mood can affect others. You identify and explain their your emotions, and reflect on how feelings can be influenced by digital experiences."
    },
    "DQ13.4": {
        code: "EmotionMgmt",
        full_name: "Goals and Emotional Management",
        definition: "Individuals manage their emotions as well as competencies to foster cooperation and positive interactions between internal and external stakeholders in order to fulfil your goals. You actively work to manage and update your emotional skillset "
    },
    "DQ14.1": {
        code: "CoConstruction",
        full_name: "Co-Construction",
        definition: "You use digital tools and technologies for co-construction and co-creation of resources and knowledge and use best practices to use and create information online. In addition, you develop programs collaboratively and for a purpose, such as expressing ideas or addressing problems."
    },
    "DQ14.2": {
        code: "Collaboration",
        full_name: "Collaboration",
        definition: "You apply socio-emotional, interpersonal, and cognitive skills to support collaborative efforts. These skills include the capacity to interact and collaborate with an online community of peers and experts for the construction and co-creation of knowledge. You understand that computing technology allows for local and global collaboration by facilitating communication and innovation. "
    },
    "DQ14.3": {
        code: "Teamwork",
        full_name: "Teamwork",
        definition: "You leverage your technical skills to efficiently exchange ideas and work together even at a distance through utilizing a variety of different communication channels. You can take on varying roles when collaborating with peers during the design, implementation, and review stages of program development and cultivate working relationships with individuals possessing diverse perspectives, skills, and personalities. You can also create team norms, expectations, and equitable workloads to increase efficiency and effectiveness. In addition, you can solicit and incorporate feedback from, and provide constructive feedback to, team members and other stakeholders."
    },
    "DQ14.4": {
        code: "CollTools",
        full_name: "Collaboration Tools",
        definition: "You can collaborate using popular video conferencing tools and web-based productivity applications. You can interact with others on a variety of tasks while effectively choosing the most appropriate software depending on the needs and context of the situation.\n"
    },
    "DQ14.5": {
        code: "MultiCol",
        full_name: "Multi-stakeholder Collaboration",
        definition: "To what extent do people, companies, and cross-sector organizations collaborate and share ideas\n"
    },
    "DQ15.1": {
        code: "AlgorithmDev",
        full_name: "Designing Algorithm Fitting to Problems",
        definition: "You can plan and develop a sequence of understandable instructions for a computing system to solve a given problem or perform a specific task. You can develop plans that describe a program’s sequence of events, goals, and expected outcomes and use flowcharts and/or pseudocode to address complex problems. "
    },
    "DQ15.2": {
        code: "ProjectProcessMgmt",
        full_name: "Project Process and Management",
        definition: "You can describe, justify, and document computational processes and solutions using appropriate terminology consistent with the intended audience and purpose. You can also use the principles of decomposition and abstraction to break down tasks into subtasks. You distribute tasks and maintain a project timeline when collaboratively developing computational artifacts and design and develop computational artifacts working in team roles using collaborative tools. You can also use an iterative process involving design, implementation, and review."
    },
    "DQ15.3": {
        code: "WebCreate",
        full_name: "Website Creation",
        definition: "You can create simple websites on free online platforms using website builders. Additionally, you know how to create websites using HTML. You can develop systems for registration and payment for products and services. You understand how your website should be tailored to the interests of the visitors or customers. "
    },
    "DQ15.4": {
        code: "CompNetSys",
        full_name: "Computing and Networking System",
        definition: "You understand that hardware consists of physical components, while software provides instructions for the system and that they work together as a system. You understand how hardware and software determine a computing system’s capability to store and process information and how the design or selection of a computing system involves multiple considerations and potential tradeoffs. You use appropriate terminology in identifying and describing the function of common physical components of computing systems. You understand how information needs a physical or wireless path to travel to be sent and received and that computers send and receive information based on a set of rules called protocols. You understand network topology and how it is determined, in part, by how many devices can be supported."
    },
    "DQ15.5": {
        code: "SolveTechProb",
        full_name: "Solving Technical Problems",
        definition: "You can identify technical problems when operating devices and using digital environments and solve them while you can also systematically identify and fix problems with computing devices and their components. You understand the levels of interaction that exist between hardware, software, and the user of a computing system and  the most common levels of software that a user interacts with. Additionally, you use appropriate terminology in identifying and describing the function of common physical components of computing systems."
    },
    "DQ15.6": {
        code: "DataCollect",
        full_name: "Data Collection",
        definition: "You recognise how everyday digital devices collect and store data over time  and can select digital tools for the collection of data based on what is being observed and how the data will be used. You understand the nature of static and dynamic data structures and effectively use primitive data types and can understand and manipulate various types and locations of external data stores. In addition, you can design algorithms and tools to automate the collection of data by computers and can design projects that combine hardware and software components to collect and exchange data."
    },
    "DQ15.7": {
        code: "DataStore",
        full_name: "Data Storage and Management",
        definition: "You understand that computers store data that can be retrieved later and that data can be composed of multiple data elements and that programmers make choices about how data elements are organized and where data is stored. You understand that applications store data as representations at multiple levels, from the arrangement of information into organized formats to the physical storage of bits. You understand data structures and how they are chosen based on functionality, storage, and performance trade-offs. You also understand that data structures are used to manage program complexity and that different software tools used to access data may store the data differently. "
    },
    "DQ15.8": {
        code: "DataViz",
        full_name: "Data Analysis and Visualization",
        definition: "You understand the theories, practices, and procedures of data analysis, statistics , and AI-related mathematical concepts. You can organize and present collected data visually to highlight relationships and support a claim and identify and describe patterns in data visualizations, such as charts or graphs, to make predictions and can communicate these ideas. You can transform data to remove errors, highlight or expose relationships, and/or make it easier for computers to process and create computational models that represent the relationships among different elements of data collected from a phenomenon or process. You can create clearly named variables that represent different data types and perform operations on their values and use lists to simplify solutions, generalizing computational problems instead of repeatedly using simple variables. "
    },
    "DQ15.9": {
        code: "Simulation",
        full_name: "Simulation and Inference ",
        definition: "You understand that computer models are abstractions that represent phenomena and use data and algorithms to emphasize key features and relationships within a system and can be used to simulate events, examine theories and inferences, or make predictions with either a few or millions of data points. You understand that data can be used to make inferences or predictions about the world. You also understand how the accuracy of inferences and predictions is related to how realistically data is represented as well as the amount, quality, and diversity of data and the features chosen can affect the quality of a model and ability to understand a system."
    },
    "DQ15.10": {
        code: "DataPres",
        full_name: "Data Presentation and Interpretation ",
        definition: "You can transform, generalize, simplify, and present large data sets in different ways to influence how other people interpret and understand the underlying information by using visualization, aggregation, rearrangement, and application of mathematical operations. You also know how to choose the form of data presentation (table, graph, diagram) in accordance with the assigned task. You understand that people select aspects and subsets of data to be transformed, organized, clustered, and categorized to provide different views and communicate insights gained from the data. You can create interactive data visualizations using software tools to help others better understand real world phenomena."
    },
    "DQ15.11": {
        code: "ContentCreateSkills",
        full_name: "Content Creation Skills",
        definition: "Create and develop digital content by executing plans to creative outputs; engage and decide on problems through creative solutions; express identity through creative means."
    },
    "DQ16.1": {
        code: "KnowCopyright",
        full_name: "Knowledge of Copyright",
        definition: "You understand the concepts of copyright and licenses and can explain the principles of protecting intellectual property while distinguishing between types of licenses for copyright protection. You understand how copyright and licences apply to data, information and digital content and can distinguish between types of software licenses."
    },
    "DQ16.2": {
        code: "IntermedSkills",
        full_name: "Protecting Intellectual Property",
        definition: "You can utilize strategies such as trademarks, creative commons, and copyright for protecting your own and others’ digital creations—in addition to content created collaboratively—through a variety of tools and legislation. You learn the conventions for creating citations and using them appropriately in order to credit others whose work has been used in the creative process. Moreover, you track and manage changes in their digital creations in order to protect their own/organizational assets from unauthorized change, use, and deviation. "
    },
    "DQ16.3": {
        code: "IPInnov",
        full_name: "IP and Innovation ",
        definition: "You can explain the beneficial and harmful effects that intellectual property laws can have on innovation. "
    },
    "DQ17.1": {
        code: "TechatWork",
        full_name: "Tehnology at Work",
        definition: "You understand how technology creates new jobs and changes the way people work. You can describe various ways technology continuously changes the workplace and identify different ways technology is used in jobs that may not be typically thought of as “tech” jobs.\nYou understand how technology creates new jobs and changes the way people work. Individuals can describe various ways technology continuously changes the workplace and identify different ways technology is used in jobs that may not be typically thought of as “tech” jobs.\n"
    },
    "DQ17.2": {
        code: "DigitalEntrepreneur",
        full_name: "Digital Entrepreneurship",
        definition: "You show a positive attitude to seeking out entrepreneurship in the digital world as well as an astuteness in seeking out commercial opportunities and gaps in existing service provision. You recognise how the internet has made the world a smaller place in bringing about global irrespective of your location. "
    },
    "DQ17.3": {
        code: "ImpactsCompCult",
        full_name: "Impacts of Computing on Life and Culture",
        definition: "You understand how life work have changed due to the adoption of computing technology. You understand the reciprocal interactions between computing technologies and culture and how technology is driven by people’s needs and wants. You can also respond to the needs of diverse end users during the design process to address accessibility and usability while considering a diverse community. You are also aware of positive and negative impact of technology and can discuss its advancement in terms of trade-offs and how access to technology and its impacts reflect existing inequalities."
    },
    "DQ17.4": {
        code: "AutoDataMgmt",
        full_name: "Autonomy and data management",
        definition: "Transact and manage identity and preference data in digital markets; assess and achieve subjective wellbeing"
    },
    "DQ17.5": {
        code: "CareerIdentity",
        full_name: "Career identity",
        definition: "Develop an identity through exploring and contributing to the digital economy"
    },
    "DQ18.1": {
        code: "CommunityEngage",
        full_name: "Community Engagement and Values",
        definition: "You understand the importance of community engagement and civic participation and have standards that are aligned with community and/or organizational values and business objectives and the well-being of their local, national, and global communities."
    },
    "DQ18.2": {
        code: "OnlineOrg",
        full_name: "Online Community Organising",
        definition: "You organize and rally a group or online community and know how to participate in organized online groups for effecting change that they aim to create. You are better equipped to engage with individuals or groups through various digital media, to develop and review procedures, to participate in synchronous and asynchronous discussions, and to create shared values."
    },
    "DQ18.3": {
        code: "CivicImpact",
        full_name: "Civic and Community Impact",
        definition: "You effectively influence communities through appropriate technologies and exhibit a belief in and respect for civic engagement and are willing to become involved in their communities for the betterment of their own organizations and/or society."
    },
    "DQ18.4": {
        code: "PolitLit",
        full_name: "Digital Political Literacy",
        definition: "Ability to politically recognize, use, and evaluate digital platforms, tools, and content"
    },
    "DQ18.5": {
        code: "PolitIdentity",
        full_name: "Digital Political Identity",
        definition: "Develop a sense of political identity through exploring and contributing to social movements online."
    },
    "DQ18.6": {
        code: "PolitActivism",
        full_name: "Digital Political Activism",
        definition: "Engage in politically motivated activities through online consumption, participation, fundraising, and hacking"
    },
    "DQ19.1": {
        code: "CommunityCRAware",
        full_name: "Commercial and Community Cyber-Risk Awareness",
        definition: "You understand different types of commercial or community cyber-risks such as embedded marketing, online propaganda, and gambling, and your exposure to such risks as members of specific communities and groups."
    },
    "DQ19.2": {
        code: "DealCommunityCR",
        full_name: "Dealing With Community and Commercial Cyber-Risks",
        definition: "You are familiar with the strategies involved in dealing with these risks and are able to identify and/or develop strategies and tools such as ad blockers. You also detect and report incidents, identify affected systems and user groups, and trigger alerts and announcements to relevant stakeholders."
    },
    "DQ19.3": {
        code: "PreventCommunityCR",
        full_name: "Preventing Community and Commercial Cyber-Risks",
        definition: "You work to pro-actively manage the lifecycle of risks to prevent problems and incidents from occurring and to eliminate recurring issues."
    },
    "DQ19.4": {
        code: "SafeOnlinePart",
        full_name: "Safe online participation",
        definition: "Work towards a safe and secure a common digital environment by developing protocols and promoting best practices."
    },
    "DQ20.1": {
        code: "UnderOrgCyberSec",
        full_name: "Understanding Organizational Cyber Security Management",
        definition: "You understand support architectures, policies, practices, and procedures that enable organizations to manage threats, including anti-malware software related to organizational data, devices, and/or systems. You have knowledge about proper the handling, usage, and storage of an organization’s IT assets to limit potential business or legal risks and can develop and implement your own digital resiliency plans."
    },
    "DQ20.2": {
        code: "OrgCyberSecSkills",
        full_name: "Organizational Cyber Security Skills",
        definition: "You have relevant skills for improving your organization’s cyber security systems. You forecast and assess existing and potential security risks and develop and implement intervention strategies to proactively protect and optimize an organization’s IT assets. You enable the prompt recovery of critical IT infrastructure and systems following a crisis or disaster"
    },
    "DQ20.3": {
        code: "OrgCyberSecFrame",
        full_name: "Organizational Cyber Security Frameworks",
        definition: "You develop and disseminate corporate security policies, frameworks, and guidelines to help ensure that day-to-day business operations guard or are well protected against risks, threats, and vulnerabilities. This includes development of communication to help ensure adoption and adherence to security policies and standards."
    },
    "DQ20.4": {
        code: "OrgCyberSecPrev",
        full_name: "Preventative Cyber Security",
        definition: "You value cyber security and proactively advocate it in your organization by providing advice and guidance on potential risks, mitigation strategies, and best practices."
    },
    "DQ20.5": {
        code: "NatCyberSec",
        full_name: "National Cyber Security",
        definition: "[national level of cyber-security infra structure]"
    },
    "DQ21.1": {
        code: "OnlineInteractKnow",
        full_name: "Understanding Online Interaction",
        definition: "You understand and manage different contexts of social interaction in online communities to achieve mutual consensus and outcomes and how different behavioral norms and emotional reactions can vary depending on the platform and context."
    },
    "DQ21.2": {
        code: "OnlineInteractSkill",
        full_name: "Managing Online Interaction",
        definition: "You manage, maintain, and grow relationships based on individual or organizational needs and build cooperative partnerships with inter-organizational and external stakeholders and leverage relations to meet organizational objectives. You manage various expectations and needs by building rapport, negotiating with, and influencing stakeholders."
    },
    "DQ21.3": {
        code: "OnlineInteractAtt",
        full_name: "Online Interaction Culture",
        definition: "You exhibit self-motivation and a commitment to providing an inclusive culture that cultivates tolerance, teamwork and growing positive communities online. You are committed to exceeding both internal and external stakeholders’ needs by demonstrating diplomacy, and a willingness to identify others’ needs first and to consider a diverse set of opinions before making sound decisions."
    },
    "DQ21.4": {
        code: "SolRelMgmt",
        full_name: "Solitary and relationship management",
        definition: "Develop genuine relationships as well as bridge differences online that create a sense of alterity and belongingness."
    },
    "DQ22.1": {
        code: "UnderMassComm",
        full_name: "Understanding Pubic and Mass Communication",
        definition: "You understand how different online platforms, digital environments, cultures, and policies can aid or restrict the dissemination of ideas and messages and how ethical and legal underpinnings shape the spread of ideas and messages online. "
    },
    "DQ22.2": {
        code: "MassCommSkill",
        full_name: "Skills in Public and Mass Communication",
        definition: "You communicate ideas and messages through digital media and technology that are available, such as setting up a crowdfunding initiatives, participating in a social movement on- line,or launching a digital marketing campaigns while mindful of varying audiences. You conceptualize digital storyboards, optimize content delivery and their messages, develop dissemination strategies on and across various channels and platforms, and track audience response and communicate effectiveness. "
    },
    "DQ22.3": {
        code: "SEOSkill",
        full_name: "SEO and Digital Marketing",
        definition: "You engage audiences in a dialogue utilizing various digital tools including search engine optimization and/or marketing strategies, and monitoring data analytics to strategically boost engagement online. You co-create your organization’s projected brand and reputation by developing and implementing branding campaigns, public relations, and reputation management strategies. You plan and conduct research to understand overall trends regarding markets, customers, and competitors to extract useful insights and optimize efforts. "
    },
    "DQ22.4": {
        code: "EthicalComms",
        full_name: "Ethical and Principled Communication ",
        definition: "You exhibit an attitude towards collaborative technology use that is ethical, purposeful, and principled to engage in a productive discourse with digital communities. You also understand and proactively respond to the environmental impacts of digital technologies and their use. "
    },
    "DQ23.1": {
        code: "AdvanceTech",
        full_name: "Advanced Technology",
        definition: "You understand concepts across multiple disciplines and identify the benefits, limits, and risks brought about through big data, AI, and related technology. In addition, you understand the nature and implications of the internet of things and virtual reality. "
    },
    "DQ23.2": {
        code: "SciResearch",
        full_name: "Advanced Tech and Scientific Research Skills",
        definition: "You utilize technology to carry out research, to apply scientific methods giving reasoned opinions on the matters and arriving at scound conclusions with training of advanced digital skills\n"
    },
    "DQ23.3": {
        code: "InclusiveTech",
        full_name: "Inclusive Technology",
        definition: "You understand the biases that affect accessibility through design in technology. You can also respond to the needs of diverse end users during the design process to address accessibility and usability while considering a diverse community. You can also develop ways to improve usability and accessibility including through self and peer advocacy during the design and development processes. "
    },
    "DQ23.4": {
        code: "InnovSkills",
        full_name: "Innovation and entrepreneurship",
        definition: "Being part of a digital ecosystem to innovate and transform the global economy to build new opportunities for sustainable employment and entrepreneurship."
    },
    "DQ24.1": {
        code: "KnowDigitalRights",
        full_name: "Understanding Digital Rights",
        definition: "You understand your rights as a digital citizen and consumer and why risks and opportunities for online participation are unevenly distributed across social groups."
    },
    "DQ24.2": {
        code: "SkillDigitalRights",
        full_name: "Protecting Digital Rights",
        definition: "You are equipped to develop skills to help ensure that digital rights are upheld and respected online and can develop complex system-level thinking for upholding individual and community rights to online participation as they monitor and improve systems and hold contradictory ideas and ideals in tension. "
    },
    "DQ24.3": {
        code: "AttDigitalRights",
        full_name: "Proactive Digital Rights",
        definition: "You exhibit proactive thinking, grounded in respect for democratic ideals, the rule of law, human rights and take responsibility for managing technology to promote the public good of society and the environment. "
    },
    "DQ25.1": {
        code: "DCConfidence",
        full_name: "Digital Confidence",
        definition: "You are confident in using digital technology and media (technology) by understanding how the digital world is structured as well as how to utilize technology to set and achieve your goals. You understand your own abilities and can identify gaps and ways to improve or update your digital competence."
    },
    "DQ26.1": {
        code: "DigitalPresence",
        full_name: "Digital Presence",
        definition: "You are present at the digital world – you have meaningful access to the Internet through proper devices and you are actively involved in various digital activities.  "
    },
    "DG1.3": {
        code: "RiskyContent",
        full_name: "Risky Content",
        definition: "Viewing of violent and sexual content. Under the age of 13 any viewing of sexual content is seen as risky content, for those at 13 years of age and above unsolicited sexual content is seen as risky content."
    },
    "DQ27.1": {
        code: "UnderContentCR",
        full_name: "Understanding Content Cyber-Risks",
        definition: "You understand risky content faced online such as violence, lewd, unwanted content, racist, hateful, or discriminatory content and images and image-based abuse."
    },
    "DQ27.2": {
        code: "DealContentCR",
        full_name: "Dealing with Content Cyber-Risks",
        definition: "You understand and can execute the strategies involved in dealing with content cyber-risks. You exhibit resilience and fortify yourself against content that can be hurtful or derogatory. You become better equipped to develop and use conflict management techniques to mitigate such risks, whether through avoiding or confronting individuals or groups involved in the creation of such content, reporting incidents to platform administrators, or other appropriate responses. "
    },
    "DQ27.3": {
        code: "ExpRiskyContent",
        full_name: "Health Check - Risky Content ",
        definition: "You have not been exposed to content cyber-risks such as violence, sexual content, racist, hateful, or discriminatory content and images and image-based abuse."
    },
    "DQ28.1": {
        code: "ProtectPwd",
        full_name: "Managing Passwords",
        definition: "You are able to secure your digital accounts with strong passwords and have various tactics to keep them confidential."
    },
    "DQ28.2": {
        code: "MobileSec",
        full_name: "Mobile Security",
        definition: "You have a good understanding of mobile security and good personal habits such as using a lock screen and password and switching off Bluetooth in public places. "
    },
    "DQ29.1": {
        code: "SelfEmotionContent",
        full_name: "Self-Awareness Attitude",
        definition: "You are sensitive to and aware of your own emotions when engaging with or is exposed to online content."
    },
    "DQ29.2": {
        code: "EmoLit",
        full_name: "Emotional literacy",
        definition: "Use and comprehend digital emotional jargons and cues (e.g., emoticons, like, share, etc.); identify emotional situations and contexts online."
    },
    "DQ30.1": {
        code: "CommunicationCog",
        full_name: "Communication Cognitive Skills",
        definition: "You apply socio-emotional, interpersonal, and cognitive skills to support communication efforts. These skills include the capacity to interact and collaborate with an online community of peers. You know that computing influences many social institutions such as family, education, religion, and the economy. In addition, you can organize and engage around issues and topics of interest through various communication platforms enabled by computing, such as social networks and media outlets. You understand the viewpoints of diverse audiences. "
    },
    "DQ30.2": {
        code: "ComLit",
        full_name: "Communicative literacy",
        definition: "Communicate online effectively and efficiently through various forms of multimedia."
    },
    "DQ31.1": {
        code: "BasicTechOperations",
        full_name: "Basic Hardware and Software Operations ",
        definition: "You can distinguish between types of electronic computers such as smartphones, tablets and laptops and know their purpose. You have basic skills to work with a computer as well as to identify and select the hardware and software that best suit your needs. You know and understand the data, information and/or digital content that are needed to operate software tools and technologies."
    },
    "DQ31.2": {
        code: "InternetOperations",
        full_name: "Internet Operations",
        definition: "You understand a basic concept of what the internet and computer networks are and how they work. You have the skills to effectively use Internet tools such as a browser, social media apps, instant messaging systems, emails, and other web applications including video calls and group video meetings.\n     "
    },
    "DQ31.3": {
        code: "OrganizeInfo",
        full_name: "Organizing and Processing Information",
        definition: "You can organise, store and retrieve data, information and content in digital environments. In addition, you can search, retrieve, modify, and delete information using a computing device such as a laptop, desktop, smartphone or tablet. You know how to save files on a local network and in cloud storage and work with files (copy, move, delete) while determining their size, type, and purpose. "
    },
    "DQ31.4": {
        code: "CreateInfoContent",
        full_name: "Productivity Software Skills",
        definition: "You can create and edit digital content in different formats to express oneself through digital means. You know the basics of working with word processors and the basic elements and rules of formatting a document. You know how to create presentations and possess the basic skills of working with spreadsheets. In addition, you know how to work in graphic editors for photo processing and create and process video files."
    },
    "DQ31.5": {
        code: "SocialMediaSkills",
        full_name: "Social Media Skills",
        definition: "Use, recognise, and understand digital and creative tools and social media platforms."
    },
    "DQ32.1": {
        code: "ConsumerAware",
        full_name: "Consumer awareness and literacy",
        definition: "Use and browse advertisements, goods, information, and services online; learn and understand novel digital commercial practices."
    },
    "DQ32.2": {
        code: "ConsumerComp",
        full_name: "Consumer rights and competencies",
        definition: "Assert consumer rights and responsibilities in the digital marketplace; introduce innovative business practices that foster collaboration and maintain genuine customer-seller relations."
    },
    "PM1.1": {
        code: "FamilyMediaRule",
        full_name: "Family Media Rule",
        definition: "Parents set clear family rules and principles on technology and media that parents and children can abide by together. Parents model appropriate media use, which not only includes media rules for children, but also media rules for parents, such as not using devices at meals, having media in common rooms, and not having personal media in bedrooms. Healthy family habits maintain a variety of activities, keeping media use in moderation. These include having meals together, playing games, reading, etc. Parents set appropriate limits for children’s media use. This includes setting limits on the amount of time and when media may be used, limits on types or content of media, and technological approaches (e.g., parental control apps, filtering, or other monitoring software) to establishing boundaries for children’s media use for your children’s overall health and well-being."
    },
    "PM1.2": {
        code: "TechReadiness",
        full_name: "Tech Readiness",
        definition: "Parents set clear policies on children's ownership and active use of digital devices and media (e.g. when children can get their own mobile and how to use it, when children can have social media accounts). They have to be aligned with laws in their own countries."
    },
    "PM1.3": {
        code: "MutualApproval",
        full_name: "Mutual Approval",
        definition: "Parents set clear family rules and principles on media content and contact that parents and children can abide by together, such as not to share private images of their children in social media without children’s consent, not downloading anything without parental approval, etc."
    },
    "PM2.1": {
        code: "CyberSafetyConvo",
        full_name: "Cyber-Safety Conversation",
        definition: "Parents engage in open and supportive basic active mediation. It involves proactively talking with children about their favourite apps, games, or websites and discussing various aspects of cyber-risks (such as privacy invasion, cyber-bullying, gaming addiction, etc).\n\nParents share media activities with their children as part of family life. They engage together by viewing the same TV shows or movies, playing the same games, or browsing the Internet to build trust and share media experiences. Parents keep lines of communication open while asking about children’s online experiences, whether they are having any issues."
    },
    "PM2.2": {
        code: "AdvancedAM",
        full_name: "Advanced Active Mediation",
        definition: "Active mediation goes beyond simply stating opinions about media, but uses questions to get children to consider why things are depicted how they are, whose point of view is shown/not shown, what the motivations of the writers may be, what the effects of viewing these types of portrayals may be, etc. This helps children learn to evaluate media messages more critically.\n\nIf parents co-view/co-play with children, they also use this time as a way to have these discussions. This allows parents to discuss and share their family’s values by using media content as a natural springboard for discussion.\n\nParents actively ask children’s opinion and help children think about the subliminal message, hidden agenda of media content, and its creation process.\n\nParents can discuss difficult issues such as relationships, sex, aggression, etc. to help children learn how to seek situations that will make them happy and healthy."
    },
    "PM2.3": {
        code: "SEGrowth",
        full_name: "Socioemotional Growth",
        definition: "Parents avoid using media as a babysitter, nor do they routinely use them to calm children down. Instead, parents help children experience and work with their emotions without necessarily trying to get away or distract themselves from them. In this way, parents model appropriate media use and how children can take responsibility for their emotional states and find more positive ways of coping than distraction."
    },
    "PM3.1": {
        code: "FamilyTechUse",
        full_name: "Family Tech Use",
        definition: "Parents provide good digital access to children with an understanding of their own and their children's digital use habits at home (e.g. how many devices, what device their children connect on which network)."
    },
    "PM3.2": {
        code: "TechImpactChildren",
        full_name: "Technology Impact",
        definition: "Parents are equipped with 8 digital citizenship skills for themselves. Moreover parents understand the trends and research about benefits of technology as well as potential risks of technology.\nThey understand how technology affects children’s well-being and development such as brain development, school grades, and mental health."
    },
    "PM3.3": {
        code: "DigitalNet",
        full_name: "Support Network",
        definition: "Parents develop a good support and protection network around their children. It begins with having consistent digital parenting policies among all caregivers within their house (e.g., partner, grandparents, babysitter, etc).\nParents proactively work with school teachers related to their online schooling and cyber-protection.\nParents know when and how to seek external helps including a school counsellor, psychologist, police, or other professional experts as necessary."
    },
    "PM3.4": {
        code: "FamilyTrust",
        full_name: "Trust Building",
        definition: "Parents build a trustful relationship with their children. "
    }
}