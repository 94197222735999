import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";

import {
    Divider as MuiDivider,
    makeStyles, withStyles, TableCell, TableRow, TableHead, TableBody, Table, Container, Button as MuiButton,
} from "@material-ui/core";

import {Paper, Box, Grid, Card, CardContent, Typography, Spacer,} from "../../../../components/StyledComponents/styledComponents"
import {GenericSidePanel} from "../../../../components/SidePanel/GenericSidePanel.comp";
import {alphaNumericSort, getUniqueValues, predefinedSort, toastDanger} from "../../../../utils/utils";
import {X} from "react-feather";
import {useAuth} from "../../../../context/auth";
import LoaderWithBackDrop from "../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import dummyData, {fetchData, fetchSelectors} from "../data/data"
import {CustomSelect} from "../components/Select.comp";
import ProgressTable from "../components/ProgressTable";
import {Helmet} from "react-helmet";

function splitList(list, min_count)
{
    const sublist = []
    const length = list.length;

    sublist.push(list.slice(0, Math.min(min_count, list.length)));
    sublist.push(list.slice(Math.min(min_count, list.length), length));
    return sublist;
}

function Progress() {

    const {authUser} = useAuth();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [pageData, setPageData] = useState(undefined);

    const subListDivision = 3;
    const [schools, setSchools] = useState(undefined);
    const [classes, setClasses] = useState([]);

    const primaryHeader = "Select a School";
    const [defaultSchools, setDefaultSchools] = useState([]);
    const [additionalSchools, setAdditionalSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState({school: undefined});

    const secondaryHeader = "Select a Class";
    const [defaultClasses, setDefaultClasses] = useState([]);
    const [additionalClasses, setAdditionalClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState({class: undefined});

    const [graphData, setGraphData] = useState(undefined);
    const [header, setHeader] = useState("");

    const [schoolState, setSchoolState] = useState(undefined);
    const [classState, setClassState] = useState(undefined);
    
    const selectedAllSchoolsOption = "All Schools"
    const selectedAllClassesOption = "All Classes"

    useEffect( () => {
        loadSelectors().then(d => console.log('', d));
    }, []);

    const loadDataList = async (page = 0, rowsPerPage = 10, order = "asc", orderBy = undefined) => {
        setGraphData(fetchData(selectedSchool.school, selectedClass.class, page, rowsPerPage, order, orderBy));
        console.log("Fetched Data List")
    }

    const loadSelectors = async () => {
        setLoading(true);
        try {
            const result = fetchSelectors();
            //TODO change >> result << to >> result.data <<
            if (result) {
                console.log('Result Data of Progress Page --> ', result);
                initializeData(result)
            }
            else {
                setPageData(undefined);
                setErrorMessage("No data available");
            }

        } catch (e) {
            console.log("Some error occurred while fetching progress data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    function initializeData(result) {
        if(result)
        {
            setPageData(result);
        }
    }

    useEffect(() => {
        if(pageData)
        {
            const {schools: _schools} = pageData;
            if(_schools)
            {
                _schools.sort().sort(predefinedSort([selectedAllSchoolsOption]))
                setSchools(_schools);
            }
        }
        else
        {
            setGraphData(undefined)
            setSelectedClass({class: undefined});
            setSelectedSchool({school: undefined});
            setClasses([]);
            setSchools([]);
        }
    }, [pageData])

    useEffect(() => {
        if(schools)
        {
            const schoolSubLists = splitList(schools, subListDivision)
            setDefaultSchools(schoolSubLists[0]);
            setAdditionalSchools(schoolSubLists[1])
            setSelectedSchool({school: schoolSubLists[0][0]})
        }
        else
        {
            setDefaultSchools([])
            setAdditionalSchools([])
            setSelectedSchool({school: undefined})
        }
    }, [schools])

    useEffect(() => {
        console.log("I ran");
        if(classes.length > 0)
        {
            classes.sort((a, b) => a - b);
            const classesSubLists = splitList(classes, subListDivision)
            setDefaultClasses([])
            setDefaultClasses(classesSubLists[0]);
            setAdditionalClasses(classesSubLists[1]);
            setSelectedClass({class: classesSubLists[0][0]})
            setClassState({
                ...Object.fromEntries(classesSubLists[0].map((cl, index) => index === 0 ? [cl, true] : [cl, false])),
            }); //ensure state consistency
        }
        else
        {
            console.log("I ran uhm");
            setDefaultClasses([])
            setAdditionalClasses([])
            setSelectedClass({class: undefined});
        }
    }, [classes])

    useEffect(() => {
        if(selectedSchool.school && pageData)
        {
            console.log("I ran yo")
            const _classes = pageData.classes[selectedSchool.school]
            setClasses(_classes ? _classes : []);
            setHeader(selectedSchool.school)
        }
        else {
            if(!pageData)
            {
                setDefaultSchools([]);
                setAdditionalSchools([]);
            }
            setDefaultClasses([])
            setAdditionalClasses([])
            setGraphData(undefined)
            setHeader("");
        }
    }, [selectedSchool])

    useEffect(() => {
        if(selectedClass.class && pageData)
        {
            console.log(selectedSchool.school, selectedClass.class, "ran3")
            loadDataList().then(() => {})
            setHeader(selectedClass.class);
        }
        else {
            if(selectedSchool.school && pageData) {
                loadDataList().then(() => {})
                setHeader(selectedSchool.school)
            }
            else {
                setGraphData(undefined);
                setHeader("");
            }
        }
    }, [selectedClass])

    const addItemToState = (item, state, setState, sort = false) => {
        if(sort)
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item].sort());
            else
                setState([...state, item].sort());
        }
        else
        {
            if(Array.isArray(item)) //in case item is an array, iterate
                setState([...state, ...item]);
            else
                setState([...state, item]);
        }
    }

    const removeItemFromState = (item, state, setState) => {
        const temp = [...state];
        if(Array.isArray(item)) //in case item is an array, iterate
        {
            item.forEach((i) => {
                const index = temp.indexOf(i);
                if(index !== -1)
                    temp.splice(index, 1);
            })
        }
        else
        {
            const index = temp.indexOf(item);
            if(index !== -1)
                temp.splice(index, 1);
        }
        setState(temp);
        return temp;
    }

    function onAddSchool(event, state, setState) {
        const item = event.target.value
        removeItemFromState(item, additionalSchools, setAdditionalSchools);
        addItemToState(item, defaultSchools, setDefaultSchools);
        setState({
            ...Object.fromEntries(defaultSchools.map((school) => [school, false])),
            [item]: true,
        });
        setSelectedSchool({school: item});
    }

    function onRemoveSchool(item, state, setState) {
        const newDefaultSchools = removeItemFromState(item, defaultSchools, setDefaultSchools)
        addItemToState(item, additionalSchools, setAdditionalSchools, true)
        setState({
            ...Object.fromEntries(newDefaultSchools.map((school, index) => index === 0 ? [school, true] : [school, false])),
        });
        setSelectedSchool({school: newDefaultSchools[0]});
    }

    function onSelectSchool(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedSchool({school: item})
            // if(item === selectedAllSchoolsOption)
            // {
            //     setClassState({
            //         ...Object.fromEntries(defaultClasses.map((cl) => [cl, false])),
            //     });
            //     setSelectedClass({class: undefined})
            // }
            // else
            // {
            //     if(selectedClass.class)
            //     {
            //         setSelectedClass({class: selectedClass.class})
            //     }
            //     else
            //     {
            //         setClassState({
            //             ...Object.fromEntries(defaultClasses.map((cl, index) => index === 0 ? [cl, true] : [cl, false])),
            //         });
            //         setSelectedClass({class: defaultClasses[0]})
            //     }
            // }
            setState(Object.fromEntries(defaultSchools.map(
                (school) => school === item ? [school, true] : [school, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    function onAddClass(event, state, setState) {
        const item = event.target.value;
        removeItemFromState(item, additionalClasses, setAdditionalClasses);
        addItemToState(item, defaultClasses, setDefaultClasses);
        setState({
            ...Object.fromEntries(defaultClasses.map((cl) => [cl, false])),
            [item]: true,
        });
        setSelectedClass({class: item});
    }

    function onRemoveClass(item, state, setState) {

        const newDefaultClasses = removeItemFromState(item, defaultClasses, setDefaultClasses)
        addItemToState(item, additionalClasses, setAdditionalClasses, true)
        setState({
            ...Object.fromEntries(newDefaultClasses.map((cl, index) => index === 0 ? [cl, true] : [cl, false])),
        });
        setSelectedClass({class: newDefaultClasses[0]});
    }

    function onSelectClass(item, isChecked, state, setState) {
        if(isChecked) {
            setSelectedClass({class: item})
            setState(Object.fromEntries(defaultClasses.map(
                (cl) => cl === item ? [cl, true] : [cl, false]
            )));
        }
        else
        {
            setState({
                ...state,
                [item]: true
            });
        }
    }

    return (
        <Paper container>
            <Helmet title="DQ-PRO | Progress"/>
            <Grid container alignItems={'stretch'} spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                    <GenericSidePanel
                        data={[defaultSchools, defaultClasses]}
                        onCheckCallbacks = {[onSelectSchool, onSelectClass]}
                        headers = {[primaryHeader, secondaryHeader]}
                        colourize = {[false, false]}
                        actions = {[X, X]}
                        actionsData = {[{style: {cursor: "pointer"}}, {style: {cursor: "pointer"}}]}
                        actionsCallbacks = {[onRemoveSchool, onRemoveClass]}
                        footers = {[CustomSelect, CustomSelect]}
                        footersData = {[
                            {
                                title: `Add School`,
                                data: additionalSchools,
                            },
                            {
                                title: "Add Class",
                                data: additionalClasses,
                            },
                        ]}
                        footersCallbacks = {[{onChange: onAddSchool}, {onChange: onAddClass}]}
                        defaultCheckIndices = {[0, 0]}
                        stateCallbacks = {[
                            {
                                state: schoolState,
                                setState: setSchoolState
                            },
                            {
                                state: classState,
                                setState: setClassState
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    {
                        pageData ?
                            <Paper container >
                                <ProgressTable
                                    data={graphData}
                                    fetchSortedData={loadDataList}
                                />
                            </Paper>
                            :
                            <Typography primary variant={"h3"} align={'center'} py={5}>{errorMessage}</Typography>
                    }
                </Grid>
            </Grid>
            <LoaderWithBackDrop loading={loading}/>
        </Paper>
    );
}

export default Progress;