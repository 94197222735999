import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import {
    USER_ACCESS_LEVEL_VERBOSE,
} from "../../../utils/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import {useAuth} from "../../../context/auth";

import {
    Add as AddIcon,
} from "@material-ui/icons";

import {
    Button,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {AccountServices} from "../../../services/organization.service";
import {toastWarning} from "../../../utils/utils";
import {getPendingApprovalsList} from "../../../services/employees.service";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;


const useStyles = makeStyles((theme)=>({
    tr_act: {

    },
    tr_d_act: {
        background: '#f8dede!important'
    },
    act_btn: {
        marginTop: '0px',
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        marginTop: '0px',
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '10px 0px'
    },
    deleteBtn: {
        marginLeft: '6px',
        marginTop: '6px!important',
        color: '#ee3434',
        cursor: 'pointer',
        '&:hover': {
            color: '#f55353',
        }
    }
}));


function ListProfilesApprovals() {

    const classes = useStyles();

    const { authUser } = useAuth();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);

    useEffect( () => {
        loadPageData().then(d => console.log('table data loaded!', d));
    }, []);

    const loadPageData = async () => {
        setLoading(true);

        try {

            const result = await getPendingApprovalsList(authUser.org_code);
            if (result.data && result.data.all_profiles) {
                console.log('result.data.all_profiles --> ', result.data.all_profiles);
                setPageData(result.data.all_profiles);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const approveAllAction = () => {
        toastWarning('Not Implemented !!');
    }

    const rejectAction = (user_id, profile_id) => {
        toastWarning('Not Implemented !!');
    }

    const approveAction = (user_id, profile_id) => {
        toastWarning('Not Implemented !!');
    }

    return (
        <React.Fragment>
            <div className={classes.headerInputGroup}>
                <Button variant="contained" color="primary"
                        onClick={approveAllAction}
                >
                    {`${GET_APP_LOCALIZE_TEXT(pageLc, 'employees__approve_all')}`}
                </Button>
            </div>

            <Card mb={6}>

                <Paper>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>Sr#</CustomTableCell>
                                <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, 'employees__email')}</CustomTableCell>
                                <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, 'employees__first_name')}</CustomTableCell>
                                <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, 'employees__last_name')}</CustomTableCell>
                                <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, 'employees__country')}</CustomTableCell>
                                <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, 'employees__postal_code')}</CustomTableCell>
                                <CustomTableCell align="center">{GET_APP_LOCALIZE_TEXT(pageLc, 'employees__actions')}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                pageData.map( (item, index) => (
                                    <CustomTableRow key={index} className={item.is_active ? classes.tr_act : classes.tr_d_act}>
                                        <CustomTableCell component="th" scope="row">{index + 1}</CustomTableCell>
                                        <CustomTableCell align="center">{item.email}</CustomTableCell>
                                        <CustomTableCell align="center">{item.first_name}</CustomTableCell>
                                        <CustomTableCell align="center">{item.last_name}</CustomTableCell>
                                        <CustomTableCell align="center">{item.country}</CustomTableCell>
                                        <CustomTableCell align="center">{item.postal_code}</CustomTableCell>
                                        <CustomTableCell align="center">
                                            <Button
                                                onClick={ () => rejectAction(item.user_id, item._id) }
                                                className={classes.d_act_btn}>{`${GET_APP_LOCALIZE_TEXT(pageLc, 'employees__reject')}`}</Button>
                                            <Button
                                                onClick={ () => approveAction(item.user_id, item._id) }
                                                style={{
                                                    marginTop: '0px',
                                                    marginLeft: '5px',
                                                }}
                                                variant="contained"  color="primary">{`${GET_APP_LOCALIZE_TEXT(pageLc, 'employees__approve')}`}</Button>
                                        </CustomTableCell>
                                    </CustomTableRow>
                                ))
                            }
                        </TableBody>
                    </Table>

                    <LoaderWithBackDrop loading={loading} />

                </Paper>
            </Card>

        </React.Fragment>

    );
}

export default ListProfilesApprovals;