import React from 'react';
import {Typography} from "../../../../components/StyledComponents/styledComponents";

export function fetchInsightHTML(skill, params) {
    switch(skill) {
        case "Support System":
            return (
                <>
                    <li>Your students reported adequate frequencies of cyber safety and computer education to be <b>{params[0]}%.</b></li>
                    <li>In addition, your students reported adequate frequencies of parental mediation to be <b>{params[1]}%.</b></li>
                </>
            )
        case "Use of Digital Media":
            return (
                <>
                    <li>Students reported an average of <b>{params[0]} hours</b> of weekly screen time, which is <b>{parseInt(params[0].toString()) >= 12 ? 'similar to' : 'different from'}</b> the global average of 32 hours per week.</li>
                    <li><b>{params[1]}</b> are the most common devices your students use to access the Internet.</li>
                    <li>Their top 3 activities are <b>{params[2]}</b></li>
                    <li><b>{params[3]}%</b> of students indicated using social media platforms, with {params[4]}% being highly active social media users</li>
                </>
            )
        case "Exposure to Risk":
            return (
                <>
                    <li>
                        <b>{params[0]}%</b> of your students are exposed to at least 1 cyber-risk including
                        online strangers, game addiction, cyberbullying, cyber victimisation, online sexual behaviour, and exposure
                        to violent content.
                    </li>
                    <li>
                        The top two cyber-risks that your students face are <b>{params[1]}</b> and <b>{params[2]}</b>.
                    </li>
                </>
            )
        default:
            return (
                <>
                    <b>Insight Unavailable</b>
                </>
            )
    }
}