import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import {Copyright} from '../../../components';

import { store } from 'react-notifications-component';
import { withRouter } from "react-router";

import { useFormik } from 'formik';

import {registerUser} from '../../../services/auth.service';
import { useAuth } from "../../../context/auth";
import Helmet from "react-helmet";
import {useLocation} from "react-router-dom";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const PreRegister = (props) => {
    const classes = useStyles();

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const registerAsCompanyAction = () => {
        props.history.push('/register_company');
    }

    const registerAsSchoolAction = () => {
        props.history.push('/register_school');
    }

    return (
        <Container component="main" maxWidth="xs">
            <Helmet title={`DQ-PRO | ${GET_APP_LOCALIZE_TEXT(pageLc, "auth__register")}`} />
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {GET_APP_LOCALIZE_TEXT(pageLc, "auth__sign_up")}
                </Typography>

                <br/>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={registerAsCompanyAction}
                >
                    {GET_APP_LOCALIZE_TEXT(pageLc, "auth__reg_as_company")}
                </Button>

                <br/>
                <br/>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={registerAsSchoolAction}
                >
                    {GET_APP_LOCALIZE_TEXT(pageLc, "auth__reg_as_school")}
                </Button>

                <br/>

                <Grid container justify="flex-end">
                    <Grid item>
                        <Link href="/login" variant="body2">
                            {GET_APP_LOCALIZE_TEXT(pageLc, "auth__already_have_acc")}
                        </Link>
                    </Grid>
                </Grid>

            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}

export default withRouter(PreRegister);