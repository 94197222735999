import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components/macro";

import {
    USER_ACCESS_LEVEL_VERBOSE,
} from "../../../utils/constants";
import DeleteIcon from "@material-ui/icons/Delete";
import {useAuth} from "../../../context/auth";

import {
    Add as AddIcon,
} from "@material-ui/icons";
import { Search as SearchIcon } from "react-feather";

import {
    Button,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TableContainer, InputBase, Tooltip, ClickAwayListener,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import {makeStyles, withStyles} from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {AccountServices} from "../../../services/organization.service";
import {toastWarning, toastSuccess, toastDanger, copyToClipboard} from "../../../utils/utils";
import {getPendingActivationsList, resendActivation, revokeLicense} from "../../../services/employees.service";
import {GET_APP_LOCALIZE_TEXT} from "../../../utils/dq_lc_service/lc_service";
import {useLocation} from "react-router-dom";
import SearchBar from "./Search.comp";
import EnhancedTableCell from "./EnhancedTableCell.comp";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#233044',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

const useStyles = makeStyles((theme)=>({
    tr_act: {

    },
    tr_d_act: {
        background: '#f8dede!important'
    },
    act_btn: {
        marginTop: '0px',
        marginLeft: '5px',
        background: '#489438',
        color: '#FFF',
        '&:hover': {
            background: "#66b066",
        },
    },
    d_act_btn: {
        marginTop: '0px',
        background: '#ee3434',
        color: '#FFF',
        '&:hover': {
            background: '#f55353',
        },
    },
    headerInputGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        margin: '10px 0px'
    },
    deleteBtn: {
        marginLeft: '6px',
        marginTop: '6px!important',
        color: '#ee3434',
        cursor: 'pointer',
        '&:hover': {
            color: '#f55353',
        }
    }
}));

const headCells = (locale) => [
    { id: "username", alignment: "center", label: GET_APP_LOCALIZE_TEXT(locale, "general__username"), sort: true },
    { id: "email", alignment: "center", label: GET_APP_LOCALIZE_TEXT(locale, "general__email"), sort: true },
    { id: "first_name", alignment: "center", label: GET_APP_LOCALIZE_TEXT(locale, "general__first_name"), sort: true },
    { id: "last_name", alignment: "center", label: GET_APP_LOCALIZE_TEXT(locale, "general__last_name"), sort: true },
    { id: "gender", alignment: "center", label: GET_APP_LOCALIZE_TEXT(locale, "general__gender"), sort: true },
    { id: "category_1", alignment: "center", label: GET_APP_LOCALIZE_TEXT(locale, "progress__class_name"), sort: true },
    { id: "category_2", alignment: "center", label: GET_APP_LOCALIZE_TEXT(locale, "progress__class_lvl"), sort: true },
    { id: "activation_link", alignment: "center", label: GET_APP_LOCALIZE_TEXT(locale, "activation__activation_link"), sort: false },
    { id: "actions", alignment: "center", label: GET_APP_LOCALIZE_TEXT(locale, "general__actions"), sort: false },
];

function ListProfilesActivations() {

    const classes = useStyles();

    const { authUser } = useAuth();
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const memoHeadCells = useMemo(() => headCells(pageLc), [])

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [recordCount, setRecordCount] = useState(0);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState(memoHeadCells[0].id);
    const [firstLoad, setFirstLoad] = useState(true);
    const [tooltipIndex, setTooltipIndex] = useState(-1);

    useEffect( () => {
        let refresh = false;
        if(firstLoad) {
            refresh = true;
            setFirstLoad(false);
        }
        loadPageData(refresh).then(d => console.log('table data loaded!', d));
    }, [page, rowsPerPage, order, orderBy, searchString]);

    const loadPageData = async (refresh) => {
        if(refresh) {
            setLoading(true);
        }
        try {

            const result = await getPendingActivationsList(authUser.org_code, page, rowsPerPage, order, orderBy, searchString);
            if (result.data && result.data.all_profiles) {
                console.log('result.data.all_profiles --> ', result.data.all_profiles);
                setPageData(result.data.all_profiles);
                setRecordCount(result.data.total_count);
            }

        } catch (e) {
            // error already toasted
        }

        setLoading(false);
    }

    const revokeAction = async (username) => {
        if(username && username.length > 0)
        {
            try {
                const result = await revokeLicense(authUser.org_code, username);
                if (result.data && result.data.message) {
                    toastSuccess(result.data.message)
                    await loadPageData();
                }
            } catch (e) {
                // error already toasted
            }
        }
        else
        {
            toastWarning(GET_APP_LOCALIZE_TEXT(pageLc, "activation__unable_revoke"));
        }
    }

    const resendAction = async (username) => {
        if(username && username.length > 0)
        {
            try {
                const result = await resendActivation(authUser.org_code, username);
                if (result.data && result.data.message) {
                    toastSuccess(result.data.message)
                }
            } catch (e) {
                // error already toasted
            }
        }
        else
        {
            toastWarning(GET_APP_LOCALIZE_TEXT(pageLc, "activation__no_username"));
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const resetTooltipIndex = () => {
        setTooltipIndex(-1);
    }

    const copyActivationUrl = (index, url) => {
        copyToClipboard(url)
            .then(() => setTooltipIndex(index))
            .catch(() => toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__unable_copy")));
    }

    return (
        <React.Fragment>
            <SearchBar placeholder={GET_APP_LOCALIZE_TEXT(pageLc, "activation__search_by_email")} callback={setSearchString} />
            <Card mb={6}>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {
                                        memoHeadCells.map((item) => (
                                            <EnhancedTableCell
                                                id={item.id}
                                                alignment={item.alignment}
                                                disablePadding={item.disablePadding}
                                                sort={item.sort}
                                                orderBy={orderBy}
                                                order={order}
                                                onRequestSort={handleRequestSort}
                                            >
                                                {item.label}
                                            </EnhancedTableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    pageData.map( (item, index) => (
                                        <CustomTableRow key={index} className={item.is_active ? classes.tr_act : classes.tr_d_act}>
                                            {/*<CustomTableCell component="th" scope="row">{rowsPerPage*(page) + index + 1}</CustomTableCell>*/}
                                            <CustomTableCell align="center">{item.username}</CustomTableCell>
                                            <CustomTableCell align="center">{item.email}</CustomTableCell>
                                            <CustomTableCell align="center">{item.first_name}</CustomTableCell>
                                            <CustomTableCell align="center">{item.last_name}</CustomTableCell>
                                            <CustomTableCell align="center">{item.gender}</CustomTableCell>
                                            <CustomTableCell align="center">{item.category_1}</CustomTableCell>
                                            <CustomTableCell align="center">{item.category_2}</CustomTableCell>
                                            <CustomTableCell align="center">
                                                {
                                                    item.connect_via_sso_only ? undefined : <ClickAwayListener onClickAway={resetTooltipIndex}>
                                                        <Tooltip
                                                            PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            open={tooltipIndex === index}
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            title={GET_APP_LOCALIZE_TEXT(pageLc, "general__copied")}
                                                            placement="top"
                                                            arrow
                                                        >
                                                            <Button
                                                                onClick={() => copyActivationUrl(index, item.activation_url)}
                                                                className={classes.act_btn}>{GET_APP_LOCALIZE_TEXT(pageLc, "general__copy")}
                                                            </Button>
                                                        </Tooltip>
                                                    </ClickAwayListener>
                                                }
                                            </CustomTableCell>
                                            <CustomTableCell align="center">
                                                <Button
                                                    onClick={ () => revokeAction(item.username) }
                                                        className={classes.d_act_btn}>{GET_APP_LOCALIZE_TEXT(pageLc, "activation__revoke")}
                                                </Button>
                                                {
                                                    item.connect_via_sso_only ? undefined : <Button
                                                        onClick={ () => resendAction(item.username) }
                                                        style={{
                                                            marginTop: '0px',
                                                            marginLeft: '5px',
                                                        }}
                                                        variant="contained"  color="primary">{GET_APP_LOCALIZE_TEXT(pageLc, "activation__resend_email")}
                                                    </Button>
                                                }
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={recordCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={`${GET_APP_LOCALIZE_TEXT(pageLc, 'general__rows_per_page')}:`}
                    />
                    <LoaderWithBackDrop loading={loading} />

                </Paper>
            </Card>

        </React.Fragment>

    );
}

export default ListProfilesActivations;