import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styled from "styled-components/macro";

import Helmet from "react-helmet";

import {
    Divider as MuiDivider,
    Typography,
    Checkbox,
    Grid,
    IconButton,
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableContainer,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    FormControlLabel,
    Switch,
} from "@material-ui/core";

import {
    Delete as DeleteIcon,
    FilterList as FilterListIcon,
} from "@material-ui/icons";

import { spacing } from "@material-ui/system";
import {makeStyles} from "@material-ui/core/styles";
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";
import {getNonOrgProfilesList} from "../../services/employees.service";
import {useAuth} from "../../context/auth";
import {azAZ} from "@material-ui/core/locale";
import {GET_APP_LOCALIZE_TEXT} from "../../utils/dq_lc_service/lc_service";


const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;

const useStyles = makeStyles((theme)=>({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center",
        marginTop: 10,
        marginBottom: 10
    },
}));



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    console.log('stableSort->array ==> ', array);
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = (locale) => [
    { id: "identifier", alignment: "left", label: `${GET_APP_LOCALIZE_TEXT(locale, 'profiles__identifier')}`, sort: false },
    { id: "first_name", numeric: false, disablePadding: false, label: `${GET_APP_LOCALIZE_TEXT(locale, 'profiles__first_name')}` },
    { id: "last_name", numeric: false, disablePadding: false, label: `${GET_APP_LOCALIZE_TEXT(locale, 'profiles__last_name')}`},
    { id: "country", numeric: false, disablePadding: false, label: `${GET_APP_LOCALIZE_TEXT(locale, 'profiles__country')}`},
    { id: "year_of_birth", numeric: false, disablePadding: false, label: `${GET_APP_LOCALIZE_TEXT(locale, 'profiles__year_of_birth')}` },
    { id: "gender", numeric: false, disablePadding: false, label: `${GET_APP_LOCALIZE_TEXT(locale, 'profiles__gender')}` },
    { id: "email", numeric: false, disablePadding: false, label: `${GET_APP_LOCALIZE_TEXT(locale, 'profiles__email')}` },
    { id: "org_code", numeric: false, disablePadding: false, label: `${GET_APP_LOCALIZE_TEXT(locale, 'profiles__org')}` },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
        locale
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells(locale).map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignment}
                        padding={headCell.disablePadding ? "none" : "default"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {
                            headCell.sort ?
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                </TableSortLabel>
                                : <Typography className={'MuiTableCell-head'}>{headCell.label}</Typography>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


function ProfilesList() {

    const { authUser } = useAuth();

    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [pageData, setPageData] = React.useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [recordCount, setRecordCount] = React.useState(0)

    useEffect( () => {
        loadProfilesList().then(d => console.log('profiles list data loaded!', d));
    }, [page, rowsPerPage]);

    const loadProfilesList = async () => {
        setLoading(true);

        try {

            const result = await getNonOrgProfilesList(authUser.org_code, page, rowsPerPage);
            if (result.data && result.data.all_profiles) {
                let profiles = [];
                for (let item of result.data.all_profiles) {
                    // item.sr_no = order_key;
                    item.identifier = '';
                    for (let i=1; i<item._id.length; i+=2) {
                        item.identifier += item._id[i];
                    }
                    item.name = `${item.first_name} ${item.last_name}`
                    profiles.push(item);
                    // order_key++;
                }
                setPageData(profiles);
                setRecordCount(result.data.total_count);
            }

        } catch (e) {
            console.log('api::getEmployeesList -> Error ==> ', e);
            // error already toasted
        }

        setLoading(false);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, pageData.length - page * rowsPerPage);

    return (
        <React.Fragment>
            <Helmet title="DQ-PRO | Profiles" />
            <Typography variant="h3" gutterBottom display="inline">
                {GET_APP_LOCALIZE_TEXT(pageLc, 'profiles__profile_list')}
            </Typography>


            <Divider my={6} />

            <Grid container spacing={6}>
                <Grid item xs={12}>

                    {pageData && <Paper>
                        <TableContainer>
                            <Table
                                aria-labelledby="tableTitle"
                                size={"small"}
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                    rowCount={pageData.length}
                                    locale={pageLc}
                                />
                                <TableBody>
                                    {
                                        pageData.map((row, index) => {
                                            const isItemSelected = false;
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row._id}
                                                >
                                                    <TableCell align="left">{row.identifier}</TableCell>
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                    >
                                                        {row.first_name}
                                                    </TableCell>
                                                    <TableCell align="left">{row.last_name}</TableCell>
                                                    <TableCell align="left">{row.country}</TableCell>
                                                    <TableCell align="left">{row.year_of_birth}</TableCell>
                                                    <TableCell align="left">{row.gender}</TableCell>
                                                    <TableCell align="left">{row.email}</TableCell>
                                                    <TableCell align="left">{row.org_code}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    {/*{emptyRows > 0 && (*/}
                                    {/*    <TableRow style={{ height: (33) * emptyRows }}>*/}
                                    {/*        <TableCell colSpan={6} />*/}
                                    {/*    </TableRow>*/}
                                    {/*)}*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100]}
                            component="div"
                            count={recordCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={`${GET_APP_LOCALIZE_TEXT(pageLc, 'profiles__rows_per_page')}`}
                        />
                    </Paper>}

                </Grid>
            </Grid>

            <LoaderWithBackDrop loading={loading} />

        </React.Fragment>
    );
}

export default ProfilesList;
