import React, {useEffect, useState} from "react";
import styled from "styled-components/macro";
import { useAuth } from "../../../../../context/auth"
import {getCyberRisksData, getDigitalSkillsData} from "../../../../../services/partner.service";
import LoaderWithBackDrop from "../../../../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

import {
    Paper,
    Tabs,
    Tab,
    Box,
    Grid,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Chip as MuiChip,
    Typography as MuiTypography,
    Icon, makeStyles, Button as MuiButton,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import {SidePanel} from "../../../../../components/SidePanel/SidePanel.comp";
import {Badge} from "../Badge.comp";
import LineChart from "../LineChart";
import {CustomSelect} from "../Select.comp";
import {X} from "react-feather";
import {
    createLcKey,
    getCountriesAverage,
    predefinedSort,
    splitCountries,
    toastDanger
} from "../../../../../utils/utils";
import {useLocation} from "react-router-dom";
import {
    GET_APP_LOCALIZE_TEXT,
    GET_BASELINING_LOCALIZE_TEXT,
    MISSING_TRANSLATION_TEXT
} from "../../../../../utils/dq_lc_service/lc_service";

const useStyles = makeStyles(theme => ({
    stretch: { height: "100%" },
    item: { display: "flex", flexDirection: "column" } // KEY CHANGES
}));

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`;

const ColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
`

const Spacer = styled.div(spacing);

const DefaultColouredTypography = styled(Typography)`
  color: ${(props) => props.color ? props.color : props.theme.sidebar.background};
  font-size: ${(props) => props.size ? props.size : "default"};
  font-family: "Lemon/Milk";
`



const Bold = (props) => <Box component={'span'} fontWeight="fontWeightBold" {...props}></Box>
const Italicize = (props) => <Box component={'span'} fontStyle="italic" {...props}></Box>
const Font = (props) => <Box component={'span'} fontFamily={props.font} {...props}></Box>

const risk_reverse_translations = {};
const cat_reverse_translations = {};

function CyberRisksTab() {

    const {authUser} = useAuth();
    const {org_country} = authUser.org_obj;
    const location = useLocation();
    const pageLc = location.pathname.split('/')[1];

    const [loading, setLoading] = useState(false);
    const [pageData, setPageData] = useState(undefined);

    const [defaultCountries, setDefaultCountries] = useState([]);
    const [additionalCountries, setAdditionalCountries] = useState([]);
    const [risks, setRisks] = useState([]);

    const [selectedRisks, setSelectedRisks] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([])
    const [errorMessage, setErrorMessage] = useState('');

    const selectAllRisksOption = GET_BASELINING_LOCALIZE_TEXT(pageLc, `cyber_risk__overall`);
    const primarySideHeader = GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_country")
    const secondarySideHeader= GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__select_cyber_risk")

    const get_risk_alias = (code) => {
        let alias = GET_BASELINING_LOCALIZE_TEXT(pageLc, `cyber_risk__${code}`);
        if(alias === MISSING_TRANSLATION_TEXT) alias = code;
        risk_reverse_translations[alias] = code;
        return alias;
    }

    const get_risk_reverse_alias = (alias) => {
        if(risk_reverse_translations[alias]) return risk_reverse_translations[alias];
        return alias;
    }

    const get_category_alias = (category) => {
        const cat_trans = GET_APP_LOCALIZE_TEXT(pageLc, `baselining__${createLcKey(category)}`, null);
        const country_trans = GET_APP_LOCALIZE_TEXT(pageLc, `dq_country__${createLcKey(category)}`, null);
        const translation = cat_trans || country_trans || category;
        cat_reverse_translations[translation] = category;
        return translation;
    }

    const get_category_reverse_alias = (alias) => {
        const reverse_alias = cat_reverse_translations[alias];
        return reverse_alias || alias;
    }

    const addItemToState = (setState, item, sort = false) => {
        if(sort)
            setState((prevState) => ([...prevState, item].sort()));
        else
            setState((prevState) => ([...prevState, item]));
    }

    const removeItemFromState = (setState, item) => {
        setState((prevState) => {
            const index = prevState.indexOf(item);
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp;
        });
    }

    useEffect(() => {
        loadPageData().then(d => console.log('Cyber Risks Data Fetched!', d));
    }, []);


    function initializeData(data) {
        data.risks.sort(predefinedSort(["expe_CyberRisks"]))
        data.countries.sort();
        const first_risk = data.risks[0]
        if(first_risk) setSelectedRisks([first_risk]);
        setSelectedCategories(["Global", "National"])
        const countriesSubList = splitCountries(data.countries, data.default_countries && data.default_countries.length > 0 ? data.default_countries : [org_country]);
        setDefaultCountries(countriesSubList[0])
        setAdditionalCountries(countriesSubList[1]);
        setSelectedCountries([countriesSubList[0][0]])
        setRisks(data.risks);
        setPageData(data);
    }

    const loadPageData = async () => {
        setLoading(true);
        try {
            const result = await getCyberRisksData(authUser.org_code);
            if (result.data) {
                console.log('Result Data of Cyber Risks --> ', result.data);
                initializeData(result.data)
            }
            else {
                setErrorMessage(GET_APP_LOCALIZE_TEXT(pageLc, "general__data_unavailable"));
            }

        } catch (e) {
            console.log("Some error occurred while fetching cyber risks data")
        }
        setTimeout(() => {setLoading(false)}, 200);
    }

    const badge_data = [
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__nation_avg"),
            value: pageData && selectedRisks.length > 0 ? selectedRisks.reduce((total, current) => {
                return parseFloat(total) + parseFloat(pageData.data[current].national_data.average);
            }, 0.0) / selectedRisks.length : 0,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__countries_avg"),
            value:
                pageData && selectedRisks.length > 0 && selectedCountries.length > 0 ?
                    getCountriesAverage(selectedCountries, selectedCountries.map(
                            (country) => selectedRisks.reduce(
                                (total, current) => {
                                    return parseFloat(total) + parseFloat(pageData.data[current].countries_data[country] ? pageData.data[current].countries_data[country].average : 0);
                                }, 0.0) / selectedRisks.length
                        )
                    ) : 0,
        },
        {
            title: GET_APP_LOCALIZE_TEXT(pageLc, "baselining__glob_avg"),
            value: pageData && selectedRisks.length > 0 ? selectedRisks.reduce((total, current) => {
                return parseFloat(total) + parseFloat(pageData.data[current].global_data.average);
            }, 0.0) / selectedRisks.length : 0,
        }
    ]

    function onAddCountry(event, setState) {
        const aliasedItem = event.target.value;
        if(selectedCategories.concat(defaultCountries).length === 11)
        {
            toastDanger(GET_APP_LOCALIZE_TEXT(pageLc, "general__error"), GET_APP_LOCALIZE_TEXT(pageLc,"baselining__max_added_error"));
        }
        else
        {
            const country = get_category_reverse_alias(aliasedItem);
            removeItemFromState(setAdditionalCountries, country)
            addItemToState(setDefaultCountries, country)
            onSelectCountry(aliasedItem, true)

            setState((prevState) => ({
                ...prevState,
                [aliasedItem]: true,
            }));
        }
    }

    function onRemoveCountry(aliasedItem, setState) {

        const country = get_category_reverse_alias(aliasedItem);
        removeItemFromState(setDefaultCountries, country)
        addItemToState(setAdditionalCountries, country, true)
        onSelectCountry(aliasedItem, false)

        setState((prevState) => ({
            ...prevState,
            [aliasedItem]: false,
        }));
    }

    function onSelectCountry(aliasedItem, isChecked) {
        const item = get_category_reverse_alias(aliasedItem);
        if(isChecked) {
            if(!selectedCountries.includes(item)) {
                addItemToState(setSelectedCountries, item)
            }
        }
        else
        {
            if(selectedCountries.includes(item)) {
                removeItemFromState(setSelectedCountries, item)
            }
        }
    }

    function onSelectIndicator(aliasedItem, isChecked, state, setState) {
        const item = get_risk_reverse_alias(aliasedItem);
        if(isChecked) {
            setSelectedRisks([item])
            setState(Object.fromEntries(risks.map((risk) => get_risk_alias(risk)).map(
                (risk) => risk === aliasedItem ? [risk, true] : [risk, false]
            )));
        }
        else
        {
            setState((prevState) => ({
                ...prevState,
                [aliasedItem]: true,
            }));
        }
    }

    const lineRef = React.createRef();

    function sumSelectedRisksAnnualScores(category, country = "")
    {
        let temp = []
        selectedRisks.forEach((risk) => {
            let data = undefined;
            const objs = []
            //Objects Initialization
            switch(category) {
                case "Global":
                    data = pageData.data[risk].global_data;
                    break;
                case "National":
                    data = pageData.data[risk].national_data;
                    break;
                case "Country":
                    data = pageData.data[risk].countries_data[country];
            }
            if(data && data.annual_scores)
            {
                objs.push(...data.annual_scores)
            }
            //Adding Count: 1 to Each Object
            if(temp.length == 0)
            {
                objs.forEach((obj) => temp.push({count: 1, ...obj}))
            }
            else
            {
                objs.forEach((obj) => {
                    const index = temp.findIndex((element) => element.year+'' === obj.year+'')
                    if(index === -1) {
                        temp.push({count: 1, ...obj})
                    } else {
                        const new_val = Number(parseFloat(temp[index].val) + parseFloat(obj.val))
                        const old_year = temp[index].year;
                        const old_count = temp[index].count;
                        temp.splice(index, 1, {year: old_year, val: new_val, count: old_count+1});
                    }
                })
            }
        })
        return temp;
    }

    let years = []
    const summed_scores = {};

    if(pageData)
    {
        if(pageData)
        {
            //Data Sum For Annual Scores of Countries
            selectedCountries.forEach((country) => {
                summed_scores[country] = {}
                const temp = sumSelectedRisksAnnualScores("Country", country)
                summed_scores[country].annual_scores = temp.map((obj) => {
                    return {
                        year: obj.year,
                        val: Number((obj.val / obj.count).toFixed(2))
                    }
                })
            })
            //Data Sum For Annual Scores of Program, Global and National
            selectedCategories.forEach((category) => {
                summed_scores[category] = {}
                const temp = sumSelectedRisksAnnualScores(category)
                summed_scores[category].annual_scores = temp.map((obj) => {
                    return {
                        year: obj.year,
                        val: Number((obj.val / obj.count).toFixed(2))
                    }
                })
            })

            //Getting Years to Display on Line Chart Depending on Available Years from Summed_Scores
            years = Object.keys(summed_scores).map((key) => summed_scores[key].annual_scores).reduce(
                (final, current) => {
                    const items_to_add = current.filter((item) => !final.includes(item.year)).map((item) => item.year)
                    return final.concat(items_to_add);
                }, [])
            years.sort(function(a, b){return a - b})
        }
    }

    const indicator_header = selectedRisks.length === risks.length ? selectAllRisksOption : (selectedRisks.length === 0 ? GET_BASELINING_LOCALIZE_TEXT(pageLc, `cyber_risk__none`) : selectedRisks.map((risk) => get_risk_alias(risk)));

    return (
        pageData ?
            <Paper container>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3}>
                        <SidePanel
                            primaryHeader={primarySideHeader}
                            secondaryHeader={secondarySideHeader}
                            primaryData={defaultCountries.map((country) => get_category_alias(country))}
                            secondaryData={risks.map((risk) => get_risk_alias(risk))}
                            onCheckPrimary={onSelectCountry}
                            onCheckSecondary={onSelectIndicator}
                            primaryColourize={true}
                            primaryColourizeIndex={selectedCategories.length}
                            // primaryFooter={CustomSelect}
                            // primaryFooterData={{
                            //     title: GET_APP_LOCALIZE_TEXT(pageLc, "side_panel__add_country"),
                            //     data: additionalCountries.map((country) => get_category_alias(country)),
                            // }}
                            // primaryFooterCallback={onAddCountry}
                            // primaryAction={X}
                            // primaryActionData={{style: {cursor: "pointer"}}}
                            // primaryActionCallback={onRemoveCountry}
                            secondarySingleSelectIndex={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card>
                            <CardContent>
                                <Box sx={{flexGrow: 1}} align={"right"}>
                                    <ColouredTypography variant={"body1"} size={"16px"} color={"grey"} align={"center"} alignText={"justify"} py={10}>
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__cyber_risk_desc")}
                                    </ColouredTypography>
                                    <ColouredTypography mt={5} mb={5} variant={"h4"} align={"center"}>
                                        {indicator_header} {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__in_past_year")}
                                    </ColouredTypography>
                                    <Grid container spacing={0} align={"center"}>
                                        {badge_data.map((badge) => (
                                                <Grid item xs={6} sm={6} md={6} lg={12/badge_data.length}>
                                                    <Badge
                                                        title={badge.title}
                                                        value={`${badge.value.toFixed(2)}%`}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    <Spacer mb={5}/>
                                    <Grid container spacing={6} justifyContent={"space-between"}>
                                        <Grid item xs={12}>
                                            <ColouredTypography align={"center"} variant={"h4"}>
                                                {GET_APP_LOCALIZE_TEXT(pageLc, "baselining__annual_trend")}
                                            </ColouredTypography>
                                            <Spacer mb={10}/>
                                            <LineChart
                                                ref={lineRef}
                                                data={{
                                                    labels: [...years],
                                                    graphData: [...selectedCategories.concat(selectedCountries.filter((country) => country !== org_country)).map((country) => {
                                                        const annual_scores_objs = []
                                                        if(summed_scores[country])
                                                        {
                                                            annual_scores_objs.push(...summed_scores[country].annual_scores)
                                                        }
                                                        const annual_scores_years = annual_scores_objs.map((obj) => obj.year)
                                                        const score_values = years.map((year) => {
                                                            const index = annual_scores_years.indexOf(year)
                                                            if(index === -1)
                                                                return undefined;
                                                            else
                                                                return annual_scores_objs[index].val ? annual_scores_objs[index].val : 0;
                                                        })
                                                        return {
                                                            index: [...selectedCategories.concat(defaultCountries)].indexOf(country),
                                                            country: get_category_alias(country),
                                                            data: [...score_values],
                                                            opacity: selectedCategories.includes(country) ? 1 : selectedCountries.includes(country) ? 0.3 : 0,
                                                            show: selectedCategories.includes(country) ? true : selectedCountries.includes(country) ? true : false,
                                                        }
                                                    })]
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Button mt={5} variant="contained">
                                        {GET_APP_LOCALIZE_TEXT(pageLc, "general__download_data")}
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
            :
            <Paper container>
                <DefaultColouredTypography variant={"h3"} align={'center'} py={5}>{errorMessage}</DefaultColouredTypography>
                <LoaderWithBackDrop loading={loading}/>
            </Paper>
    );
}

export default CyberRisksTab;