import {isNUSOrg} from "../../../../utils/utils";

export function postProcessSelectors(selectors, country, org_code = '')
{
    if (isNUSOrg(org_code))
    {
        const {states, postal_codes, institute_codes, modules, years} = selectors;
        const local_states = ["All"];
        const local_postal_codes = {"All": ["All"]};
        const local_institute_codes = {"All": {"All": ["All"]}};
        const local_modules = {"All": {"All": {"All": ["All"]}}};
        const local_years = years[country] ? years[country] : [];

        return {
            states: local_states,
            postal_codes: local_postal_codes,
            institute_codes: local_institute_codes,
            modules: local_modules,
            years: local_years
        };
    }
    else {
        const {states, postal_codes, institute_codes, modules, years} = selectors;
        let local_states = []
        if (states[country] && Array.isArray(states[country])) {
            local_states = ["All", ...states[country]];
        } else {
            local_states = ["All"];
        }
        const local_postal_codes = {"All": ["All"]};
        const local_institute_codes = {"All": {"All": ["All"]}};
        const local_modules = {"All": {"All": {"All": ["All"]}}};
        const local_years = years[country] ? years[country] : [];
        if(states[country] && postal_codes[country]) {
            for (let state of states[country]) {
                local_postal_codes[state] = ["All", ...postal_codes[country][state]]
                local_postal_codes["All"].push(...postal_codes[country][state])

                local_institute_codes[state] = {"All": ["All"]}
                local_modules[state] = {"All": {"All": ["All"]}}

                for (let postal_code in institute_codes[country][state]) {
                    const codes = institute_codes[country][state][postal_code]
                    local_institute_codes[state][postal_code] = ["All", ...codes]
                    local_institute_codes[state]["All"] = [...new Set([...local_institute_codes[state]["All"], ...codes])];
                    local_institute_codes["All"][postal_code] = ["All", ...codes]
                    local_institute_codes["All"]["All"] = [...new Set([...local_institute_codes["All"]["All"], ...codes])];

                    local_modules[state][postal_code] = {"All": ["All"]}
                    local_modules["All"][postal_code] = {"All": ["All"]}

                    for (let code of codes) {
                        const mods = modules[country][state][code];

                        local_modules[state][postal_code][code] = ["All", ...mods];
                        local_modules[state][postal_code]["All"] = [...new Set([...local_modules[state][postal_code]["All"], ...mods])]

                        local_modules[state]["All"][code] = ["All", ...mods];
                        local_modules[state]["All"]["All"] = [...new Set([...local_modules[state]["All"]["All"], ...mods])]

                        local_modules["All"][postal_code][code] = ["All", ...mods];
                        local_modules["All"][postal_code]["All"] = [...new Set([...local_modules["All"][postal_code]["All"], ...mods])]

                        local_modules["All"]["All"][code] = ["All", ...mods];
                        local_modules["All"]["All"]["All"] = [...new Set([...local_modules["All"]["All"]["All"], ...mods])]
                    }
                }
            }
        }

        return {
            states: local_states,
            postal_codes: local_postal_codes,
            institute_codes: local_institute_codes,
            modules: local_modules,
            years: local_years
        };
    }
}
